import { memo, useEffect, useMemo, useState } from 'react';
import { useStyles } from 'uinix-ui';

import { useEscape } from '../../hooks';
import { SharedPanelProps, UserAction } from '../../types';
import { Backdrop } from '../Backdrop';
import { Box } from '../Box';
import { Button } from '../Button';
import { ContentContainer } from '../ContentContainer';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Layout } from '../Layout';
import { Tabs } from '../Tabs';
import { basePanelWrapperStyle, getPanelPosition } from './utils';

export const Panel = memo(
  ({
    actions = [],
    animate,
    backAction,
    children,
    chips,
    enableBackdrop = false,
    enableContentPadding = true,
    enableMaximize = false,
    footer: footerProps,
    hidden,
    icon,
    loadingContent,
    mode,
    moreActions,
    moreActionsPosition,
    placeholderContent,
    position,
    sideContent,
    tabs,
    title,
    width: initialWidth = '100%',
    welcomeContent,
  }: SharedPanelProps) => {
    const styles = useStyles();

    const isSideContentVisible = sideContent?.isVisible ?? false;

    const toggleAction = sideContent
      ? {
          ...sideContent.action,
        }
      : undefined;

    const [width, setWidth] = useState(initialWidth);

    useEffect(() => {
      setWidth(initialWidth);
    }, [initialWidth]);

    const handleHide = () => hidden?.onHide?.();

    useEscape(handleHide, Boolean(hidden && hidden.onHide && !hidden.isHidden));

    const isLoading = loadingContent?.isLoading;
    const isMaximized = width === '100%';
    const shouldShowCloseButton = mode === 'fullscreen' || mode === 'floating';

    const isWidthToken = initialWidth === 'm' || initialWidth === 'l';

    const headerActions = useMemo(() => {
      const _headerActions: UserAction[] = actions.map((action) => ({
        disabled: isLoading || action.disabled,
        mode: 'icon' as const,
        ...action,
      }));

      // only support this action if it is enabled explicitly, and an explicit non-fill `initialWidth` is provided (i.e. `m`, `l`)
      if (enableMaximize && initialWidth !== '100%') {
        _headerActions.push({
          icon: isMaximized ? 'minimize' : 'maximize',
          mode: 'icon',
          text: isMaximized ? 'Minimize' : 'Maximize',
          onClick: () => setWidth(isMaximized ? initialWidth : '100%'),
        });
      }

      if (shouldShowCloseButton) {
        _headerActions.push({
          icon: 'x',
          text: 'Close',
          mode: 'icon',
          onClick: handleHide,
        });
      }

      return _headerActions;
    }, [actions, initialWidth, isMaximized, loadingContent, hidden]);

    const hiddenAction = useMemo(() => {
      if (!hidden || shouldShowCloseButton) {
        return null;
      }
      const { isHidden = false, placement, onHide, onShow } = hidden;
      if (!isHidden && onHide) {
        return (
          <Button
            mode="area"
            icon={placement === 'right' ? 'collapse-left' : 'collapse-right'}
            label="Hide"
            tooltip="Hide"
            onClick={handleHide}
          />
        );
      } else if (isHidden && onShow) {
        return (
          <Button
            mode="area"
            icon={placement === 'right' ? 'collapse-right' : 'collapse-left'}
            label="Show"
            tooltip="Show"
            onClick={onShow}
          />
        );
      } else {
        return null;
      }
    }, [hidden]);

    const enableHiddenContent = hidden?.isHidden && hidden?.content;
    const enablePosition =
      Boolean(position) || mode === 'floating' || mode === 'fullscreen';
    const header =
      !enableHiddenContent && title ? (
        <Header
          actions={headerActions}
          backAction={backAction}
          toggleAction={toggleAction}
          chips={chips}
          icon={icon}
          iconPosition={icon?.position}
          moreActions={moreActions}
          moreActionsPosition={moreActionsPosition}
          title={title}
        />
      ) : undefined;

    const _footer = footerProps && <Footer {...footerProps} />;

    const footer =
      _footer && mode === 'fullscreen' ? (
        // @ts-ignore -- uinix type limitation, valid usage
        <Box styles={[componentStyles.contentContainer]} styleProps={{ mode }}>
          {_footer}
        </Box>
      ) : (
        _footer
      );
    const p = enableContentPadding ? 4 : 0;
    const w = enableHiddenContent
      ? 'fit-content'
      : width === '100%' || isSideContentVisible
      ? '100%'
      : isWidthToken
      ? `panel.width.${width}`
      : width;

    const mainContentElement = (
      <Layout direction="column" h="100%" minH={0} w="100%">
        {enableHiddenContent ? (
          hidden?.content
        ) : (
          <Box
            h="100%"
            overflowY="auto"
            p={p}
            // @ts-ignore -- uinix type limitation, valid usage
            styles={[componentStyles.contentContainer]}
            styleProps={{ mode, width: initialWidth }}
          >
            <ContentContainer
              loadingContent={loadingContent}
              placeholderContent={placeholderContent}
              welcomeContent={welcomeContent}
            >
              <>
                {tabs && <Tabs enableBorder {...tabs} />}
                {children}
              </>
            </ContentContainer>
          </Box>
        )}
        {!enableHiddenContent && footer}
        {hiddenAction && (
          <Box
            // @ts-ignore -- uinix type limitation, valid usage
            styles={componentStyles.hiddenAction}
            styleProps={{
              placement: hidden?.placement,
            }}
          >
            {hiddenAction}
          </Box>
        )}
      </Layout>
    );

    const sideContentElement = sideContent && isSideContentVisible && (
      <>
        <Layout
          direction="column"
          // @ts-ignore -- uinix type limitation, valid usage
          styles={componentStyles.sideContent}
        >
          <ContentContainer
            loadingContent={sideContent.loadingContent}
            placeholderContent={sideContent.placeholderContent}
            welcomeContent={sideContent.welcomeContent}
          >
            <>
              {sideContent.tabs && (
                <Tabs panelHeight="100%" {...sideContent.tabs} />
              )}
              {sideContent.content}
            </>
          </ContentContainer>
        </Layout>
      </>
    );

    const panelComponent = (
      <Layout
        as="aside"
        direction="column"
        flex="none"
        justify="space-between"
        styles={[
          animate ? styles.animations.slideInRight : null,
          componentStyles.container,
        ]}
        styleProps={{ mode }}
        w={mode !== 'fullscreen' ? w : undefined}
      >
        {!enableHiddenContent && header}
        <Layout
          h="100%"
          minH={0}
          styles={[styles.background.image(welcomeContent?.backgroundURL)]}
        >
          {sideContentElement}
          {mainContentElement}
        </Layout>
      </Layout>
    );

    let panel = enablePosition ? (
      <Box
        // @ts-ignore -- uinix type limitation, valid usage
        styles={[
          basePanelWrapperStyle,
          styles.position,
          mode === 'fullscreen' ? styles.size.fullscreen : undefined,
        ]}
        styleProps={getPanelPosition({ mode, position })}
      >
        {panelComponent}
      </Box>
    ) : (
      panelComponent
    );

    panel = enableBackdrop ? <Backdrop>{panel}</Backdrop> : panel;

    return panel;
  },
);

const componentStyles = {
  container: ({ mode }: { mode: SharedPanelProps['mode'] }) => ({
    backgroundColor: 'background',
    border: 'border.divider',
    boxSizing: 'content-box',
    height: '100%',
    marginTop: '-1px', // panel is usually embedded in bordered layouts, this makes it more convenient for UI composition
    position: 'relative',
    borderRadius: mode === 'floating' ? 'm' : undefined,
  }),
  contentContainer: ({
    mode,
    width,
  }: {
    mode: SharedPanelProps['mode'];
    width: SharedPanelProps['width'];
  }) =>
    mode === 'fullscreen'
      ? {
          width: 'panel.width.l',
          margin: '0 auto',
        }
      : { width },
  sideContent: ({ placement = 'left' }: { placement: 'left' | 'right' }) => {
    const isLeft = placement === 'left';
    return {
      width: '100%',
      border: 'border.divider',
      borderLeftColor: !isLeft ? undefined : 'background',
      borderRightColor: !isLeft ? 'background' : undefined,
      borderBottomWidth: 0,
      borderTopWidth: 0,
    };
  },
  hiddenAction: ({ placement = 'left' }: { placement?: 'left' | 'right' }) => {
    const isLeft = placement === 'left';
    return {
      backgroundColor: 'background',
      border: 'border.divider',
      borderLeftColor: isLeft ? undefined : 'background',
      borderRightColor: isLeft ? 'background' : undefined,
      borderBottomLeftRadius: isLeft ? 'm' : undefined,
      borderBottomRightRadius: isLeft ? undefined : 'm',
      borderTopLeftRadius: isLeft ? 'm' : undefined,
      borderTopRightRadius: isLeft ? undefined : 'm',
      left: isLeft ? 0 : undefined,
      marginTop: '-1px', // offset border
      position: 'absolute',
      right: isLeft ? undefined : 0,
      top: 12,
      transform: `translateX(${isLeft ? '-' : ''}100%)`,
      width: '36px',
      '& button': {
        alignItems: 'center',
        justifyContent: 'center',
      },
    };
  },
};
