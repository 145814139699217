import React from 'react';

import { PAGE_SIZE } from '~/constants/page';
import { Box, Info, Link, Panel, Table, Text } from '~/eds';
import { api } from '~/redux';

import { AribaError, ConfigurationPanelProps } from './types';
import { useConfigurationInfos } from './useConfigurationInfos';

const { useState } = React;

export const ConfigurationDetailPanel = ({
  config,
  tab,
  onHide,
}: ConfigurationPanelProps) => {
  const {
    id,
    allowedSyncFileTypes,
    createdBy,
    dateCreated,
    dateUpdated,
    evisortFolderPath,
    provider,
    providerOptions,
  } = config;

  type PaginationParams = {
    pageIndex: number;
  };

  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(tab);

  const {
    data: errors,
    isLoading,
  } = api.endpoints.getSyncPairsAribaFailures.useQuery({
    syncPair: id,
  });

  const columns = [
    {
      title: 'Document Id',
      key: 'id',
      cellType: 'link',
      renderCell: (data: AribaError) => {
        return (
          <Link href={`${data.aribaUrl}?ID=${data.id}&realm=${data.realm}`}>
            {data.id}
          </Link>
        );
      },
    },
    {
      title: 'Evisort Id',
      key: 'evisortId',
      cellType: 'link',
      renderCell: (data: AribaError) => {
        if (data.evisortId) {
          return (
            <Link pathname={`/document/${data.evisortId}`} enableNewTab={true}>
              {data.evisortId}
            </Link>
          );
        } else {
          return <Text>No document exists</Text>;
        }
      },
    },
    {
      title: 'Reason',
      key: 'status',
      cellType: 'text',
      mapCellProps: (data: AribaError) => ({
        text: data.status,
      }),
    },
    {
      title: 'Timestamp',
      key: 'timestamp',
      cellType: 'datetime',
      mapCellProps: (data: AribaError) => ({
        datetime: data.timestamp,
        format: 'iso',
      }),
    },
  ];

  const tabs = {
    selectedTab: activeTab,
    onSelectTab: setActiveTab,
    tabs: [
      {
        label: 'Configuration',
        value: 'config',
        panel: (
          <Box>
            <Info
              sections={[
                {
                  title: '',
                  infos: useConfigurationInfos({
                    id,
                    providerOptions,
                    createdBy,
                    evisortFolderPath,
                    allowedSyncFileTypes,
                    dateCreated,
                    dateUpdated,
                    provider,
                  }),
                },
              ]}
            />
          </Box>
        ),
      },
      {
        label: 'Error Log',
        value: 'errors',
        count: errors?.count,
        panel: (
          <Box sx={{ flex: 'auto' }}>
            <Table
              name="errors"
              columns={columns}
              data={
                errors?.results.slice(
                  (page - 1) * PAGE_SIZE,
                  page * PAGE_SIZE,
                ) || []
              }
              totalCount={errors?.count}
              isLoading={isLoading}
              options={{
                enableExportXlsx: false,
                enableManageColumns: false,
                enableSelectRows: false,
              }}
              state={{ pageIndex: page, pageSize: PAGE_SIZE }}
              onPaginate={({ pageIndex }: PaginationParams) => {
                setPage(pageIndex);
              }}
            />
          </Box>
        ),
      },
    ],
  };

  return (
    <Panel
      hidden={{
        placement: 'left',
        onHide,
      }}
      position="right"
      title={`${evisortFolderPath} - Configuration`}
      width="l"
      tabs={tabs}
    ></Panel>
  );
};
