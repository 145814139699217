import { useCallback, useEffect, useMemo, useState } from 'react';

import { FlagType, useFlag } from '~/flags';
import { checkPermissions } from '~/permissions';
import { RbacPermission } from '~/types';

type Props = {
  permissions?: RbacPermission[];
};

export const usePermissions = ({ permissions = [] }: Props) => {
  const [permissionsResult, setPermissionsResult] = useState<
    Record<string, Record<string, boolean>>
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const hasNewUsersPage = useFlag(FlagType.NewUsersPage);

  const retrievePermissions = async () => {
    if (hasNewUsersPage) {
      const permissionsResult = await checkPermissions(permissions);
      setPermissionsResult(permissionsResult);
    } else {
      for (const permission of permissions) {
        setPermissionsResult((prevState) => {
          const permissionsResult = {
            ...prevState,
            [permission.resourceId]: {
              ...(prevState[permission.resourceId] ?? {}),
              [permission.resourceType]: true,
            },
          };
          return permissionsResult;
        });
      }
    }
    setIsLoading(false);
  };

  const checkPermission = useCallback(
    (resourceId: string, resourceType: string) => {
      return permissionsResult[resourceId]?.[resourceType] ?? false;
    },
    [permissionsResult],
  );

  const hasSomePermissions = useMemo(() => {
    if (!permissions || !permissions?.length) return true;
    for (const permission of permissions) {
      if (checkPermission(permission.resourceId, permission.resourceType)) {
        return true;
      }
    }

    return false;
  }, [permissions, permissionsResult]);

  useEffect(() => {
    if (permissions && permissions?.length) {
      retrievePermissions();
    } else {
      setIsLoading(false);
    }
  }, [permissions]);

  return {
    hasSomePermissions,
    isLoading,
    checkPermission,
  };
};
