import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '~/eds';
import { slices } from '~/redux';

export const ModelPreview = () => {
  const dispatch = useDispatch();
  const model = useSelector(slices.xRayLibrary.selectors.selectModelPreview);

  return (
    <Modal
      isVisible={Boolean(model)}
      title={model?.label ?? ''}
      onCancel={() =>
        dispatch(slices.xRayLibrary.actions.setModelPreview(null))
      }
    >
      <div>TODO [EKP-29378] Model Preview</div>
    </Modal>
  );
};
