import { Filters } from '~/evifields';
import { api } from '~/redux';
import { Nullable } from '~/types';

import { LibraryModelVersion, Tag } from './types';

export const useGetLatestLibraryModelVersions = ({
  category,
  filters,
}: {
  category?: Nullable<Tag>;
  filters?: Filters;
}): LibraryModelVersion[] => {
  const { data: models = [] } = api.endpoints.getLibraryModels.useQuery({
    page_size: 500,
  });

  const latestModelVersions = models.flatMap((model) =>
    model.versions.filter((version) => version.latest),
  );

  if (category) {
    const categoryModelVersions = latestModelVersions.filter((version) =>
      version.tags.includes(category),
    );
    return categoryModelVersions;
  }

  if (filters) {
    // TODO [EKP-29371]: Model Library Filters
  }

  return latestModelVersions;
};
