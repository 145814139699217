import { capitalize } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CodeSample, copy, Markdown, Modal, useToast } from '~/eds';
import { api, slices } from '~/redux';
import { Nullable } from '~/types';

import { EndpointConfiguration } from '../Configuration/EndpointConfiguration';
import {
  ConfigurationType,
  Endpoint,
  EndpointType,
  SamplePayloadsType,
} from '../types';
import {
  getDisabledCopyTooltip,
  getDisabledTooltip,
  getEventSamplePayload,
} from '../utils';

interface Props {
  action: Nullable<EndpointType>;
  endpoint?: Nullable<Endpoint>;
  sampleEvent?: Nullable<string>;
  configuration?: Nullable<ConfigurationType>;
  isLoading?: boolean;
  samplePayloads?: SamplePayloadsType;
}

export const EndpointModal = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();

  const [
    createEndpoint,
    { isLoading: isCreatingEndpoint },
  ] = api.endpoints.createEndpoint.useMutation();

  const [
    deleteEndpoint,
    { isLoading: isDeletingEndpoint },
  ] = api.endpoints.deleteEndpoint.useMutation();

  const [
    suspendEndpoint,
    { isLoading: isSuspendingEndpoint },
  ] = api.endpoints.suspendEndpoint.useMutation();

  const [
    activateEndpoint,
    { isLoading: isActivatingEndpoint },
  ] = api.endpoints.activateEndpoint.useMutation();

  const [
    updateEndpoint,
    { isLoading: isUpdatingEndpoint },
  ] = api.endpoints.updateEndpoint.useMutation();

  const [
    rotateEndpointKey,
    { isLoading: isRotatingEndpointKey },
  ] = api.endpoints.rotateEndpointKey.useMutation();

  const {
    activeEndpoint,
    activeEndpointType: action,
    sampleEvent,
    configuration,
  } = useSelector(slices.webhooks.selectors.selectEndpointConfiguration);

  const {
    data: eventSamplePayload,
    isLoading: isLoadingEventSamplePayload,
  } = api.endpoints.getEventSamplePayload.useQuery(undefined);

  if (action === 'view' || action === null) {
    return null;
  }

  const handleHide = () =>
    dispatch(slices.webhooks.actions.resetEndpointConfiguration());

  const handleActionConfirm = () => {
    const queries = {
      delete: deleteEndpoint,
      suspend: suspendEndpoint,
      edit: updateEndpoint,
      add: createEndpoint,
      activate: activateEndpoint,
      'rotate-key': rotateEndpointKey,
    };

    const query = queries[action];

    query({
      configuration,
      endpoint: activeEndpoint!,
    })
      ?.unwrap()
      .then(() => {
        toast({
          message: `${toastMessage?.success}`,
          status: 'success',
        });
      })
      .catch(() =>
        toast({
          message: `${toastMessage?.failure}`,
          status: 'danger',
        }),
      )
      .finally(handleHide);
  };

  const isLoading =
    isDeletingEndpoint ||
    isSuspendingEndpoint ||
    isUpdatingEndpoint ||
    isCreatingEndpoint ||
    isActivatingEndpoint ||
    isRotatingEndpointKey;

  const {
    children,
    isFullPage,
    variant,
    panel,
    toastMessage,
    tooltip,
    disabled,
  } =
    resolveModalProps({
      action,
      endpoint: activeEndpoint,
      sampleEvent,
      samplePayloads: eventSamplePayload,
      isLoading: isLoadingEventSamplePayload,
      configuration,
    }) ?? {};

  const modalProps = {
    children:
      children === typeof 'string' ? <Markdown text={children} /> : children,
    isFullPage,
    isVisible: Boolean(action),
    panel,
    primaryAction: {
      disabled,
      isLoading,
      onClick: handleActionConfirm,
      tooltip,
      text: `${capitalize(action ?? '')}`,
      variant,
    },
    onCancel: handleHide,
    onHide: handleHide,
    title: `${capitalize(action ?? '')} Endpoint`,
  };

  return <Modal {...modalProps} />;
};

const resolveModalProps = ({
  action,
  endpoint,
  sampleEvent,
  samplePayloads,
  isLoading,
  configuration,
}: Props) => {
  const endpointUrl = endpoint?.endpointUrl ?? '';
  const eventSamplePayload = getEventSamplePayload(sampleEvent, samplePayloads);
  const disabledTooltip = getDisabledTooltip(configuration ?? null);
  const disabledCopyTooltip = getDisabledCopyTooltip({
    sampleEvent,
    samplePayloads,
  });

  switch (action) {
    case 'delete': {
      return {
        children: `Once this endpoint is deleted, no more events will be sent.  The previous attempts will be archived. Are you sure you want to delete ${endpointUrl}?`,
        variant: 'danger' as const,
        toastMessage: {
          success: `Endpoint \`${endpointUrl}\` deleted.`,
          failure: `There was an error deleting \`${endpointUrl}\`. Please try again.`,
        },
        isFullPage: false,
      };
    }
    case 'suspend': {
      return {
        children: `Evisort will not send any events until the endpoint is activated again. Are you sure you want to suspend ${endpointUrl}`,
        variant: 'danger' as const,
        toastMessage: {
          success: `Endpoint \`${endpointUrl}\` suspended.`,
          failure: `There was an error suspending  \`${endpointUrl}\`. Please try again.`,
        },
        isFullPage: false,
      };
    }
    case 'rotate-key': {
      return {
        children: `Evisort will send new events immediately with the new secret key. The old key will be available for 72 hours. Are you sure you want to rotate the key for ${endpointUrl}?`,
        variant: 'primary' as const,
        toastMessage: {
          success: `Key for endpoint \`${endpointUrl}\` rotated.`,
          failure: `There was an error rotating the key for \`${endpointUrl}\`. Please try again.`,
        },
        isFullPage: false,
      };
    }
    case 'activate': {
      return {
        children: `Are you sure you want to re-activate ${endpointUrl}?`,
        variant: 'primary' as const,
        toastMessage: {
          success: `Endpoint \`${endpointUrl}\` active.`,
          failure: `There was an error re-activating \`${endpointUrl}\`. Please try again.`,
        },
        isFullPage: false,
      };
    }
    case 'edit':
    case 'add':
      return {
        children: <EndpointConfiguration />,
        disabled: Boolean(disabledTooltip),
        tooltip: disabledTooltip,
        panel: {
          actions: [
            {
              disabled: !sampleEvent || eventSamplePayload === null,
              tooltip: disabledCopyTooltip,
              text: 'Copy',
              icon: 'clipboard' as const,
              onClick: () => {
                copy(eventSamplePayload!);
              },
            },
          ],
          children: <CodeSample sample={eventSamplePayload} />,
          loadingContent: {
            isLoading,
            message: 'Loading event sample payload…',
          },
          placeholderContent: !sampleEvent
            ? {
                image: 'empty-no-groups',
                message: 'Preview code when clicking on events…',
              }
            : !eventSamplePayload
            ? {
                image: 'empty-no-groups',
                message:
                  'This event type does not have a sample payload. Please try another event type.',
              }
            : undefined,
          title: 'Sample Payload',
          width: 'l',
        },
        isFullPage: true,
        status: 'primary' as const,
        toastMessage: {
          success: `Endpoint \`${configuration?.url}\` added.`,
          failure: `There was an error creating the endpoint. Please try again.`,
        },
        variant: 'primary' as const,
      };
    case 'view':
    default:
      return null;
  }
};
