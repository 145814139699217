import { courier } from '~/services';

import { GetSamplePayloadResponseDto } from '../../types/courier/eventTypes';

type Response = GetSamplePayloadResponseDto;
export const getEventSamplePayload = async (): Promise<Response> => {
  const response: Response = await courier.get(`event-types/sample-payloads`);

  return response;
};
