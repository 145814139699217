import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { Document, Page, pdfjs } from 'react-pdf';

import { AlgorithmStatusType } from '~/enums';
import { withFlags } from '~/flags';
import { HtmlContent } from '~/ui';

import EcCard from '../../Shared/EcCard';
import LoadingSpinner from '../../Shared/Icons/LoadingSpinner';
import SyncIcon from '../../Shared/Icons/SyncIcon';
import WarningIcon from '../../Shared/Icons/WarningIcon';
import styles from './EcDocumentViewer.styles';

if (typeof window !== 'undefined' && 'Worker' in window) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
}

class EcDocumentViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOCR: true,
      htmlData: this.props.htmlData,
      pdfHtmlData: this.props.pdfHtmlData,
      pdfFile: null,
      ecCardContentStyles: {},
    };

    this.docViewerRef = React.createRef();

    this.handleOCRClick = this.handleOCRClick.bind(this);
    this.handlePDFClick = this.handlePDFClick.bind(this);
  }

  componentDidMount() {
    this.setState({ ecCardContentStyles: this.props.containerStyle });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.pdfFile !== prevState.pdfFile ||
      nextProps.htmlData !== prevState.htmlData ||
      nextProps.pdfHtmlData !== prevState.pdfHtmlData
    ) {
      return {
        pdfFile: nextProps.pdfFile,
        htmlData: nextProps.htmlData,
        pdfHtmlData: nextProps.pdfHtmlData,
      };
    } else if (nextProps.containerStyle !== prevState.ecCardContentStyles) {
      return { ecCardContentStyles: nextProps.containerStyle };
    } else {
      return null;
    }
  }

  handleOCRClick() {
    this.docViewerRef.current.focus();
    this.setState({ showOCR: true });
    this.props.handleOCRClick();
  }

  handlePDFClick() {
    this.docViewerRef.current.focus();
    this.setState({ showOCR: false });
    this.props.handlePDFClick();
  }

  handleOnMouseDown = () => {
    this.props.onMouseDownHandler && this.props.onMouseDownHandler();
  };

  handleOnMouseUp = () => {
    this.props.onMouseUpHandler && this.props.onMouseUpHandler();
  };

  loadDocument = () => {
    this.props.loadDocument();
  };

  onDocumentLoadSuccess = (...args) => {
    this.props.onDocumentLoadSuccessHandler(...args);
  };

  onDocumentLoadError = (...args) => {
    this.props.onDocumentLoadErrorHandler(...args);
  };

  renderLoader() {
    const { classes, errorLoadDocument } = this.props;

    if (errorLoadDocument) return null;

    return (
      <div className={classes.loadingContainer}>
        <LoadingSpinner size="medium" />
      </div>
    );
  }

  renderTitleRightControls() {
    const { classes } = this.props;

    return (
      <div className={classes.viewerTitleRightControls}>
        {this.renderOcrPdfSwitch()}
      </div>
    );
  }

  renderOcrPdfSwitch() {
    const { classes, fileType, forWorkflowDoc } = this.props;
    const { showOCR } = this.state;

    if (fileType !== '.pdf' || forWorkflowDoc) return null;

    const viewOcrClass = showOCR ? classes.viewSelected : null;
    const viewPdfClass = showOCR ? null : classes.viewSelected;

    return (
      <div className={classes.viewTypeSwitch}>
        <button
          aria-pressed={showOCR}
          className={viewOcrClass}
          onClick={this.handleOCRClick}
        >
          Text
        </button>
        <button
          aria-pressed={!showOCR}
          className={viewPdfClass}
          onClick={this.handlePDFClick}
        >
          PDF
        </button>
      </div>
    );
  }

  renderDocumentViewerMessage(IconComponent, headerMessage, bodyMessage) {
    const { classes } = this.props;
    return (
      <div className={classes.viewerWarningWrapper}>
        <div>
          <IconComponent size="48" color="#eb890f" />
        </div>
        <div className={classes.viewerWarningHeader}>{headerMessage}</div>
        {bodyMessage && (
          <div className={classes.viewerWarningBody}>{bodyMessage}</div>
        )}
      </div>
    );
  }

  renderDocumentViewerWarning() {
    const headerMessage =
      'We could not perform optical character recognition on this document.';
    const bodyMessage = `OCR failed because of an issue with this document. 
      Please open the original file using the “Download” button and contact support if you require further assistance.`;

    return this.renderDocumentViewerMessage(
      WarningIcon,
      headerMessage,
      bodyMessage,
    );
  }

  renderDocumentViewerProcessing() {
    const headerMessage =
      'The preview for this document is generating and will be available shortly.';
    return this.renderDocumentViewerMessage(SyncIcon, headerMessage);
  }

  renderHTMLContent(htmlData) {
    const { classes } = this.props;
    const HTMLcontent = (
      <HtmlContent
        className={classes.documentContentWrapper}
        html={htmlData}
        tabIndex={0}
        onMouseDown={this.handleOnMouseDown}
        onMouseUp={this.handleOnMouseUp}
      />
    );

    return HTMLcontent;
  }

  renderOcrHTML() {
    const { htmlData } = this.props;
    return this.renderHTMLContent(htmlData);
  }

  renderOcrContent() {
    const { OCRStatus } = this.props;

    switch (OCRStatus) {
      case AlgorithmStatusType.Failed:
        return this.renderDocumentViewerWarning();
      case AlgorithmStatusType.InProgress:
        return this.renderDocumentViewerProcessing();
      case AlgorithmStatusType.Success:
      case AlgorithmStatusType.Fallback:
      default:
        return this.renderOcrHTML();
    }
  }

  renderPdfContent() {
    const { numPages, pdfFile, errorLoadDocument, pdfHtmlData } = this.props;

    if (errorLoadDocument) {
      const headerMessage =
        'There has been an error with loading this PDF file.';
      return this.renderDocumentViewerMessage(WarningIcon, headerMessage);
    }

    if (pdfHtmlData) {
      return this.renderHTMLContent(pdfHtmlData);
    }

    if (!pdfFile) {
      this.loadDocument();
    }

    return (
      <Document
        tabIndex={0}
        file={pdfFile}
        options={{
          isEvalSupported: false,
        }}
        onLoadSuccess={this.onDocumentLoadSuccess}
        onLoadError={this.onDocumentLoadError}
        loading={this.renderLoader()}
        noData={this.renderLoader()}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            width={this.docViewerRef.current.getBoundingClientRect().width}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            loading={this.renderLoader()}
          />
        ))}
      </Document>
    );
  }

  renderDocumentViewerContent() {
    return this.state.showOCR
      ? this.renderOcrContent()
      : this.renderPdfContent();
  }

  render() {
    const { classes, workflowDocument, algoFailState } = this.props; //errorLoadDocument, OCRStatus, warningState
    const { showOCR, ecCardContentStyles } = this.state;

    //TODO / errorLoadDocument is flag for failed downloading/loading pdf doc
    //TODO / but in ReviewersViewPage it is flag for failed HTML document

    return (
      <EcCard
        title="Document Viewer"
        titleRightComponent={this.renderTitleRightControls()}
        contentStyles={ecCardContentStyles}
      >
        {/*{this.renderDocumentContent()}*/}

        <div role="dialog">
          <div
            className={classes.documentViewerWrapper}
            role="document"
            ref={this.docViewerRef}
          >
            {showOCR ? this.renderOcrContent() : this.renderPdfContent()}
          </div>
        </div>

        {this.props.children}
        {/*warningState && (showOCR || workflowDocument)
          ? this.renderDocumentViewerWarning()
          : this.renderDocumentViewerContent()*/}
        {algoFailState && workflowDocument
          ? this.renderYellowWarningBanner()
          : null}
      </EcCard>
    );
  }
}

EcDocumentViewer.propTypes = {
  htmlData: PropTypes.string,
  classes: PropTypes.object.isRequired,
  warningState: PropTypes.bool,
  fileType: PropTypes.string.isRequired,
  numPages: PropTypes.number,
  pdfFile: PropTypes.object,
  pdfHtmlData: PropTypes.string,
  onMouseDownHandler: PropTypes.func,
  onMouseUpHandler: PropTypes.func,
  loadDocument: PropTypes.func.isRequired,
  handleOCRClick: PropTypes.func,
  handlePDFClick: PropTypes.func,
  onDocumentLoadSuccessHandler: PropTypes.func.isRequired,
  onDocumentLoadErrorHandler: PropTypes.func.isRequired,
  OCRStatus: PropTypes.number,
  forModal: PropTypes.bool,
};

export default injectSheet(styles)(withFlags(EcDocumentViewer));
