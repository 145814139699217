import {
  ControlledMenu,
  MenuGroup,
  MenuHeader,
  MenuItem,
  useMenuState,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { interactions } from '../../system/styles/rules';
import { Option as DefaultOption, Nullable } from '../../types';
import { typedMemo } from '../../utils';
import { Box } from '../Box';
import { Text } from '../Text';
import { MenuOption } from './subcomponents/MenuOption';

interface Option<V> extends DefaultOption {
  /** Optional click handler */
  onClick?: (option: DefaultOption<V>) => void;
}

interface GroupedOptions<V> {
  /** Group label */
  label: Nullable<string>;
  /** Options */
  options: Option<V>[];
}

interface Props<V = unknown> {
  /** Menu name to uniquely identify the menu */
  name: string;
  /** List of options to render. */
  options: Option<V>[] | GroupedOptions<V>[];
  /** Element to trigger the menu.  Please use the documented list of recommended triggers (e.g. `Button`, `IconButton`). Specify trigger=null to set Menu in embedded mode. */
  trigger: React.ReactNode;
  /** Callback when option is selected.  If not provided, one should specify it in each option[i].onClick */
  onSelectOption?: (option: Option<V>) => void;
  /** Menu direction relative to the trigger */
  direction?: 'top' | 'bottom' | 'right' | 'left';
  /** Disables the menu trigger */
  disabled?: boolean;
  /** Enables menu border */
  enableMenuBorder?: boolean;
  /** If true, menu is rendered as a direct child of document.body. */
  enablePortal?: boolean;
  /** Search string to match on options */
  search?: string;
  /** Defines the offset to the trigger element on the X axis*/
  offsetX?: number;
  /** Defines the offset to the trigger element on the Y axis*/
  offsetY?: number;
  /** Width of the trigger element */
  triggerWidth?: '100%';
  /** Height of the trigger element */
  triggerHeight?: '100%' | 'auto';
}

/**
 * Menu is an uncontrolled/stateful component.
 *
 * Dev behavior notes on `isEmbedded` mode (i.e. triggerless mode, `trigger === null`).
 * - Internally, there is a code branch around `isEmbedded` mode.
 * - This is set up as the vendor does not have native support for triggerless menus.
 * - In embedded mode, we render a "dummy" menu button that is always hidden to the SR.
 * - Only the menu is visible and embedded in its parent container.
 * - The `openMenu` behavior is triggered on mount.
 * - The `closeMenu` behavior is disabled.
 */
export const Menu = typedMemo(
  <V extends unknown>({
    direction,
    disabled,
    enableMenuBorder = true,
    enablePortal,
    name,
    offsetX,
    offsetY,
    options: initialOptions,
    search,
    trigger,
    triggerWidth,
    triggerHeight,
    onSelectOption,
  }: Props<V>) => {
    const anchorRef = useRef(null);

    const { isOpen, isMounted, closeMenu, openMenu } = useMenuState();

    const isEmbedded = !Boolean(trigger);

    // automatically open the menu on mount in embedded (i.e. triggerless) mode
    useEffect(() => {
      if (isEmbedded) {
        openMenu();
      }
    }, []);

    const handleOpenMenu = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        openMenu();
      },
      [openMenu],
    );

    const handleCloseMenu = useCallback(() => {
      if (!isEmbedded) {
        closeMenu();
      }
    }, [isEmbedded, closeMenu]);

    /**
     * The vendor does not handle keydown logic for ControlledMenu.
     * From https://github.com/szhsin/react-menu/blob/v1.2.2/src/components/Menu.js#L54-L70, we copy its implementation and pass to `menuButton`, following the logic of the vendor source code.
     */
    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLButtonElement>) => {
        let handled = false;
        switch (event.key) {
          case 'ArrowUp':
            openMenu('last');
            handled = true;
            break;
          case 'ArrowDown':
            openMenu('first');
            handled = true;
            break;
          default:
            break;
        }

        if (handled) {
          event.preventDefault();
        }
      },
      [openMenu],
    );

    const groupedOptions = useMemo(() => {
      const isGrouped =
        initialOptions.length > 0 && 'options' in initialOptions[0];
      return isGrouped
        ? (initialOptions as GroupedOptions<V>[])
        : ([{ label: null, options: initialOptions }] as GroupedOptions<V>[]);
    }, [initialOptions]);

    const menuItems = groupedOptions.map((groupedOption, groupIndex) => {
      const menuOptions = groupedOption.options.map((option, optionIndex) => (
        <MenuItem
          key={optionIndex}
          // this is a false positive error.
          ariaLabel={option.ariaLabel}
          disabled={option.disabled}
          styles={componentStyles.menuItem}
          // vendor is untyped
          onClick={(event: any) => {
            event.syntheticEvent.stopPropagation();
            (option.onClick || onSelectOption)?.(option as DefaultOption<V>);
          }}
        >
          <MenuOption
            pathname={option.pathname}
            option={option}
            search={search}
          />
        </MenuItem>
      ));

      if (groupedOption.label === null || groupedOption.options.length === 0) {
        return menuOptions;
      }

      return (
        <MenuGroup key={groupIndex} className="eds__react-menu__group">
          {groupedOption.label && (
            <MenuHeader className="eds__react-menu__header">
              <Text
                color="text.quiet"
                py={2}
                px={4}
                textTransform="none"
                variant="tiny"
                w="100%"
              >
                {groupedOption.label}
              </Text>
            </MenuHeader>
          )}
          {menuOptions}
        </MenuGroup>
      );
    });

    const menuButton = (
      <button
        aria-haspopup={true}
        aria-hidden={isEmbedded}
        aria-expanded={isOpen}
        className={
          isEmbedded
            ? 'eds__react-menu__menu-button--embedded'
            : 'eds__react-menu__menu-button'
        }
        disabled={disabled}
        ref={anchorRef}
        onClick={handleOpenMenu}
        onKeyDown={handleKeyDown}
        style={{ width: triggerWidth, height: triggerHeight }}
      >
        {trigger}
      </button>
    );
    const menu = (
      <ControlledMenu
        anchorRef={anchorRef}
        animation={false}
        aria-label={name}
        captureFocus={!isEmbedded}
        className={
          isEmbedded && !enableMenuBorder
            ? 'eds__react-menu--embedded'
            : 'eds__react-menu'
        }
        direction={direction}
        isMounted={isMounted}
        isOpen={isOpen}
        portal={enablePortal}
        onClose={handleCloseMenu}
        offsetX={offsetX}
        offsetY={offsetY}
      >
        {menuItems}
      </ControlledMenu>
    );

    return (
      <>
        {menuButton}
        <Box styles={componentStyles.vendorOverrides}>{menu}</Box>
      </>
    );
  },
);

const componentStyles = {
  menuItem: ({ hover }: { hover: boolean }) => ({
    backgroundColor: 'transparent',
    display: 'block',
    padding: 0,
    ...(hover ? interactions[':hover'] : {}), // this is the only expression to integrate with the vendor API
  }),
  vendorOverrides: {
    '& .rc-menu-container': {
      height: 'unset',
      width: '100%',
    },
  },
};
