import { union } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import { Box, CheckboxGroup, Layout, Text } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';
import { ExportExcelField } from '~/redux/api/methods/searchV3';
import { Nullable } from '~/types';

import { Fields } from '../Fields';
import { OptionsManager } from '../OptionsManager';

type Props = {
  selectedFieldIds?: string[];
  settings: Record<string, Nullable<string[]>>;
  data?: {
    fields: ExportExcelField[];
    clauses: Array<{
      label: string;
      value: string;
    }>;
    tables: Array<{
      label: string;
      value: string;
    }>;
  };
  onChange: (localSettings: Record<string, Nullable<string[]>>) => void;
};

export const useExportSettings = ({
  data,
  selectedFieldIds = [],
  settings,
  onChange,
}: Props) => {
  const enableClauseValueExportSettings = useFlag(
    FlagType.ClauseValueExportSettings,
  );
  const [search, setSearch] = useState('');

  const { data: sections } = api.endpoints.getFilterSections.useQuery(
    undefined,
  );

  // add missing table colums as options in the export settings
  const missingFieldIds = useMemo(
    () =>
      selectedFieldIds.filter(
        (fieldId) => !data?.fields.map((field) => field.id).includes(fieldId),
      ),
    [selectedFieldIds, data?.fields],
  );
  const missingFields = useMemo(
    () =>
      missingFieldIds.map((fieldId) => {
        const section = sections?.fieldGroups.find((section) =>
          section.fieldIds.includes(fieldId),
        );
        const field = sections?.fields[fieldId];
        return {
          label: field?.label || '',
          section: section?.label || '',
          id: fieldId,
        };
      }),
    [missingFieldIds, sections?.fieldGroups, sections?.fields],
  );

  const allFields = [...missingFields, ...(data?.fields || [])];

  const fieldOptionIds = useMemo(
    () =>
      (allFields.filter((field) => searchFilter(field, search)) || []).map(
        (field) => `${field.id}`,
      ),
    [allFields, search],
  );

  const clauseOptions = useMemo(
    () => data?.clauses?.filter((clause) => searchFilter(clause, search)) || [],
    [data?.clauses, search],
  );
  const clauseOptionsIds = useMemo(
    () => clauseOptions.map((clause) => clause.value),
    [clauseOptions],
  );

  const tableOptions = useMemo(
    () => data?.tables?.filter((table) => searchFilter(table, search)) || [],
    [data?.tables, search],
  );

  const tableOptionsIds = useMemo(
    () => tableOptions.map((table) => table.value),
    [tableOptions],
  );

  const handleSelectAll = useCallback(
    (value: Nullable<boolean>) => {
      if (value) {
        onChange({
          fields: union(settings.fields, fieldOptionIds),
          clauses: union(settings.clauses, clauseOptionsIds),
          tables: union(settings.tables, tableOptionsIds),
        });
      } else {
        onChange({
          fields:
            settings.fields?.filter(
              (field) => !fieldOptionIds.includes(field),
            ) || [],
          clauses:
            settings.clauses?.filter(
              (clause) => !clauseOptionsIds.includes(clause),
            ) || [],
          tables:
            settings.tables?.filter(
              (table) => !tableOptionsIds.includes(table),
            ) || [],
        });
      }
    },
    [fieldOptionIds, clauseOptionsIds, tableOptionsIds, settings],
  );

  const handleSelectAllOptions = useCallback(
    (
      value: Nullable<boolean>,
      entity: 'fields' | 'clauses' | 'tables',
      options: string[],
    ) => {
      if (value) {
        onChange({
          ...settings,
          [entity]: union(settings[entity], options),
        });
      } else {
        onChange({
          ...settings,
          [entity]:
            settings[entity]?.filter((opt) => !options.includes(opt)) || [],
        });
      }
    },
    [settings],
  );

  const handleSelectAllFields = useCallback(
    (value: Nullable<boolean>) => {
      handleSelectAllOptions(value, 'fields', fieldOptionIds);
    },
    [fieldOptionIds, selectedFieldIds, settings],
  );

  const handleSelectAllClauses = useCallback(
    (value: Nullable<boolean>) => {
      handleSelectAllOptions(value, 'clauses', clauseOptionsIds);
    },
    [clauseOptionsIds, settings],
  );

  const handleSelectAllTables = useCallback(
    (value: Nullable<boolean>) => {
      handleSelectAllOptions(value, 'tables', tableOptionsIds);
    },
    [tableOptionsIds, settings],
  );

  const isAllSelected = (
    options: string[],
    selectedOptions: Nullable<string[]>,
  ) => options.every((opt) => (selectedOptions || []).includes(opt));

  const isAnySelected = (
    options: string[],
    selectedOptions: Nullable<string[]>,
  ) => options.some((field) => (selectedOptions || []).includes(field));

  const fieldsSelectAll = useMemo(() => {
    if (!fieldOptionIds.length) return false;

    const allFieldsSelected = isAllSelected(fieldOptionIds, settings?.fields);
    if (allFieldsSelected) return true;

    return isAnySelected(fieldOptionIds, settings.fields) ? null : false;
  }, [fieldOptionIds, settings?.fields]);

  const clausesSelectAll = useMemo(() => {
    if (!clauseOptionsIds.length) return false;

    const allClauses = isAllSelected(clauseOptionsIds, settings?.clauses);
    if (allClauses) return true;

    return isAnySelected(clauseOptionsIds, settings?.clauses) ? null : false;
  }, [clauseOptionsIds, settings?.clauses]);

  const tablesSelectAll = useMemo(() => {
    if (!tableOptionsIds.length) return false;

    const allTables = isAllSelected(tableOptionsIds, settings?.tables);
    if (allTables) return true;

    return isAnySelected(tableOptionsIds, settings?.tables) ? null : false;
  }, [tableOptionsIds, settings?.tables]);

  const allSelectAll = useMemo(() => {
    const options = {
      fields: fieldOptionIds,
      clauses: clauseOptionsIds,
      tables: tableOptionsIds,
    };

    const allFields = isAllSelected(options.fields, settings?.fields);
    const allClauses = isAllSelected(options.clauses, settings?.clauses);
    const allTables = isAllSelected(options.tables, settings?.tables);

    if (allFields && allClauses && allTables) {
      return true;
    } else {
      const someFields = isAnySelected(options.fields, settings?.fields);
      const someClauses = isAnySelected(options.clauses, settings?.clauses);
      const someTables = isAnySelected(options.tables, settings?.tables);
      return someFields || someClauses || someTables ? null : false;
    }
  }, [settings, fieldOptionIds, clauseOptionsIds, tableOptionsIds]);

  const tabs = [
    {
      value: 'all',
      label: 'All',
      items: data?.fields,
      panel: (
        <Box pr={4} pl={4} pb={4}>
          <OptionsManager
            search={search}
            selectAll={allSelectAll}
            onSearch={setSearch}
            onSelectAllToggle={handleSelectAll}
            options={[
              ...fieldOptionIds,
              ...clauseOptionsIds,
              ...tableOptionsIds,
            ]}
          />
          {!fieldOptionIds.length &&
          !clauseOptions.length &&
          !tableOptions.length ? (
            <NoResults search={search} />
          ) : (
            <>
              {fieldOptionIds?.length ? (
                <Box pr={4} pl={4}>
                  <Box mb={2}>
                    <Text variant="tiny">Fields</Text>
                  </Box>
                  <Fields
                    value={settings.fields}
                    onChange={(fields) => onChange({ ...settings, fields })}
                    fields={allFields}
                    selectedFieldIds={selectedFieldIds}
                    search={search}
                  />
                </Box>
              ) : null}

              {clauseOptions.length ? (
                <Box mt={4} pr={4} pl={4}>
                  <Box mb={2}>
                    <Text variant="tiny">Clauses</Text>
                  </Box>

                  <CheckboxGroup
                    name="Clauses"
                    onChange={(clauses) => onChange({ ...settings, clauses })}
                    options={clauseOptions || []}
                    value={settings.clauses}
                    columns={3}
                  />
                </Box>
              ) : null}

              {tableOptions.length ? (
                <Box mt={4} pr={4} pl={4}>
                  <Box mb={2}>
                    <Text variant="tiny">Tables</Text>
                  </Box>

                  <CheckboxGroup
                    name="Tables"
                    onChange={(tables) => onChange({ ...settings, tables })}
                    options={tableOptions || []}
                    value={settings.tables}
                    columns={3}
                  />
                </Box>
              ) : null}
            </>
          )}
        </Box>
      ),
    },
    {
      value: 'fields',
      label: 'Fields',
      items: data?.fields,
      panel: (
        <Box p={4} flex="1">
          <OptionsManager
            search={search}
            selectAll={fieldsSelectAll}
            onSearch={setSearch}
            onSelectAllToggle={handleSelectAllFields}
            options={fieldOptionIds}
          />
          {!fieldOptionIds.length ? (
            <NoResults search={search} />
          ) : (
            <Fields
              selectedFieldIds={selectedFieldIds}
              value={settings.fields}
              onChange={(fields) => onChange({ ...settings, fields })}
              fields={allFields}
              search={search}
            />
          )}
        </Box>
      ),
    },
    {
      value: 'clauses',
      label: 'Clauses',
      items: data?.clauses,
      panel: (
        <Box pr={4} pl={4} flex="1">
          <OptionsManager
            search={search}
            selectAll={clausesSelectAll}
            onSearch={setSearch}
            onSelectAllToggle={handleSelectAllClauses}
            options={clauseOptionsIds}
          />
          {!clauseOptions.length ? (
            <NoResults search={search} />
          ) : (
            <CheckboxGroup
              name="Clauses"
              onChange={(clauses) => onChange({ ...settings, clauses })}
              options={clauseOptions}
              value={settings.clauses}
              columns={3}
            />
          )}
        </Box>
      ),
    },
  ];

  if (!enableClauseValueExportSettings) {
    tabs.push({
      value: 'tables',
      label: 'Tables',
      items: data?.tables,
      panel: (
        <Box pr={4} pl={4} flex="1">
          <OptionsManager
            search={search}
            selectAll={tablesSelectAll}
            onSearch={setSearch}
            onSelectAllToggle={handleSelectAllTables}
            options={tableOptionsIds}
          />
          {!tableOptions.length ? (
            <NoResults search={search} />
          ) : (
            <CheckboxGroup
              name="Tables"
              onChange={(tables) => onChange({ ...settings, tables })}
              options={tableOptions}
              value={settings.tables}
              columns={3}
            />
          )}
        </Box>
      ),
    });
  }

  return tabs;
};

const NoResults = ({ search }: { search: string }) => (
  <Layout align="center" justify="center">
    <Text>
      No results for: <Text variant="body-bold">{search}</Text>
    </Text>
  </Layout>
);

const searchFilter = (
  filter: ExportExcelField | { label: string },
  search: string,
) => filter.label?.toLowerCase().indexOf(search.toLowerCase()) >= 0;
