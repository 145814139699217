import { api } from '../createApi';
import { TagType } from '../TagType';

const TAG_BY_LIST = { type: TagType.TableViews, id: 'LIST' };

api.enhanceEndpoints({
  addTagTypes: [TagType.TableViews as TagType],
  endpoints: {
    getCurrentTableView: {
      providesTags: [TAG_BY_LIST],
    },
    getTableViews: {
      providesTags: [TAG_BY_LIST],
    },
    createTableView: {
      invalidatesTags: [TAG_BY_LIST],
    },
    updateTableView: {
      invalidatesTags: [TAG_BY_LIST],
    },
    loadTableView: {
      invalidatesTags: [TAG_BY_LIST],
    },
    loadDefaultTableView: {
      invalidatesTags: [TAG_BY_LIST],
    },
    renameTableView: {
      invalidatesTags: [TAG_BY_LIST],
    },
    deleteTableView: {
      invalidatesTags: [TAG_BY_LIST],
    },
  },
});
