import PT from 'prop-types';
import React from 'react';

import { Select } from '~/eds';
import { InputLabel } from '~/ui';

function AvailableOptions({
  hasPresets = false,
  id,
  options,
  values,
  width,
  onChange,
}) {
  function handleAddOption(newOption) {
    const updatedOptions = [...options, newOption];
    onChange(updatedOptions);
  }

  function handleUpdateValues(updatedValues) {
    const updatedOptions = updatedValues.map(
      (value) =>
        options.find((opt) => opt.value === value) || { value, label: value }, // selected option can be one of the presets or newly created
    );
    onChange(updatedOptions);
  }
  return (
    <InputLabel
      flex={width === 'fullWidth' ? '1 1 auto' : undefined}
      id={id}
      isRequired
      label="Available Options"
    >
      <Select
        options={options || []}
        placeholder="Add options"
        value={values}
        width="input.l.width"
        onAddOption={hasPresets ? undefined : handleAddOption}
        onChange={handleUpdateValues}
        isMulti
        enableSelectAll={false}
        enableErrorMessage
        enablePortal
      />
    </InputLabel>
  );
}

AvailableOptions.propTypes = {
  hasPresets: PT.bool,
  id: PT.string.isRequired,
  options: PT.arrayOf(
    PT.shape({
      value: PT.any.isRequired,
      label: PT.string.isRequired,
    }).isRequired,
  ),
  values: PT.array,
  onChange: PT.func.isRequired,
};

export default AvailableOptions;
