import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { showToast } from '~/components/Shared/EcToast';
import { ActionsMenu, Button, ContentContainer, Layout, Text } from '~/eds';
import {
  DEFAULT_CHAT_CONTEXT,
  getDocumentGroupQuery,
} from '~/features/ask-anything';
import { usePermission } from '~/hooks';
import { api } from '~/redux';
import { PersistRedux } from '~/redux/persist';
import { chatbotSlice } from '~/redux/slices/chatbot';
import documentGroup, { pathsToPersist } from '~/redux/slices/documentGroup';
import { useRouting } from '~/routing';
import {
  MODAL_ADD_DOCUMENTS_TO_GROUP,
  MODAL_DELETE_DOCUMENTS_GROUP,
  MODAL_EDIT_DOCUMENTS_GROUP,
} from '~/types/modal.types';
import { ERROR } from '~/types/toast.types';

import AddDocuments from './AddDocuments';
import { DeleteGroup } from './DeleteGroup';
import { EditGroup } from './EditGroup';
import GroupDocuments from './GroupDocuments';

function DocumentGroupPage() {
  const { params } = useRouting();
  const id = params.groupId!;

  const dispatch = useDispatch();

  const { data, error, isFetching } = api.endpoints.getDocumentGroup.useQuery({
    id,
  });

  const {
    hasPermission: hasEditPermission,
    isLoading: isLoadingPermission,
  } = usePermission({
    permission: { resourceId: 'document_groups', resourceType: 'edit' },
  });

  const title = data?.name;

  const showAddDocumentsToGroupModal = () => {
    dispatch(
      documentGroup.actions.setModal({
        modalType: MODAL_ADD_DOCUMENTS_TO_GROUP,
        context: {},
      }),
    );
  };

  const showEditGroupModal = useCallback(() => {
    dispatch(
      documentGroup.actions.setModal({
        modalType: MODAL_EDIT_DOCUMENTS_GROUP,
        context: {},
      }),
    );
  }, []);

  const showDeleteGroupModal = useCallback(() => {
    dispatch(
      documentGroup.actions.setModal({
        modalType: MODAL_DELETE_DOCUMENTS_GROUP,
        context: {},
      }),
    );
  }, []);

  useEffect(() => {
    if (error) {
      showToast(ERROR, 'An error occurred while loading the group data.');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      dispatch(
        chatbotSlice.actions.setContext([
          DEFAULT_CHAT_CONTEXT,
          {
            label: 'This Group',
            query: getDocumentGroupQuery(String(data.groupId)),
            title: data.name,
            icon: 'groups',
            primary: true,
            searchEntity: 'document',
            selectedIds: [],
            id: String(data.groupId),
          },
        ]),
      );
    }

    return () => {
      dispatch(chatbotSlice.actions.setContext([DEFAULT_CHAT_CONTEXT]));
    };
  }, [data]);

  const hasData = data && !error && !isFetching;

  if (hasData) {
    return (
      <>
        {pathsToPersist.map((path) => (
          <PersistRedux path={path!} />
        ))}
        <ContentContainer
          loadingContent={{
            isLoading: Boolean(isFetching || isLoadingPermission),
          }}
        >
          <Layout direction="column">
            <Layout direction="row" justify="space-between">
              <Text variant="title" as="h2" mt={1}>
                {title}
              </Text>
              <Layout>
                <Button
                  disabled={!hasEditPermission}
                  variant="primary"
                  text="+ Add Documents"
                  onClick={showAddDocumentsToGroupModal}
                />
                <Layout ml={1}>
                  <ActionsMenu
                    disabled={!hasEditPermission}
                    name="group actions"
                    actions={[
                      {
                        label: 'Edit',
                        value: 'edit',
                        onClick: showEditGroupModal,
                      },
                      {
                        label: 'Delete',
                        value: 'delete',
                        onClick: showDeleteGroupModal,
                      },
                    ]}
                  />
                </Layout>
              </Layout>
            </Layout>
            <GroupDocuments
              groupId={id}
              numberOfDocs={data?.numberOfDocs}
              hasEditPermission={!!hasEditPermission}
            />
            <AddDocuments groupId={id} groupName={title} />
            <EditGroup
              groupId={Number(id)}
              name={data?.name}
              note={data?.note}
            />
            <DeleteGroup groupId={Number(id)} name={data?.name} />
          </Layout>
        </ContentContainer>
      </>
    );
  } else {
    return null;
  }
}

export default DocumentGroupPage;
