import { PilotId } from '~/types';

export interface Token {
  apiKey: string;
  tokenType: string;
  tokenId: string;
  version: string;
}

export interface AribaProviderOptions {
  apiKey: string;
  importFile?: string;
  location: string;
  mapping?: any[];
  realm: string;
  streamPosition: number;
  syncDelaySeconds: number;
  tokens: Token[];
  syncType?: string;
  file?: File;
  extraContext?: object;
  updatedBy?: number;
}
export interface SyncPairConfigInfo {
  id: string;
  allowedSyncFileTypes: string[];
  createdBy: PilotId;
  dateCreated: string;
  dateUpdated: string;
  evisortFolderPath: string;
  provider: string;
  providerOptions: AribaProviderOptions;
  tokenId?: string;
  skipEmptyFolders?: boolean;
}

export interface SyncPairConfig extends SyncPairConfigInfo {
  name: string;
  providerFolderPath?: string;
  title: string;
}

export interface AribaError {
  id: string;
  aribaUrl: string;
  realm: string;
  evisortId: string;
  status: string;
  timestamp: string;
}

export interface ConfigurationPanelProps {
  config: SyncPairConfig;
  tab: string;
  onHide: () => void;
}

export enum InfoDataType {
  FILESYSTEM = 'filesystem',
  CHIPS = 'chips',
  USER_ACTIVITY = 'user-activity',
  TEXT = 'text',
  CONTENT = 'content',
}
