import React, { Component, Fragment } from 'react';

import ActionsMenu from '~/components/Shared/ActionsMenu';
import { Markdown, Modal } from '~/eds';

import { MODAL_DELETE, MODAL_NAME } from '../../../types/modal.types';
import DeleteDocumentWithReasonModal from '../../Modals/DeleteDocumentWithReasonModal';
import EcModal from '../../Shared/EcModal';

class EditDocumentMenu extends Component {
  state = { currentModal: null };

  handleShowModal = (modal) => {
    this.setState({ currentModal: modal });
  };

  handleHideModal = () => {
    this.setState({ currentModal: null });
  };

  renderDeleteModal = () => {
    const {
      handleDocumentDelete,
      documentData,
      hasDeleteWithReasonEnabled,
    } = this.props;
    const { currentModal } = this.state;

    if (hasDeleteWithReasonEnabled) {
      return (
        <DeleteDocumentWithReasonModal
          type="document"
          isVisible={!!currentModal}
          count={1}
          selectedItemName={`"${documentData?.document_name}"`}
          hideModal={this.handleHideModal}
          onConfirmDelete={handleDocumentDelete}
        />
      );
    }

    return (
      <Modal
        children={
          <Markdown
            text={`Are you sure you want to delete ${documentData.document_name}? You won’t be able to undo this action.`}
          />
        }
        isVisible
        primaryAction={{
          text: 'Delete',
          variant: 'danger',
          onClick: () => {
            handleDocumentDelete();
            this.handleHideModal();
          },
        }}
        onHide={this.handleHideModal}
        onCancel={this.handleHideModal}
        title="Delete document?"
      />
    );
  };

  renderNameModal = () => {
    const { documentData, handleDocumentRename } = this.props;

    return (
      <EcModal
        modalType={MODAL_NAME}
        width="560px"
        title="Rename Document"
        labelText="DOCUMENT NAME"
        itemsParentFolderId={documentData.parent_folder}
        itemsCurrentName={documentData.document_name}
        confirmButtonText="Save"
        handleNameChange={handleDocumentRename}
        hideModal={this.handleHideModal}
      />
    );
  };

  getMenuItems = () => {
    const items = [
      {
        content: 'Rename',
        onClick: () => this.handleShowModal(MODAL_NAME),
      },
      {
        content: 'Delete',
        onClick: () => this.handleShowModal(MODAL_DELETE),
      },
    ];

    return items;
  };

  render() {
    const { currentModal } = this.state;
    const { documentData } = this.props;

    return (
      <>
        <ActionsMenu
          items={this.getMenuItems(documentData)}
          align="end"
          title="Edit Document Menu"
        />
        {currentModal === MODAL_DELETE ? this.renderDeleteModal() : null}
        {currentModal === MODAL_NAME ? this.renderNameModal() : null}
      </>
    );
  }
}

export default EditDocumentMenu;
