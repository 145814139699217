import { useDispatch } from 'react-redux';

import { Card, Section } from '~/eds';
import { slices } from '~/redux';

import { LibraryModelVersion } from '../types';

interface Props {
  model: LibraryModelVersion;
}

export const ModelCard = ({ model }: Props) => {
  const dispatch = useDispatch();

  return (
    <Card
      footer={{
        actions: [
          {
            text: 'View Model',
            icon: 'chevron-right',
            iconPosition: 'right',
            level: 'tertiary',
            onClick: () =>
              dispatch(slices.xRayLibrary.actions.setModelPreview(model)),
          },
        ],
      }}
      onClick={() => dispatch(slices.xRayLibrary.actions.addModelToCart(model))}
    >
      <Section
        title={model.label}
        description={model.targetEntityDetails.label}
      >
        {model.description}
      </Section>
    </Card>
  );
};
