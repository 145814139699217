import { useState } from 'react';
import { ActionType } from 'react-table';

import { PAGE_SIZE } from '~/constants/page';
import { types, useTableSelection } from '~/eds';
import { ColumnSortOrder } from '~/types';

import { useTableSettings } from './useTableSettings';

type Props = {
  id: string;
  columnOrder: string[];
  sortBy: ColumnSortOrder;
};

export const useTableState = ({ id, columnOrder, sortBy }: Props) => {
  const { tableSettings, updateTableSettings } = useTableSettings(id);

  const [state, setState] = useState({
    columnOrder: tableSettings?.columnOrder ?? columnOrder,
    pageIndex: 1,
    pageSize: tableSettings?.pageSize ?? PAGE_SIZE,
    sortBy: tableSettings?.sortBy ?? sortBy,
  });
  const {
    selectedIds,
    isAllSelected,
    clear,
    onTableUpdate,
  } = useTableSelection();

  const handleUpdateState = (
    tableState: types.TableState,
    action?: ActionType,
  ) => {
    onTableUpdate(tableState, action);
    setState((prev) => {
      const updates = Object.fromEntries(
        Object.entries({
          pageIndex:
            action?.type === 'gotoPage' ? tableState.pageIndex : undefined,
          pageSize: tableState.pageSize,
          sortBy: tableState.sortBy?.[0],
        }).filter(
          ([key, value]) =>
            value !== undefined && value !== prev[key as keyof typeof prev],
        ),
      );
      return Object.keys(updates).length > 0 ? { ...prev, ...updates } : prev;
    });
  };

  const partialUpdateState = (updates: Partial<types.TableState>) => {
    setState((prev) => {
      const newUpdates = Object.fromEntries(
        Object.entries(updates).filter(
          ([key, value]) =>
            value !== undefined && value !== prev[key as keyof typeof prev],
        ),
      );

      if (updates.columnOrder && tableSettings) {
        updateTableSettings({
          ...tableSettings,
          columnOrder: updates.columnOrder,
          columnWidths: tableSettings?.columnWidths ?? {},
        });
      }

      return Object.keys(newUpdates).length > 0
        ? { ...prev, ...newUpdates }
        : prev;
    });
  };

  return {
    tableState: { ...state, selectedIds, isAllSelected },
    handleUpdateTableState: handleUpdateState,
    partialUpdateTableState: partialUpdateState,
    clearSelectedRows: clear,
  };
};
