import { uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { ListItems, Panel } from '~/eds';
import { slices } from '~/redux';

import { useGetLatestLibraryModelVersions } from '../hooks';

export const Categories = () => {
  const dispatch = useDispatch();
  const activeCategory = useSelector(
    slices.xRayLibrary.selectors.selectCategory,
  );
  const models = useGetLatestLibraryModelVersions({});
  const categories = uniq(models.flatMap((model) => model.tags));
  const items = categories.map((category) => {
    return {
      title: `${category.label} (${models.length})`,
      description: category.description,
      isSelected: activeCategory?.label === category.label,
      onClick: () => dispatch(slices.xRayLibrary.actions.setCategory(category)),
    };
  });

  return (
    <Panel
      enableContentPadding={false}
      title={`Categories (${categories.length})`}
      width="280px"
    >
      <ListItems as="ul" items={items} enablePadding={true} />
    </Panel>
  );
};
