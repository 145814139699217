import pluralize from 'pluralize';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom-v5-compat';

import {
  createDocumentGroup,
  deleteDocumentGroup,
  editDocumentGroup,
  getAllDocumentGroups,
  styles,
} from '~/components/DocumentGroupsPage';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import EcModal from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import PageTitle from '~/components/Shared/PageTitle';
import ReactTable from '~/components/Shared/ReactTable';
import { PAGE_SIZE, PAGE_START } from '~/constants/page';
import { Button, EmptyPage, formatDate, Icon, Modal, Text } from '~/eds';
import { QueryParamType } from '~/enums';
import { FlagType, withFlags } from '~/flags';
import { withPermissions } from '~/hocs';
import { MODAL_DELETE, MODAL_NAME } from '~/types/modal.types';
import { ERROR, SUCCESS, WARNING } from '~/types/toast.types';
import {
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';

const PERMISSIONS = [
  {
    resourceId: 'document_groups',
    resourceType: 'edit',
  },
];
class Page extends Component {
  constructor(props) {
    super(props);

    this.onGroupCreate = this.onGroupCreate.bind(this);
    this.onGroupEdit = this.onGroupEdit.bind(this);
    this.onGroupDelete = this.onGroupDelete.bind(this);

    this.state = {
      loadingData: false,
      groupData: [],
      selectedItem: null,
      currentModal: null,
    };

    this.urlParams = {
      page: getPageSearchQueryByKey(QueryParamType.Page, PAGE_START),
      pageSize: getPageSearchQueryByKey(QueryParamType.PageSize, PAGE_SIZE),
      sortedColumn: getPageSearchQueryByKey(
        QueryParamType.SortedColumn,
        'lastModified',
      ),
      sortedOrder: getPageSearchQueryByKey(QueryParamType.SortedOrder, 'true'),
    };
  }

  componentDidMount() {
    this.setState({ ...this.urlParams }, () => {
      this.loadDocumentGroupsData();
      updatePageSearchQuery(this.urlParams);
    });
  }

  loadDocumentGroupsData() {
    this.setState({ loadingData: true });
    getAllDocumentGroups()
      .then((groupData) => {
        this.setState({
          groupData,
          loadingData: false,
          errorLoadingMessage: false,
        });
      })
      .catch(() => {
        this.setState({ loadingData: false, errorLoadingMessage: true });
      });
  }

  handleShowModal = (currentModal, selectedItem = null) => {
    this.setState({ currentModal, selectedItem });
  };

  handleHideModal = () => {
    this.setState({ currentModal: null, selectedItem: null });
  };

  onGroupCreate(name, note) {
    createDocumentGroup({ name, note })
      .then((res) => {
        showToast(SUCCESS, `${res.name} has been created.`);
        this.setState(
          {
            page: PAGE_START,
            sortedColumn: 'lastModified',
            sortedOrder: 'true',
          },
          () => {
            this.loadDocumentGroupsData();
            updatePageSearchQuery({
              [QueryParamType.Page]: PAGE_START,
              [QueryParamType.SortedColumn]: 'lastModified',
              [QueryParamType.SortedOrder]: 'true',
            });
          },
        );
        this.handleHideModal();
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          showToast(ERROR, `${err.response?.data?.message}`);
        } else {
          showToast(
            WARNING,
            'An error occurred while creating a new document group.',
          );
        }
      });
  }

  onGroupEdit(name, note) {
    const { selectedItem } = this.state;
    editDocumentGroup(selectedItem.groupId, { name, note })
      .then((res) => {
        showToast(SUCCESS, `${res.name} has been updated.`);
        this.loadDocumentGroupsData();
        this.handleHideModal();
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          showToast(ERROR, `${err.response?.data?.message}`);
        } else {
          showToast(
            WARNING,
            'An error occurred while updating a new document group.',
          );
        }
      });
  }

  onGroupDelete() {
    const { selectedItem } = this.state;
    deleteDocumentGroup(selectedItem.groupId)
      .then(() => {
        showToast(SUCCESS, 'The group has been deleted.');
        this.loadDocumentGroupsData();
        this.handleHideModal();
      })
      .catch(() => {
        showToast(
          WARNING,
          'An error occurred while deleting the document group.',
        );
      });
  }

  hasEditPermission = () => {
    const { checkPermission } = this.props;

    return checkPermission('document_groups', 'edit');
  };

  renderNameCell = (item) => {
    const { classes } = this.props;

    return (
      <Link className={classes.nameCell} to={`/document-group/${item.groupId}`}>
        {item.name}
      </Link>
    );
  };

  renderActionMenuCell = (item) => {
    const items = [
      {
        content: 'Edit',
        onClick: () => this.handleShowModal(MODAL_NAME, item),
      },
      {
        content: 'Delete',
        onClick: () => this.handleShowModal(MODAL_DELETE, item),
      },
    ];

    return (
      <ActionsMenu
        id={`documents_row_actions_menu?groupId=${item.groupId}`}
        disabled={!this.hasEditPermission()}
        items={items}
        smallIcon
        title="edit group documents menu"
      />
    );
  };

  renderDateAddedCell = (item) => {
    return formatDate(new Date(item.dateAdded ? item.dateAdded : null), 'long');
  };

  renderLastUpdatedDateCell = (item) => {
    return formatDate(
      item.lastModified ? new Date(item.lastModified) : null,
      'long',
    );
  };

  renderNumberOfDocumentsCell = (row) => {
    return `${row.numberOfDocs} ${pluralize('document', row.numberOfDocs)}`;
  };

  renderDocumentGroupsTable() {
    const { groupData, page, pageSize, sortedColumn, sortedOrder } = this.state;
    let columns = [
      {
        label: '',
        value: 'actionMenu',
        width: 56,
        sortable: false,
        renderCustomCell: this.renderActionMenuCell,
      },
      {
        label: 'Name',
        value: 'name',
        width: 400,
        resizable: true,
        renderCustomCell: this.renderNameCell,
      },
      { label: 'Created By', value: 'createdBy' },
      {
        label: 'Created Date',
        value: 'dateAdded',
        disableGlobalFilter: true,
        renderCustomCell: this.renderDateAddedCell,
      },
      {
        label: 'Last Updated',
        value: 'lastModified',
        disableGlobalFilter: true,
        renderCustomCell: this.renderLastUpdatedDateCell,
      },
      {
        label: '# of Documents',
        value: 'numberOfDocs',
        disableGlobalFilter: true,
        renderCustomCell: this.renderNumberOfDocumentsCell,
      },
      { label: 'Notes', value: 'note', width: 400 },
    ];

    const disableSorting = this.props.flags[
      FlagType.DisableDocumentTableSorting
    ];
    if (disableSorting) {
      columns = columns.map((column) => ({
        ...column,
        sortable: false,
      }));
    }

    const tableSettings = {
      page,
      pageSize,
      sortedColumn: disableSorting ? '' : sortedColumn,
      sortedOrder: disableSorting ? 'false' : sortedOrder,
    };

    return (
      <ReactTable
        columns={columns}
        data={groupData.results}
        title="document groups"
        handleTablePropsChange={updatePageSearchQuery}
        settings={tableSettings}
      />
    );
  }

  renderEmptyDocumentGroups() {
    return (
      <EmptyPage
        preset="no-groups"
        resolveAction={{
          icon: 'plus',
          id: 'documents_groups_empty_newGroupButton',
          onClick: () => this.handleShowModal(MODAL_NAME),
          text: 'New Group',
          disabled: !this.hasEditPermission(),
        }}
      />
    );
  }

  renderNameModal() {
    const { selectedItem } = this.state;

    let title;
    const labelText = 'GROUP NAME';
    let confirmButtonIcon;
    let confirmButtonText;
    let currentName;
    const labelTextArea = 'NOTE';
    let itemsCurrentDescription;
    const includeTextArea = true;
    let handleNameChange;

    if (selectedItem) {
      title = 'Edit Group';
      currentName = selectedItem.name;
      itemsCurrentDescription = selectedItem.note;
      confirmButtonText = 'Save';
      handleNameChange = this.onGroupEdit;
    } else {
      title = 'New Group';
      currentName = '';
      itemsCurrentDescription = '';
      confirmButtonIcon = <Icon icon="plus" />;
      confirmButtonText = 'Create Group';
      handleNameChange = this.onGroupCreate;
    }

    return (
      <EcModal
        modalType={MODAL_NAME}
        width="560px"
        title={title}
        labelText={labelText}
        itemsCurrentName={currentName}
        includeTextArea={includeTextArea}
        labelTextArea={labelTextArea}
        itemsCurrentDescription={itemsCurrentDescription}
        confirmButtonIcon={confirmButtonIcon}
        confirmButtonText={confirmButtonText}
        handleNameChange={handleNameChange}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderDeleteGroupModalText() {
    const { selectedItem } = this.state;

    return (
      <Fragment>
        Are you sure you want to delete the group
        <span> {selectedItem.name}</span>? You won’t be able to undo this
        action.
      </Fragment>
    );
  }

  renderDeleteModal() {
    return (
      <Modal
        children={<Text>{this.renderDeleteGroupModalText()}</Text>}
        isVisible
        onHide={this.handleHideModal}
        onCancel={this.handleHideModal}
        primaryAction={{
          text: 'Delete',
          onClick: () => {
            this.onGroupDelete();
            this.handleHideModal();
          },
          variant: 'danger',
        }}
        title="Delete Group?"
      />
    );
  }

  render() {
    const { classes } = this.props;
    const {
      groupData,
      currentModal,
      loadingData,
      errorLoadingMessage,
    } = this.state;

    const { isPermissionsLoading } = this.props;

    const loading = loadingData || isPermissionsLoading;

    return (
      <div>
        <div className={classes.documentHeader}>
          <div className={classes.documentFullTitle}>
            <PageTitle title="Document Groups" />
          </div>
          <div className={classes.documentHeaderActions}>
            {!loading && groupData.results && groupData.results.length ? (
              <Button
                id="documents_header_newGroupButton"
                onClick={() => {
                  this.handleShowModal(MODAL_NAME);
                }}
                text="New Group"
                icon="plus"
                iconPosition="left"
                variant="primary"
                disabled={!this.hasEditPermission()}
              ></Button>
            ) : null}
          </div>
        </div>
        {loading && !errorLoadingMessage ? (
          <div className={classes.loadingContainer}>
            <LoadingSpinner size="medium" />
          </div>
        ) : !loading && errorLoadingMessage ? (
          <div>An error occurred when loading the document groups.</div>
        ) : !loading &&
          !errorLoadingMessage &&
          groupData &&
          groupData.results &&
          groupData.results.length ? (
          this.renderDocumentGroupsTable()
        ) : (
          this.renderEmptyDocumentGroups()
        )}
        {currentModal === MODAL_NAME && this.renderNameModal()}
        {currentModal === MODAL_DELETE && this.renderDeleteModal()}
      </div>
    );
  }
}

export default withPermissions(
  injectSheet(styles)(withFlags(Page)),
  PERMISSIONS,
);
