import { noop } from 'lodash';
import React, { memo } from 'react';

import {
  SharedChipProps,
  SharedModalProps,
  SharedPanelProps,
} from '../../types';
import { Actions } from '../Actions';
import { Button } from '../Button';
import { Header } from '../Header';
import { Layout } from '../Layout';
import { PageOverlay } from '../PageOverlay';

interface Props extends SharedModalProps {
  /** Optional panel (only supported when `isFullPage=true` */
  panel?: SharedPanelProps;
  /** Optional informative chip for the header **/
  chips?: SharedChipProps[];
}

/**
 * `Modal` is a controlled component that renders popover content with action buttons.
 *
 * `Modal` visibiliy behaviors
 * - `isVisible` controls the visibility
 * - `onHide` callback is called when the modal is being hidden.
 * - `onCancel`, when specified, renders the `ButtonCancel`.  It also attaches this callback to the following actions:
 *   1. Cancel button
 *   2. Close icon
 *   3. `Escape` keypress
 */
export const Modal = memo(
  ({
    children,
    backAction: providedBackAction,
    backgroundGradient,
    cancelText = 'Cancel',
    chips,
    disableHideOnEscape,
    enableHideOnPrimaryAction,
    enableContentPadding,
    headerCalloutContent,
    isFullPage,
    isStretched,
    isVisible = false,
    leftActions: providedLeftActions,
    loadingContent,
    placeholderContent,
    primaryAction,
    overflow,
    secondaryActions = [],
    disableFooter = false,
    panel,
    title,
    titleLink,
    width = 'm',
    onCancel = noop,
    onHide,
  }: Props) => {
    const hasCancel = Boolean(onCancel);

    const handleCancel = () => {
      onCancel?.();
      onHide?.();
    };

    const header = (
      <Header
        actions={
          hasCancel
            ? [
                {
                  icon: 'x',
                  mode: 'icon',
                  text: 'Close',
                  tooltip: 'Close',
                  onClick: handleCancel,
                },
              ]
            : []
        }
        calloutContent={headerCalloutContent}
        size={isFullPage ? 'l' : 'm'}
        title={title}
        titleLink={titleLink}
        chips={chips}
      />
    );

    // ensures certain properties of backAction cannot be configured
    const backAction = providedBackAction
      ? {
          ...providedBackAction,
          icon: undefined,
          level: 'tertiary' as const,
        }
      : null;

    // Combine backAction and leftActions
    const leftActions =
      backAction && providedLeftActions
        ? [backAction, ...providedLeftActions]
        : backAction
        ? [backAction]
        : providedLeftActions
        ? providedLeftActions
        : null;

    const footer = (
      // TODO: refactor to use `Footer`
      <Layout
        align="center"
        flex="none"
        h="footer.height"
        justify="space-between"
        px={6}
      >
        {leftActions ? <Actions actions={leftActions} /> : <div />}
        <Layout preset="buttons">
          {hasCancel && cancelText && (
            <Button
              text={cancelText}
              variant="tertiary"
              onClick={handleCancel}
            />
          )}
          {secondaryActions.map((secondaryAction, i) => (
            <Button key={i} {...secondaryAction} />
          ))}
          {primaryAction && (
            <Button
              variant="primary"
              {...primaryAction}
              onClick={() => {
                primaryAction.onClick?.();
                if (enableHideOnPrimaryAction) {
                  onHide?.();
                }
              }}
            />
          )}
        </Layout>
      </Layout>
    );

    return (
      <PageOverlay
        backgroundGradient={backgroundGradient}
        disableHideOnClickOutside
        disableHideOnEscape={disableHideOnEscape}
        enableContentPadding={enableContentPadding}
        footer={!disableFooter && footer}
        header={header}
        isFullPage={isFullPage}
        isStretched={isStretched}
        isVisible={isVisible}
        loadingContent={loadingContent}
        panel={panel}
        placeholderContent={placeholderContent}
        width={width}
        overflow={overflow}
        onHide={handleCancel}
      >
        {children}
      </PageOverlay>
    );
  },
);
