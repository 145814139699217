import { get, groupBy, isArray } from 'lodash';

import {
  DEFAULT_PLACEHOLDER_LABEL,
  Folder,
  formatDate,
  Icon,
  Layout,
  Link,
  Text,
} from '~/eds';
import { FieldId } from '~/evifields';
import { SearchItem } from '~/redux/api/methods';
import { SearchFilter } from '~/types';

type Props = {
  staticColumns: Array<any>;
  searchFilters: Record<FieldId, SearchFilter>;
};

export const useEvisearchColumns = ({
  staticColumns,
  searchFilters,
}: Props) => {
  const searchFiltersList = Object.values(searchFilters)
    .filter((filter) => filter.is_requestable_column)
    .map(fieldToColumn);

  const dynamicColumns = searchFiltersList.filter(
    (column) =>
      !staticColumns.some((staticColumn) => staticColumn.key === column.key),
  );
  const columns = [...staticColumns, ...dynamicColumns];
  const columnGroups = Object.entries(
    groupBy(
      columns.filter((column) => column.section),
      'section',
    ),
  ).map(([section, group]) => ({
    name: section,
    label: section,
    columns: group.map((column) => column.key),
  }));

  return { columns, columnGroups };
};

const fieldToColumn = (searchFilter: SearchFilter) => {
  switch (searchFilter.id) {
    case 'document_group_Document':
      return {
        key: searchFilter.id,
        title: searchFilter.label,
        minWidth: 'm',
        disableSortBy: true,
        section: searchFilter.section,
        renderCell: getGroupCell,
      };
    case 'folder_Document':
      return {
        key: searchFilter.id,
        title: searchFilter.label,
        minWidth: 'm',
        disableSortBy: true,
        section: searchFilter.section,
        renderCell: getFolderCell,
      };

    default:
      return {
        key: searchFilter.id,
        title: searchFilter.label,
        disableSortBy: false,
        section: searchFilter.section,
        cellType: getCellType(searchFilter.type),
        mapCellProps: (searchItem: SearchItem) => {
          const value = get(
            searchItem,
            `selected_field_values[${searchFilter.id}].value`,
            [],
          );
          const rawValue = isArray(value) ? value.join(', ') : value;
          return {
            text: getText(rawValue),
            datetime: isDate(rawValue) ? new Date(rawValue) : undefined,
          };
        },
      };
  }
};

export const getGroupCell = (document: SearchItem) => {
  const group = get(
    document,
    `selected_field_values[document_group_Document].value_structured[0]`,
    {},
  );

  return group.id ? (
    <Layout align="center" spacing={2}>
      <Icon icon="groups" role="presentation" />
      <Link pathname={`/document-group/${group.id}`}>{group.name}</Link>
    </Layout>
  ) : (
    DEFAULT_PLACEHOLDER_LABEL
  );
};

export const getFolderCell = (document: SearchItem) => {
  const handlers = get(
    document,
    'selected_field_values.document_handler_id_Document.value_structured',
    [],
  );
  if (handlers?.length > 1) {
    return (
      <Layout align="center" spacing={2}>
        <Icon icon="folder" role="presentation" />
        <Text>Multiple Locations</Text>
      </Layout>
    );
  } else {
    const folder = get(
      document,
      'selected_field_values.folder_Document.value_structured[0]',
    );
    return folder ? (
      <Folder
        link={{
          pathname: `/documents/${folder.id}`,
        }}
        path={folder.name}
        name={folder.name}
      />
    ) : (
      DEFAULT_PLACEHOLDER_LABEL
    );
  }
};

const isDate = (value: string) => {
  return !isNaN(Date.parse(value));
};

const isBoolean = (value: string) => {
  return value === 'true' || value === 'false';
};

export const getText = (value: string) => {
  if (!value) return DEFAULT_PLACEHOLDER_LABEL;

  if (isDate(value)) {
    return formatDate(new Date(value));
  } else if (isBoolean(value)) {
    return value === 'true' ? 'Yes' : 'No';
  }
  return value;
};

export const getCellType = (value: string) => {
  switch (value) {
    case 'date':
      return 'datetime';

    default:
      return 'text';
  }
};
