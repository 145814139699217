import axios from 'axios';
import { saveAs } from 'file-saver';
import React, { Component, Fragment, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { sendInvitationEmail } from '~/api';
import { black1 } from '~/assets/shared-styles/general';
import AdminConsolePageNavHeader from '~/components/Admin/AdminConsolePageNavHeader';
import ImportJobsPolling from '~/components/Admin/ImportJobsPolling';
import {
  BulkImportUserWizard,
  createUser,
  deleteUser,
  downloadImportResultsExcelFile,
  exportUsersExcelFile,
  getClientUsers,
  importUsersListExcelFile,
  startImportUsersListProcessing,
  styles,
  updateUser,
  UserImportStatusBarList,
} from '~/components/Admin/UsersPage';
import { trackSegment } from '~/components/SegmentAnalytics';
import EcCard from '~/components/Shared/EcCard';
import EcModal from '~/components/Shared/EcModal';
import EcPaginate from '~/components/Shared/EcPaginate';
import { showToast } from '~/components/Shared/EcToast';
import EcUserTable from '~/components/Shared/EcUserTable';
import withHover from '~/components/Shared/HOCs/withHover';
import BackIcon from '~/components/Shared/Icons/BackIcon';
import CheckmarkIcon from '~/components/Shared/Icons/CheckmarkIcon';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import PlusIcon from '~/components/Shared/Icons/PlusIcon';
import { PAGE_START } from '~/constants/page';
import { Button, formatDate, Markdown, Modal } from '~/eds';
import {
  BulkImportUserType,
  CodedErrorCodeType,
  FeatureFlagType,
  QueryParamType,
  UserRoleType,
} from '~/enums';
import { OnboardingIdType } from '~/features/onboarding';
import { FlagType, withFlags } from '~/flags';
import { testHasFlag } from '~/permissions';
import { RoutePathType, withRouting } from '~/routing';
import {
  MODAL_ADMIN_USER,
  MODAL_DELETE,
  MODAL_DEPARTMENT_TREE,
  MODAL_SEND_EMAIL,
} from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';
import { FlexLayout } from '~/ui';
import { parseApiError } from '~/utils/parseApiError';
import {
  getPageSearchQueryByKey,
  updatePageSearchQuery,
} from '~/utils/searchQuery';
import { createPassword, getClientInfo, getUserClientInfo } from '~/utils/user';

const BackIconWithHover = withHover(BackIcon);
const PAGE_SIZE = 10; //BE defines

const getPositionName = (positionName) => {
  if (positionName === 'Power User - Edit Capabilites') return 'Power User';
  if (positionName === 'Workflow Only') return 'Workflow Only User';
  return positionName;
};

const getClientId = (props) => {
  const {
    params: { clientId },
  } = props;

  return clientId;
};

class Page extends Component {
  constructor(props) {
    super(props);

    this.navigateBack = this.navigateBack.bind(this);
    this.onUserCreate = this.onUserCreate.bind(this);
    this.onUserUpdate = this.onUserUpdate.bind(this);
    this.onUserDelete = this.onUserDelete.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleShowSecondaryModal = this.handleShowSecondaryModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleHideSecondaryModal = this.handleHideSecondaryModal.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleFormFieldsUpdate = this.handleFormFieldsUpdate.bind(this);
    this.importStatusRequestSource = axios.CancelToken.source();

    this.state = {
      searchText: '',
      page: 1,
      usersListTotalCount: 0,
      usersList: [],
      departmentTree: null,
      departmentsSelected: [],
      currentModal: null,
      secondaryModal: null,
      selectedUser: null,
      errorMessage: '',
      newUserData: this.getDefaultUserData(),
      importStep: BulkImportUserType.Upload,
      importStepLoading: false,
      importSummaryData: null,
      showImportWizard: false,
      isUpdatingUser: false,
    };
  }

  componentDidMount() {
    const urlParams = {
      page: getPageSearchQueryByKey(QueryParamType.Page, PAGE_START),
      searchText: getPageSearchQueryByKey(QueryParamType.SearchText, ''),
    };
    this.setState({ ...urlParams }, () => {
      this.loadUserList();
      updatePageSearchQuery({
        [QueryParamType.Page]: urlParams.page,
        [QueryParamType.SearchText]: urlParams.searchText,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedUser !== this.state.selectedUser &&
      this.state.selectedUser
    ) {
      this.setState({
        departmentsSelected: this.state.selectedUser.departments,
      });
    }
  }

  getClientId = () => {
    return getClientId(this.props);
  };

  loadUserList() {
    const { page, searchText } = this.state;

    this.setState({ loading: true });

    getClientUsers(this.getClientId(), page, searchText)
      .then((res) => {
        let list = [];
        res.results.map((item) => {
          const position = getPositionName(item.position_name);

          const positionName =
            position +
            (item.is_workflow_admin ? ' • Workflow Admin' : '') +
            (item.is_automation_admin ? ' • Automation Admin' : '');

          return list.push({
            id: item.id,
            email: item.email,
            firstName: item.first_name,
            lastName: item.last_name,
            lastLogin: item.last_login,
            role: item.role,
            positionName,
            password: item.password,
            departments: item.departments,
            departmentsSelected: item.departments,
            jobTitle: item.job_title,
            workflowAdmin: item.is_workflow_admin,
            automationAdmin: item.is_automation_admin,
          });
        });

        this.setState({
          usersList: list,
          usersListTotalCount: res.count,
          loading: false,
          errorLoadingUsers: false,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          this.setState({
            loading: false,
            errorLoadingUsers: false,
            noAccess: true,
          });
        } else {
          this.setState({ loading: false, errorLoadingUsers: true });
        }
      });
  }

  navigateBack() {
    this.props.navigate(-1);
  }

  getDefaultUserData() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      role: UserRoleType.PowerUserWithEdit,
      password: '',
      departments: [],
      workflowAdmin: false,
      automationAdmin: false,
      shouldSendEmail: true,
    };
  }

  onUserCreate = async (
    email,
    firstName,
    lastName,
    role,
    password,
    jobTitle,
    workflowAdmin,
    automationAdmin,
    shouldSendEmail = false,
  ) => {
    const { currentUser } = this.props;
    let first_password = password || this.state.newUserData.password;
    const { departmentsSelected, selectedUser } = this.state;
    const departmentsToSubmit = departmentsSelected
      ? departmentsSelected?.map((d) => d.department_id)
      : selectedUser
      ? selectedUser.departmentsSelected?.map((d) => d.department_id)
      : [];

    try {
      const res = await createUser(
        this.getClientId(),
        email,
        firstName,
        lastName,
        jobTitle,
        role,
        first_password,
        departmentsToSubmit,
        workflowAdmin,
        automationAdmin,
      );

      if (shouldSendEmail) {
        this.handlePasswordReset(res);
      }

      this.handleHideModal();
      this.setState({
        errorMessage: '',
        newUserData: this.getDefaultUserData(),
      });
      showToast(
        SUCCESS,
        `User "${res.first_name} ${res.last_name}" has been added to the system.`,
      );
      this.loadUserList();

      trackSegment('New User Created', {
        shouldSendEmail,
        groupId: getClientInfo(currentUser),
        userId: getUserClientInfo(currentUser),
        userCreatedDate: formatDate(new Date(), 'utc'),
        userLoginEmailSentDate: shouldSendEmail
          ? formatDate(new Date(), 'utc')
          : '',
      });
    } catch (err) {
      if (err.response) {
        const response = JSON.parse(err.response.request.response);
        if (response['non_field_errors']) {
          this.handleAddUserError(
            'Please enter an unregistered email.',
            firstName,
            lastName,
            email,
            role,
            password,
          );
        } else if (response['email']) {
          this.handleAddUserError(
            'Please enter a valid email address.',
            firstName,
            lastName,
            email,
            role,
            password,
          );
        } else if (err.response.request.response.startsWith('["password ')) {
          this.handleAddUserError(
            'Please enter a password that meets the requirements.',
            firstName,
            lastName,
            email,
            role,
            password,
          );
        } else {
          this.handleAddUserError(
            'Please check the values you entered.',
            firstName,
            lastName,
            email,
            role,
            password,
          );
        }
      }
    }
  };

  onUserUpdate(
    email,
    firstName,
    lastName,
    role,
    password,
    jobTitle,
    workflowAdmin,
    automationAdmin,
  ) {
    const { currentUser } = this.props;
    const { selectedUser, departmentsSelected } = this.state;
    const departmentsToSubmit = departmentsSelected
      ? departmentsSelected?.map((d) => d.department_id)
      : selectedUser
      ? selectedUser.departmentsSelected?.map((d) => d.department_id)
      : [];

    updateUser(
      selectedUser.id,
      email,
      firstName,
      lastName,
      role,
      departmentsToSubmit,
      jobTitle,
      workflowAdmin,
      automationAdmin,
    )
      .then(() => {
        showToast(
          SUCCESS,
          `User information for "${selectedUser.firstName}  ${selectedUser.lastName}" has been updated.`,
        );
        this.handleHideModal();
        this.loadUserList();
        if (selectedUser.id === currentUser.id) {
          window.location.reload();
        }
      })
      .catch((err) => {
        const response = JSON.parse(err.response.request.response);
        if (response['non_field_errors']) {
          this.handleAddUserError(
            'Please enter an unregistered email.',
            firstName,
            lastName,
            email,
            role,
          );
        } else if (response['email']) {
          this.handleAddUserError(
            'Please enter a valid email address.',
            firstName,
            lastName,
            email,
            role,
          );
        } else {
          this.handleAddUserError(
            'Please check the values you entered.',
            firstName,
            lastName,
            email,
            role,
          );
        }
      });
  }

  onUserDelete() {
    const { currentUser } = this.props;
    const { selectedUser } = this.state;

    deleteUser(selectedUser.id)
      .then(() => {
        let newUsersList = [...this.state.usersList];
        newUsersList = newUsersList.filter(
          (user) => user.id !== selectedUser.id,
        );
        showToast(
          SUCCESS,
          `"${selectedUser.firstName}  ${selectedUser.lastName}" has been removed from the system.`,
        );
        this.setState({ usersList: newUsersList });
        trackSegment('User Deleted', {
          groupId: getClientInfo(currentUser),
          userId: getUserClientInfo(currentUser),
          userIdDeleted: selectedUser.id,
          userDeletedDate: formatDate(new Date(), 'utc'),
        });
      })
      .catch((err) => showToast(ERROR, err.message));
  }

  handleNameChange(
    email,
    firstName,
    lastName,
    role,
    password,
    jobTitle,
    workflowAdmin,
    automationAdmin,
    shouldSendEmail = false,
  ) {
    const { forEditing, isUpdatingUser } = this.state;
    if (isUpdatingUser) return;

    const callback = forEditing ? this.onUserUpdate : this.onUserCreate;
    this.setState({ isUpdatingUser: true }, () =>
      callback(
        email,
        firstName,
        lastName,
        role,
        password,
        jobTitle,
        workflowAdmin,
        automationAdmin,
        shouldSendEmail,
      ),
    );
  }

  handleShowModal(modal, forEditing, selectedUser = null) {
    this.setState({
      currentModal: modal,
      forEditing,
      selectedUser,
    });
  }

  handleHideModal() {
    this.setState({
      currentModal: null,
      selectedUser: null,
      departmentsSelected: null,
      newUserData: {
        firstName: '',
        lastName: '',
        email: '',
        role: UserRoleType.PowerUserWithEdit,
        password: '',
        departments: [],
        departmentsSelected: null,
        jobTitle: '',
        shouldSendEmail: true,
      },
      errorMessage: '',
      importStep: BulkImportUserType.Upload,
      importStepLoading: false,
      isUpdatingUser: false,
    });
  }

  handlePageClick(clickedPage) {
    const { searchText } = this.state;
    const selectedPage = clickedPage.selected + 1;

    this.setState({ page: selectedPage, loading: true }, () => {
      this.loadUserList();
      updatePageSearchQuery(
        {
          [QueryParamType.Page]: selectedPage,
          [QueryParamType.SearchText]: searchText,
        },
        true,
      );
    });
  }

  handleInputChangeSearchText = (e) => {
    const { value } = e.target;

    this.setState({ searchText: value, page: 1 }, () => {
      this.loadUserList();
      updatePageSearchQuery({
        [QueryParamType.Page]: 1,
        [QueryParamType.SearchText]: value,
      });
    });
  };

  handleAddUserError(
    errorMessage,
    firstName = '',
    lastName = '',
    email = '',
    role = 1,
    password = '',
    workflowAdmin = false,
    automationAdmin = false,
  ) {
    const newUserData = {
      firstName,
      lastName,
      email,
      role,
      password,
      workflowAdmin,
      automationAdmin,
    };

    this.setState({ errorMessage, newUserData, isUpdatingUser: false });
  }

  handlePasswordReset = async (user) => {
    const { currentUser } = this.props;
    const selectedUser = user || this.state.selectedUser;
    try {
      await sendInvitationEmail({ userId: selectedUser.id });
      showToast(
        SUCCESS,
        `We have sent a reset password link to "${selectedUser.email}"`,
      );
      trackSegment('Admin Sent Reset Password Email', {
        groupId: getClientInfo(currentUser),
        userId: getUserClientInfo(currentUser),
        userIdResetPasswordSent: selectedUser.id,
        adminResetPasswordEmailSentDate: formatDate(new Date(), 'utc'),
      });
    } catch (err) {
      showToast(ERROR, err.message);
    }
  };

  handleFormFieldsUpdate(form) {
    this.setState({ selectedUser: form });
  }

  sortUsers() {
    let newUsersList = [...this.state.usersList];
    newUsersList.sort((a, b) => (a.id < b.id ? 1 : -1));

    return newUsersList;
  }

  renderUserModal() {
    const {
      selectedUser,
      newUserData,
      errorMessage,
      departmentsSelected,
      forEditing,
      isUpdatingUser,
    } = this.state;
    const newUserPassword = createPassword();

    const selectedDept = departmentsSelected
      ? departmentsSelected
      : selectedUser
      ? selectedUser.departmentsSelected
      : [];

    return (
      <EcModal
        modalType={MODAL_ADMIN_USER}
        width="560px"
        formFields={{
          email: selectedUser ? selectedUser.email : newUserData.email,
          firstName: selectedUser
            ? selectedUser.firstName
            : newUserData.firstName,
          lastName: selectedUser ? selectedUser.lastName : newUserData.lastName,
          password: selectedUser ? selectedUser.password : newUserPassword,
          departmentsSelected: selectedDept,
          id: selectedUser ? selectedUser.id : null,
          role: selectedUser ? selectedUser.role : newUserData.role,
          jobTitle: selectedUser ? selectedUser.jobTitle : newUserData.jobTitle,
          workflowAdmin: selectedUser
            ? selectedUser.workflowAdmin
            : newUserData.workflowAdmin,
          automationAdmin: selectedUser
            ? selectedUser.automationAdmin
            : newUserData.automationAdmin,
          shouldSendEmail: selectedUser
            ? selectedUser.shouldSendEmail
            : newUserData.shouldSendEmail,
        }}
        title={forEditing ? 'Edit User' : 'Add User'}
        confirmButtonIcon={
          forEditing ? null : <PlusIcon size="20" color={black1} />
        }
        confirmButtonText={forEditing ? 'Save' : 'Create User'}
        confirmButtonDisabled={isUpdatingUser}
        onUserFormUpdate={this.handleFormFieldsUpdate}
        handleNameChange={this.handleNameChange}
        hideModal={this.handleHideModal}
        handleShowModal={this.handleShowModal}
        handleShowSecondaryModal={this.handleShowSecondaryModal}
        errorMessage={errorMessage}
        forEditing={forEditing}
        clientId={this.getClientId()}
      />
    );
  }

  handleShowSecondaryModal(modal) {
    this.setState({ secondaryModal: modal });
  }

  handleHideSecondaryModal() {
    this.setState({ secondaryModal: null });
  }

  handleDepartmentChange(departmentsSelected) {
    this.setState({ departmentsSelected });
  }

  handleUserDownloadClick = (onlyHeaders) => {
    let filename = 'evisort-users-import-template.xlsx';
    let customErrorCode =
      CodedErrorCodeType.BulkExportUserTemplateErrorDownloading;
    if (!onlyHeaders) {
      filename =
        'evisort-users-' + formatDate(new Date(), 'iso_datetime') + '.xlsx';
      customErrorCode = CodedErrorCodeType.BulkExportUserErrorDownloading;
    }

    exportUsersExcelFile({ clientId: this.getClientId(), onlyHeaders })
      .then((res) => {
        showToast(SUCCESS, 'File downloaded successfully.');
        saveAs(res, filename);
      })
      .catch((errorResponse) => {
        this.handleApiError(errorResponse, customErrorCode);
      });
  };

  handleUserListUploadClick = (file) => {
    this.setState({ importStepLoading: true });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('client_id', this.getClientId());

    importUsersListExcelFile(formData)
      .then((response) => {
        showToast(SUCCESS, 'Users List file has been imported successfully.');
        this.setState({
          importStep: BulkImportUserType.Summary,
          importStepLoading: false,
          importSummaryData: response,
        });
      })
      .catch((errorResponse) => {
        this.handleApiError(
          errorResponse,
          CodedErrorCodeType.BulkImportUserErrorSubmittingFile,
        );
        this.setState({
          importStep: BulkImportUserType.Upload,
          importStepLoading: false,
        });
      });
  };

  handleSelectedImportStep = (importStep) => {
    this.setState({ importStep });
  };

  handleImportResultsFileDownloadClick = (resultType) => {
    const importId = this.state.importSummaryData.import_id;

    downloadImportResultsExcelFile({
      clientId: this.getClientId(),
      importId,
      resultType,
    })
      .then((res) => {
        showToast(SUCCESS, `${resultType} file downloaded successfully.`);
        const timestamp = formatDate(new Date(), 'iso_datetime');
        saveAs(res, `evisort-users-${resultType}-${timestamp}.xlsx`);
      })
      .catch((errorResponse) => {
        this.handleApiError(
          errorResponse,
          CodedErrorCodeType.BulkImportErrorDownloadingJobResult,
          { resultType },
        );
      });
  };

  handleStartImportUserBulkProcessing = (importActions, hideModalFn) => {
    this.setState({ importStepLoading: true });
    startImportUsersListProcessing({
      client_id: this.getClientId(),
      import_id: this.state.importSummaryData.import_id,
      email_new_users: importActions.emailNewUsers,
      update_existing_users: importActions.updateExistingUsers,
      delete_missing_users: importActions.deleteMissingUsers,
    })
      .then(() => {
        showToast(
          SUCCESS,
          'Request to start Import Users List processing successfully queued.',
        );
        this.props.setImportJobId(this.state.importSummaryData.import_id);
        this.handleHideImportWizard();
        hideModalFn();
      })
      .catch((errorResponse) => {
        this.handleApiError(
          errorResponse,
          CodedErrorCodeType.BulkImportUserErrorQueuingJob,
        );
        this.setState({ importStepLoading: false });
      });
  };

  handleShowImportWizard = () => {
    this.setState({ showImportWizard: true });
  };

  handleHideImportWizard = () => {
    this.setState({
      showImportWizard: false,
      importStep: BulkImportUserType.Upload,
      importStepLoading: false,
    });
  };

  handleApiError = (
    errorResponse,
    customErrorCode,
    pairsKeyValueToFillTemplate = {},
  ) => {
    const error = parseApiError(
      errorResponse,
      customErrorCode,
      pairsKeyValueToFillTemplate,
    );
    showToast(ERROR, error.message);
  };

  renderDepartmentTreeModal() {
    const { departmentTree, selectedUser, departmentsSelected } = this.state;
    const deptsSelected = departmentsSelected
      ? departmentsSelected
      : selectedUser
      ? selectedUser.departments
      : [];

    return (
      <EcModal
        modalType={MODAL_DEPARTMENT_TREE}
        width="540px"
        title="Select a Department"
        client={this.getClientId()}
        departmentTree={departmentTree}
        departmentsSelected={deptsSelected}
        handleDepartmentChange={this.handleDepartmentChange}
        confirmButtonIcon={<CheckmarkIcon size="20" />}
        confirmButtonText="Select Department"
        hideModal={this.handleHideSecondaryModal}
      />
    );
  }

  renderSendPasswordEmailModalText() {
    const { selectedUser } = this.state;

    return (
      <Fragment>
        Are you sure you want to send? Clicking this button will send an email
        to the user&nbsp;
        <span>
          {selectedUser.firstName} {selectedUser.lastName}
        </span>
        &nbsp;at
        <span>
          &nbsp;
          {'('}
          {selectedUser.email}
          {')'}
        </span>
        &nbsp;with a link to set a new password.
      </Fragment>
    );
  }

  renderSendPasswordEmailModal() {
    return (
      <EcModal
        modalType={MODAL_SEND_EMAIL}
        width="560px"
        title="Send Link to Set New Password"
        text={this.renderSendPasswordEmailModalText()}
        confirmButtonText="Send Link"
        hideModal={this.handleHideModal}
        resetPassword={this.handlePasswordReset}
      />
    );
  }

  renderDeleteModal() {
    const { selectedUser } = this.state;
    return (
      <Modal
        children={
          <Markdown
            text={`Are you sure you want to delete ${selectedUser.firstName} ${selectedUser.lastName}? You won’t be able to undo this action.`}
          />
        }
        title="Delete Selected User?"
        isVisible
        onHide={this.handleHideModal}
        onCancel={this.handleHideModal}
        primaryAction={{
          text: 'Delete',
          variant: 'danger',
          onClick: () => {
            this.onUserDelete();
            this.handleHideModal();
          },
        }}
      />
    );
  }

  renderBulkImportUserWizard = () => {
    const { importStep, importStepLoading, importSummaryData } = this.state;
    return (
      <BulkImportUserWizard
        handleDownloadUserTemplateFile={this.handleUserDownloadClick}
        handleImportResultsFileDownload={
          this.handleImportResultsFileDownloadClick
        }
        handleSelectedStep={this.handleSelectedImportStep}
        onClose={this.handleHideImportWizard}
        importSummaryData={importSummaryData}
        onUploadActionClick={this.handleUserListUploadClick}
        step={importStep}
        stepLoading={importStepLoading}
        onStartImportUserBulkProcessing={
          this.handleStartImportUserBulkProcessing
        }
      />
    );
  };

  renderPagination() {
    const { classes } = this.props;
    const { page, usersListTotalCount } = this.state;

    return usersListTotalCount > PAGE_SIZE ? (
      <div className={classes.paginationWrapper}>
        <EcPaginate
          onPageChange={this.handlePageClick}
          pageCount={Math.ceil(usersListTotalCount / PAGE_SIZE)}
          forcePage={page - 1}
        />
      </div>
    ) : null;
  }

  renderUsersContent() {
    const { usersList } = this.state;
    const sortedUsers = this.sortUsers(usersList);

    return (
      <Fragment>
        <EcCard contentStyles={{ display: 'flex', padding: '0 16px' }}>
          <EcUserTable
            listItems={sortedUsers}
            handleShowModal={this.handleShowModal}
          />
        </EcCard>
        {this.renderPagination()}
      </Fragment>
    );
  }

  renderNavHeader() {
    const { classes } = this.props;

    return (
      <div className={classes.pageHeader}>
        <button
          className={classes.pageBackAction}
          onClick={() => this.navigateBack()}
        >
          <BackIconWithHover />
        </button>
        <div className={classes.pageTitle}>
          <span className={classes.subTitle}>ADMIN CONSOLE</span>
          <h2 className={classes.title}>Users</h2>
        </div>
      </div>
    );
  }

  render() {
    const {
      classes,
      importJobsData = [],
      handleAcknowledgeImportJobSuccessUpdate = () => {},
      flags,
    } = this.props;
    const {
      loading,
      errorLoadingUsers,
      noAccess,
      searchText,
      currentModal,
      secondaryModal,
      showImportWizard,
    } = this.state;

    if (flags[FlagType.NewUsersPage]) {
      return (
        <Redirect
          to={RoutePathType.AdminUsers.replace(':clientId', this.getClientId())}
        />
      );
    }

    const hasBulkUserFeatureFlag = testHasFlag(FeatureFlagType.BulkUser)(
      this.props.currentUser,
    );

    if (loading && !searchText) {
      return (
        <div className={classes.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    } else if (noAccess) {
      return (
        <div>
          <p>You do not have permission to access this page.</p>
          <p>To request permission, contact your organization administrator.</p>
        </div>
      );
    } else if (errorLoadingUsers) {
      return <p>An error occurred while loading this page.</p>;
    }

    return (
      <div className={classes.adminUsersTabContent}>
        <AdminConsolePageNavHeader
          title="Users"
          navigateBack={this.navigateBack}
        />
        <FlexLayout justifyContent="space-between" space={4}>
          <div className={classes.userSearchRow}>
            <div
              className={classes.userSearchContainer}
              data-testid="search-by-user-name"
            >
              <div className={classes.userSearchHeader}>
                SEARCH BY USER NAME
              </div>
              <DebounceInput
                autoFocus={true}
                debounceTimeout={500}
                id="admin_searchByUserName"
                placeholder="Search by user name"
                type="text"
                value={searchText}
                onChange={this.handleInputChangeSearchText}
              />
            </div>
          </div>
          <FlexLayout space={4} py={4}>
            {hasBulkUserFeatureFlag && (
              <Button
                text="Export Users"
                onClick={() => this.handleUserDownloadClick(false)}
              />
            )}
            {hasBulkUserFeatureFlag && (
              <Button
                id={OnboardingIdType.ImportNewUser}
                text="Import Users"
                onClick={() => this.handleShowImportWizard()}
              />
            )}
            <Button
              ariahaspopup
              id={OnboardingIdType.AddNewUser}
              text="Add New User"
              variant="primary"
              onClick={() => this.handleShowModal(MODAL_ADMIN_USER, false)}
            />
          </FlexLayout>
        </FlexLayout>

        {hasBulkUserFeatureFlag && (
          <UserImportStatusBarList
            importJobsData={importJobsData}
            clientId={this.getClientId()}
            handleAcknowledgeImportJobSuccessUpdate={
              handleAcknowledgeImportJobSuccessUpdate
            }
          />
        )}

        {this.renderUsersContent()}

        {currentModal === MODAL_ADMIN_USER ? this.renderUserModal() : null}
        {currentModal === MODAL_SEND_EMAIL
          ? this.renderSendPasswordEmailModal()
          : null}
        {currentModal === MODAL_DELETE ? this.renderDeleteModal() : null}
        {secondaryModal === MODAL_DEPARTMENT_TREE
          ? this.renderDepartmentTreeModal()
          : null}

        {showImportWizard && this.renderBulkImportUserWizard()}
      </div>
    );
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

const PageWithPolling = (props) => {
  const [importedJobId, setImportJobId] = useState(null);
  return (
    <ImportJobsPolling
      clientId={getClientId(props)}
      importedJobId={importedJobId}
      jobsKind="user"
      nullifyImportedJobIdProp={() => setImportJobId(null)}
    >
      <Page {...{ ...props, setImportJobId }} />
    </ImportJobsPolling>
  );
};

const PageWithPollingAndState = connect(mapStateToProps)(
  withFlags(withRouting(PageWithPolling)),
);

export default injectSheet(styles)(PageWithPollingAndState);
