import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PersistedTable } from '~/components/Shared/PersistedTable';
import { User } from '~/components/Shared/User';
import { ContentContainer } from '~/eds';
import { TableContextType } from '~/enums';
import { useTableSettings } from '~/hooks';
import { api, slices } from '~/redux';
import { useRouting } from '~/routing';
import { capitalize } from '~/utils/strings';

import { useCreateAction } from '../hooks';
import { ModelProgress } from '../ModelProgress';
import { ModelScope } from '../ModelScope';
import { ModelActionType, ModelResult, TargetEntity } from '../types';
import { testIsActivePublishStatus } from '../utils';
import { ModelResultTargetEntity } from './ModelResultTargetEntity';

interface Props {
  tabKey?: 'all' | 'published' | 'suspended' | 'drafts';
  entityType: TargetEntity['type'];
  onShowResult: (modelResult: ModelResult) => void;
}

export const ModelResults = ({ tabKey, entityType, onShowResult }: Props) => {
  const dispatch = useDispatch();
  const { getSearchParam, setSearchParams } = useRouting();

  const {
    tableSettings: { pageSize } = {
      pageSize: 10,
    },
  } = useTableSettings(TableContextType.FieldAi);

  const pageIndex = Number(getSearchParam('pageIndex') ?? 1);

  const {
    data: { results, totalCount } = {
      results: [],
      totalCount: 0,
    },
    isError,
    isFetching,
    isLoading,
  } = api.endpoints.getPromptModelResults.useQuery({ pageIndex, pageSize });

  //TODO: this should be moved to be a param of getPromptModelResults to filter on the server
  const tabResults = results.filter((modelResult) => {
    if (!tabKey) {
      return modelResult;
    }
    switch (tabKey) {
      case 'published':
        return modelResult.currentPublishStatus === 'completed';
      case 'suspended':
        return modelResult.currentPublishStatus === 'suspended';
      case 'drafts':
        return modelResult.currentPublishStatus === null;
      default:
      case 'all':
        return modelResult;
    }
  });

  const [
    updatePromptModelProgress,
    { isLoading: isUpdatingPromptModelProgress },
  ] = api.endpoints.updatePromptModelProgress.useMutation();

  const handlePaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      setSearchParams({
        pageIndex: String(pageIndex),
      });
    },
    [pageIndex],
  );

  const getRowActions = useCallback(
    (modelResult: ModelResult) => {
      const { currentPublishStatus } = modelResult;
      const modelActions = [
        currentPublishStatus === 'suspended'
          ? 'resume'
          : testIsActivePublishStatus(currentPublishStatus)
          ? 'suspend'
          : null,
        'delete',
      ].filter((action) => action !== null) as ModelActionType[];

      const disabled = isFetching || isUpdatingPromptModelProgress;

      return modelActions.map((action) => ({
        key: action,
        disabled,
        label: capitalize(action),
        tooltip: disabled ? 'Updating model…' : undefined,
        onClick: () => {
          if (action === 'resume') {
            updatePromptModelProgress({ modelId: modelResult.id });
            onShowResult(modelResult);
          }
          dispatch(
            slices.xRay.actions.setModelAction({
              model: {
                id: modelResult.id,
                entity: modelResult.targetEntity,
                name: '',
                version: modelResult.latestVersion,
              },
              action,
            }),
          );
        },
      }));
    },
    [isFetching, isUpdatingPromptModelProgress],
  );

  const hasModels = results.length > 0;

  const createAction = useCreateAction(entityType);

  const emptyContent = {
    action: createAction,
    image: 'empty-no-groups',
    title: `Build a ${capitalize(entityType)} Model`,
    message:
      'Let our AI find the information you are looking for across documents.',
  };

  const placeholderContent = isError
    ? {
        description:
          'There was an error loading X-Ray models. Refresh the page to try again.',
      }
    : !hasModels
    ? emptyContent
    : undefined;

  return (
    <ContentContainer
      loadingContent={{
        isLoading,
        message: 'Loading X-Ray models…',
      }}
      placeholderContent={placeholderContent}
    >
      <PersistedTable
        columns={columns}
        context={TableContextType.FieldAi}
        data={tabResults}
        getRowActions={getRowActions}
        name="field-model-list"
        options={{
          enableExportXlsx: false,
          enableSelectRows: false,
        }}
        reactTableOptions={{
          autoResetHiddenColumns: false,
          autoResetResize: false,
        }}
        rowDetails={{
          condition: (modelResult: ModelResult) =>
            modelResult.versions.length > 0,
          onClick: onShowResult,
        }}
        state={{
          pageIndex,
          pageSize,
        }}
        totalCount={totalCount}
        onPaginate={handlePaginate}
      />
    </ContentContainer>
  );
};

const columns = [
  {
    key: 'name',
    cellType: 'link',
    disableSortBy: true,
    title: 'Model',
    renderCell: (modelResult: ModelResult) => (
      <ModelResultTargetEntity modelResult={modelResult} />
    ),
  },
  {
    key: 'scope',
    cellType: 'text',
    disableSortBy: true,
    title: 'Scope',
    width: 'l',
    renderCell: (modelResult: ModelResult) => (
      <ModelScope readOnly scope={modelResult.scope} />
    ),
  },
  {
    key: 'docsInScope',
    align: 'right',
    disableSortBy: true,
    title: 'Documents in Scope',
    width: 'm',
    renderCell: (modelResult: ModelResult) => (
      <ModelProgress metric="docsInScope" modelId={modelResult.id} />
    ),
  },
  {
    key: 'valuesFound',
    align: 'right',
    disableSortBy: true,
    title: 'Values Found',
    width: 'm',
    renderCell: (modelResult: ModelResult) => (
      <ModelProgress metric="valuesFound" modelId={modelResult.id} />
    ),
  },
  {
    key: 'modifiedBy',
    cellType: 'user',
    disableSortBy: true,
    title: 'Modified By',
    mapCellProps: (modelResult: ModelResult) => ({
      asyncUser: {
        id: modelResult.modifiedBy,
        render: User,
      },
      mode: 'avatar-name',
    }),
  },
  {
    key: 'modifiedDate',
    cellType: 'datetime',
    disableSortBy: true,
    title: 'Modified Date',
    mapCellProps: (modelResult: ModelResult) => ({
      datetime: modelResult.modifiedDate,
      format: 'short',
    }),
  },
  {
    key: 'publishStatus',
    disableSortBy: true,
    title: 'Status',
    width: 'm',
    renderCell: (modelResult: ModelResult) => (
      <ModelProgress metric="status" modelId={modelResult.id} />
    ),
  },
];
