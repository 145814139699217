import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { LibraryModelVersion } from '~/features/x-ray/library/types';

import { SliceType } from '../types';
import { LibraryState as State } from './types';

export const initialState: State = {
  modelPreview: null,
  modelsInCart: [],
  selectedCategory: null,
};

export default createSlice({
  name: SliceType.XRayLibrary,
  initialState,
  selectors: {
    selectCart: (state) => state.modelsInCart,
    selectCategory: (state) => state.selectedCategory,
    selectModelPreview: (state) => state.modelPreview,
  },
  reducers: {
    addModelToCart: (state, action: PayloadAction<LibraryModelVersion>) => {
      state.modelsInCart = uniq([...state.modelsInCart, action.payload]);
    },
    setCategory: (state, action: PayloadAction<State['selectedCategory']>) => {
      state.selectedCategory = action.payload;
    },
    setModelPreview: (state, action: PayloadAction<State['modelPreview']>) => {
      state.modelPreview = action.payload;
    },
  },
});
