import React from 'react';

import { Layout, NumberInput } from '~/eds';
import { Nullable } from '~/types';

import { Filter, NumberValue } from '../types';

interface Props {
  filter: Filter<NumberValue>;
  onChange: (updatedValues: NumberValue[]) => void;
}

export const NumberValues = ({ filter, onChange }: Props) => {
  const { id, operatorId, values } = filter;
  const [min = null, max = null] = values;

  const handleValueChange = (updatedValue: Nullable<number>) => {
    onChange(updatedValue ? [updatedValue] : []);
  };

  const handleFirstValueChange = (updatedMin: Nullable<number>) => {
    onChange([updatedMin, max]);
  };

  const handleSecondValueChange = (updatedMax: Nullable<number>) => {
    onChange([min, updatedMax]);
  };

  switch (operatorId) {
    case 'between': {
      return (
        <Layout spacing={2}>
          <NumberInput
            name={`${id}-min`}
            value={min}
            max={max}
            placeholder="Min"
            width="50%"
            onChange={handleFirstValueChange}
          />
          <NumberInput
            name={`${id}-max`}
            value={max}
            min={min}
            placeholder="Max"
            width="50%"
            onChange={handleSecondValueChange}
          />
        </Layout>
      );
    }
    default:
      return (
        <NumberInput
          name={id}
          value={min}
          placeholder="Number"
          width="100%"
          onChange={handleValueChange}
        />
      );
  }
};
