import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import injectSheet from 'react-jss';
import { Document, Page, pdfjs } from 'react-pdf';

import { Box, useResponsiveWidth } from '~/ui';

import styles from './DocumentViewer.styles';

// https://github.com/wojtekmaj/react-pdf#create-react-app
if (typeof window !== 'undefined' && 'Worker' in window) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
}

function DocumentPdfViewer({ classes, file }) {
  const ref = useRef();
  const [pageCount, setPageCount] = useState(null);
  const pageWidth = useResponsiveWidth(ref);

  function onLoadSuccess({ numPages }) {
    setPageCount(numPages);
  }

  const pages = Array.from(new Array(pageCount), (_, index) => (
    <Box key={`page_${index + 1}`} py={16} sx={{ borderBottom: 'border' }}>
      <Page pageNumber={index + 1} width={pageWidth} />
    </Box>
  ));

  return (
    <div className={classes.container} ref={ref}>
      <Document
        file={file}
        onLoadSuccess={onLoadSuccess}
        options={{
          isEvalSupported: false,
        }}
      >
        {pages}
      </Document>
    </div>
  );
}

DocumentPdfViewer.propTypes = {
  file: PropTypes.any.isRequired,
};

export default injectSheet(styles)(DocumentPdfViewer);
