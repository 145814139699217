import { XRayLibrary } from '~/features/x-ray/library';
import { RoutePathType, useRouting } from '~/routing';

const Page = () => {
  const { navigate, getSearchParam } = useRouting();
  const source = getSearchParam('source');

  const returnToSource = () => {
    if (source) {
      navigate(`${RoutePathType.AutomationHubFields}/${source}`);
    } else navigate(RoutePathType.AutomationHubFields);
  };

  return <XRayLibrary onCancel={returnToSource} />;
};

export default Page;
