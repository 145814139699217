import uuid from 'uuid';

import { DataFieldType } from '~/enums';
import { Filter } from '~/evifields';
import { Highlight } from '~/features/document-viewer';
import {
  FieldClassificationValueBase,
  FieldExtractionValueBase,
  FieldValue,
  FieldValueBase,
  OptimizeResult,
  OptimizeResults,
  OptimizeTestCase,
  PromptModelTestCase,
  TargetEntityDetails,
} from '~/features/x-ray';
import { FieldModel, FieldModelConfigState } from '~/features/x-ray/fields';
import { LibraryModelVersion, Tag } from '~/features/x-ray/library/types';
import {
  EntityQuery,
  FilterQueryEntity,
  Nullable,
  PilotId,
  Uuid,
} from '~/types';

import {
  ApiLibraryModelVersion,
  FieldTargetInformation,
  LibraryTag,
} from '../../types/sylvanus/library';
import {
  ClassificationOption,
  OptimizeResultsForConfig,
  OptimizeRun,
  OptimizeState,
  PromptModelConfig,
  PromptModelPublishStatus,
  PromptModelTestCase as PromptModelTestCaseResponse,
  PromptModelVersion,
  SearchScope,
  OptimizeTestCase as ServerOptimizeTestCase,
} from '../../types/sylvanus/model';
import { SearchFilterRecord, toSearchV3Filter } from '../searchV3';

const mapFieldValueSourcesToHighlights = (
  value: FieldValueBase,
): Highlight[] | undefined => {
  const highlights: Highlight[] = [];

  if (!value.sources) {
    return undefined;
  }

  value.sources.forEach((source) => {
    if (source.context) {
      const {
        coordinates: sourceCoordinates = [],
        html_tokens = [],
      } = source.context;

      highlights.push({
        id: uuid.v4(),
        data: source,
        highlighter: 'ai',
        htmlTokens: html_tokens ?? [],
        pdfCoordinates: sourceCoordinates?.[0]?.coordinates,
        ...(source.context.text ? { text: source.context.text } : {}),
      });
    }
  });

  return highlights;
};

const mapValue = (value: Nullable<FieldValueBase>): Nullable<FieldValue> => {
  if (!value) {
    return null;
  }
  const mappedValue = {
    highlights: mapFieldValueSourcesToHighlights(value),
    type: value.value_type,
  };
  // split the value into classification and extraction
  // to prevent TS from complaining about the type
  if (value.value_type === 'classification') {
    return {
      ...mappedValue,
      value: value.value as FieldClassificationValueBase['value'],
    } as FieldValue;
  }
  return {
    ...mappedValue,
    value: value.value as FieldExtractionValueBase['value'],
  };
};

export const mapTestCase = (
  response: PromptModelTestCaseResponse,
): PromptModelTestCase => {
  return {
    entity: response.entity,
    feedback: response.user_feedback ?? null,
    goldValue: mapValue((response.gold_value as FieldValueBase) ?? null),
    llmOutput: response.llm_output ? JSON.stringify(response.llm_output) : null,
    modelValue: mapValue(
      (response.promptmodel_value as FieldValueBase) ?? null,
    ),
    number: response.number,
    outcome: response.outcome ?? 'unknown',
    state: response.state ?? 'not_started',
    versionNumber: response.prompt_model_version_number,
    modelId: response.prompt_model_id,
  };
};

const mapDataFieldType = (config: PromptModelConfig): DataFieldType => {
  switch (config.prompt_model_type) {
    case 'field_classification':
      return config.is_multi
        ? DataFieldType.ARRAY_MULTIPLE
        : DataFieldType.ARRAY_SINGLE;
    case 'field_extraction':
      switch (config.output_type) {
        case 'datetime':
          return DataFieldType.DATE;
        case 'float':
        case 'int':
          return DataFieldType.NUMBER;
        case 'text_area':
          return DataFieldType.TEXT_AREA;
        case 'string':
        default:
          return DataFieldType.STRING;
      }
  }
};

export const mapFieldClassificationConfigAttributes = (
  config: FieldModelConfigState,
): Partial<{
  allow_additional_options: boolean;
  default_option: Nullable<ClassificationOption>;
  is_multi: boolean;
  options: ClassificationOption[];
}> => {
  const { fieldClassification } = config;
  if (fieldClassification) {
    return {
      allow_additional_options: fieldClassification.isOpenEnded,
      default_option: fieldClassification.defaultOption,
      is_multi: fieldClassification.isMulti,
      options: fieldClassification.options,
    };
  }
  return {};
};

export const mapLibraryModelVersion = (
  version: ApiLibraryModelVersion,
): LibraryModelVersion => ({
  config: version.configuration,
  description: version.description,
  label: version.name,
  latest: version.latest,
  libraryModelId: version.library_model_id,
  promptModelId: version.prompt_model_id ?? null,
  tags: version.tags ? mapLibraryModelVersionTags(version.tags) : [],
  targetEntityDetails: mapLibraryModelVersionTarget(version.target),
  version: version.version,
});

const mapLibraryModelVersionTags = (tags: LibraryTag[]): Tag[] => {
  return tags.map((tag) => ({
    type: tag.tag_type,
    label: tag.tag_value,
  }));
};

const mapLibraryModelVersionTarget = (
  target: FieldTargetInformation,
): TargetEntityDetails => ({
  id: '-',
  label: mapValueTypeToEntityLabel(target.value_type),
  type: target.entity_type ?? 'field',
});

const mapValueTypeToEntityLabel = (
  type: FieldTargetInformation['value_type'],
) => {
  switch (type) {
    case 'string':
      return 'Text';
    case 'text_area':
      return 'Text area';
    case 'int':
    case 'float':
      return 'Number';
    case 'datetime':
      return 'Date';
    case 'classification':
      return 'Dropdown';
    default:
      return 'Unknown';
  }
};

export const mapModelConfigAttributes = (
  config: FieldModelConfigState,
): {
  output_type: PromptModelConfig['output_type'];
  prompt_model_type: PromptModelConfig['prompt_model_type'];
} => {
  const { field, fieldClassification } = config;

  let outputType: PromptModelConfig['output_type'] = 'string';
  switch (field?.type) {
    case DataFieldType.DATE:
      outputType = 'datetime' as const;
      break;
    case DataFieldType.NUMBER:
      outputType = 'float' as const;
      break;
    case DataFieldType.TEXT_AREA:
      outputType = 'text_area' as const;
      break;
    case DataFieldType.STRING:
    default:
      outputType = 'string' as const;
      break;
  }

  return {
    output_type: outputType,
    prompt_model_type: fieldClassification
      ? 'field_classification'
      : 'field_extraction',
  };
};

export const mapModelVersionToFieldModel = ({
  modelId,
  version,
  fieldId,
  searchFilters,
  optimizeState,
  publishStatus,
}: {
  modelId: Uuid;
  version: PromptModelVersion;
  fieldId?: PilotId;
  searchFilters: SearchFilterRecord;
  optimizeState: Nullable<OptimizeState>;
  publishStatus: Nullable<PromptModelPublishStatus>;
}): FieldModel => {
  const filters = (version.config.scope?.data?.attributes
    .query as EntityQuery[])
    .filter(
      (filter: EntityQuery): filter is FilterQueryEntity =>
        filter.type === 'filter',
    )
    .map((filter: FilterQueryEntity) =>
      toSearchV3Filter(filter, searchFilters),
    );

  const testCases: PromptModelTestCase[] =
    Object.values(version.test_cases ?? {}).map(mapTestCase) ?? [];

  return {
    config: {
      field: {
        // @ts-ignore -- TODO: decouple field-specific logic from the model config
        id: fieldId,
        label: version.config.field_name,
        type: mapDataFieldType(version.config),
      },
      filters,
      instructions: version.config.instructions ?? '',
      fieldClassification:
        version.config.prompt_model_type === 'field_classification'
          ? {
              isMulti: version.config.is_multi ?? false,
              options: version.config.options ?? [],
              defaultOption: version.config.default_option ?? null,
              isOpenEnded: version.config.allow_additional_options ?? true, // sylvanus defaults to open-ended
            }
          : undefined,
      internal: version.config.internal,
      stringMatchingLevel:
        version.config.prompt_model_type === 'field_extraction'
          ? version.config.string_matching_level
          : undefined,
    },
    id: modelId,
    name: version.config.field_name,
    modifiedBy: parseInt(version.created_by_user_id) as PilotId,
    modifiedDate: new Date(version.datetime_created),
    optimizeState,
    publishStatus,
    testCases,
    version: version.version_number,
  };
};

const mapOptimizeTestCase = (
  testCase: ServerOptimizeTestCase,
  originalTestCase: ServerOptimizeTestCase,
): OptimizeTestCase => ({
  ...testCase,
  modelValue: mapValue((testCase.promptmodel_value as FieldValueBase) ?? null),
  isNotChanged: testCase.outcome === originalTestCase.outcome,
});

const mapOptimizeOption = (
  result: OptimizeResultsForConfig,
  originalResult: OptimizeResultsForConfig,
  isRecommended: boolean,
): OptimizeResult => {
  const testCases = Object.values(result.test_cases).map((testCase) =>
    mapOptimizeTestCase(testCase, originalResult.test_cases[testCase.number]),
  );
  return {
    config: result.config,
    isRecommended,
    noUserConfigChanges: result.no_user_config_changes,
    stats: result.stats,
    tag: result.tag,
    testCases,
  };
};

/**
 * Maps the results of an OptimizeRun to an array of `OptimizeResult`s and sorts them
 */
const mapResults = ({
  original_results,
  results,
}: OptimizeRun): OptimizeResult[] => {
  return results!
    .map((result) => {
      const isRecommended =
        result.tag === 'best_overall' ||
        (results!.length === 1 && result.tag === 'best_no_user_config_changes');
      return mapOptimizeOption(result, original_results, isRecommended);
    })
    .sort((a: OptimizeResult, b: OptimizeResult) => {
      const order = [
        'best_no_user_config_changes',
        'best_overall',
        'best_strictly_better',
      ];
      return order.indexOf(a.tag) - order.indexOf(b.tag);
    });
};

export const mapOptimizeResults = (response: OptimizeRun): OptimizeResults => ({
  state: response.state,
  progress: response.progress ?? null,
  results: response.results ? mapResults(response) : null,
});

export const mapScopeToFilters = (
  scope: SearchScope,
  searchFilters: SearchFilterRecord,
): Filter[] => {
  return (scope.data.attributes.query as EntityQuery[])
    .filter(
      (filter: EntityQuery): filter is FilterQueryEntity =>
        filter.type === 'filter',
    )
    .map((filter: FilterQueryEntity) =>
      toSearchV3Filter(filter, searchFilters),
    );
};
