import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import * as actions from '~/actions';
import { black2, black3, bold, semibold } from '~/assets/shared-styles/general';
import DocumentGroupPage from '~/components/DocumentGroupPage';
import {
  addDocumentsToGroup,
  deleteDocumentGroup,
  editDocumentGroup,
  getDocumentGroupDetails,
} from '~/components/DocumentGroupsPage/DocumentGroupsPage.data';
import { getDocHandlerPaths } from '~/components/DocumentsViewPage/Document.data';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import DocumentTree from '~/components/Shared/DocumentTree';
import EcButton from '~/components/Shared/EcButton';
import EcFilterInput from '~/components/Shared/EcFilterInput';
import EcModal from '~/components/Shared/EcModal';
import {
  EcTab,
  EcTabList,
  EcTabPanel,
  EcTabs,
} from '~/components/Shared/EcTabs';
import { showToast } from '~/components/Shared/EcToast';
import withHover from '~/components/Shared/HOCs/withHover';
import BackIcon from '~/components/Shared/Icons/BackIcon';
import FolderEmptyIcon from '~/components/Shared/Icons/FolderEmptyIcon';
import LinkIcon from '~/components/Shared/Icons/LinkIcon';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import { formatDate, Markdown, Modal } from '~/eds';
import { canUseDocumentGroupRevamp } from '~/permissions';
import { withRouting } from '~/routing';
import {
  MODAL_AUTOCOMPLETE,
  MODAL_DELETE,
  MODAL_DOCUMENT_GROUP_RELATION,
  MODAL_NAME,
} from '~/types/modal.types';
import { ERROR, SUCCESS, WARNING } from '~/types/toast.types';

const BackIconWithHover = withHover(BackIcon);

class Page extends Component {
  constructor(props) {
    super(props);

    this.navigateBack = this.navigateBack.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleOnShowMoreClick = this.handleOnShowMoreClick.bind(this);
    this.onGroupEdit = this.onGroupEdit.bind(this);
    this.onGroupDelete = this.onGroupDelete.bind(this);
    this.handleOnDocumentSearchClick = this.handleOnDocumentSearchClick.bind(
      this,
    );
    this.loadDocumentGroupDetails = this.loadDocumentGroupDetails.bind(this);

    this.state = {
      searchString: '',
      expandedAll: false,
      loading: false,
      errorLoadingMessage: false,
      groupData: {},
      configuredColumns: [],
      selectedItem: null,
      uploadLocation: null,
      uploadFiles: [],
      primaryModal: null,
      secondaryModal: null,
      canModifyFolder: false,
    };
  }

  componentDidMount() {
    this.loadDocumentGroupDetails();
  }

  loadDocumentGroupDetails() {
    const {
      params: { groupId },
    } = this.props;

    this.setState({ groupData: {}, loading: true, errorLoadingMessage: false });
    getDocumentGroupDetails(groupId)
      .then((groupData) => {
        this.setState(
          { groupData, loading: false, errorLoadingMessage: false },
          this.loadHandlerInfo,
        );
      })
      .catch(() =>
        this.setState({ loading: false, errorLoadingMessage: false }),
      );
  }

  loadHandlerInfo() {
    const { groupData } = this.state;
    const { documentTree } = groupData;

    const setDocGroupHandlers = (docGroup) => {
      const { children, handlerIds } = docGroup;
      docGroup.handlers = [];
      getDocHandlerPaths(handlerIds).then(
        ({ data }) => (docGroup.handlers = data),
      );
      if (children.length)
        children.forEach((childDocGroup) => setDocGroupHandlers(childDocGroup));
    };

    for (let relationship in documentTree) {
      const relationshipTree = documentTree[relationship];
      if (!relationshipTree.length) continue;
      relationshipTree.forEach((docGroup) => setDocGroupHandlers(docGroup));
    }
    this.setState({ groupData });
  }

  navigateBack() {
    const { navigate } = this.props;

    navigate(-1);
  }

  handleShowModal(modal, selectedItem = null) {
    this.setState({
      primaryModal: modal,
      selectedItem,
    });
  }

  handleHideModal() {
    this.setState({ primaryModal: null, selectedItem: null });
  }

  onGroupEdit(name, note) {
    const { groupData } = this.state;
    editDocumentGroup(groupData.groupId, { name, note })
      .then((res) => {
        showToast(SUCCESS, `${res.name} has been updated.`);
        this.loadDocumentGroupDetails();
        this.handleHideModal();
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          showToast(
            ERROR,
            'There is a group somewhere in your organization with the same name. Please choose a different name.',
          );
        } else {
          showToast(
            WARNING,
            'An error occurred while updating a new document group.',
          );
        }
      });
  }

  onGroupDelete() {
    const {
      params: { groupId },
    } = this.props;

    deleteDocumentGroup(groupId)
      .then((res) => {
        showToast(SUCCESS, `"${res.name}" has been deleted.`);
        this.handleHideModal();
        this.navigateBack();
      })
      .catch(() => {
        showToast(
          WARNING,
          'An error occurred while deleting the document group.',
        );
      });
  }

  handleOnDocumentSearchClick(documentId) {
    const { groupData } = this.state;
    const documentIdAsArray = [Number(documentId)];

    addDocumentsToGroup(groupData.groupId, documentIdAsArray)
      .then((data) => {
        this.handleHideModal();
        showToast(
          SUCCESS,
          `Document(s) added to "${data.name}" group successfully.`,
        );
        this.loadDocumentGroupDetails();
      })
      .catch((err) => {
        this.handleHideModal();
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.status === 400
        ) {
          showToast(WARNING, `${err.response.data.message}`);
        } else {
          showToast(
            WARNING,
            'An error occurred while adding the document to the group.',
          );
        }
      });
  }

  handleOnShowMoreClick(query) {
    const { actions, navigate } = this.props;

    actions.analyzerSetData({
      filters: [{ entity: 'bool_text_search', query }],
    });
    this.handleHideModal();
    navigate('/analyzer/results');
  }

  getMenuItems = () => {
    const items = [];

    items.push(
      {
        content: 'Edit',
        onClick: () => this.handleShowModal(MODAL_NAME),
      },
      {
        content: 'Delete',
        onClick: () => this.handleShowModal(MODAL_DELETE),
      },
    );

    return items;
  };

  renderExtraOptions() {
    const { classes } = this.props;

    return (
      <div className={classes.actionsWrapper}>
        <ActionsMenu
          align="end"
          id="document_tree_page_header_actions_menu"
          items={this.getMenuItems(document)}
          title="edit document group menu"
        />
      </div>
    );
  }

  renderEmptyDocumentTree() {
    const { classes } = this.props;

    return (
      <div className={classes.emptyPageWrapper}>
        <div className={classes.emptyPageContent}>
          <FolderEmptyIcon />
          <div className={classes.folderEmptyLabel}>No documents.</div>
          <span className={classes.folderEmptyInfo}>
            This group does not have any documents yet.
          </span>
        </div>
      </div>
    );
  }

  renderEditModal() {
    const { groupData } = this.state;

    return (
      <EcModal
        modalType={MODAL_NAME}
        width="560px"
        title="Edit Group"
        labelText="Group"
        itemsCurrentName={groupData ? groupData.name : ''}
        includeTextArea={true}
        labelTextArea="NOTES"
        itemsCurrentDescription={groupData ? groupData.note : ''}
        confirmButtonText="Save"
        handleNameChange={this.onGroupEdit}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderDeleteModal() {
    const modalText = `Are you sure you want to delete
    ${this.state.groupData.name}? You won’t be able to undo
    this action.`;

    return (
      <Modal
        children={<Markdown text={modalText} />}
        isVisible
        primaryAction={{
          text: 'Delete',
          variant: 'danger',
          onClick: () => {
            this.onGroupDelete();
            this.handleHideModal();
          },
        }}
        onHide={this.handleHideModal}
        onCancel={this.handleHideModal}
        title="Delete Group?"
      />
    );
  }

  renderDocumentGroupRelationModal() {
    const { selectedItem, groupData } = this.state;
    let documents = [];
    const aggregatedDocuments = (array) => {
      if (!array) return;
      array.forEach(function (c) {
        documents.push({
          title: c.title,
          documentId: c.documentId,
          fileType: c.fileType,
          relationship: c.relationship,
        });
        if (c.children !== undefined) {
          aggregatedDocuments(c.children);
        }
      });
      return documents;
    };

    const groupDocuments = aggregatedDocuments([
      ...groupData.documentTree.linked,
      ...groupData.documentTree.uncategorized,
      ...groupData.documentTree.supporting,
    ]);
    const groupDataForTree = groupDocuments
      ? groupDocuments
          .map(({ title, documentId, fileType, relationship }) => {
            return {
              label: title,
              value: title,
              documentId,
              fileType,
              relationship,
            };
          })
          .filter((doc) => doc.documentId !== selectedItem.node.documentId)
      : null;

    return (
      <EcModal
        modalType={MODAL_DOCUMENT_GROUP_RELATION}
        width="560px"
        title={`Edit Relationship for ${selectedItem.node.title}`}
        groupId={groupData.groupId}
        documentGroupCategory={selectedItem.node.relationship}
        groupData={groupDataForTree}
        selectedDocument={selectedItem}
        handleSaveGroupRelation={this.handleSaveGroupRelation}
        hideModal={this.handleHideModal}
        reloadDocumentTreeData={this.loadDocumentGroupDetails}
      />
    );
  }

  renderPageHeader() {
    const { classes } = this.props;
    const { groupData } = this.state;

    return (
      <div className={classes.headerWrapper}>
        <div className={classes.pageHeader}>
          <button
            className={classes.pageBackAction}
            title="Go back to previous page"
            onClick={this.navigateBack}
          >
            <BackIconWithHover />
          </button>
          <h2 className={classes.pageName}>{groupData.name}</h2>
        </div>
        <div className={classes.documentHeaderActions}>
          <EcButton
            id="documents_group_tree_header_addDocumentsButton"
            onClick={() => this.handleShowModal(MODAL_AUTOCOMPLETE)}
            text="Link New Documents"
            yellow
            iconLeft={<LinkIcon size="20" color={black2} />}
          />
          <div className={classes.documentHeaderActionsMenu}>
            {this.renderExtraOptions()}
          </div>
        </div>
      </div>
    );
  }

  renderPageRightPanel() {
    const { classes } = this.props;
    const { groupData } = this.state;
    const formattedDateAdded = formatDate(
      groupData.dateAdded ? new Date(groupData.dateAdded) : null,
      'long',
    );

    return (
      <div className={classes.rightPanel}>
        <h2>Group Details</h2>
        <div className={classes.rightPanelLabel}>NOTES!</div>
        <div className={classes.groupDetailsInfo}>{groupData.note}</div>

        <div className={classes.rightPanelLabel}>CREATED BY</div>
        <div className={classes.groupDetailsInfo}>{groupData.createdBy}</div>

        <div className={classes.rightPanelLabel}>CREATED DATE</div>
        <div className={classes.groupDetailsInfo}>{formattedDateAdded}</div>
      </div>
    );
  }

  renderSearchModal() {
    return (
      <EcModal
        modalType={MODAL_AUTOCOMPLETE}
        width="720px"
        toViewDocument={false}
        forGroupTreeView={true}
        hideModal={this.handleHideModal}
        handleOnSearchItemClick={this.handleOnDocumentSearchClick}
        handleOnShowMoreClick={this.handleOnShowMoreClick}
      />
    );
  }

  render() {
    const { classes, currentUser, ...rest } = this.props;
    const {
      groupData,
      loading,
      errorLoadingMessage,
      primaryModal,
      searchString,
    } = this.state;

    if (canUseDocumentGroupRevamp(currentUser)) {
      // TODO: modernize DocumentGroupPage to reduce need of passing route props (packaged in ...rest).
      return <DocumentGroupPage {...rest} />;
    }

    return (
      <div className={classes.documentsPage}>
        {this.renderPageHeader()}
        {loading && !errorLoadingMessage ? (
          <div className={classes.loadingContainer}>
            <LoadingSpinner size="medium" />
          </div>
        ) : !loading && errorLoadingMessage ? (
          <div className={classes.errorLoadingWrapper}>
            <div className={classes.errorLoadingMessage}>
              An error occurred when loading the document group information.
            </div>
          </div>
        ) : !loading && !errorLoadingMessage && groupData ? (
          <div className={classes.documentsPageContent}>
            <div className={classes.documentsPageContentTabs}>
              <EcTabs
                className={classes.documentTabs}
                selectedIndex={this.state.tabIndex}
                onSelect={(tabIndex) => this.setState({ tabIndex })}
              >
                <EcTabList className={classes.documentTabList}>
                  <EcTab>Documents</EcTab>
                </EcTabList>
                <EcTabPanel>
                  {groupData.documentTree &&
                  (groupData.documentTree.linked.length ||
                    groupData.documentTree.supporting.length ||
                    groupData.documentTree.uncategorized.length) ? (
                    <div>
                      <div className={classes.pageWrapper}>
                        <div className={classes.pageFilterWrapper}>
                          <EcFilterInput
                            value={searchString}
                            onChange={(searchString) =>
                              this.setState({ searchString })
                            }
                            onClearSearch={() =>
                              this.setState({ searchString: '' })
                            }
                            placeholder="Filter documents..."
                          />
                        </div>
                      </div>

                      <div className={classes.groupCategory}>Uncategorized</div>
                      {groupData.documentTree.uncategorized.length ? (
                        <div className={classes.groupCategoryContainer}>
                          <DocumentTree
                            allowEdit={true}
                            treeId="uncategorizedTree"
                            groupId={groupData.groupId}
                            masterDocument={groupData.masterDocumentName}
                            treeData={groupData.documentTree.uncategorized}
                            searchString={searchString}
                            handleShowModal={this.handleShowModal}
                            reloadDocumentTreeData={
                              this.loadDocumentGroupDetails
                            }
                          />
                        </div>
                      ) : (
                        <div className={classes.groupCategoryEmptyDocs}>
                          This group does not have any uncategorized documents.
                        </div>
                      )}

                      <div className={classes.groupCategory}>Document Tree</div>
                      {groupData.documentTree.linked.length ? (
                        <div className={classes.groupCategoryContainer}>
                          <DocumentTree
                            allowEdit={true}
                            treeId="linkedTree"
                            groupId={groupData.groupId}
                            masterDocument={groupData.masterDocumentName}
                            treeData={groupData.documentTree.linked}
                            searchString={searchString}
                            handleShowModal={this.handleShowModal}
                            reloadDocumentTreeData={
                              this.loadDocumentGroupDetails
                            }
                          />
                        </div>
                      ) : (
                        <div className={classes.groupCategoryEmptyDocs}>
                          This group does not have a document tree.
                        </div>
                      )}

                      <div className={classes.groupCategory}>
                        Supporting Documents
                      </div>
                      {groupData.documentTree.supporting.length ? (
                        <div className={classes.groupCategoryContainer}>
                          <DocumentTree
                            allowEdit={true}
                            treeId="supportingTree"
                            groupId={groupData.groupId}
                            masterDocument={groupData.masterDocumentName}
                            treeData={groupData.documentTree.supporting}
                            searchString={searchString}
                            handleShowModal={this.handleShowModal}
                            reloadDocumentTreeData={
                              this.loadDocumentGroupDetails
                            }
                          />
                        </div>
                      ) : (
                        <div className={classes.groupCategoryEmptyDocs}>
                          This group does not have any supporting documents.
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>{this.renderEmptyDocumentTree()}</div>
                  )}
                </EcTabPanel>
              </EcTabs>
            </div>
            {this.renderPageRightPanel()}
          </div>
        ) : null}
        {primaryModal === MODAL_AUTOCOMPLETE && this.renderSearchModal()}
        {primaryModal === MODAL_NAME && this.renderEditModal()}
        {primaryModal === MODAL_DELETE && this.renderDeleteModal()}
        {primaryModal === MODAL_DOCUMENT_GROUP_RELATION &&
          this.renderDocumentGroupRelationModal()}
      </div>
    );
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const styles = {
  documentsPage: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  loadingContainer: {
    marginTop: '64px',
    textAlign: 'center',
  },
  emptyPageWrapper: {
    display: 'flex',
    flexGrow: '1',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70%',
  },
  emptyPageContent: {
    textAlign: 'center',
  },
  folderEmptyLabel: {
    margin: '72px 0 8px 0',
    color: black2,
    fontSize: '17px',
    fontWeight: semibold,
    textAlign: 'center',
  },
  headerWrapper: {
    marginBottom: '21px',
    display: 'flex',
    alignItems: 'center',
  },
  pageName: {
    margin: '0 0 0 8px',
    color: black2,
    fontSize: '32px',
    fontWeight: bold,
  },
  pageHeader: {
    marginBottom: '21px',
    display: 'inline-flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  pageBackAction: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  documentHeaderActions: {
    display: 'flex',
    alignItems: 'center',
  },
  documentHeaderActionsMenu: {
    marginLeft: '20px',
  },
  documentsPageContent: {
    display: 'flex',
  },
  documentsPageContentTabs: {
    flexGrow: 1,
  },
  rightPanel: {
    margin: '23px 0 0 30px',
    width: '320px',
    fontSize: '14px',
  },
  rightPanelLabel: {
    fontSize: '12px',
    fontWeight: semibold,
  },
  groupDetailsInfo: {
    margin: '10px 0 20px',
  },
  pageWrapper: {
    height: '100%',
    width: '100%',
    margin: 0,
    display: 'flex',
  },
  pageFilterWrapper: {
    display: 'flex',
    flexGrow: 1,
  },
  groupCategory: {
    marginTop: '30px',
    color: black2,
    fontSize: '21px',
    fontWeight: bold,
  },
  groupCategoryNoMasterDocs: {
    marginTop: '20px',
    padding: '10px',
    width: '94%',
    border: '1px dashed #ffc449',
    backgroundColor: '#fffdf6',
  },
  groupCategoryEmptyDocs: {
    marginTop: '20px',
    color: black3,
  },
};

Page = withRouting(Page);

Page = connect(mapStateToProps, mapDispatchToProps)(Page);

export default injectSheet(styles)(Page);
