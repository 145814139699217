import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  CopyButton,
  IconButton,
  Info,
  Layout,
  Panel,
  TruncateText,
  useToggle,
} from '~/eds';
import { useCurrentUser } from '~/hooks';
import { api, slices } from '~/redux';
import { useRouting } from '~/routing';

import { EndpointStatus } from '../types';
import { getUpdatedFilters, testIsActiveStatus } from '../utils';

export const EndpointDetails = () => {
  const { setSearchParams } = useRouting();
  const dispatch = useDispatch();
  const { client } = useCurrentUser();

  const filters = useSelector(slices.webhooks.selectors.selectedFilters);
  const { activeEndpoint, activeEndpointType: action } = useSelector(
    slices.webhooks.selectors.selectActiveEndpoint,
  );

  const {
    data: userCreated = {
      firstName: '',
      lastName: '',
      email: '',
    },
    isLoading: userCreatedLoading,
  } = api.endpoints.resolveUser.useQuery(
    {
      id: activeEndpoint?.createdBy ?? 0,
      params: { clientId: client },
    },
    { skip: !activeEndpoint },
  );

  const {
    data: userModified = {
      firstName: '',
      lastName: '',
      email: '',
    },
    isLoading: userModifiedLoading,
  } = api.endpoints.resolveUser.useQuery(
    {
      id: activeEndpoint?.modifiedBy ?? 0,
      params: { clientId: client },
    },
    { skip: !activeEndpoint },
  );

  const [isKeyVisible, _toggle, showKey, hideKey] = useToggle();

  if (!activeEndpoint || action !== 'view') return null;

  return (
    <Panel
      actions={[
        {
          icon: 'x',
          text: 'Close',
          onClick: () => {
            dispatch(slices.webhooks.actions.resetEndpointConfiguration());
            hideKey();
          },
        },
      ]}
      children={
        <Info
          sections={[
            {
              title: activeEndpoint.endpointUrl,
              infos: [
                {
                  title: 'Status',
                  type: 'text' as const,
                  props: { text: activeEndpoint.status },
                },
                {
                  title: 'Description',
                  type: 'text' as const,
                  props: { text: activeEndpoint.description },
                },
                {
                  title: 'Selected Events',
                  type: 'text' as const,
                  props: {
                    text: activeEndpoint.events.join(', ').toString(),
                  },
                },
                {
                  title: 'Created',
                  isLoading: userCreatedLoading,
                  type: 'user-activity' as const,
                  props: {
                    activity: 'Created',
                    date: activeEndpoint.dateCreated,
                    user: {
                      id: activeEndpoint.createdBy,
                      firstName: userCreated?.firstName,
                      lastName: userCreated?.lastName,
                      email: userCreated?.email,
                    },
                  },
                },
                {
                  title: 'Modified',
                  isLoading: userModifiedLoading,
                  type: 'user-activity' as const,
                  props: {
                    activity: 'Modified',
                    date: activeEndpoint.dateModified,
                    user: {
                      id: activeEndpoint.modifiedBy,
                      firstName: userModified?.firstName,
                      lastName: userModified?.lastName,
                      email: userModified?.email,
                    },
                  },
                },
                {
                  title: 'Secure Signing',
                  type: 'content' as const,
                  props: {
                    children: (
                      <>
                        {isKeyVisible ? (
                          <Layout direction="column">
                            <Layout preset="buttons">
                              <TruncateText maxW={300}>
                                {activeEndpoint.secret}
                              </TruncateText>
                              <CopyButton
                                copyText={activeEndpoint.secret}
                                disableText
                                size="s"
                              />
                              <IconButton
                                text="rotate"
                                icon="sync"
                                size="s"
                                tooltip="Refresh key"
                                onClick={() => {
                                  dispatch(
                                    slices.webhooks.actions.setActiveEndpointType(
                                      'rotate-key',
                                    ),
                                  );
                                }}
                              />
                            </Layout>
                            <Layout
                              styles={{
                                '> button': {
                                  padding: 0,
                                },
                              }}
                            >
                              <Button
                                text="Hide"
                                onClick={hideKey}
                                variant="action"
                              />
                            </Layout>
                          </Layout>
                        ) : (
                          <Layout
                            styles={{
                              '> button': {
                                padding: 0,
                              },
                            }}
                          >
                            <Button
                              text="Reveal Key"
                              onClick={showKey}
                              variant="action"
                            />
                          </Layout>
                        )}
                      </>
                    ),
                  },
                },
                {
                  title: '',
                  type: 'link' as const,
                  props: {
                    text: 'See all attempts',
                    onClick: () => {
                      const updatedFilters = getUpdatedFilters({
                        filters,
                        endpoint: activeEndpoint,
                      });
                      dispatch(
                        slices.webhooks.actions.setFilters(updatedFilters),
                      );
                      setSearchParams({ secondaryTab: 'attempts' });
                      dispatch(
                        slices.webhooks.actions.setActiveEndpointType(null),
                      );
                    },
                  },
                },
              ],
            },
          ]}
        />
      }
      footer={{
        actions: [
          {
            text: 'Delete',
            level: 'tertiary',
            onClick: () => {
              dispatch(slices.webhooks.actions.setActiveEndpointType('delete'));
            },
          },
          {
            text: testIsActiveStatus(activeEndpoint.status as EndpointStatus)
              ? 'Suspend'
              : 'Activate',
            onClick: () => {
              const action =
                activeEndpoint.status === 'active' ? 'suspend' : 'activate';
              dispatch(slices.webhooks.actions.setActiveEndpointType(action));
            },
          },
          {
            text: 'Edit',
            onClick: () => {
              dispatch(slices.webhooks.actions.setActiveEndpointType('edit'));
            },
          },
        ],
      }}
      hidden={{
        isHidden: false,
        onHide: () => {
          dispatch(slices.webhooks.actions.resetEndpointConfiguration());
        },
      }}
      position="right"
      title="Endpoint Configuration"
      width="m"
    />
  );
};
