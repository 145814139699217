import { useMemo } from 'react';

import { AND } from '~/constants/openSearch';
import { Nullable } from '~/eds/src/types';
import { TicketStageType, TicketStatusType } from '~/enums';
import { useCurrentUser } from '~/hooks';
import { api } from '~/redux';
import { SearchItem, SearchResult } from '~/redux/api/methods';
import {
  ClauseMeta,
  ColumnSortOrder,
  FilterQueryEntity,
  MultiSelectMeta,
  PilotId,
  QueryOperatorEntity,
  TableTicket,
  Uuid,
} from '~/types';

export enum TicketListType {
  All = 'all',
  Cancelled = 'canceled',
  Done = 'done',
  InProgress = 'inProgress',
  NeedsAction = 'needsAction',
}

const EmptyTicketCompletedDateQuery = {
  type: 'filter' as const,
  id: 'ticket_completed_date_Ticket',
  operator: 'is_blank' as const,
  value: {
    value_list: [],
    value_meta: {},
  },
};

const EmptyTicketCancelledDateQuery = {
  type: 'filter' as const,
  id: 'ticket_cancelled_date_Ticket',
  operator: 'is_blank' as const,
  value: {
    value_list: [],
    value_meta: {},
  },
};

const NotEmptyTicketCancelledDateQuery = {
  type: 'filter' as const,
  id: 'ticket_cancelled_date_Ticket',
  operator: 'is_not_blank' as const,
  value: {
    value_list: [],
    value_meta: {},
  },
};

const NotEmptyTicketCompletedDateQuery = {
  type: 'filter' as const,
  id: 'ticket_completed_date_Ticket',
  operator: 'is_not_blank' as const,
  value: {
    value_list: [],
    value_meta: {},
  },
};

const getAssigneesQuery = (userId: PilotId, assignedToCurrentUser = true) => ({
  type: 'filter' as const,
  id: 'assignee_Ticket',
  operator: assignedToCurrentUser
    ? ('contains_all' as const)
    : ('not_contains_any' as const),
  value: {
    value_list: [userId],
    value_meta: {
      multi_select_data: {
        is_all_selected: false,
        length: 1,
        search: '',
        selected_values_map: {
          [userId]: true,
        },
      },
    },
  },
});

type QueryType =
  | FilterQueryEntity<string | number, ClauseMeta | MultiSelectMeta>
  | QueryOperatorEntity;

type TicketQueryType = Record<TicketListType, QueryType[]>;

type Value = {
  value: Nullable<string[]>;
};

type UserStructuredValue = {
  id: PilotId;
  name: string;
  email: string;
};

interface UserValue extends Value {
  value_structured: Nullable<UserStructuredValue[]>;
}

type StageStructuredValue = {
  id: Uuid;
  name: TicketStageType;
  isCurrentStage: boolean;
};

interface EnabledStagesValue extends Value {
  value_structured: Nullable<StageStructuredValue[]>;
}

type TicketData = {
  id: Uuid;
  selected_field_values: {
    name_Ticket: Value;
    enabled_stages_Ticket: EnabledStagesValue;
    submitter_Ticket: UserValue;
    submitter_name_Ticket: Value;
    stage_Ticket: Value;
    ticket_cancelled_date_Ticket: Value;
    ticket_completed_date_Ticket: Value;
    ticket_submit_date_Ticket: Value;
    ticket_last_activity_date_Ticket: Value;
    assignee_Ticket: UserValue;
    participant_Ticket: UserValue;
    current_party_type_Ticket: Value;
    turns_count_Ticket: Value;
    current_turn_start_date_Ticket: Value;
  };
};

const getStatus = (
  stage: TicketStageType,
  isCompleted: boolean,
  isCancelled: boolean,
): TicketStatusType => {
  if (isCompleted) {
    return TicketStatusType.Completed;
  }
  if (isCancelled) {
    return TicketStatusType.Cancelled;
  }

  switch (stage) {
    case TicketStageType.Edit:
      return TicketStatusType.Editing;
    case TicketStageType.Review:
      return TicketStatusType.Review;
    case TicketStageType.Sign:
      return TicketStatusType.PendingSignatures;
    case TicketStageType.Finalize:
      return TicketStatusType.PendingFinalization;
    default:
      return TicketStatusType.PendingCompletion;
  }
};

const transformTicketList = (
  ticketList: SearchItem<EnabledStagesValue | UserValue>[],
): TableTicket[] => {
  return ticketList.map(({ id, selected_field_values }) => {
    const ticketData = ({ id, selected_field_values } as unknown) as TicketData;
    const currentStage =
      ticketData.selected_field_values?.stage_Ticket?.value?.[0] || '';
    const isCompleted = !!ticketData.selected_field_values
      .ticket_completed_date_Ticket.value;
    const isCancelled = !!ticketData.selected_field_values
      .ticket_cancelled_date_Ticket.value;
    return {
      assignees:
        ticketData.selected_field_values.assignee_Ticket?.value_structured?.map(
          (v: any) => v.id,
        ) || [],
      createdDate: ticketData.selected_field_values.ticket_submit_date_Ticket
        .value?.[0]
        ? new Date(
            ticketData.selected_field_values.ticket_submit_date_Ticket.value?.[0],
          )
        : null,
      creatorName:
        ticketData.selected_field_values.submitter_Ticket.value_structured?.[0]
          ?.name || '',
      creator:
        ticketData.selected_field_values.submitter_Ticket.value_structured?.[0]
          ?.id,
      currentParty:
        ticketData.selected_field_values.current_party_type_Ticket
          ?.value?.[0] || 'Not Set',
      currentStageName: currentStage,
      currentTurnStartDate: ticketData.selected_field_values
        .current_turn_start_date_Ticket?.value?.[0]
        ? new Date(
            ticketData.selected_field_values.current_turn_start_date_Ticket.value[0],
          )
        : null,
      enabledStages:
        ticketData.selected_field_values.enabled_stages_Ticket.value || '',
      id,
      isCompleted,
      isCancelled,
      lastActivityDate:
        ticketData.selected_field_values.ticket_last_activity_date_Ticket
          ?.value?.[0] || '',

      name: ticketData.selected_field_values.name_Ticket?.value?.[0],
      participants:
        ticketData.selected_field_values.participant_Ticket.value_structured?.map(
          (v: UserStructuredValue) => v.id,
        ) || [],
      status: getStatus(
        currentStage as TicketStageType,
        isCompleted,
        isCancelled,
      ),
      turnsCount:
        ticketData.selected_field_values.turns_count_Ticket?.value?.[0] || 0,
    };
  }) as TableTicket[];
};

const buildSearchQuery = (queries: QueryType[], searchText: string) => {
  const searchQuery = {
    id: 'search_bar',
    operator: 'simple_text_search',
    type: 'filter',
    value: { query: searchText },
  };
  return queries.length === 0 ? [searchQuery] : [AND, searchQuery];
};

interface SearchTicketListParams {
  params: {
    pageSize: number;
    page?: number;
    sortBy?: ColumnSortOrder;
  };
  searchText?: string;
  ticketListType: TicketListType;
  options?: {
    skip: boolean;
  };
}

export const useSearchTicketListQuery = ({
  ticketListType,
  params: {
    page = 1,
    pageSize,
    sortBy = { id: 'ticket_last_activity_date_Ticket', desc: true },
  },
  options,
  searchText,
}: SearchTicketListParams) => {
  const currentUser = useCurrentUser();
  const TicketQueries: TicketQueryType = useMemo(
    () => ({
      [TicketListType.All]: [],
      [TicketListType.NeedsAction]: [
        EmptyTicketCompletedDateQuery,
        AND,
        EmptyTicketCancelledDateQuery,
        AND,
        getAssigneesQuery(currentUser.id),
      ],
      [TicketListType.InProgress]: [
        EmptyTicketCompletedDateQuery,
        AND,
        EmptyTicketCancelledDateQuery,
        AND,
        getAssigneesQuery(currentUser.id, false),
      ],
      [TicketListType.Cancelled]: [NotEmptyTicketCancelledDateQuery],
      [TicketListType.Done]: [NotEmptyTicketCompletedDateQuery],
    }),
    [currentUser.id],
  );

  const selectedQuery = TicketQueries[ticketListType];
  const query = [
    ...selectedQuery,
    ...(searchText ? buildSearchQuery(selectedQuery, searchText) : []),
  ];

  const searchParams = {
    page,
    pageSize,
    sortBy: { ...sortBy },
    type: 'ticket' as const,
    query,
    filters: [
      'name_Ticket',
      'stage_Ticket',
      'enabled_stages_Ticket',
      'submitter_Ticket',
      'ticket_submit_date_Ticket',
      'ticket_last_activity_date_Ticket',
      'assignee_Ticket',
      'participant_Ticket',
      'ticket_cancelled_date_Ticket',
      'ticket_completed_date_Ticket',
      'current_party_type_Ticket',
      'turns_count_Ticket',
      'current_turn_start_date_Ticket',
    ],
  };

  // useQuery is not returning the correct type when passing options
  const { data, ...rest } = api.endpoints.search.useQuery(searchParams, {
    skip: options?.skip,
  });

  const dataTyped = (data as unknown) as SearchResult<
    EnabledStagesValue | UserValue
  >;

  return {
    data: transformTicketList(dataTyped?.results || []),
    count: data?.meta?.total || 0,
    ...rest,
  };
};
