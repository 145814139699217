import { useCallback, useEffect, useState } from 'react';

import { useToast } from '~/eds';
import { Field, FieldId } from '~/evifields';
import { api } from '~/redux';
import { ContextType } from '~/redux/api/methods';

export function usePinnedFilters(
  fields: Record<FieldId, Field> = {},
  context: ContextType = 'SEARCH',
) {
  const [
    getPinnedFilters,
    { data: serverPinnedFilters, isFetching, isSuccess },
  ] = api.endpoints.getPinnedFilters.useLazyQuery();
  const [updatePinnedFilters] = api.endpoints.updatePinnedFilters.useMutation();
  const [localPinnedFilters, setLocalPinnedFilters] = useState<
    Array<number | string>
  >([]);
  const { toast } = useToast();

  useEffect(() => {
    const result = [];
    if (Object.values(fields).length > 0) {
      for (const filterId of serverPinnedFilters?.data ?? []) {
        const field = fields[filterId];
        if (field) {
          result.push(isNaN(Number(field.id)) ? field.id : Number(field.id));
        }
      }
      setLocalPinnedFilters(result);
    }
  }, [serverPinnedFilters?.data, fields]);

  const updatePinnedFiltersLocal = useCallback(
    async (pinnedFilters: Array<string | number>) => {
      setLocalPinnedFilters(pinnedFilters);
      try {
        await updatePinnedFilters({
          pinnedFilters: pinnedFilters.map(String),
          context,
        }).unwrap();
      } catch (error) {
        toast({
          status: 'danger',
          message: 'Error updating pinned filters',
        });
      }
    },
    [updatePinnedFilters, getPinnedFilters, context],
  );

  return {
    updatePinnedFilters: updatePinnedFiltersLocal,
    getPinnedFilters: () => getPinnedFilters(context),
    pinnedFiltersIds: localPinnedFilters,
    isFetching,
    isSuccess,
  };
}
