import React, { useEffect } from 'react';

import { useToast } from '~/eds';
import { useDebouncedCallback } from '~/hooks';
import { api, coerceRtkqError } from '~/redux';
import { DashboardEntityType } from '~/redux/api/methods';
import { SearchFilter } from '~/types';
import * as componentTypes from '~/types';

export const getAsyncOptionsContainer = (
  searchFilter: SearchFilter,
  type: DashboardEntityType = 'ticket',
) => {
  const AsyncOptionsContainer: componentTypes.Filters.FieldRendererProps = ({
    Component,
    innerProps,
  }) => {
    const [
      getFilterSuggestions,
      result,
    ] = api.endpoints.getFilterSuggestionsV2.useLazyQuery();

    const debouncedGetFieldValues = useDebouncedCallback(getFilterSuggestions);
    const { toast } = useToast();

    const { data: response, error, isFetching } = result;

    const selectAllCount = response?.meta?.select_all_count;

    const data = response?.data?.map((entity) => entity.attributes);
    const asyncOptions = {
      data,
      error,
      isFetching,
      selectAllCount,
    };
    const fetchOptions = (substringQuery = '') => {
      debouncedGetFieldValues({
        filterId: searchFilter.id,
        type,
        query: substringQuery,
      });
    };
    const asyncProps = {
      asyncOptions,
      onMount: fetchOptions,
      onQuery: fetchOptions,
    };

    useEffect(() => {
      if (error) {
        toast({
          message:
            coerceRtkqError(error)?.response?.data?.detail ||
            'An error occurred while loading field suggestions.',
          status: 'danger',
        });
      }
    }, [error]);

    return <Component asyncProps={asyncProps} innerProps={innerProps} />;
  };
  return AsyncOptionsContainer;
};
