import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDocumentProcessingStatus } from '~/features/document';
import { chatbotSlice } from '~/redux/slices/chatbot';
import { CurrentDocumentInfo } from '~/redux/slices/documentsNavigation';
import { matchPath, useRouting } from '~/routing';
import { Entity, EntityType, Nullable } from '~/types';

import { selectDocument } from '../../ask-anything-button/selectors';
import { ROUTES, testIsPreSig } from '../../ask-anything-button/utils';
import { useGetChatBotStatusMessage } from './useGetChatBotStatusMessage';

interface Props {
  entity: Entity<EntityType>;
  onFinishProcessing?: () => void;
}

export const useDocumentStatusChecker = ({
  entity,
  onFinishProcessing,
}: Props) => {
  const [_isProcessing, setIsDocumentProcessing] = useState(false);
  const document = useSelector(selectDocument);
  const dispatch = useDispatch();
  const { location } = useRouting();

  const currentTicketId: string | null = [
    ROUTES.ticketViewPage,
    ROUTES.reviewerPage,
  ].reduce<string | null>((acc, route) => {
    const match = matchPath(route, location.pathname);
    return match ? match.params.id ?? null : acc;
  }, null);

  const [documentEntity, setDocumentEntity] = useState(
    getStatusEntity(entity, document),
  );
  const ticketVersionId = document?.ticketVersionId;
  const shouldCheckStatus =
    (entity.type === 'document_version' && !testIsPreSig(location.pathname)) ||
    entity.type === 'ticket';

  useEffect(() => {
    if (documentEntity && ticketVersionId && currentTicketId === entity.id) {
      setDocumentEntity(getStatusEntity(entity, document));
    }
  }, [ticketVersionId]);

  const { isOcrProcessing: isProcessing, status } = useDocumentProcessingStatus(
    shouldCheckStatus ? documentEntity : null,
  );

  const statusMessage = useGetChatBotStatusMessage({
    isProcessing,
    isLatestVersion: document?.isLatestVersion ?? true,
    isEdited: document?.isEdited,
  });

  useEffect(() => {
    dispatch(chatbotSlice.actions.setIsTextInputDisabled(isProcessing));
    setIsDocumentProcessing((prevIsProcessing) => {
      if (prevIsProcessing && !isProcessing) {
        onFinishProcessing?.();
      }
      return isProcessing;
    });
  }, [isProcessing]);

  return {
    status,
    isProcessing,
    statusMessage,
  };
};

const getStatusEntity = (
  entity: Entity<EntityType>,
  document: CurrentDocumentInfo | undefined,
): Nullable<Entity<'ticket' | 'document_handler'>> => {
  if (!document) return null;
  if (entity.type === 'document_version') {
    return { id: `${document.handlerId}`, type: 'document_handler' };
  } else if (entity.type === 'ticket') {
    return { id: `${document.ticketVersionId}`, type: 'ticket' };
  }
  return null;
};
