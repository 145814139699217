import {
  endOfDay,
  isToday,
  setHours,
  startOfDay,
  sub,
  subDays,
} from 'date-fns';

import { Filter } from '~/evifields';
import { Attempt } from '~/features/webhooks';
import { courier } from '~/services';

import {
  DeliveryAttemptGetResponseDto,
  ListApiArg,
  PageMetaDto,
} from '../../types/courier/deliveryAttempt';

interface Request {
  filters: Filter[];
  page?: number;
  pageSize?: number;
  sortBy?: {
    id: string;
    desc: boolean;
  };
}

interface Response {
  meta: PageMetaDto;
  data: DeliveryAttemptGetResponseDto[];
}

type AttemptsParamPayload = ListApiArg;

export const getAttempts = async ({
  filters,
  page,
  pageSize,
  sortBy,
}: Request): Promise<{
  results: Attempt[];
  total: number;
}> => {
  const params = mapToParams({
    filters,
    page,
    pageSize,
    sortBy,
  });

  const queryString = buildQueryString(params as AttemptsParamPayload);

  const response: Response = await courier.get(
    `/delivery-attempts${queryString}`,
  );

  return {
    results: response.data.map((attempt) => ({
      ...attempt,
      endpointUrl: attempt.url,
      dateSent: attempt.dateDelivered ? new Date(attempt.dateDelivered) : null,
      status: attempt.deliveryStatus,
      messageId: attempt.id,
      statusDescription: attempt.statusDescription
        ? attempt.statusDescription
        : '',
    })),
    total: response.meta.totalItems,
  };
};

const mapToParams = ({
  filters,
  page = 1,
  pageSize = 500,
  sortBy = {
    id: 'dateDelivered',
    desc: true,
  },
}: Request) => {
  const queryParam = filters.reduce((query, filter) => {
    switch (filter.id) {
      case 'endpointUrl':
        query.url = filter.values as string[];
        break;
      case 'status':
        query.deliveryStatus = filter.values as string[];
        break;
      case 'eventType':
        query.eventType = filter.values as string[];
        break;
      case 'dateDelivered':
        const { operatorId, values } = filter;

        const [date1, date2] = (values as Date[]) ?? [];

        const today = new Date();

        const minEndDate = (date: Date) => {
          return isToday(date) ? today : endOfDay(date);
        };

        const maxEndDate = (date: Date) => {
          return isToday(date) ? startOfDay(today) : startOfDay(date);
        };

        switch (operatorId) {
          case 'date_after':
            query.dateDeliveredStart = maxEndDate(date1).toString();
            break;
          case 'date_before':
            query.dateDeliveredStart = subDays(
              setHours(today, 0),
              29,
            ).toString();
            query.dateDeliveredEnd = minEndDate(date1).toString();
            break;
          case 'date_on':
            query.dateDeliveredStart = setHours(date1, 0).toString();
            query.dateDeliveredEnd = minEndDate(date1).toString();
            break;
          case 'date_between':
            query.dateDeliveredStart = date1.toString();
            query.dateDeliveredEnd = minEndDate(date2).toString();
            break;
          case 'date_in_the_last':
          default:
            const { unit, value } = filter.values[0] as {
              unit: string;
              value: number;
            };
            const lastNumberOfDays = sub(setHours(today, 0), {
              [unit]: value,
            });

            query.dateDeliveredStart = lastNumberOfDays.toString();
        }
        break;
    }

    return query;
  }, {} as AttemptsParamPayload);

  return {
    page: page.toString(),
    pageSize: pageSize.toString(),
    sortBy: sortBy.id,
    desc: sortBy.desc,
    dateDeliveredStart: queryParam.dateDeliveredStart,
    dateDeliveredEnd: queryParam?.dateDeliveredEnd
      ? queryParam.dateDeliveredEnd
      : undefined,
    deliveryStatus:
      queryParam?.deliveryStatus && queryParam.deliveryStatus.length > 0
        ? queryParam?.deliveryStatus
        : undefined,
    eventType:
      queryParam?.eventType && queryParam?.eventType?.length > 0
        ? queryParam?.eventType
        : undefined,
    endpointId:
      queryParam?.url && queryParam.url.length > 0
        ? queryParam?.url
        : undefined,
  };
};

const buildQueryString = (params: AttemptsParamPayload) => {
  const queryParams = [];

  if (params.page) {
    queryParams.push(`page=${params.page}`);
  }

  if (params.pageSize) {
    queryParams.push(`pageSize=${params.pageSize}`);
  }

  if (params.sortBy) {
    queryParams.push(`sortBy=${params.sortBy}`);
    queryParams.push(`desc=${params.desc}`);
  }

  if (params.deliveryStatus) {
    queryParams.push(
      `deliveryStatus=${encodeURIComponent(params.deliveryStatus.join(','))}`,
    );
  }

  if (params.eventType) {
    queryParams.push(
      `eventType=${encodeURIComponent(params.eventType.join(','))}`,
    );
  }

  if (params.dateDeliveredStart) {
    queryParams.push(`dateDeliveredStart=${params.dateDeliveredStart}`);
  }

  if (params.dateDeliveredEnd) {
    queryParams.push(`dateDeliveredEnd=${params.dateDeliveredEnd}`);
  }

  if (params.endpointId) {
    queryParams.push(
      `endpointId=${encodeURIComponent(params.endpointId.join(','))}`,
    );
  }

  return queryParams.length ? `?${queryParams.join('&')}` : '';
};
