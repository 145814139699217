import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'uuid';

import BaseLoadView from '~/components/Shared/BaseLoadView';
import BaseSaveView from '~/components/Shared/BaseSaveView';
import { EntityVisibilityType } from '~/components/Shared/EntityVisibilitySelect';
import { DEFAULT_SORT_BY, STATIC_COLUMNS } from '~/constants/search';
import { Box, EmptyPage, Layout, PageLayout, useToast } from '~/eds';
import { TableViewContextType } from '~/enums';
import { FieldId, Filter } from '~/evifields';
import {
  useCurrentUser,
  useDocumentDetails,
  usePinnedFilters,
  useTableState,
} from '~/hooks';
import { api, selectors } from '~/redux';
import { SearchItem } from '~/redux/api/methods';
import { buildQuery, QueryGroup } from '~/redux/api/methods/searchV3';
import { chatbotSlice } from '~/redux/slices/chatbot';
import searchV3 from '~/redux/slices/searchV3';
import { RoutePathType, useRouting } from '~/routing';
import { Nullable, QueryOperatorEntity } from '~/types';
import {
  MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE,
  MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH,
} from '~/types/modal.types';
import { testIsAdmin } from '~/utils/user';

import { DEFAULT_CHAT_CONTEXT } from '../ask-anything';
import { DEFAULT_SEARCH_ID } from '../ask-anything/ask-anything-button/utils';
import {
  getActiveFilterIds,
  SaveActionButton,
  SaveEditEntityModal,
  useDocumentsBulkActionsPanel,
} from '../search';
import { ReadOnlyComplexQuery } from '../search/QueryBuilder';
import { BooleanSearchBar } from './boolean-search-bar';
import { attachRenderersAndSerializers, Filters } from './filters';
import { QueryBuilder } from './query-builder';
import { ResultsTable } from './results-table';

const THE_ENTITY: 'document' | 'ticket' = 'document' as const;

export const AdvancedSearch = () => {
  const dispatch = useDispatch();
  const { navigate, params } = useRouting();
  const { searchId = DEFAULT_SEARCH_ID } = params;
  const currentUser = useCurrentUser();
  const { toast } = useToast();
  const isDefaultSearch = !searchId || searchId === DEFAULT_SEARCH_ID;

  const {
    featuredClauses,
    resultsCache,
    searchText,
    queryBuilder,
    selectedFilters,
    isInitialized,
    queryBuilderPanelVisible,
  } = useSelector(selectors.selectSearchConfig);
  const isDirty = useSelector(selectors.selectIsSearchV3Dirty);
  const activeFilters = useSelector(selectors.selectSearchV3ActiveFilters);
  const shouldPerformSearch =
    isInitialized || activeFilters.length > 0 || queryBuilder;
  const [documentDetails, setDocumentDetails] = useState<Nullable<SearchItem>>(
    null,
  );
  const {
    tableState,
    handleUpdateTableState,
    partialUpdateTableState,
    clearSelectedRows,
  } = useTableState({
    id: `ResultsTable:${DEFAULT_SEARCH_ID}`,
    columnOrder: [],
    sortBy: DEFAULT_SORT_BY[THE_ENTITY],
  });
  const {
    data: searchFilters,
    isFetching: isFetchingFilters,
  } = api.endpoints.getFiltersV2.useQuery({ type: THE_ENTITY });

  const [
    fetchCurrentTableView,
    tableViewResult,
  ] = api.endpoints.getCurrentTableView.useLazyQuery();
  const { data: tableView, isFetching: isFetchingTableView } = tableViewResult;
  const [updateTableView] = api.endpoints.updateTableView.useMutation();

  const {
    data: savedSearch,
    isLoading: isLoadingSavedSearch,
    error: savedSearchError,
  } = api.endpoints.getSavedSearch.useQuery(searchId, {
    skip: isDefaultSearch,
  });
  const [postSavedSearch] = api.endpoints.postSavedSearch.useMutation();
  const [putSavedSearch] = api.endpoints.putSavedSearch.useMutation();

  const hasSavePermission =
    testIsAdmin(currentUser) || savedSearch?.userId === currentUser.id;
  const isSaveDisabled = !isDirty || !hasSavePermission;

  const [isLoadViewVisible, setIsLoadViewVisible] = useState(false);
  const [isSaveViewVisible, setIsSaveViewVisible] = useState(false);
  const [isSaveAsVisible, setIsSaveAsVisible] = useState(false);

  const query = useMemo(() => {
    if (!searchFilters) return [];

    return (
      queryBuilder ??
      buildQuery({
        booleanQuery: searchText ?? '',
        filters: activeFilters,
        searchFilters,
      })
    );
  }, [searchText, activeFilters, queryBuilder, searchFilters]);

  const {
    getPinnedFilters,
    updatePinnedFilters,
    pinnedFiltersIds,
    isFetching: isFetchingPinnedFilters,
  } = usePinnedFilters(searchFilters, 'EVISEARCH_SEARCH');

  const {
    data: searchResponse,
    isFetching: isFetchingSearch,
    refetch: performSearch,
  } = api.endpoints.search.useQuery(
    {
      pageSize: tableState.pageSize,
      page: tableState.pageIndex,
      sortBy: tableState.sortBy,
      type: THE_ENTITY,
      query,
      filters: [...STATIC_COLUMNS[THE_ENTITY], ...tableState.columnOrder],
    },
    { skip: !shouldPerformSearch },
  );

  const fieldsWithLazyLoad = useMemo(
    () =>
      attachRenderersAndSerializers({
        filters: Object.values(searchFilters || {}),
        fieldValues: {},
        type: THE_ENTITY,
      }),
    [searchFilters],
  );

  const { DetailsPanel, panelProps } = useDocumentDetails({
    index: searchResponse?.results?.indexOf(documentDetails!) ?? -1,
    count: searchResponse?.results?.length ?? 0,
    handlers: get(
      documentDetails,
      'selected_field_values.document_handler_id_Document.value_structured',
      [],
    ),
    featuredClauses,
    onHide: () => setDocumentDetails(null),
    onNavigate: (index) =>
      setDocumentDetails(searchResponse?.results?.[index] ?? null),
  });

  const {
    panel: bulkActionPanel,
    limitExceededModal,
    getBulkActions,
  } = useDocumentsBulkActionsPanel({
    selectedDocumentIds: tableState?.selectedIds,
    isAllSelected: tableState?.isAllSelected,
    totalCount: searchResponse?.meta?.total ?? 0,
    query,
    searchAPI: 'v3',
    onActionCompleted: () => {
      clearSelectedRows();
      performSearch();
    },
    resultsCache,
  });

  const handleBooleanSearch = (value: string) => {
    dispatch(searchV3.actions.setSearchText(value));
    partialUpdateTableState({ pageIndex: 1 });
  };

  const handleFiltersChange = (filters: Filter[]) => {
    dispatch(searchV3.actions.setSelectedFilters(filters));
    partialUpdateTableState({ pageIndex: 1 });
  };

  const handleColumnOrderChange = (columnOrder: string[]) => {
    partialUpdateTableState({ columnOrder });
    dispatch(searchV3.actions.setColumnOrder(columnOrder));
    if (isDefaultSearch) {
      updateTableView({
        context: TableViewContextType.EVISEARCH_SEARCH,
        fields: columnOrder.map((field_id, column_number) => ({
          field_id,
          column_number,
        })),
      });
    }
  };

  const openQueryBuilderPanel = () => {
    dispatch(searchV3.actions.setQueryBuilderPanelVisible(true));
  };
  const hideQueryBuilderPanel = () => {
    dispatch(searchV3.actions.setQueryBuilderPanelVisible(false));
  };
  const handleSearchQueryBuilder = (
    groupsEntity: (QueryGroup | QueryOperatorEntity)[],
  ) => {
    const queryBuilderPayload = buildQuery({
      booleanQuery: '',
      filters: [],
      searchFilters,
      queryBuilder: groupsEntity,
    });
    dispatch(searchV3.actions.setQueryBuilder(queryBuilderPayload));
    dispatch(searchV3.actions.setQueryBuilderPanelVisible(false));
    dispatch(searchV3.actions.setSearchText(''));
    partialUpdateTableState({ pageIndex: 1 });
  };

  const handleSaveAs = async (
    data: { name: string } & EntityVisibilityType,
  ) => {
    try {
      const activeFilters = getActiveFilterIds(query);
      const response = await postSavedSearch({
        name: data.name,
        visibility: data.visibility,
        userIds: data.userIds,
        departmentIds: data.departmentIds,
        query: query,
        filters: selectedFilters
          .filter((filter) => !activeFilters.includes(filter.fieldId as string))
          .map((filter) => filter.fieldId),
        resultTable: tableState.columnOrder
          ?.filter((column) => !STATIC_COLUMNS[THE_ENTITY].includes(column))
          .map((column, index) => ({
            filterId: column,
            columnIndex: index,
          })),
      }).unwrap();
      if (response) {
        toast({
          status: 'success',
          message: `Search **${response.name}** has been saved.`,
        });
        navigate(`/search/${response.id}`);
      }
    } catch (error: any) {
      toast({
        status: 'danger',
        message: error.message,
      });
    }
  };

  const handleSave = async () => {
    if (savedSearch) {
      try {
        const activeFilters = getActiveFilterIds(query);
        const response = await putSavedSearch({
          id: savedSearch.id,
          savedSearch: {
            name: savedSearch.name,
            visibility: savedSearch.visibility,
            userIds: savedSearch.userIds,
            departmentIds: savedSearch.departmentIds,
            query: query,
            filters: selectedFilters
              .filter((filter) => !activeFilters.includes(filter.fieldId))
              .map((filter) => filter.fieldId),
            resultTable: tableState.columnOrder
              ?.filter((column) => column !== 'name')
              .map((column, index) => ({
                filterId: column,
                columnIndex: index,
              })),
          },
        }).unwrap();
        if (response) {
          toast({
            status: 'success',
            message: `Search **${response.name}** has been saved.`,
          });
        }
      } catch (error: any) {
        toast({
          status: 'danger',
          message: error.message,
        });
      }
    }
  };

  useEffect(() => {
    if (searchResponse?.results) {
      dispatch(
        searchV3.actions.patchEvisearchResultsCache(searchResponse.results),
      );
      dispatch(searchV3.actions.setSearchText(searchText ?? ''));

      if (searchResponse.results.length > 0) {
        dispatch(
          chatbotSlice.actions.setContext([
            DEFAULT_CHAT_CONTEXT,
            {
              label: 'All Results',
              query,
              title: `All Results (${searchResponse?.meta?.total})`,
              icon: 'search',
              primary: true,
              searchEntity: THE_ENTITY,
              selectedIds: [],
              id: DEFAULT_SEARCH_ID, // or saved search ID?
            },
          ]),
        );
      }
    }
  }, [searchResponse]);
  useEffect(() => {
    if (isDefaultSearch) {
      getPinnedFilters()
        .unwrap()
        .then((res) => {
          const pinnedFilters = res?.data?.map((fieldId) => ({
            id: uuid.v4(),
            fieldId: (Number(fieldId) || fieldId) as FieldId,
            operatorId: null,
            values: [],
          }));
          if (selectedFilters.length === 0) {
            dispatch(searchV3.actions.setSelectedFilters(pinnedFilters ?? []));
          }
        });
      fetchCurrentTableView({
        context: TableViewContextType.EVISEARCH_SEARCH,
      });
    } else {
      getPinnedFilters();
    }

    dispatch(
      chatbotSlice.actions.setContext([
        { ...DEFAULT_CHAT_CONTEXT, primary: true },
      ]),
    );
  }, []);

  useEffect(() => {
    if (tableView?.length) {
      const tableViewFieldIds = tableView.map(
        (tableViewItem) => tableViewItem.field_id,
      ) as string[];

      partialUpdateTableState({ columnOrder: tableViewFieldIds });
    }
  }, [tableView]);

  useEffect(() => {
    if (savedSearch && searchFilters) {
      dispatch(searchV3.actions.setSavedSearch(savedSearch));
      dispatch(
        searchV3.actions.initSavedSearchData({
          searchFilters,
          pageSize: tableState.pageSize,
          sortBy: tableState.sortBy,
        }),
      );
      const columnOrder = savedSearch.resultTable.map(
        (column) => column.filterId,
      ) as string[];
      partialUpdateTableState({ columnOrder: columnOrder });
    }
    return () => {
      dispatch(searchV3.actions.setSavedSearch(undefined));
    };
  }, [searchFilters, savedSearch]);

  const columnOrderIds = useMemo(
    () =>
      tableState.columnOrder.map((field_id, column_number) => ({
        field_id,
        column_number,
      })),
    [tableState.columnOrder],
  );

  const placeholderContent = savedSearchError
    ? {
        title: 'No Access to Page',
        message:
          'The saved search does not exist or you do not have permission to access this saved search. To request permission, contact the user who created the saved search.',
        image: 'error-page-access',
        action: {
          text: 'Take me Home',
          level: 'primary' as const,
          onClick: () => navigate(RoutePathType.Home),
        },
      }
    : undefined;

  const isLoading =
    isFetchingFilters || isFetchingSearch || isFetchingTableView;
  const hasResults = !!searchResponse?.results?.length;

  return (
    <PageLayout
      loadingContent={{
        isLoading: isLoadingSavedSearch,
      }}
      placeholderContent={placeholderContent}
      title={searchId && savedSearch ? savedSearch.name : 'Search'}
    >
      <Layout direction="column" spacing={4}>
        <Layout direction="row" spacing={4} justify="space-between">
          <Box w="100%">
            <BooleanSearchBar
              value={searchText ?? ''}
              onSubmit={handleBooleanSearch}
              isSubmitting={isLoading || !!queryBuilder}
            />
          </Box>
          <Box alignSelf="flex-end">
            <SaveActionButton
              entity="Search"
              saveAsNewAction={{
                disabled: !isDefaultSearch && !isDirty,
                onClick: () => setIsSaveAsVisible(true),
              }}
              overwriteAction={
                !isDefaultSearch
                  ? {
                      onClick: handleSave,
                      tooltip: hasSavePermission
                        ? ''
                        : 'You do not have privileges to save this search.',
                      disabled: isSaveDisabled,
                    }
                  : undefined
              }
            />
          </Box>
        </Layout>

        {queryBuilder ? (
          <ReadOnlyComplexQuery
            queryBuilder={queryBuilder}
            isQueryBuilderSupported={true}
            searchFilters={searchFilters || {}}
            onClear={() => {
              dispatch(searchV3.actions.setQueryBuilder(undefined));
              dispatch(searchV3.actions.setQueryBuilderPanelVisible(false));
            }}
            onEdit={() => {
              dispatch(searchV3.actions.setQueryBuilderPanelVisible(true));
            }}
          />
        ) : (
          <Filters
            isLoading={isFetchingFilters || isFetchingPinnedFilters}
            pinnedFiltersIds={pinnedFiltersIds}
            selectedFilters={selectedFilters}
            searchFilters={fieldsWithLazyLoad}
            onQueryButtonClick={openQueryBuilderPanel}
            onChange={handleFiltersChange}
            onUpdatePinnedFilters={(fieldIds) => updatePinnedFilters(fieldIds)}
          />
        )}

        {shouldPerformSearch || isLoading || hasResults ? (
          <ResultsTable
            data={searchResponse}
            id={DEFAULT_SEARCH_ID}
            isLoading={isLoading}
            enablePersistedTable={isDefaultSearch}
            entity={THE_ENTITY}
            query={query}
            searchFilters={searchFilters ?? {}}
            state={{
              columnOrder: tableState.columnOrder,
              pageIndex: tableState.pageIndex,
              pageSize: tableState.pageSize,
              sortBy: [tableState.sortBy],
              selectedRowIds: tableState.selectedIds,
            }}
            getBulkActions={getBulkActions}
            onUpdateState={handleUpdateTableState}
            onSetColumnOrder={handleColumnOrderChange}
            onRowDetailsClick={setDocumentDetails}
            onSaveView={() => setIsSaveViewVisible(true)}
            onLoadView={() => setIsLoadViewVisible(true)}
          />
        ) : (
          <EmptyPage preset="no-search" />
        )}

        {DetailsPanel && <DetailsPanel {...panelProps} />}
        {queryBuilderPanelVisible && (
          <QueryBuilder
            value={queryBuilder}
            pinnedFiltersIds={pinnedFiltersIds}
            searchFilters={fieldsWithLazyLoad}
            onSubmit={handleSearchQueryBuilder}
            onHide={hideQueryBuilderPanel}
          />
        )}
        {limitExceededModal}
        {bulkActionPanel}

        {isSaveViewVisible && (
          <BaseSaveView
            context={TableViewContextType.EVISEARCH_SEARCH}
            fields={columnOrderIds}
            hideModal={() => setIsSaveViewVisible(false)}
            modalType={MODAL_DOCUMENTS_COLUMN_FIELDS_SAVE}
          />
        )}

        {isLoadViewVisible && (
          <BaseLoadView
            context={TableViewContextType.EVISEARCH_SEARCH}
            modalType={MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH}
            hideModal={() => setIsLoadViewVisible(false)}
            onViewSelected={() =>
              fetchCurrentTableView({
                context: TableViewContextType.EVISEARCH_SEARCH,
              })
            }
          />
        )}

        <SaveEditEntityModal
          placeholder="Saved Search Name"
          onSubmit={handleSaveAs}
          onHide={() => setIsSaveAsVisible(false)}
          isVisible={isSaveAsVisible}
          title="Save as New Search"
        />
      </Layout>
    </PageLayout>
  );
};
