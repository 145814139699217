import React, { useEffect } from 'react';

import AuthenticationStore from '~/auth';
import { Box, ErrorPage } from '~/eds';
import { RoutePathType, useRouting } from '~/routing';

const AS = AuthenticationStore();

const Page = () => {
  const { navigate } = useRouting();

  const isLoggedIn = AS.isLoggedIn();
  const isEmbed = AS.isEmbed();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(isEmbed ? RoutePathType.LoginEmbed : RoutePathType.Login);
    }
  }, [isLoggedIn]);

  const resolveAction = {
    text: 'Take me home',
    onClick: () => navigate('/'),
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <Box py={16}>
      <ErrorPage preset="page-not-found" resolveAction={resolveAction} />
    </Box>
  );
};

export default Page;
