import { capitalize } from 'lodash';

import { EventList } from '~/features/webhooks/types';
import { courier } from '~/services';

import {
  EventDescriptionDto,
  GetEventTypeNestedResponseDto,
} from '../../types/courier/eventTypes';

type EventType = EventDescriptionDto;
type Response = GetEventTypeNestedResponseDto;

export const getConfigurationEvents = async (): Promise<EventList[]> => {
  const response: Response = await courier.get(`/event-types/available/nested`);

  return mapResponseToEventsConfiguration(response);
};

const mapResponseToEventsConfiguration = (
  data: Record<string, EventType[]>,
): EventList[] => {
  const groups: EventList[] = Object.keys(data).map((group) => {
    return {
      name: group,
      label: capitalize(group),
      options: data[group].map((option) => ({
        label: option.eventType,
        value: option.eventType,
        description: option.description ?? '',
      })),
    };
  });

  return groups;
};
