import React, { useState } from 'react';

import { Layout, Modal, Section, useModal } from '~/eds';
import { Filter } from '~/evifields';
import { SearchFiltersPreview } from '~/features/filters';

type Callback = () => void;

interface Props {
  onBack: Callback;
  onCancel: Callback;
}

export const ModelAddScope = ({ onBack, onCancel }: Props) => {
  const [globalFilters, setGlobalFilters] = useState<Filter[]>([]);

  const [confirmPublish, showConfirmPublish] = useModal({
    title: 'Are you sure you want to publish?',
    children: 'TODO [EKP-29508]: Review & Publish',
  });

  return (
    <Modal
      isFullPage
      isVisible
      title="2. Add Scope"
      backAction={{ text: 'Back', onClick: onBack }}
      primaryAction={{ text: 'Review & Publish', onClick: showConfirmPublish }}
      secondaryActions={[{ text: 'Save as Drafts' }]}
      cancelText="Close"
      onCancel={onCancel}
    >
      <Layout preset="sections" p={2}>
        <Section
          title="Set global scope for all categories"
          id="global-scope-filters"
        >
          <SearchFiltersPreview
            description="Apply filters to all categories to choose which documents the models will run on."
            defaultFilterIds={['folder', 'clause', 'Contract Type']}
            filters={globalFilters}
            title="Global Scope"
            onUpdateFilters={(updatedFilters) => {
              setGlobalFilters(updatedFilters);
            }}
          />
        </Section>
        <div>TODO [EKP-29494]: Custom Scope</div>
      </Layout>
      {confirmPublish}
    </Modal>
  );
};
