import { useEffect, useMemo, useState } from 'react';

import { usePdfHighlighterContext } from '~/components/Shared/EcPdfViewerHighlighter';
import { types, useToast } from '~/eds';
import { EntityType, HighlightType } from '~/enums';
import { api } from '~/redux';
import {
  Comment,
  CommentData,
  ContextEntityType,
  CreateCommentHighlightParams,
  Highlight,
  HighlightEntityType,
  HighlightResponse,
  Nullable,
  Uuid,
} from '~/types';
import { scrollToHighlight } from '~/utils/highlights';

interface Props {
  /** if entity is null, getComments request is skipped. */
  entity: types.Entity | null;
  context: types.Entity<ContextEntityType>[];
  /** if highlightEntity is not defined, getHighlights request is skipped. */
  highlightEntity?: HighlightEntityType | null;
  activeCommentId?: types.Uuid;
}

export function useCommentPdfHighlights({
  activeCommentId: initialActiveCommentId,
  context,
  entity,
  highlightEntity,
}: Props) {
  const { toast } = useToast();
  const [activeHighlightId, setActiveHighlightId] = useState<Nullable<Uuid>>(
    null,
  );
  const [createHighlight] = api.endpoints.createHighlight.useMutation();
  const [createComment] = api.endpoints.createComment.useMutation();
  const ticketDocumentVersion = context.find(
    (context) => context.type === EntityType.TicketDocumentVersion,
  );
  const {
    data: highlights = [],
    error: hasErrorGetHighlights,
  } = api.endpoints.getHighlights.useQuery(
    { version_id: Number(highlightEntity?.id), tag: ticketDocumentVersion?.id },
    { skip: !highlightEntity || !highlightEntity.id },
  );
  const { data: comments = [] } = api.endpoints.getComments.useQuery(entity!, {
    skip: !entity || !entity.id,
  });
  const [isDocumentLoading, setIsDocumentLoading] = useState(true);
  const [activeCommentId, setActiveCommentId] = useState<Nullable<Uuid>>(
    initialActiveCommentId || null,
  );
  const [shouldNavigateToHighlight, setShouldNavigateToHighlight] = useState(
    Boolean(initialActiveCommentId),
  );
  const [highlightsToDisplay, setHighlightsToDisplay] = useState<Set<Uuid>>();
  const { viewer } = usePdfHighlighterContext();

  useEffect(() => {
    if (hasErrorGetHighlights) {
      toast({
        message: 'An error occurred while loading the highlights.',
        status: 'danger',
      });
    }
  }, [hasErrorGetHighlights]);

  useEffect(() => {
    if (activeHighlightId) {
      const comment = comments.find((comment) =>
        comment.context.some(
          (context) =>
            context.type === 'highlight' && context.id === activeHighlightId,
        ),
      );
      if (comment) {
        setActiveCommentId(comment.id);
      } else {
        setActiveCommentId(null);
      }
    }
  }, [activeHighlightId]);

  const normalizedDocVersionHighlights = useMemo(() => {
    const map: Record<Uuid, HighlightResponse> = {};
    highlights.forEach((h) => {
      map[h.id] = {
        ...h,
      };
    });
    return map;
  }, [highlights]);

  /** find a highlight from an activeComment from URL */
  useEffect(() => {
    if (activeCommentId && !isDocumentLoading && shouldNavigateToHighlight) {
      const activeComment = comments.find(
        (comment) => comment.id === activeCommentId,
      );
      const activeHighlightEntity = activeComment?.context?.find(
        (context) => context.type === EntityType.Highlight,
      );
      if (activeHighlightEntity) {
        const activeHighlight =
          normalizedDocVersionHighlights[activeHighlightEntity?.id];
        setActiveHighlightId(activeHighlight.id);
        scrollToHighlight(viewer, activeHighlight.highlights.coordinates);
      }
    }
  }, [isDocumentLoading, activeCommentId, comments]);

  const onCreateComment = async ({
    highlight,
    comment,
  }: CreateCommentHighlightParams) => {
    const { highlightedText, position } = highlight;
    let createdComment;
    try {
      const highlightPayload = {
        highlightedText,
        coordinates: position,
      };
      if (highlightEntity) {
        const createdHighlight = await createHighlight({
          version_id: Number(highlightEntity.id),
          tag: ticketDocumentVersion?.id,
          highlights: highlightPayload,
        }).unwrap();
        if (createdHighlight) {
          const commentToCreate: CommentData = {
            ...comment,
            context: [
              ...context,
              {
                type: 'highlight',
                id: createdHighlight.id,
              },
            ],
          };
          createdComment = await createComment(commentToCreate).unwrap();
          setActiveCommentId(createdComment?.id || null);
        }
      }
    } catch {
      toast({
        message: 'An error occured while creating the comment.',
        status: 'danger',
      });
    }
    return createdComment ? createdComment : null;
  };

  const highlightResolver = (highlightEntity: types.Entity) => {
    const highlight = normalizedDocVersionHighlights[highlightEntity.id];
    let resolvedHighlight = null;
    if (highlight) {
      resolvedHighlight = {
        id: highlight.id,
        text: highlight.highlights.highlightedText,
      };
    }
    return resolvedHighlight;
  };

  const setActiveHighlight = (
    highlightId: Uuid | null,
    navigateToHighlight = false,
  ) => {
    setActiveHighlightId(highlightId);
    setShouldNavigateToHighlight(navigateToHighlight);
  };

  const onUpdateFilters = (filteredComments: Comment[]) => {
    const highlightEntities = getHighlightEntites(filteredComments);
    setHighlightsToDisplay(highlightEntities);
  };

  const commentHighlights: Highlight[] = useMemo(() => {
    const filteredHighlights = highlightsToDisplay
      ? highlights.filter((h) => highlightsToDisplay.has(h.id))
      : highlights;

    return filteredHighlights.map((highlightResponse) => ({
      type: HighlightType.Comment,
      id: highlightResponse.id,
      position: highlightResponse.highlights.coordinates,
      content: { text: highlightResponse.highlights.highlightedText },
      comment: { text: '', emoji: '' },
    }));
  }, [highlightsToDisplay, highlights]);

  return {
    activeCommentId,
    activeHighlightId,
    highlights: commentHighlights,
    onUpdateFilters,
    highlightResolver,
    onCreateComment,
    setActiveCommentId,
    setActiveHighlight,
    setIsDocumentLoading,
  };
}

export const getHighlightEntites = (comments: Comment[]) => {
  const highlightSet = new Set<types.Uuid>();

  for (const comment of comments) {
    for (const entity of comment.context) {
      if (entity.type === 'highlight') {
        highlightSet.add(entity.id);
      }
    }
  }

  return highlightSet;
};
