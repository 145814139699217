import * as apiServices from '~/services';
import { HighlightData, HighlightResponse, Uuid } from '~/types';

const { pilot } = apiServices;

interface GetHighlightsParams {
  version_id: number;
  tag?: Uuid;
}

interface HighlightListResponse {
  data: HighlightResponse[];
}

export const getHighlights = async ({
  version_id,
  tag,
}: GetHighlightsParams): Promise<HighlightResponse[]> => {
  const response = (await pilot.get(`version/${version_id}/highlights/`, {
    params: { tag },
  })) as HighlightListResponse;
  return response.data;
};

export const postHighlight = async ({
  tag,
  version_id,
  highlights,
}: HighlightData): Promise<HighlightResponse> => {
  return (await pilot.post(
    `version/${version_id}/highlights/`,
    {
      version_id,
      highlights,
    },
    tag ? { params: { tag } } : undefined,
  )) as HighlightResponse;
};
