import React, { useMemo, useState } from 'react';

import { Layout, Markdown, Panel, types, useToast } from '~/eds';
import { DataFieldType } from '~/enums';
import { EditFieldsAction } from '~/features/document-bulk-actions';
import type { EditFieldData } from '~/features/document-bulk-actions';
import { FlagType, useFlag } from '~/flags';
import { api } from '~/redux';

import { BaseBulkActionProps, DocumentInformationField } from '../types';
import { useDeleteConfirmationModal } from './hooks/useDeleteConfirmationModal';
import { convertFieldValueData, validateFieldValue } from './utils';

interface Props extends BaseBulkActionProps {
  onHide: () => void;
  selectedCount: number;
  selectedDocumentHandlerIds: types.PilotId[];
}

export const EditFieldsActionPanel = ({
  isAllSelected = false,
  query = {},
  selectedCount,
  selectedDocumentIds = [],
  selectedDocumentHandlerIds = [],
  searchAPI,
  onActionCompleted,
  onHide,
}: Props) => {
  const [editFieldData, setEditFieldData] = useState<
    EditFieldData | undefined
  >();
  const isDeleteActionEnabled = useFlag(FlagType.BulkDeleteFieldValues);
  const isBulkEditFieldsRebuildEnabled = useFlag(
    FlagType.BulkEditFieldsRebuild,
  );
  const { toast } = useToast();
  const { selectedFields, fieldValueRecord, actionType } = editFieldData ?? {};

  const [
    bulkAddReplaceFields,
    { isLoading: isLoadingAddReplace },
  ] = api.endpoints.searchV2BulkAddReplaceFields.useMutation();
  const [
    bulkEditFields,
    { isLoading },
  ] = api.endpoints.searchV2BulkEditFields.useMutation();

  const {
    data: fields,
    isLoading: isLoadingFieldData,
  } = api.endpoints.searchV2BulkEditData.useQuery({
    isAllSelected,
    documentHandlerIds: selectedDocumentHandlerIds,
    searchQuery: query,
  });

  const [deleteModal, showDeleteModal] = useDeleteConfirmationModal({
    entity: 'fields',
    selectedCount,
    entityNames: editFieldData?.selectedFields ?? [],
    isAllSelected: false,
    onDeleteClick: submit,
    isDeleteActionLoading: isLoading,
  });

  const fieldsMap: Record<string, DocumentInformationField> = useMemo(() => {
    if (fields) {
      return Object.fromEntries(
        fields
          .filter((field) =>
            isDeleteActionEnabled
              ? true
              : field.type !== DataFieldType.ATTACHMENT,
          )
          .map((field) => [field.label, field]),
      );
    }
    return {};
  }, [fields]);

  const isAnyFieldValueEmpty = useMemo(() => {
    if (!fieldValueRecord) {
      return false;
    }
    return Object.entries(fieldValueRecord).some(([fieldKey, fieldRecord]) => {
      return (
        (fieldsMap[fieldKey].type === DataFieldType.ARRAY_MULTIPLE &&
          fieldRecord.keepOldValues === undefined) ||
        !validateFieldValue(fieldsMap[fieldKey].type, fieldRecord.value)
      );
    });
  }, [fieldValueRecord]);

  async function submit() {
    try {
      if (actionType === 'add-replace' && !isBulkEditFieldsRebuildEnabled) {
        await bulkAddReplaceFields({
          documentHandlerIds: selectedDocumentHandlerIds,
          searchQuery: query,
          searchAPI: searchAPI,
          isAllSelected,
          fields:
            actionType === 'add-replace' && fieldValueRecord
              ? Object.entries(fieldValueRecord).map(
                  ([fieldKey, { value, keepOldValues }]) => ({
                    id: fieldsMap[fieldKey].id,
                    value: convertFieldValueData(
                      fieldsMap[fieldKey].type,
                      value,
                    ),
                    keepOldValues: keepOldValues ?? false,
                  }),
                )
              : undefined,
        }).unwrap();
      } else {
        await bulkEditFields({
          type: actionType === 'delete' ? 'delete' : 'add-replace',
          fieldIds: getFieldIds(),
          fieldUpdates: getFieldUpdates(),
          documentIds: selectedDocumentIds,
          searchAPI: searchAPI,
          isAllSelected,
          searchQuery: query,
        }).unwrap();
      }
      toast({
        status: 'inactive',
        title: 'We are processing your request to edit fields',
        message: 'You can browse away',
      });
    } catch {
      toast({
        status: 'danger',
        message: 'An error occurred while editing fields.',
      });
    } finally {
      onActionCompleted?.();
    }
  }

  const getFieldIds = () => {
    if (actionType !== 'delete') return undefined;
    return selectedFields?.map((field) => fieldsMap[field].id);
  };

  const getFieldUpdates = () => {
    if (actionType !== 'add-replace' || !fieldValueRecord) return undefined;
    return Object.entries(fieldValueRecord).map(
      ([fieldKey, { value, keepOldValues }]) => ({
        id: fieldsMap[fieldKey].id,
        value: convertFieldValueData(fieldsMap[fieldKey].type, value),
        keepOldValues: keepOldValues ?? false,
      }),
    );
  };

  const handleSave = () => {
    if (actionType === 'delete') {
      showDeleteModal();
    } else {
      submit();
    }
  };
  const isSaveDisabled =
    !editFieldData?.selectedFields.length ||
    !editFieldData?.actionType ||
    (editFieldData?.actionType === 'add-replace' && isAnyFieldValueEmpty);

  const footer = {
    actions: [
      {
        text: 'Save',
        onClick: handleSave,
        level: 'primary' as const,
        disabled: isSaveDisabled,
        isLoading: isLoading || isLoadingAddReplace,
      },
    ],
  };
  const loadingContent = { isLoading: isLoadingFieldData };

  return (
    <>
      <Panel
        hidden={{ isHidden: false, onHide }}
        title="Edit Fields"
        enableBackdrop
        position="right"
        width="m"
        footer={footer}
        loadingContent={loadingContent}
      >
        <Layout direction="column" spacing={4}>
          <Markdown
            text={`Editing is limited to documents and fields you have permission to edit. Specify which data field(s) and value(s) to edit across the **${selectedCount}** selected document(s) and their duplicate(s).`}
          />
          <EditFieldsAction fields={fieldsMap} onChange={setEditFieldData} />
        </Layout>
      </Panel>
      {deleteModal}
    </>
  );
};
