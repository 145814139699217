import React, { Component } from 'react';

import {
  EmailSettings,
  FolderSettings,
  getClientWhitelist,
  PermissionSettings,
  updateClientWhitelist,
} from '~/components/Admin/EmailIntakePage';
import { showToast } from '~/components/Shared/EcToast';
import { Button, Layout, PageLayout } from '~/eds';
import { withCurrentUser } from '~/hocs';
import { generateNav, RoutePathType, withRouting } from '~/routing';
import { ERROR, SUCCESS } from '~/types/toast.types';

class Page extends Component {
  constructor(props) {
    super(props);

    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleShowToast = this.handleShowToast.bind(this);
    this.handleFolderChange = this.handleFolderChange.bind(this);
    this.handlePermissionChange = this.handlePermissionChange.bind(this);

    this.state = {
      submitting: false,
      inboundEmail: undefined,
      inboundUploadLocation: undefined,
      whitelistedUsers: undefined,
      whitelistedEmailList: undefined,
      whitelistedDomainList: undefined,
      userNamesDropdownList: undefined,
    };
    this.formData = {
      inboundUploadLocation: undefined,
      whitelistedUsers: undefined,
      whitelistedEmailList: undefined,
      whitelistedDomainList: undefined,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const {
      params: { clientId },
    } = this.props;

    this.setState({ loading: true });
    getClientWhitelist(clientId)
      .then((data) => {
        this.setState({ loading: false, errorLoading: false });
        this.processData(data);
      })
      .catch(() => this.handleErrorLoadingData());
  }

  handleErrorLoadingData() {
    this.setState({ loading: false, errorLoading: true });
  }

  processData(data) {
    let listUserNames = [];

    if (data.user_list) {
      data.user_list.forEach((item) => {
        if (item.first_name !== '' && item.last_name !== '') {
          listUserNames.push({
            display_value: item.first_name + ' ' + item.last_name,
            value: item.id,
            item: item,
          });
        }
      });
    }

    this.setState({
      inboundEmail: data.inbound_email ? data.inbound_email : '',
      inboundUploadLocation: data.inbound_default_folder
        ? data.inbound_default_folder
        : undefined,
      whitelistedUsers: data.inbound_whitelisted_users
        ? data.inbound_whitelisted_users.map((val) => ({
            label: val.first_name + ' ' + val.last_name,
            value: val.id,
            key: val,
          }))
        : [],
      whitelistedEmailList: data.inbound_whitelisted_emails
        ? data.inbound_whitelisted_emails.map((val) => ({
            label: val,
            value: val,
            key: val,
          }))
        : [],
      whitelistedDomainList: data.inbound_whitelisted_domains
        ? data.inbound_whitelisted_domains.map((val) => ({
            label: val,
            value: val,
            key: val,
          }))
        : [],
      userNamesDropdownList: listUserNames,
    });
  }

  handleShowToast(type, text) {
    showToast(type, text);
  }

  handleFolderChange(id) {
    this.formData.inboundUploadLocation = id;
  }

  handlePermissionChange(data, includeSpecificWhitelistedUsers) {
    this.formData.whitelistedUsers = includeSpecificWhitelistedUsers
      ? data.whitelistedUsers.map((item) => item.value)
      : [];
    this.formData.whitelistedEmailList = data.whitelistedEmailList.map(
      (item) => item.value,
    );
    this.formData.whitelistedDomainList = data.whitelistedDomainList.map(
      (item) => item.value,
    );
  }

  handleActionClick() {
    const {
      params: { clientId },
    } = this.props;
    const {
      inboundUploadLocation,
      whitelistedUsers,
      whitelistedEmailList,
      whitelistedDomainList,
    } = this.formData;

    const inboundUploadLocationToUpdate =
      inboundUploadLocation || this.state.inboundUploadLocation.id;
    const whitelistedUsersToUpdate =
      whitelistedUsers || this.state.whitelistedUsers.map((user) => user.value);
    const whitelistedEmailListToUpdate =
      whitelistedEmailList ||
      this.state.whitelistedEmailList.map((email) => email.value);
    const whitelistedDomainListToUpdate =
      whitelistedDomainList ||
      this.state.whitelistedDomainList.map((domain) => domain.value);

    this.setState({ submitting: true });
    updateClientWhitelist(
      clientId,
      inboundUploadLocationToUpdate,
      whitelistedUsersToUpdate,
      whitelistedEmailListToUpdate,
      whitelistedDomainListToUpdate,
    )
      .then(() => {
        this.setState({ submitting: false });
        this.handleShowToast(SUCCESS, 'Saved!');
      })
      .catch(() => {
        this.setState({ submitting: false });
        this.handleShowToast(ERROR, 'An error occurred when saving the data.');
      });
  }

  renderFolderSettings() {
    const { inboundUploadLocation } = this.state;

    if (inboundUploadLocation !== undefined) {
      return (
        <FolderSettings
          inboundUploadLocation={inboundUploadLocation}
          handleFolderChange={this.handleFolderChange}
        />
      );
    } else {
      return null;
    }
  }

  renderPermissionSettings() {
    const {
      params: { clientId },
    } = this.props;
    const {
      whitelistedUsers,
      whitelistedEmailList,
      whitelistedDomainList,
      userNamesDropdownList,
    } = this.state;

    if (
      whitelistedUsers !== undefined &&
      whitelistedEmailList !== undefined &&
      whitelistedDomainList !== undefined
    ) {
      return (
        <PermissionSettings
          clientId={clientId}
          whitelistedUsers={whitelistedUsers}
          whitelistedEmailList={whitelistedEmailList}
          whitelistedDomainList={whitelistedDomainList}
          userNamesDropdownList={userNamesDropdownList}
          handlePermissionChange={this.handlePermissionChange}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const { loading, errorLoading, submitting, inboundEmail } = this.state;

    const loadingContent =
      loading && !errorLoading
        ? {
            isLoading: true,
          }
        : undefined;
    const placeholderContent =
      !loading && errorLoading
        ? {
            message: 'An error occurred while loading this page.',
          }
        : undefined;
    const nav = generateNav({
      current: {
        text: 'Email Intake',
        pathname: RoutePathType.AdminConsoleClientEmailIntake,
      },
      from: RoutePathType.AdminConsoleClient,
      params: {
        clientId: this.props.currentUser.client,
      },
    });

    const {
      params: { clientId },
    } = this.props;

    const renderEmailSettings = !!inboundEmail ? (
      <EmailSettings inboundEmail={inboundEmail} clientId={clientId} />
    ) : null;

    return (
      <PageLayout
        title="Email Intake"
        nav={nav}
        loadingContent={loadingContent}
        placeholderContent={placeholderContent}
      >
        <Layout direction="column" preset="cards">
          {renderEmailSettings}
          {this.renderFolderSettings()}
          {this.renderPermissionSettings()}
          <Layout>
            <Button
              text="Save Changes"
              shouldTruncate={true}
              disabled={submitting}
              variant="primary"
              onClick={this.handleActionClick}
            />
          </Layout>
        </Layout>
      </PageLayout>
    );
  }
}
export default withCurrentUser(withRouting(Page));
