import { orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  ticketActivitiesSet,
  ticketCurrentVersionIdSet,
  ticketCurrentVersionSet,
  ticketDocModeSet,
  ticketDocumentSet,
  ticketDocumentVersionsSet,
  ticketIsInEditorModeSet,
  ticketParticipantsSet,
  ticketStagesSet,
  ticketSummarySet,
} from '~/actions';
import {
  deleteTicketDocumentVersion,
  downloadTicketDocument,
  getActivities,
  getTicketDocumentFiles,
  getTicketDocumentVersions,
  uploadNewDocumentVersion,
} from '~/api';
import { createTraceSpan } from '~/components/App/AppTracingInitializer';
import { ClauseLibraryPanel } from '~/components/ClauseLibrary/ClauseLibraryPanel';
import AccessControl from '~/components/Shared/AccessControl';
import AppLayout from '~/components/Shared/AppLayout';
import {
  ActivityLogPanel,
  Comments,
  DocumentDetails,
  GenAiMvpPanel,
  GenAiPanel,
  RevisionsPanel,
  TicketIntakeForm,
} from '~/components/Shared/AppLayout/SidebarContent';
import { filterHighlightsOfDifferentVersion } from '~/components/Shared/AppLayout/SidebarContent/Comments/utils';
import { CommentDraftWithMentions } from '~/components/Shared/Comments';
import CompareDocVersionMenu from '~/components/Shared/CompareDocVersionMenu';
import { ContextMenuValues } from '~/components/Shared/ContextMenu';
import CurrentDocVersionMenu from '~/components/Shared/CurrentDocVersionMenu';
import DocModeMenu from '~/components/Shared/DocModeMenu';
import {
  getComment,
  useDocumentEditorContext,
} from '~/components/Shared/DocumentEditor';
import {
  extractPositionFromTokens,
  getRevisions,
  getTokensFromSelection,
  testIsTokenMatch,
  updateRevisions,
} from '~/components/Shared/DocumentEditor/utils';
import { showToast } from '~/components/Shared/EcToast';
import { ACTIVE_HIGHLIGHT_DISTANCE_TO_TOP_OF_PAGE } from '~/constants/highlights';
import { panels } from '~/constants/panels';
import { TraceAction, TraceName } from '~/constants/trace';
import { useAppConfigContext, usePusherContext } from '~/contexts';
import {
  Button,
  ContentContainer,
  Text as EdsText,
  EmptyPage,
  ErrorPage,
  IconButton,
  INPUT_DEBOUNCE_MS,
  Layout,
  Link,
  LoadingSpinner,
  PageSidebar,
  serializeContentAst,
  useDebounce,
  useIntervalDateText,
  useToast,
} from '~/eds';
import {
  DocModeType,
  EntityType,
  FeatureFlagType,
  FileExtensionType,
  HighlightType,
  PanelType,
  PusherEventType,
  QueryParamType,
  TicketActivityType,
  TicketPermissionType,
  TicketStageType,
  UploadStatusType,
} from '~/enums';
import { FlagType, useFlag } from '~/flags';
import { withUsers } from '~/hocs';
import {
  useAsync,
  useCommentPdfHighlights,
  useHasFeatureFlag,
  useIsOnline,
  usePermission,
  usePollingRequest,
  useSearchParams,
} from '~/hooks';
import {
  getDocumentVersion,
  getHasTemporaryViewAnyPermission,
} from '~/reducers/ticket';
import { actions, api, selectors, TAGS } from '~/redux';
import { TagType } from '~/redux/api/TagType';
import { chatbotSlice } from '~/redux/slices/chatbot';
import { useRouting } from '~/routing';
import { redirectToPage } from '~/services/redirects';
import * as toastTypes from '~/types/toast.types';
import {
  Box,
  FlexLayout,
  Icon,
  PageLayout,
  Text,
  Tooltip,
  useModal,
} from '~/ui';
import { captureException } from '~/utils';
import { coerceFileType, downloadFile, getBasename } from '~/utils/files';
import { copyToClipboard } from '~/utils/helper.utils';
import {
  createPositionObject,
  getClauseHighlight,
  getClauseHighlightId,
  getClauseIdFromHighlight,
  renderHighlight,
  scrollToHighlight,
} from '~/utils/highlights';
import { getPdfDocumentFromBlob } from '~/utils/pdfjs';
import {
  deletePageSearchParam,
  getPageSearchQueryByKey,
} from '~/utils/searchQuery';
import {
  getStagePermissions,
  getVersionFileName,
  isCurrentVersionCounterparty,
  removeFileNameExtension,
} from '~/utils/ticket';

import PdfViewerHighlighter, {
  usePdfHighlighterContext,
} from '../../Shared/EcPdfViewerHighlighter';
import ShareDocumentButton from '../shared/ShareDocumentButton';
import { useAddTicketParticipantModal } from '../shared/useAddTicketParticipantModal';
import UploadVersionAction from '../TicketsViewPage/TicketDocumentActions/UploadVersionAction';
import {
  DOCUMENT_COMMENT_CHIP,
  ERROR_MESSAGES,
  SAVE_DOCUMENT_CACHE_KEY,
  TICKET_COMMENT_CHIP,
} from './constants';
import { TicketReviewerDocumentEditor } from './DocumentViewersAndEditors/TicketReviewerDocumentEditor';
import {
  getClauseById,
  getCompareToVersionId,
  getHeaderTitleWidth,
} from './TicketReviewerPage.utils';

function TicketReviewerPage({
  // connected props
  currentUser,
  hasTemporaryViewAnyPermission,
  ticket,
  selectedDocumentHighlights,
  ticketDocModeSet,
  ticketCurrentVersionSet,
  ticketCurrentVersionIdSet,
  ticketIsInEditorModeSet,
  ticketDocumentVersionsSet,
}) {
  const { location, navigate, params } = useRouting();
  const { pusher } = usePusherContext();
  const dispatch = useDispatch();
  const {
    hasPermission: hasAskAIPermission,
    isLoading: isLoadingPermission,
  } = usePermission({
    permission: {
      resourceId: 'conversational_ai',
      resourceType: 'edit',
    },
  });
  const { ticketId } = params;
  const activeDocumentVersion = getDocumentVersion(
    ticket,
    params.versionNumber,
  );
  const isVersionNumberUuid = params.versionNumber.includes('-');
  const [commentId] = useSearchParams(QueryParamType.CommentId);
  const [ticketCommentId] = useSearchParams(QueryParamType.TicketCommentId);
  const { viewer } = usePdfHighlighterContext();
  const documentId = ticket?.document.id;
  const versionNumber = Number(params.versionNumber);
  const versionId = activeDocumentVersion?.id;
  const versionDocType = activeDocumentVersion?.fileType;
  const isPdfType = versionDocType === coerceFileType(FileExtensionType.Pdf);

  const coerceDocFileTypes = [
    FileExtensionType.Doc,
    FileExtensionType.Docx,
  ].map((type) => coerceFileType(type));
  const isDocumentEditorDocType = coerceDocFileTypes.includes(versionDocType);
  const coerceWopitestTypes = [
    FileExtensionType.Wopitest,
    FileExtensionType.Wopitestx,
  ].map((type) => coerceFileType(type));
  const isWopitestType = coerceWopitestTypes.includes(versionDocType);
  const isPreSigCommentsEnabled = useHasFeatureFlag(
    FeatureFlagType.PreSigComments,
  );
  const isBringAiToWorkflowEnabled = useFlag(FlagType.BringAiToWorkflow);
  const isConvertPdfToDocxEnabled = useFlag(FlagType.ConvertPdfToDocx);
  const isActivityLogPanelEnabled = useFlag(FlagType.ActivityLogPanel);
  const enableTurnTracking = useFlag(FlagType.TurnTracking);
  const isBreakIntakeFormLinkEnabled = useFlag(FlagType.BreakIntakeFormLink);
  const isClauseLibraryInDocumentEditorEnabled = useFlag(
    FlagType.ClauseLibraryInDocumentEditor,
  );
  const isAskAnythingEnabled = useFlag(FlagType.AskAnything);
  const isAskAnythingPreSigEnabled =
    useFlag(FlagType.AskAnythingPreSig) &&
    isAskAnythingEnabled &&
    hasAskAIPermission;

  const { hasPermission: hasGenAiPermission } = usePermission({
    permission: {
      resourceId: 'ai_drafting_tools',
      resourceType: 'edit',
    },
  });
  const enableGenAi = useFlag(FlagType.GenAi);
  const enableGenAiRedline = useFlag(FlagType.GenAiRedline);
  const enableGenAiM2 = useFlag(FlagType.GenAiM2);
  const enableIntakeForm = useFlag(FlagType.IntakeFormPanel);

  const shouldShowTasks =
    isBringAiToWorkflowEnabled &&
    ticket.stage === TicketStageType.Review &&
    hasTemporaryViewAnyPermission;
  const shouldSidebarOpen = !!(ticketCommentId || shouldShowTasks);
  const LOADING_MESSAGES = {
    uploadingDocument: 'Uploading your document',
    scanningDocument: 'Scanning your document for viruses',
    convertingDocument: 'Converting document to be loaded in the editor',
    extractingComments: 'Extracting comments from the document',
  };

  const {
    isPanelEnlarged,
    togglePanelEnlarged,
    condensePanel,
    isContentEnlarged,
    toggleContentEnlarged,
    enlargeContent,
    condenseContent,
    isSidebarExpanded,
    toggleSidebarExpand,
    openSidebar,
    closeSidebar,
    setPanelWidth,
  } = useAppConfigContext();

  const [selectedRiskId, setSelectedRiskId] = useState(null);
  const [pdfHighlights, setPdfHighlights] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [isLoadingRedlines, setIsLoadingRedlines] = useState(false);
  const [baseVersionId, setBaseVersionId] = useState(versionId);
  const [isComparingDocs, setIsComparingDocs] = useState(false);
  const [isCurrentlySaving, setIsCurrentlySaving] = useState(false);
  const [selectedClause, setSelectedClause] = useState(null);
  const [selectedClausesForGenAi, setSelectedClausesForGenAi] = useState([]);
  const [selectedText, setSelectedText] = useState('');
  const [genAiMode, setGenAiMode] = useState('clause');
  const compareToVersionNumber = Number(
    getPageSearchQueryByKey(QueryParamType.CompareTo),
  );
  const isEditMode = getPageSearchQueryByKey(QueryParamType.IsEditMode);
  const currentVersionHasWorkingDraft = activeDocumentVersion.hasWorkingDraft;

  const [isOnMostRecentVersion, setIsOnMostRecentVersion] = useState(false);
  const [isDocxHighlightsLoading, setIsDocxHighlightsLoading] = useState(true);
  const [hasAlgorithmsFinished, setHasAlgorithmsFinished] = useState(false);
  const [activeCommentId, setActiveCommentId] = useState(ticketCommentId);
  const [activeEditorCommentId, setActiveEditorCommentId] = useState(commentId);
  const [activeRevisionId, setActiveRevisionId] = useState('');
  const [selectedPanel, setSelectedPanel] = useState('');
  const [isEj2DownloadingDocument, setIsEj2DownloadingDocument] = useState(
    false,
  );
  const [loadingMessageOnSave, setLoadingMessageOnSave] = useState(
    LOADING_MESSAGES.uploadingDocument,
  );
  const [loadingMessagePageContent, setLoadingMessagePageContent] = useState(
    LOADING_MESSAGES.convertingDocument,
  );

  const onUploadNewVersion = useCallback(() => {
    setIsCurrentlySaving(true);
  }, []);

  // remove clause when starting to compare documents
  useEffect(() => {
    if (compareToVersionNumber) {
      setSelectedClause(null);
    }
  }, [compareToVersionNumber]);

  // loading ticket information

  const {
    data: ticketDocumentData = {},
    isSuccess: isTicketDocumentSuccess,
    fulfilledTimeStamp: ticketDocumentFulfilledTimeStamp,
    isFetching: isTicketDocumentFetching,
  } = api.endpoints.getTicketDocument.useQuery({
    ticketId,
  });

  const {
    data: ticketCurrentStageData = {},
    isSuccess: isTicketCurrentStageSuccess,
    fulfilledTimeStamp: ticketCurrentStageFulfilledTimeStamp,
  } = api.endpoints.getTicketCurrentStage.useQuery({ ticketId });

  const {
    data: ticketSummaryData = {},
    isError: isTicketSummaryError,
    error: ticketSummaryError,
    isSuccess: isTicketSummarySuccess,
    fulfilledTimeStamp: ticketSummaryFulfilledTimeStamp,
  } = api.endpoints.getTicketSummary.useQuery({
    ticketId,
  });

  const [isLoadingDocument, setIsLoadingDocument] = useState(true);
  const [isLoadingTicketSummary, setIsLoadingTicketSummary] = useState(true);
  const [isLoadingCurrentStage, setIsLoadingCurrentStage] = useState(true);
  const [isLoadingDocumentVersions, setIsLoadingDocumentVersions] = useState(
    true,
  );

  const handleTicketError = () => {
    toast({
      message: 'There has been an error when loading this ticket.',
      type: 'error',
    });
  };

  useEffect(() => {
    setIsLoadingDocumentVersions(isTicketDocumentFetching);
  }, [isTicketDocumentFetching]);

  // onSuccess for the ticketDocument fetch
  useEffect(() => {
    if (isTicketDocumentSuccess) {
      try {
        dispatch(ticketDocumentSet(ticketDocumentData));
        getUpToDateVersions(ticketDocumentData.id);
      } catch {
        handleTicketError();
      } finally {
        setIsLoadingDocument(false);
      }
    }
  }, [ticketDocumentFulfilledTimeStamp]);

  // onSuccess for the ticketSummary fetch
  useEffect(() => {
    if (isTicketSummarySuccess) {
      try {
        dispatch(ticketSummarySet(ticketSummaryData));
      } catch {
        handleTicketError();
      } finally {
        setIsLoadingTicketSummary(false);
      }
    }
  }, [ticketSummaryFulfilledTimeStamp]);

  // onSuccess for the ticketCurrentStage fetch
  useEffect(() => {
    if (isTicketCurrentStageSuccess) {
      try {
        dispatch(ticketStagesSet(ticketCurrentStageData));
      } catch {
        handleTicketError();
      } finally {
        setIsLoadingCurrentStage(false);
      }
    }
  }, [ticketCurrentStageFulfilledTimeStamp]);

  useEffect(() => {
    if (
      isCurrentlySaving &&
      loadingMessageOnSave === LOADING_MESSAGES.uploadingDocument
    ) {
      setTimeout(
        () => setLoadingMessageOnSave(LOADING_MESSAGES.scanningDocument),
        2000,
      );
    }
  }, [isCurrentlySaving]);

  /** this hook monitors for urls that uses version number as uuid.
  It replaces by the correct version number for better UX */
  useEffect(() => {
    if (isVersionNumberUuid && ticket.documentVersions?.length > 0) {
      const versionId = params.versionNumber;
      const version = ticket.documentVersions.find(
        (version) => version.id === versionId,
      );
      if (version) {
        ticketCurrentVersionIdSet(version.id);
        const paths = location.pathname.split('/');
        const newUrl =
          // remove the id and replace with the version number
          paths.slice(0, paths.length - 1).join('/') +
          `/${version.versionNumber}${window.location.search}`;
        navigate(newUrl, { replace: true });
      }
    }
  }, [isVersionNumberUuid, ticket.documentVersions.length]);

  const { toast } = useToast();

  const shouldUsePusherToReceiveDocUpdates =
    isDocumentEditorDocType && isOnMostRecentVersion;

  const hasPreSigCommentsFlagEnabled = useHasFeatureFlag(
    FeatureFlagType.PreSigComments,
  );

  useEffect(() => {
    if (versionId) {
      setBaseVersionId(versionId);
    }
  }, [versionId]);

  const [
    createActivityLog,
  ] = api.endpoints.createActivityLogEvent.useMutation();

  const {
    data: documentData,
    error: documentError,
    isPollingFinished: isPollingDocumentFinished,
    reset: resetDocumentPolling,
  } = usePollingRequest(
    api.endpoints.getDocumentByTag.useQuery,
    { tag: versionId },
    {
      skip:
        !activeDocumentVersion.hasAi ||
        !versionId ||
        !isBringAiToWorkflowEnabled ||
        hasAlgorithmsFinished,
      maxRetries: 360,
      pollingInterval: 10000,
    },
  );
  const arePDFProvisionsLoading =
    !hasAlgorithmsFinished || !isPollingDocumentFinished;
  const ticketEntity = { id: ticketId, type: EntityType.Ticket };
  const versionEntity = {
    id: baseVersionId,
    type: EntityType.TicketDocumentVersion,
  };
  const documentVersionEntity = {
    id:
      documentData && documentData.currentVersion
        ? documentData.currentVersion.id
        : null,
    type: EntityType.DocumentVersion,
  };
  const isProcessingClauses = activeDocumentVersion?.hasAi
    ? arePDFProvisionsLoading
    : false;

  const {
    data: documentContent,
    refetch: refetchDocumentContent,
    requestId: documentContentRequestId,
    isUninitialized: isDocumentContentUninitialized,
  } = api.endpoints.getDocumentContent.useQuery(
    {
      tag: versionId,
      documentVersionId: documentVersionEntity.id,
    },
    {
      skip: !versionId || !documentVersionEntity?.id,
    },
  );

  // if the words are empty, we should refetch it. we use the requestId to identify a new request
  useEffect(() => {
    if (!isDocumentContentUninitialized && !documentContent?.words?.length) {
      const timeout = setTimeout(() => {
        refetchDocumentContent();
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [documentContent, documentContentRequestId]);

  const panelWidth = isPanelEnlarged ? 'fill' : 'm';

  useEffect(() => {
    !shouldSidebarOpen ? enlargeContent() : openSidebar();
    setPanelWidth(panelWidth);
  }, [shouldSidebarOpen]);

  const {
    data: pdfOcrBlob,
    error: pdfOcrError,
    isLoading: isPDFOCRLoading,
  } = api.endpoints.getPdfDocumentOcr.useQuery(
    { tag: versionId },
    { skip: !isPdfType },
  );

  const [
    convertPdfToDocx,
    { isLoading: isConvertingDocument },
  ] = api.endpoints.convertPdfToDocx.useMutation();
  const {
    data: activities = [],
    fulfilledTimeStamp: activitiesFulfilledTimeStamp,
    isSuccess: isActivitiesSuccess,
  } = api.endpoints.getActivities.useQuery({
    entityId: ticketId,
    entityType: EntityType.Ticket,
  });

  useEffect(() => {
    if (isActivitiesSuccess) {
      dispatch(ticketActivitiesSet(activities));
    }
  }, [activitiesFulfilledTimeStamp]);

  const lastSavedActivityLog = useMemo(() => {
    return orderBy(activities, 'modifiedDate', 'desc').find(
      (activity) => activity.action === TicketActivityType.DocumentEdit,
    );
  }, [activities]);

  const {
    comments: ej2Comments,
    documentEditor,
    isInEditMode,
    isDocumentReady: isEj2DocumentReady,
    commentResolver,
    deleteComment,
    getSelectedText,
    insertText,
    navigateToBookmark,
    navigateToComment,
    replaceTextFromDiff,
    replyComment,
    resolveComment,
    toggleTrackChanges,
    updateComment: updateEditorComment,
    unresolveComment,
    setIsLoading: setIsDocumentEditorLoading,
  } = useDocumentEditorContext();

  const debouncedOnContentChanged = useDebounce(
    onDocEditorContentChanged,
    INPUT_DEBOUNCE_MS,
    [documentEditor?.trackChangesPane?.sortedRevisions],
  );

  const [revisions, setRevisions] = useState(getRevisions(documentEditor));

  const {
    data: clausesData = [],
  } = api.endpoints.getDocumentDetailClauses.useQuery(
    { documentTag: versionId },
    {
      skip:
        !activeDocumentVersion.hasAi ||
        isWopitestType ||
        !isBringAiToWorkflowEnabled,
    },
  );

  const { data: comments = [] } = api.endpoints.getComments.useQuery(
    ticketEntity,
    {
      skip: !ticketEntity || !ticketEntity.id,
    },
  );

  const {
    data: turnTrackingHistory,
  } = api.endpoints.getTurnTrackingHistory.useQuery(
    { ticketId },
    { skip: !ticketId || !enableTurnTracking },
  );

  const [
    addParticipantModal,
    openAddParticipantModal,
  ] = useAddTicketParticipantModal({
    ticketId,
  });

  const {
    activeCommentId: pdfHighlighterActiveCommentId,
    activeHighlightId,
    highlights: commentHighlights,
    highlightResolver,
    onUpdateFilters,
    setActiveHighlight,
    setIsDocumentLoading,
    onCreateComment,
  } = useCommentPdfHighlights({
    activeCommentId: ticketCommentId,
    context: [versionEntity],
    entity: ticketEntity,
    highlightEntity: isPdfType ? documentVersionEntity : null,
  });

  const selectedSource = useSelector(selectors.selectCurrentSource);

  const sourceHighlights = useMemo(
    () =>
      selectedSource
        ? [
            {
              id: selectedSource.id,
              type: HighlightType.Source,
              position: createPositionObject(selectedSource.coordinates),
            },
          ]
        : [],
    [selectedSource],
  );

  useEffect(() => {
    if (isPdfType && selectedSource) {
      setActiveHighlight(selectedSource.id);
    } else {
      setActiveHighlight(null);
    }
  }, [selectedSource]);

  const visibleHighlights = useMemo(() => {
    const activeHighlights = pdfHighlights.filter(
      (highlight) => highlight.id === activeHighlightId,
    );

    return [...commentHighlights, ...activeHighlights, ...sourceHighlights];
  }, [activeHighlightId, pdfHighlights, commentHighlights, sourceHighlights]);

  const allHighlights = useMemo(() => {
    return [...commentHighlights, ...pdfHighlights, ...sourceHighlights];
  }, [pdfHighlights, commentHighlights, sourceHighlights]);

  const documentName = useMemo(() => {
    if (ticket && activeDocumentVersion) {
      if (!compareToVersionNumber) {
        return getVersionFileName(ticket.document, activeDocumentVersion);
      } else {
        return `${ticket?.name} - redline changes v${compareToVersionNumber} to v${versionNumber}.docx`;
      }
    }
  }, [ticket, activeDocumentVersion, versionNumber, compareToVersionNumber]);

  useEffect(() => {
    if (
      documentEditor &&
      documentEditor?.trackChangesPane?.sortedRevisions &&
      isEj2DocumentReady
    ) {
      setRevisions(getRevisions(documentEditor));
    }
  }, [
    documentEditor,
    isEj2DocumentReady,
    documentEditor?.trackChangesPane?.sortedRevisions,
  ]);

  useEffect(() => {
    if (turnTrackingHistory) {
      dispatch(actions.setTurnTrackingHistory(turnTrackingHistory));
    }
  }, [turnTrackingHistory]);

  useEffect(() => {
    if (documentData) {
      if (
        ![UploadStatusType.Processing, UploadStatusType.Queued].includes(
          documentData.processingStatus,
        )
      ) {
        setHasAlgorithmsFinished(true);
        setIsDocxHighlightsLoading(false);
      }
    }
  }, [documentData]);

  useEffect(() => {
    if (pdfOcrError) {
      fetchDocumentFiles();
    }
  }, [pdfOcrError]);

  useEffect(() => {
    if (documentError && isPollingDocumentFinished) {
      showToast(
        toastTypes.ERROR,
        'An error occurred when fetching information related to the document. Some functionalities may not work correctly.',
      );
    }
  }, [documentError]);

  useEffect(() => {
    if (isTicketSummaryError) {
      if (ticketSummaryError.response?.status) {
        setIsLoadingTicketSummary(false);
      }
    }
  }, [isTicketSummaryError, ticketSummaryError]);

  useEffect(() => {
    const getPdfDocument = async (blob) => {
      const pdf = await getPdfDocumentFromBlob(blob);
      setPdfFile(pdf);
    };
    if (pdfOcrBlob) {
      getPdfDocument(pdfOcrBlob);
    }
  }, [pdfOcrBlob]);

  useEffect(() => {
    if (clausesData.length > 0) {
      let clauses = [];
      clausesData.forEach((clause) => {
        clauses = clauses.concat(clause.content.map(getClauseHighlight));
      });
      setPdfHighlights(clauses);
    }
  }, [clausesData]);

  useEffect(() => {
    if (activeHighlightId) {
      const highlight = allHighlights.find((h) => h.id === activeHighlightId);

      if (highlight) {
        scrollToHighlight(
          viewer,
          highlight.position,
          ACTIVE_HIGHLIGHT_DISTANCE_TO_TOP_OF_PAGE,
        );
      }
    }
  }, [activeHighlightId]);

  useEffect(() => {
    const activeVersionId = ticket.currentVersionId;
    let isLatestVersion = activeVersionId
      ? activeVersionId === ticket.documentVersions[0]?.id
      : versionNumber === ticket.documentVersions[0]?.versionNumber;

    setIsOnMostRecentVersion(isLatestVersion);
  }, [versionNumber, ticket.documentVersions.length, ticket.currentVersionId]);

  useEffect(() => {
    if (versionId) {
      subscribeAndListenToDocUpdates();
    }

    return () => {
      unsubscribeDocUpdates();
    };
  }, [versionId, shouldUsePusherToReceiveDocUpdates]);

  function unsubscribeDocUpdates() {
    if (shouldUsePusherToReceiveDocUpdates) {
      pusher.unsubscribe(`${versionId}`);
    }
  }

  const isNotInShowAllMarkup = ticket.docMode !== DocModeType.AllMarkup;

  const shouldSaveUpButtonBeDisabled =
    isNotInShowAllMarkup ||
    isCurrentlySaving ||
    isLoadingRedlines ||
    !isEj2DocumentReady;

  function getSaveUpButtonTooltip() {
    if (isNotInShowAllMarkup) {
      return 'Must view as "Show All Markup" to save redline as a new version.';
    } else if (isCurrentlySaving) {
      return 'A new version is being saved.';
    } else if (isLoadingRedlines) {
      return 'Save as a new version is disabled while redlines are loading.';
    }
    return 'Save as new version';
  }

  function getShareIconTooltip() {
    if (currentVersionHasWorkingDraft) {
      return 'Sharing is disabled while there are edits in progress. Please save up as a new version to share.';
    } else if (isComparingDocs) {
      return 'Sharing is disabled for Redline previews. Please save as a new version to share this redline.';
    } else {
      return null;
    }
  }

  const shouldShowDeleteButton =
    !isWopitestType &&
    !isComparingDocs &&
    !isCurrentVersionCounterparty(ticket.documentVersions, versionNumber);
  // don't allow users to delete a version prior to the current one and don't allow them to delete the only version.
  const shouldDeleteButtonBeDisabled =
    !isOnMostRecentVersion || versionNumber === 1 || isCurrentlySaving;

  function getDeleteIconTooltip() {
    if (!isOnMostRecentVersion) {
      return 'Only the latest version can be deleted.';
    } else if (versionNumber === 1) {
      return 'Cannot delete the only version.';
    } else {
      return 'Delete this version';
    }
  }

  useEffect(() => {
    ticketCurrentVersionSet(versionNumber);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * this functions subscribes and listen to Pusher envents
   * when a working_draft is created or deleted.
   */
  function subscribeAndListenToDocUpdates() {
    if (shouldUsePusherToReceiveDocUpdates) {
      const channel = pusher.subscribe(versionId);

      channel.bind(PusherEventType.DocEditingUpdate, (data) => {
        if (data.workingDraftOutdated) {
          showWorkingDraftOutdatedModal();
        } else if (data.workingDraftDeleted) {
          showWorkingDraftDeletedModal();
        }
      });
    }
  }

  useEffect(() => {
    if (isEditMode) {
      ticketIsInEditorModeSet(true);
    }
  }, [isEditMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (compareToVersionNumber) {
      setIsLoadingRedlines(true);
    }
  }, [compareToVersionNumber]);

  function updateActivityLog() {
    isActivityLogPanelEnabled
      ? dispatch(
          api.util.invalidateTags([
            { type: TagType.Ticket, id: 'ACTIVITY_LOG' },
          ]),
        )
      : handleGetActivities();
  }

  const {
    executor: fetchDocumentFiles,
    isLoading: isLoadingFiles,
    error: errorGetTicketDocument,
  } = useAsync(
    getTicketDocumentFiles,
    { documentId, versionId },
    {
      async successHandler(response) {
        if (versionDocType === coerceFileType(FileExtensionType.Pdf)) {
          setPdfFile(await getPdfDocumentFromBlob(response.orig));
        }
      },
      errorToastMessage:
        'An error occurred while fetching file. Please try again.',
    },
  );

  const { executor: handleGetActivities } = useAsync(
    getActivities,
    { entityId: ticketId, entityType: EntityType.Ticket },
    {
      successHandler: ticketActivitiesSet,
    },
  );

  const {
    executor: handleOnDocDownload,
    isLoading: isDownloadingDocument,
  } = useAsync(
    downloadTicketDocument,
    { documentId, versionId },
    {
      successToastMessage: `"${ticket.name}" is now downloading\u2026`,
      successToastType: toastTypes.DOWNLOAD,
      successHandler: downloadTicketDocumentSuccessHandler,
      errorToastMessage:
        'There has been an error with downloading this document. Please try again.',
    },
  );

  function downloadTicketDocumentSuccessHandler(file) {
    const fileName =
      activeDocumentVersion && activeDocumentVersion.name
        ? activeDocumentVersion.name
        : ticket.document.name;
    downloadFile(
      file,
      `${getBasename(fileName)}_${versionNumber}.${
        activeDocumentVersion.fileType
      }`,
    );
    updateActivityLog();
  }

  async function onBack() {
    try {
      if (isInEditMode) {
        await saveDocumentSfdt({ isDoneEditing: true });
      }
    } finally {
      navigate(`/workflow/tickets/${ticketId}`);
    }
  }

  const { name: ticketName, permissionMap, stage } = ticket;
  const permissions = getStagePermissions(permissionMap, stage);
  const isTicketInEditReviewStage = [
    TicketStageType.Edit,
    TicketStageType.Review,
  ].includes(stage);

  const { highlights } = selectedDocumentHighlights;

  const isHideShareButtonEnabled = useFlag(FlagType.HideShareButton);

  const shouldShareButtonBeDisabled =
    isComparingDocs ||
    (isDocumentEditorDocType && !isEj2DocumentReady) ||
    currentVersionHasWorkingDraft ||
    ticket.isInEditorMode ||
    isCurrentlySaving;

  const saveNewVersion = async () => {
    try {
      const documentBlob = await documentEditor.saveAsBlob('Docx');
      setIsCurrentlySaving(true);
      const file = new File([documentBlob], documentName, {
        type:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        lastModified: Date.now(),
      });
      return uploadNewDocumentVersion({
        ticketId,
        documentId,
        file,
        text: '',
        isCounterparty: false,
        isGenerated: true,
        referenceVersionId: baseVersionId,
        targetVersionId: versionId,
      }).then((res) => {
        dispatch(
          // push the new version to redux
          ticketDocumentVersionsSet([
            { ...res, fileType: coerceFileType(res.fileType) },
            ...ticket.documentVersions,
          ]),
        );
        dispatch(api.util.invalidateTags([TAGS.TAG_CURRENT_VERSION]));
        showToast(
          toastTypes.SUCCESS,
          'New version has been saved successfully.',
        );
        setIsCurrentlySaving(false);
        ticketIsInEditorModeSet(false);
        updateActivityLog();
        resetDocumentPolling();
        const newVersionNumber = res.id;
        navigate(`/workflow/tickets/${ticketId}/reviewer/${newVersionNumber}`);
      });
    } catch (error) {
      setIsCurrentlySaving(false);
      showToast(
        toastTypes.ERROR,
        'An error occurred when saving a new version.',
      );
    }
  };

  function addDownloadRedlineActivityLog() {
    const { id, isGenerated } = activeDocumentVersion;
    const referenceVersionId = baseVersionId;
    const targetVersionId = id;
    createActivityLog({
      documentId,
      isGenerated,
      referenceVersionId,
      targetVersionId,
      ticketId,
      type: TicketActivityType.DownloadRedline,
      versionId,
      versionNumber: null,
    });
  }

  function addDownloadDraftActivityLog() {
    const { isGenerated } = activeDocumentVersion;
    createActivityLog({
      documentId,
      isGenerated,
      referenceVersionId: null,
      targetVersionId: null,
      ticketId,
      type: TicketActivityType.DownloadVersion,
      versionId,
      versionNumber,
    });
  }

  function onMentionRequestPermission(mentionedEntity) {
    const { type } = mentionedEntity;
    const entity = mentionedEntity.data[type];
    if (entity && type === EntityType.User && mentionedEntity.isInvalid) {
      openAddParticipantModal({ type: 'user', value: entity });
    } else if (entity && type === EntityType.Department) {
      openAddParticipantModal({ type: 'department', value: entity });
    }
  }

  const handleModeChange = (mode) => {
    ticketDocModeSet(mode);
  };

  const handleCompareVersion = (_hasWorkingDraft, versionUUId) => {
    setIsComparingDocs(true);
    if (ticket.docMode !== DocModeType.AllMarkup) {
      handleModeChange(DocModeType.AllMarkup);
    }
    setBaseVersionId(versionUUId);
  };

  function stopComparing() {
    setIsComparingDocs(false);
    if (ticket.docMode !== DocModeType.AllMarkup) {
      handleModeChange(DocModeType.AllMarkup);
    }
    setBaseVersionId(versionId);
    deletePageSearchParam(QueryParamType.CompareTo);
  }

  function handleSaveVersion() {
    saveNewVersion();
  }

  /**
   * generates the docx directly from the document editor and creates an activity log with the acording type
   * @param {string} type can be 'draft' or 'redlining'
   */
  const downloadEj2Document = (type = 'draft') => {
    if (documentEditor) {
      try {
        const span = createTraceSpan({
          name: `${TraceName.DocumentEditor}.${TraceAction.ConvertToDocx}`,
          additionalAttributes: {
            versionId,
            documentId,
          },
        });
        documentEditor.save(
          removeFileNameExtension(
            documentName,
            activeDocumentVersion?.fileType || 'docx',
          ),
          'Docx',
        );
        span.end();
        type === 'redlining'
          ? addDownloadRedlineActivityLog()
          : addDownloadDraftActivityLog();
        updateActivityLog();
      } catch (error) {
        toast({
          message: 'An error occurred when downloading this document.',
          status: 'danger',
        });
        captureException(
          'An error occurred when downloading this document.',
          error,
          {
            section: 'ej2 document editor',
          },
        );
      }
    }
  };

  async function handleDownloadDocument() {
    if (isDocumentEditorDocType) {
      setIsEj2DownloadingDocument(true);
      downloadEj2Document(isComparingDocs ? 'redlining' : 'draft');
      setIsEj2DownloadingDocument(false);
    } else {
      handleOnDocDownload();
    }
  }

  async function goToNewVersionViewMode() {
    const upToDateVersions = await getTicketDocumentVersions(documentId);
    if (upToDateVersions?.length) {
      ticketIsInEditorModeSet(false);
      const path = `workflow/tickets/${ticketId}/reviewer/${upToDateVersions[0].versionNumber}`;
      redirectToPage(currentUser.client_config.sub_domain, path);
    }
  }

  const [deleteWorkingDraftModal, showDeleteWorkingDraftModal] = useModal({
    actionButton: {
      promise: async () => {
        try {
          unsubscribeDocUpdates();
          await deleteTicketDocumentVersion(ticket.document.id, versionId);
          showToast(toastTypes.SUCCESS, 'This version has been deleted.');
          goToNewVersionViewMode();
        } catch (err) {
          subscribeAndListenToDocUpdates();
          showToast(
            toastTypes.ERROR,
            'Delete this version has failed. Please try again.',
          );
        }
      },
      text: 'Delete Version',
      variant: 'red',
    },
    title: 'Delete Version?',
    content: (
      <Text color="gray-900" variant="s-spaced">
        Are you sure? All edits contained in this version will be lost. Please
        consider downloading a copy before you delete it.
      </Text>
    ),
  });

  const [workingDraftOutdatedModal, showWorkingDraftOutdatedModal] = useModal({
    disableAutoHide: true,
    disableCancelButton: true,
    disableCloseButton: true,
    actionButton: {
      promise: () => {
        goToNewVersionViewMode();
      },
      text: 'View most recent version',
    },
    title: 'This version is no longer editable',
    content: (
      <Text color="gray-900" variant="s-spaced">
        There is a new version.{' '}
        {ticket.isInEditorMode
          ? 'Your edits to this version have been retained.'
          : null}
      </Text>
    ),
  });

  const [workingDraftDeletedModal, showWorkingDraftDeletedModal] = useModal({
    disableAutoHide: true,
    disableCancelButton: true,
    disableCloseButton: true,
    actionButton: {
      promise: async () => {
        try {
          downloadEj2Document();
          showToast(
            toastTypes.SUCCESS,
            'This version has been downloaded successfully.',
          );
          setTimeout(() => {
            goToNewVersionViewMode();
          }, 500);
        } catch {
          showToast(
            toastTypes.ERROR,
            'An error occurred while downloading this version.',
          );
        }
      },
      text: 'Download a Copy',
    },
    secondaryButton: {
      promise: () => {
        goToNewVersionViewMode();
      },
      text: 'Discard Edits',
      variant: 'ghost',
    },
    title: 'This version has been deleted',
    content: (
      <Text color="gray-900" variant="s-spaced">
        This version has been deleted by another user and is no longer
        available. To retain your edits contained in this version, please
        download a copy.
      </Text>
    ),
  });

  // using the same fixedCacheKey, we can reuse the isLoading state from the saveDocumentSfdt endpoint.
  const [
    saveDocument,
    {
      isLoading: isSavingSfdt,
      isError: isSfdtSavingError,
      data: lastSavedVersion,
    },
  ] = api.endpoints.saveDocumentSfdt.useMutation({
    fixedCacheKey: SAVE_DOCUMENT_CACHE_KEY,
  });

  const lastSaved = lastSavedVersion
    ? lastSavedVersion.lastUpdated
    : activeDocumentVersion?.lastUpdated;
  const lastSavedDate = lastSaved ? new Date(lastSaved) : new Date();

  const formattedDate = useIntervalDateText(lastSavedDate);
  const isOnline = useIsOnline();

  const getSavingIndicatorMessage = () => {
    let message = '';
    const isError = isSfdtSavingError || !isOnline;
    if (isSavingSfdt) {
      message = 'Saving...';
    } else if (!isOnline) {
      message = 'You are disconnected. Try connecting again.';
    } else if (isSfdtSavingError) {
      message = 'There was a problem saving. Please check your connection.';
    } else if (
      lastSavedActivityLog?.modifiedDate &&
      lastSavedDate.getTime() ===
        new Date(lastSavedActivityLog.modifiedDate).getTime()
    ) {
      return (
        <Link
          variant="tiny"
          onClick={() => {
            openSidebar();
            setSelectedPanel(PanelType.ActivityLogs);
          }}
        >{`Last edit was ${formattedDate}`}</Link>
      );
    } else {
      message = `Last edit was ${formattedDate}`;
    }

    return (
      <Layout spacing={2}>
        {isSavingSfdt && <LoadingSpinner />}
        <EdsText
          variant="tiny"
          color={isError ? 'status.danger' : 'text.quiet'}
        >
          {message}
        </EdsText>
      </Layout>
    );
  };

  const headerRightContent = (
    <Layout preset="icons" spacing={4}>
      {getSavingIndicatorMessage()}
      {!ticket.isInEditorMode && !isWopitestType && (
        <CompareDocVersionMenu
          selectedDocVersion={compareToVersionNumber}
          onVersionCompare={handleCompareVersion}
          onStopComparing={stopComparing}
        />
      )}

      {isDocumentEditorDocType && isTicketInEditReviewStage && (
        <AccessControl
          requiredPermission={TicketPermissionType.Download}
          userPermissions={permissions}
        >
          <IconButton
            icon="save-as-version"
            tooltip={getSaveUpButtonTooltip()}
            disabled={shouldSaveUpButtonBeDisabled}
            onClick={handleSaveVersion}
          />
        </AccessControl>
      )}
      {isTicketInEditReviewStage && (
        <AccessControl
          requiredPermission={TicketPermissionType.UploadVersion}
          userPermissions={permissions}
        >
          <UploadVersionAction
            disabled={ticket.isInEditorMode || isCurrentlySaving}
            iconOnly={true}
            onUpload={onUploadNewVersion}
          />
        </AccessControl>
      )}
      <AccessControl
        requiredPermission={TicketPermissionType.Download}
        userPermissions={permissions}
      >
        <IconButton
          icon="download"
          tooltip="Download current version"
          disabled={
            isDownloadingDocument ||
            isEj2DownloadingDocument ||
            (isDocumentEditorDocType && !isEj2DocumentReady)
          }
          onClick={handleDownloadDocument}
        />
      </AccessControl>
      {!isHideShareButtonEnabled && !isWopitestType && (
        <AccessControl
          requiredPermission={TicketPermissionType.Share}
          userPermissions={permissions}
        >
          <ShareDocumentButton
            versionId={versionId}
            iconOnly={true}
            isDisabled={shouldShareButtonBeDisabled}
            tooltip={getShareIconTooltip()}
            onShare={updateActivityLog}
          />
        </AccessControl>
      )}

      {shouldShowDeleteButton && (
        <AccessControl
          requiredPermission={TicketPermissionType.Delete}
          userPermissions={permissions}
        >
          <IconButton
            icon="trash"
            tooltip={getDeleteIconTooltip()}
            disabled={shouldDeleteButtonBeDisabled}
            onClick={showDeleteWorkingDraftModal}
          />
        </AccessControl>
      )}
    </Layout>
  );

  function renderSubheader() {
    if (isComparingDocs) {
      return saveComparingDocAsNewVersionNotice;
    } else {
      return nonCurrentDocumentVersionNotice;
    }
  }

  const saveComparingDocAsNewVersionNotice = (
    <Box sx={{ width: '100%' }}>
      <Text
        color="gray-700"
        variant="body1"
        sx={{
          borderRadius: 'm',
          maxWidth: 'document-max-width',
          width: '100%',
        }}
      >
        Previewing redlines.{' '}
        {isTicketInEditReviewStage
          ? 'Save as a new version to preserve any changes.'
          : 'Redlines cannot be saved as a new version when tickets are in the Sign, Finalize, or Completed stage.'}
      </Text>
    </Box>
  );

  const nonCurrentDocumentVersionNotice = !activeDocumentVersion.isLatestVersion && (
    <Text
      color="gray-700"
      variant="xs-dense-medium"
      sx={{
        borderRadius: 'm',
        maxWidth: 'document-max-width',
        width: '100%',
      }}
    >
      You are looking at an older document version (Version {versionNumber})
    </Text>
  );

  const documentViewerHtmlProps = {};
  if (activeDocumentVersion.isLatestVersion) {
    documentViewerHtmlProps.activeElementId = highlights[0]?.id;
    documentViewerHtmlProps.highlights = highlights;
  }

  async function getUpToDateVersions(id = documentId) {
    const upToDateVersions = await getTicketDocumentVersions(id);
    ticketDocumentVersionsSet(upToDateVersions);
    return upToDateVersions;
  }

  function renderDocumentViewer() {
    if (isPdfType) {
      return (
        <PdfViewerHighlighter
          renderHighlight={(highlight) =>
            renderHighlight(highlight, activeHighlightId === highlight?.id, {
              onClick: setActiveHighlight,
            })
          }
          highlights={visibleHighlights}
          errorLoadDocument={pdfOcrError && errorGetTicketDocument}
          OCRStatus={1}
          pdfFile={pdfFile}
          setIsDocumentLoading={setIsDocumentLoading}
          contextMenu={(position, text, hideMenu) => [
            {
              icon: 'message',
              tooltip: 'Add a comment',
              value: ContextMenuValues.Comments,
              hidden: !isPreSigCommentsEnabled,
              component: (
                <CommentDraftWithMentions
                  entity={ticketEntity}
                  onMention={onMentionRequestPermission}
                  isFocused
                  chips={[TICKET_COMMENT_CHIP]}
                  onSubmit={(comment) => {
                    onCreateComment({
                      highlight: { position: position, highlightedText: text },
                      comment,
                    });
                    hideMenu();
                  }}
                />
              ),
            },
          ]}
        />
      );
    }
    return null;
  }

  const handleOpenAutoRedline = () => {
    setGenAiMode('redline');
    setSelectedPanel(PanelType.GenAi);
    openSidebar();
  };

  // onSelectionFinish run inside an eventListener,
  // so it doest access state, we need a ref for that
  const documentMapping = useRef(null);

  // We need to extract the position of each token in the document
  // we'll do it only once due to performance constraints
  useEffect(() => {
    if (documentContent && documentEditor) {
      documentMapping.current = extractPositionFromTokens(
        documentEditor,
        documentContent,
      );
    }
  }, [documentContent, documentEditor]);

  const onSelectionFinish = (startSelection, endSelection) => {
    if (documentEditor && documentMapping.current) {
      const selectedTokens = getTokensFromSelection(
        startSelection,
        endSelection,
        documentMapping.current,
      );

      const matchingClauses = [];
      for (const clause of clausesData) {
        for (const clauseContent of clause.content) {
          const clauseContentTokens = clauseContent.htmlTokens;
          if (testIsTokenMatch(selectedTokens, clauseContentTokens)) {
            const composedClause = getClauseById(clausesData, clauseContent.id);
            composedClause && matchingClauses.push(composedClause);
          }
        }
      }
      // Send all matching clauses and Gen AI will choose the best one.
      setSelectedClausesForGenAi(matchingClauses);
      // Gen AI treats unaccepted tracked changes as accepted,
      // meaning added text is included and deleted text is ignored.
      setSelectedText(getSelectedText('Insertion'));
    }
  };

  const onCommentClick = (editorCommentId) => {
    let comment = comments.find((comment) =>
      comment.context.find((context) => context.id === editorCommentId),
    );
    if (comment) {
      openSidebar();
      setActiveCommentId(comment.id);
      setActiveEditorCommentId(editorCommentId);
    }
  };

  const onCreateEj2Comment = (comment) => {
    setActiveCommentId(comment.id);
    const activeEditorCommentEntity = comment.context.find(
      (context) => context.type === EntityType.EditorHighlight,
    );
    activeEditorCommentEntity &&
      setActiveEditorCommentId(activeEditorCommentEntity.id);
    openSidebar();
  };

  const onCreateCommentError = () => {
    showToast(
      toastTypes.ERROR,
      'There was an error creating the comment. Try again.',
    );
  };

  function onDocEditorContentChanged() {
    /**
     * EJ2 probably stopsPropagation of keyboard events (keyup, keydown), because of that, we need to track keyboard usage by
     * using the onContentChange callback and change the reference of revisions each time to force a rerendering of this componenent.
     */
    setRevisions(getRevisions(documentEditor));
  }

  const deleteCommentHighlight = (comment) => {
    const commentId =
      comment.context[
        comment.context.findIndex(
          (context) => context.type === EntityType.EditorHighlight,
        )
      ]?.['id'];
    if (commentId === activeEditorCommentId) {
      setActiveEditorCommentId(null);
    }
  };

  const onDeleteComment = useCallback(
    (comment) => {
      const deletableComment = comments.find((localComment) =>
        localComment.context.some(
          (context) => context.id === comment.commentId,
        ),
      );
      if (deletableComment) {
        deleteCommentHighlight(deletableComment);
      }
    },
    [comments, activeHighlightId],
  );

  const editorHighlights = useMemo(() => {
    const formattedHighlights = [];

    if (selectedClause?.id) {
      formattedHighlights.push({
        id: selectedClause.id,
        data: {
          type: 'clause',
        },
        htmlTokens: selectedClause.htmlTokens,
      });
    }
    if (activeEditorCommentId) {
      formattedHighlights.push({
        id: activeEditorCommentId,
        data: {
          type: 'comment',
        },
      });
    }
    if (selectedRiskId) {
      formattedHighlights.push({
        id: selectedRiskId,
        data: {
          type: 'risk',
        },
      });
    }
    if (activeRevisionId) {
      formattedHighlights.push({
        id: activeRevisionId,
        data: {
          type: 'revision',
        },
      });
    }
    if (selectedSource?.id) {
      formattedHighlights.push({
        id: selectedSource.id,
        data: {
          type: 'source',
        },
        htmlTokens: selectedSource.htmlTokens,
      });
    }

    return formattedHighlights;
  }, [
    selectedClause,
    activeEditorCommentId,
    activeRevisionId,
    selectedRiskId,
    selectedSource,
  ]);

  const ej2ActiveHighlightId =
    selectedClause?.id ||
    activeEditorCommentId ||
    activeRevisionId ||
    selectedRiskId ||
    selectedSource?.id;

  const contentResolver = () => {
    if (documentContent) {
      return documentContent;
    } else {
      return {
        words: [],
      };
    }
  };

  function renderEditor() {
    const activeVersion = ticket.currentVersionId
      ? ticket.documentVersions.find(
          (version) => version.id === ticket.currentVersionId,
        )
      : activeDocumentVersion;
    return (
      <TicketReviewerDocumentEditor
        clauses={clausesData}
        comparingTargetVersionId={
          compareToVersionNumber
            ? getCompareToVersionId(ticket, compareToVersionNumber)?.id
            : undefined
        }
        contextMenuOptions={[
          {
            icon: 'ai',
            tooltip: 'Automated Redlining',
            text: 'AI Drafting Tools',
            value: ContextMenuValues.GenAi,
            onClick: handleOpenAutoRedline,
            hidden: !enableGenAiM2 || !hasGenAiPermission,
          },
        ]}
        documentId={documentId}
        documentVersionId={documentVersionEntity?.id}
        // with false we don't evaluate if the document is a template
        isGeneratedFromTemplate={
          isBreakIntakeFormLinkEnabled ? !ticket.wasManuallyUpdated : false
        }
        activeHighlightId={ej2ActiveHighlightId}
        isLatestVersion={isOnMostRecentVersion}
        isProcessingClauses={isProcessingClauses}
        isTicketInEditReviewStage={isTicketInEditReviewStage}
        isWopitestType={isWopitestType}
        loadingMessage={loadingMessagePageContent}
        layoutView={ticket.docMode}
        highlights={editorHighlights}
        contentResolver={contentResolver}
        ticketId={ticketId}
        currentVersion={activeVersion}
        onClausesCreated={() => setIsDocxHighlightsLoading(false)}
        onCommentClick={onCommentClick}
        onContentChange={debouncedOnContentChanged}
        onCreateComment={onCreateEj2Comment}
        onCreateCommentError={onCreateCommentError}
        onDocumentLoad={onDocumentLoadCallback}
        onRedlinesLoaded={onRedlinesLoadedCallback}
        onSelectionFinish={onSelectionFinish}
        onDeleteComment={onDeleteComment}
        onMention={onMentionRequestPermission}
        isReadOnly={ticket.isCancelled}
      />
    );
  }

  useEffect(() => {
    if (isEj2DocumentReady && commentId) {
      navigateToComment(commentId);
    }
  }, [isEj2DocumentReady, commentId]);

  function onCopyLink(comment) {
    const documentComment = comment.context.find(
      (context) => context.type === EntityType.EditorHighlight,
    );
    const documentCommentQueryParam = documentComment
      ? `&${QueryParamType.CommentId}=${documentComment.id}`
      : '';
    const linkConstructed = `${window.location.origin}${window.location.pathname}?${QueryParamType.TicketCommentId}=${comment.id}${documentCommentQueryParam}`;
    copyToClipboard(linkConstructed, undefined, undefined, undefined, false);
  }

  const isLoadingTicket = isLoadingDocument || isLoadingTicketSummary;

  const isLoading =
    isLoadingTicket ||
    isLoadingCurrentStage ||
    isLoadingFiles ||
    !baseVersionId ||
    isLoadingPermission ||
    isPDFOCRLoading;
  let docViewer;

  const onDocumentLoadCallback = () => {
    updateRevisions(documentEditor);
    setRevisions(getRevisions(documentEditor));
  };

  function onRedlinesLoadedCallback() {
    setIsLoadingRedlines(false);
  }

  const handlePDFClauseClick = (clause) => {
    setActiveHighlight(
      getClauseHighlightId(clause.id, HighlightType.Provision),
    );
  };

  const handleCommentHighlightClick = (id) => {
    if (!isPdfType) {
      setActiveEditorCommentId(id);
      navigateToComment(id);
      const highlightOwner = comments.find((comment) =>
        comment.context.some((c) => c.id === id),
      );
      highlightOwner && setActiveCommentId(highlightOwner.id);
    }
    setActiveHighlight(id);
  };

  const onClearClauseHighlight = () => {
    if (isPdfType) {
      setActiveHighlight(null);
    } else {
      setSelectedClause(null);
    }
  };

  const onClauseClick = async (clause) => {
    setSelectedRiskId(null);
    if (isPdfType) {
      handlePDFClauseClick(clause);
    } else {
      setSelectedClause(clause);
    }
  };

  const onRiskClick = (riskId) => {
    setSelectedClause(null);
    try {
      setSelectedRiskId(riskId);
      navigateToBookmark(`_risk_${riskId}`);
    } catch (e) {
      captureException(
        `There was an error navigating to risk with id: ${riskId.id}`,
        e,
        {
          section: 'ej2 document editor',
        },
      );
      showToast(toastTypes.ERROR, 'There was an error navigating to this risk');
    }
  };

  const handleInsertClause = (text) => {
    toggleTrackChanges(true);
    // this is necessary to wait for track change to propagate
    setTimeout(() => {
      insertText(text);
    }, 100);
  };

  const handleAcceptEdit = (text) => {
    toggleTrackChanges(true);
    // this is necessary to wait for track change to propagate
    setIsDocumentEditorLoading(true);
    setTimeout(() => {
      replaceTextFromDiff(text, { shouldAcceptRevisions: true });
      setIsDocumentEditorLoading(false);
    }, 100);
  };

  const onDocumentViewerExpandClick = () => {
    toggleSidebarExpand();
    toggleContentEnlarged();
  };

  if (!isLoading) {
    if (isDocumentEditorDocType || isWopitestType) {
      docViewer = renderEditor();
    } else {
      docViewer = renderDocumentViewer();
    }
  }

  const renderErrorMessage = () => {
    const { preset, title, description } =
      ERROR_MESSAGES[ticketSummaryError?.response?.status] ??
      ERROR_MESSAGES['default'];

    return (
      <ErrorPage
        preset={preset}
        title={title}
        description={description}
        resolveActionText="Take me to Tickets"
        resolveAction={{
          onClick: () => navigate('/workflow/tickets'),
        }}
      />
    );
  };

  const pageContent = (
    <Layout direction="column" h="100%">
      {!isLoading && (
        <Layout
          bg="background.secondary"
          justify="end"
          mb={isPdfType ? 4 : 0}
          px={6}
          py={3}
          spacing={4}
        >
          {
            <>
              {renderSubheader()}
              {!isInEditMode && (
                <DocModeMenu
                  disabled={!isEj2DocumentReady || isComparingDocs}
                  docMode={ticket.docMode}
                  onMenuItemClick={handleModeChange}
                />
              )}
              {isInEditMode && (
                <FlexLayout>
                  <Tooltip content='To select "Show Original" or "Show Final", switch to View Mode.'>
                    <Text
                      color="gray-600"
                      variant="body2"
                      sx={{ marginTop: '3px' }}
                    >
                      Show All Markup
                    </Text>
                    <Icon ml={3} color="gray-600" icon="chevronDown" size="m" />
                  </Tooltip>
                </FlexLayout>
              )}
            </>
          }

          <IconButton
            icon="maximize"
            size="s"
            status={isContentEnlarged ? 'active' : undefined}
            tooltip={isContentEnlarged ? 'Condense' : 'Enlarge'}
            onClick={onDocumentViewerExpandClick}
          />
        </Layout>
      )}
      {/* the activeDocumentVersion.id will be undefined if activeDocumentVersion is a deleted version */}
      {!isLoadingDocumentVersions &&
      ticket.documentVersions.length > 0 &&
      !activeDocumentVersion.id ? (
        <EmptyPage preset="no-version-match" />
      ) : isTicketSummaryError ? (
        renderErrorMessage()
      ) : (
        <ContentContainer
          loadingContent={{ isLoading, description: loadingMessagePageContent }}
        >
          {docViewer}
        </ContentContainer>
      )}
      {deleteWorkingDraftModal}
      {workingDraftOutdatedModal}
      {workingDraftDeletedModal}
      {addParticipantModal}
    </Layout>
  );

  const onPanelClick = (_action) => {
    if (isSidebarExpanded) {
      condensePanel();
      closeSidebar();
      enlargeContent();
    } else {
      setSelectedPanel(PanelType.DocumentDetails);
      openSidebar();
      condenseContent();
    }
  };

  const isChatBotPanelVisible = useSelector(selectors.selectIsChatbotVisible);

  useEffect(() => {
    if (documentData && documentData.currentVersion) {
      const { currentVersion, id } = documentData;
      // format the name to be "(v1) My document"
      const parsedFileName = `(v${
        activeDocumentVersion?.versionNumber
      }) ${removeFileNameExtension(
        currentVersion.fileName,
        activeDocumentVersion?.fileType,
      )}`;
      const isEdited = ticket.activities?.some(
        (activity) =>
          activity.action === TicketActivityType.DocumentEdit &&
          activity.data?.version?.id === versionId,
      );
      dispatch(
        actions.setCurrentDocument({
          id,
          versionId: currentVersion.id,
          ticketVersionId: versionId,
          name: parsedFileName,
          type: currentVersion.fileType,
          folder: '',
          isLatestVersion: isOnMostRecentVersion,
          isEdited,
        }),
      );
    }
    return () => {
      dispatch(actions.setCurrentDocument(null));
    };
  }, [documentData, versionId, isOnMostRecentVersion, ticket.activities]);

  useEffect(() => {
    if (isChatBotPanelVisible) {
      openSidebar();
      condenseContent();
    } else {
      closeSidebar();
      enlargeContent();
    }
  }, [isChatBotPanelVisible]);

  const openChat = () => {
    const docVersion = documentData?.currentVersion?.id;
    if (docVersion) {
      dispatch(chatbotSlice.actions.toggleChatbot());
    }
  };

  const onCommentAction = (comment, action) => {
    const editorCommentEntity = comment.context.find(
      (context) => context.type === EntityType.EditorHighlight,
    );

    switch (action) {
      case 'delete':
        if (editorCommentEntity) {
          deleteComment(editorCommentEntity.id);
        }
        break;
      case 'resolve':
        if (editorCommentEntity) {
          resolveComment(editorCommentEntity.id);
        }
        break;
      case 'unresolve':
        if (editorCommentEntity) {
          unresolveComment(editorCommentEntity.id);
        }
        break;
      case 'update':
        if (editorCommentEntity) {
          const newText = serializeContentAst(comment.content);
          if (comment.threadId) {
            const editorComment = getComment(
              editorCommentEntity.id,
              documentEditor,
            );
            const newText = serializeContentAst(comment.content);
            updateEditorComment(editorComment.commentId, newText);
          } else {
            updateEditorComment(editorCommentEntity.commentId, newText);
          }
        }
        break;
      case 'beforeCreate':
        // if it's a reply, we need to add the reply comment id as context before adding it to weaver
        if (comment.threadId) {
          const parentComment = comments.find(
            (localComment) => localComment.id === comment.threadId,
          );
          const parentCommentEntity = parentComment.context.find(
            (context) => context.type === EntityType.EditorHighlight,
          );
          if (parentCommentEntity) {
            const newText = serializeContentAst(comment.content);
            replyComment(parentCommentEntity.id, newText);
            // the new reply is always the last
            const reply = getComment(
              parentCommentEntity.id,
              documentEditor,
            ).replyComments.at(-1);
            if (reply) {
              comment.context.push({
                type: EntityType.EditorHighlight,
                id: reply.commentId,
              });
            }
          }
        }
        break;
      default:
      //do nothing
    }
  };

  const onFilterCommentAction = (action, comment) => {
    // disable actions for embedded comments
    const isEmbeddedComment = comment.context.some(
      (context) => context.type === EntityType.EditorHighlight,
    );
    if (isInEditMode) return action;
    if (isEmbeddedComment && action.value !== 'copyLink') {
      return {
        ...action,
        disabled: true,
        tooltip: 'Enable edit mode to perform this action.',
      };
    }
    return action;
  };

  /**
   * filter out comments that are not from the current version and
   * embedded comments that for some reason are not in the document anymore
   * (e.g deleted by the user)
   */
  const onFilterComments = (comment) => {
    const isFromCurrentVersion = filterHighlightsOfDifferentVersion(
      comment,
      versionEntity,
    );
    if (isFromCurrentVersion) {
      if (!isPdfType) {
        const context = comment.context.find(
          (context) => context.type === EntityType.EditorHighlight,
        );
        if (context) {
          // we search the root comments and its replies
          return ej2Comments.some(
            (ej2Comment) =>
              ej2Comment.commentId === context.id ||
              ej2Comment.replyComments.some(
                (reply) => reply.commentId === context.id,
              ),
          );
        }
      }
    }
    return isFromCurrentVersion;
  };

  function isCommentEmbeddedResolver(comment) {
    if (isPdfType) return false;
    return comment.context.some(
      (context) => context.type === EntityType.EditorHighlight,
    );
  }

  const externalCommentAuthorNameResolver = (comment) => {
    const editorCommentEntity = comment.context.find(
      (context) => context.type === EntityType.EditorHighlight,
    );
    if (editorCommentEntity && isEj2DocumentReady) {
      const editorComment = getComment(editorCommentEntity.id, documentEditor);
      return editorComment?.author || '';
    }
    return '';
  };

  const onOrderComments = (comments, filters) => {
    const entityType = isPdfType
      ? EntityType.Highlight
      : EntityType.EditorHighlight;
    const editorComments =
      filters.order === 'documentPosition'
        ? comments.filter((comment) =>
            comment.context.some((context) => context.type === entityType),
          )
        : [...comments];

    if (filters.order === 'documentPosition') {
      editorComments.sort((a, b) => {
        const aEntity = a.context.find(
          (context) => context.type === entityType,
        );
        const bEntity = b.context.find(
          (context) => context.type === entityType,
        );
        if (isPdfType) {
          const aHighlight = commentHighlights.find(
            (highlight) => highlight.id === aEntity.id,
          );
          const bHighlight = commentHighlights.find(
            (highlight) => highlight.id === bEntity.id,
          );
          // if any of the highlgihts are null we can't compare, so we return -1 to avoid errors
          if (!aHighlight || !bHighlight) return -1;

          if (aHighlight.position.pageNumber !== bHighlight.position.pageNumber)
            return (
              aHighlight.position.pageNumber - bHighlight.position.pageNumber
            );

          const aEntityPosition = aHighlight.position.boundingRect.y1;
          const bEntityPosition = bHighlight.position.boundingRect.y1;

          return aEntityPosition - bEntityPosition;
        } else {
          // if ej2 is not ready we can't read the comments, so  we return -1 to avoid errors
          if (!isEj2DocumentReady) return -1;
          const aEntityPosition = ej2Comments.findIndex(
            (comment) => comment.commentId === aEntity.id,
          );
          const bEntityPosition = ej2Comments.findIndex(
            (comment) => comment.commentId === bEntity.id,
          );
          return aEntityPosition - bEntityPosition;
        }
      });
      return editorComments;
    } else {
      return orderBy(editorComments, ['createdDate'], ['asc']);
    }
  };

  const activityLogCustomActions = enableIntakeForm
    ? { intakeFormAction: () => setSelectedPanel(PanelType.IntakeForm) }
    : {};

  useEffect(() => {
    if (pdfHighlighterActiveCommentId) {
      openSidebar();
      setSelectedPanel(PanelType.Comments);
    }
  }, [pdfHighlighterActiveCommentId]);

  async function saveDocumentSfdt(
    { isDoneEditing } = { isDoneEditing: false },
  ) {
    const documentBlob = await documentEditor.saveAsBlob('Sfdt');
    const documentFile = new File([documentBlob], 'document.json', {
      type: 'application/json',
    });
    await saveDocument({
      document: documentFile,
      versionId: versionId,
      documentId: documentId,
      isDoneEditing,
    });
  }

  const [commentChips, setCommentChips] = useState([TICKET_COMMENT_CHIP]);
  const onCommentViewChange = (comment) => {
    if (comment?.isCommentEmbedded) {
      setCommentChips([DOCUMENT_COMMENT_CHIP]);
    } else {
      setCommentChips([TICKET_COMMENT_CHIP]);
    }
  };

  const handleRevisionClick = (revisionId) => {
    setActiveRevisionId(revisionId);
  };

  const onRevisionAction = (action) => {
    if (action.id === activeRevisionId) {
      setActiveRevisionId(null);
    }
  };

  const getPanels = () => {
    const defaultActions = [
      {
        icon: 'maximize',
        isActive: isPanelEnlarged,
        label: isPanelEnlarged ? 'Condense' : 'Enlarge',
        value: isPanelEnlarged ? 'Condense' : 'Enlarge',
        onClick: togglePanelEnlarged,
      },
    ];

    const resolver = !isPdfType ? commentResolver : highlightResolver;
    const activeHighlight = isPdfType
      ? getClauseIdFromHighlight(activeHighlightId)
      : selectedClause?.id;
    const activeComment = !isPdfType
      ? activeCommentId
      : pdfHighlighterActiveCommentId;
    const commentAuthorNameResolver = !isPdfType
      ? externalCommentAuthorNameResolver
      : undefined;

    return {
      ...(isAskAnythingPreSigEnabled
        ? {
            [PanelType.AskAI]: {
              action: {
                disabled: !documentData,
                ...panels[PanelType.AskAI],
              },
              component: null,
            },
          }
        : {}),
      comments: {
        action: {
          ...panels[PanelType.Comments],
          onClick: onPanelClick,
        },
        component: (
          <Comments
            actions={defaultActions}
            activeCommentId={activeComment}
            context={[versionEntity]}
            enabledFilters={{
              source: true,
              order: true,
            }}
            entity={ticketEntity}
            highlightResolver={resolver}
            commentAuthorNameResolver={commentAuthorNameResolver}
            isCommentEmbeddedResolver={isCommentEmbeddedResolver}
            isCommentsEnlarged={isPanelEnlarged}
            isExpanded={Boolean(activeCommentId)}
            isInEditMode={isInEditMode}
            textEditorChips={commentChips}
            onMention={onMentionRequestPermission}
            width={panelWidth}
            onCopyLink={onCopyLink}
            onHighlightClick={handleCommentHighlightClick}
            onUpdateFilters={onUpdateFilters}
            onUpdate={onCommentAction}
            onFilterAction={onFilterCommentAction}
            filterComment={onFilterComments}
            orderComments={onOrderComments}
            onCommentViewChange={onCommentViewChange}
          />
        ),
      },
      ...(isBringAiToWorkflowEnabled
        ? {
            [PanelType.DocumentDetails]: {
              action: {
                ...panels[PanelType.DocumentDetails],
                onClick: onPanelClick,
              },
              component: (
                <DocumentDetails
                  versionHasAi={activeDocumentVersion.hasAi}
                  actions={defaultActions}
                  activeClauseId={activeHighlight}
                  id={baseVersionId}
                  isClausesPanelEnabled={!compareToVersionNumber}
                  isLoadingHighlights={
                    isPdfType
                      ? arePDFProvisionsLoading
                      : isDocxHighlightsLoading
                  }
                  onClauseClick={onClauseClick}
                  onClearHighlight={onClearClauseHighlight}
                  onRiskClick={onRiskClick}
                  title={documentName}
                  width={panelWidth}
                  version={`v${versionNumber}`}
                  // only poll the claues if we have the html tokens available
                  isReadyToPoll={
                    isPdfType ||
                    (documentContent && documentContent?.words?.length > 0)
                  }
                />
              ),
            },
          }
        : {}),
      ...(enableIntakeForm
        ? {
            [PanelType.IntakeForm]: {
              action: {
                ...panels[PanelType.IntakeForm],
                onClick: onPanelClick,
              },
              component: (
                <TicketIntakeForm
                  actions={defaultActions}
                  ticketId={ticketId}
                  title="Form Information"
                  width={panelWidth}
                />
              ),
            },
          }
        : {}),
      ...(isActivityLogPanelEnabled
        ? {
            [PanelType.ActivityLogs]: {
              action: {
                ...panels[PanelType.ActivityLogs],
                onClick: onPanelClick,
              },
              component: (
                <ActivityLogPanel
                  actions={defaultActions}
                  width={panelWidth}
                  ticketId={ticketId}
                  versions={ticket.documentVersions}
                  customActions={activityLogCustomActions}
                />
              ),
            },
          }
        : {}),
      ...(enableGenAiM2 && hasGenAiPermission
        ? {
            [PanelType.GenAi]: {
              action: {
                ...panels[PanelType.GenAi],
                onClick: onPanelClick,
              },
              component: (
                <GenAiPanel
                  actions={defaultActions}
                  mode={genAiMode}
                  selectedClauses={selectedClausesForGenAi}
                  selectedText={selectedText}
                  onAccept={
                    genAiMode === 'redline'
                      ? handleAcceptEdit
                      : handleInsertClause
                  }
                  disableAccept={!isInEditMode}
                  onUpdateMode={setGenAiMode}
                />
              ),
            },
          }
        : (enableGenAi || enableGenAiRedline) &&
          hasGenAiPermission && {
            [PanelType.GenAi]: {
              action: {
                ...panels[PanelType.GenAi],
                onClick: onPanelClick,
              },
              component: (
                <GenAiMvpPanel
                  actions={defaultActions}
                  width={panelWidth}
                  onHide={closeSidebar}
                />
              ),
            },
          }),
      ...(isClauseLibraryInDocumentEditorEnabled && {
        [PanelType.ClauseLibrary]: {
          action: {
            ...panels[PanelType.ClauseLibrary],
            onClick: onPanelClick,
          },
          component: (
            <ClauseLibraryPanel
              actions={defaultActions}
              isInsertClauseEnabled={isInEditMode}
              onInsertClause={
                isDocumentEditorDocType
                  ? (text) => {
                      insertText(text);
                    }
                  : undefined
              }
            />
          ),
        },
      }),
      ...(isDocumentEditorDocType
        ? {
            [PanelType.TrackChanges]: {
              action: {
                ...panels[PanelType.TrackChanges],
                onClick: onPanelClick,
              },
              component: isEj2DocumentReady ? (
                <RevisionsPanel
                  actions={defaultActions}
                  width={panelWidth}
                  revisions={revisions}
                  disabled={!isInEditMode}
                  documentEditor={documentEditor}
                  onRevisionClick={handleRevisionClick}
                  onAction={onRevisionAction}
                />
              ) : null,
            },
          }
        : {}),
    };
  };

  const handleConvertDocument = async () => {
    try {
      const newVersion = await convertPdfToDocx({
        documentId,
        versionId,
      }).unwrap();
      await getUpToDateVersions();
      const newVersionNumber = newVersion.versionNumber;
      navigate(`/workflow/tickets/${ticketId}/reviewer/${newVersionNumber}`);
    } catch (e) {
      captureException(
        `An error occurred when converting the document ${documentId} with version ${versionId}.`,
        e,
        {
          section: 'pdf to docx conversion',
        },
      );
      showToast(
        toastTypes.ERROR,
        'An error occurred when converting the document.',
      );
    }
  };

  useEffect(() => {
    if (activeCommentId || ticketCommentId || !isBringAiToWorkflowEnabled) {
      setSelectedPanel(PanelType.Comments);
    } else {
      setSelectedPanel(PanelType.DocumentDetails);
    }
  }, [activeCommentId, ticketCommentId, isBringAiToWorkflowEnabled]);

  const clearSelections = (selectedPanel) => {
    if (selectedPanel !== PanelType.Comments) {
      setActiveHighlight(null);
      setActiveCommentId(null);
      setActiveEditorCommentId(null);
    }

    if (selectedPanel !== PanelType.DocumentDetails) {
      setSelectedClause(null);
      setSelectedRiskId(null);
    }

    if (selectedPanel !== PanelType.TrackChanges) {
      setActiveRevisionId(null);
    }
  };

  const clearAllSelections = () => {
    setActiveHighlight(null);
    setActiveCommentId(null);
    setActiveEditorCommentId(null);
    setSelectedClause(null);
    setSelectedRiskId(null);
    setActiveRevisionId(null);
  };

  useEffect(() => {
    if (loadingMessagePageContent === LOADING_MESSAGES.convertingDocument) {
      const updateLoadingMessage = () => {
        setLoadingMessagePageContent(LOADING_MESSAGES.extractingComments);
      };
      setTimeout(updateLoadingMessage, 3000);
    }
  }, []);

  return (
    <PageLayout
      bg="background.quiet"
      header={
        <PageLayout.Header
          leftContent={
            <FlexLayout alignItems="center">
              {ticket.isInEditorMode ? (
                <Text color="gray-900" variant="body1">
                  Version {versionNumber}
                </Text>
              ) : (
                <CurrentDocVersionMenu />
              )}
              {isConvertPdfToDocxEnabled && isPdfType && (
                <Button
                  isLoading={isConvertingDocument}
                  text="Convert to Docx"
                  variant="action"
                  onClick={handleConvertDocument}
                />
              )}
            </FlexLayout>
          }
          rightContent={headerRightContent}
          title={{ text: ticketName, maxWidth: getHeaderTitleWidth() }}
          onClose={onBack}
        />
      }
      isContentFill
      isPadded={false}
    >
      <ContentContainer
        loadingContent={{
          isLoading: isCurrentlySaving,
          description: loadingMessageOnSave,
        }}
      >
        <AppLayout
          sidebar={
            !isLoading &&
            hasPreSigCommentsFlagEnabled && (
              <Box sx={{ minWidth: 48 }}>
                <PageSidebar
                  isExpanded={isSidebarExpanded}
                  panels={getPanels()}
                  selectedPanel={selectedPanel}
                  onUpdate={(selectedPanel) => {
                    if (selectedPanel === PanelType.GenAi)
                      setGenAiMode('clause');
                    clearSelections(selectedPanel);
                    setSelectedPanel(selectedPanel);
                    // set revisions before opening the panel
                    if (selectedPanel === PanelType.TrackChanges)
                      setRevisions(getRevisions(documentEditor));
                    if (selectedPanel === PanelType.AskAI) {
                      openChat();
                    }
                  }}
                  onClose={clearAllSelections}
                  width={panelWidth}
                />
              </Box>
            )
          }
        >
          {pageContent}
        </AppLayout>
      </ContentContainer>
    </PageLayout>
  );
}

const mapStateToProps = ({ currentUser, ticket, ticketReviewer }) => ({
  currentUser,
  hasTemporaryViewAnyPermission: getHasTemporaryViewAnyPermission(ticket),
  selectedDocumentHighlights: ticketReviewer.selectedDocumentHighlights,
  ticket,
});

export default connect(mapStateToProps, {
  ticketActivitiesSet,
  ticketDocModeSet,
  ticketCurrentVersionSet,
  ticketCurrentVersionIdSet,
  ticketIsInEditorModeSet,
  ticketDocumentVersionsSet,
  ticketParticipantsSet,
})(withUsers(TicketReviewerPage));
