import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import ActionsMenu from '~/components/Shared/ActionsMenu';
import { formatDate, Markdown, Modal } from '~/eds';

import { MODAL_DELETE, MODAL_NAME } from '../../../types/modal.types';
import EcCard from '../../Shared/EcCard';
import EcModal from '../../Shared/EcModal';
import EcPaginate from '../../Shared/EcPaginate';
import EcTooltip from '../../Shared/EcTooltip';
import EditIcon from '../../Shared/Icons/EditIcon';
import RerunIcon from '../../Shared/Icons/RerunIcon';
import SearchQueryEntry from '../SearchQueryEntry';
import styles from './AnalyzersQueryTable.styles';

let SEARCH_ITEM_INDEX = 0;
const PAGE_SIZE = 10;

class AnalyzersQueryTable extends Component {
  constructor(props) {
    super(props);

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleDeleteSavedSearch = this.handleDeleteSavedSearch.bind(this);
    this.handleRenameSavedSearch = this.handleRenameSavedSearch.bind(this);

    this.state = {
      currentModal: null,
      page: 1,
      listItems: this.props.listItems,
      count: this.props.count,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.listItems !== prevState.listItems) {
      return { listItems: nextProps.listItems, count: nextProps.count };
    } else {
      return null;
    }
  }

  handlePageClick(clickedPage) {
    const { onPageChange } = this.props;
    const selectedPage = clickedPage.selected + 1;
    this.setState({ page: selectedPage }, () => onPageChange(selectedPage));
  }

  handleShowModal(modal, savedSearch) {
    this.setState({ currentModal: { modal: modal, savedSearch } });
  }

  handleHideModal() {
    this.setState({ currentModal: null });
  }

  handleDeleteSavedSearch() {
    const { currentModal } = this.state;
    this.props.deleteQuery(currentModal.savedSearch);
    this.setState({ page: 1 });
  }

  handleRenameSavedSearch(itemName) {
    const { currentModal } = this.state;
    this.props.renameQuery(currentModal.savedSearch, itemName);
  }

  renderDeleteModal(item) {
    const modalText = `Are you sure you want to delete "${item.name}" query? You won’t be able to undo this action.`;

    return (
      <Modal
        isVisible
        children={<Markdown text={modalText} />}
        title="Delete Saved Query?"
        primaryAction={{
          text: 'Delete',
          variant: 'danger',
          onClick: () => {
            this.handleDeleteSavedSearch();
            this.handleHideModal();
          },
        }}
        onHide={this.handleHideModal}
        onCancel={this.handleHideModal}
      />
    );
  }

  renderNameModal(search) {
    return (
      <EcModal
        modalType={MODAL_NAME}
        width="560px"
        title="Rename Saved Search"
        labelText="SAVED SEARCH NAME"
        itemsCurrentName={search.name}
        confirmButtonText="Save"
        handleNameChange={this.handleRenameSavedSearch}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderTextSearchEntity(search) {
    const { classes } = this.props;
    const entityIndex = SEARCH_ITEM_INDEX++;

    return (
      <Fragment key={`text-search-entity-${entityIndex}`}>
        <span
          className={classes.recentSearchFilter}
          data-tip
          data-for={`text-search-entity-${entityIndex}`}
        >
          Text Search
        </span>
        <EcTooltip
          id={`text-search-entity-${entityIndex}`}
          place="top"
          width="280px"
        >
          <div className={classes.tooltip}>
            <span>{search.area.label}</span>
            &nbsp;
            {search.contains.label}
            &nbsp;
            {search.scope.label}
            &nbsp; &mdash; &nbsp;
            {`“${search.text}”.`}
          </div>
        </EcTooltip>
      </Fragment>
    );
  }

  renderDateAddedCell = (item) => {
    return formatDate(new Date(item.date_added), 'full');
  };

  getMenuItems = (search) => {
    const items = [];

    items.push(
      {
        content: 'Rename',
        onClick: () => this.handleShowModal(MODAL_NAME, search),
      },
      {
        content: 'Delete',
        onClick: () => this.handleShowModal(MODAL_DELETE, search),
      },
    );

    return items;
  };

  renderEmptyState() {
    const { classes, savedSearches } = this.props;

    return savedSearches ? (
      <div className={classes.emptyState}>
        <div className={classes.emptyStateTitle}>No saved searches.</div>
        <div className={classes.emptyStateText}>
          Run your favorite searches and save them so you can come back to them
          later.
        </div>
      </div>
    ) : (
      <div className={classes.emptyState}>
        <div className={classes.emptyStateTitle}>No recent searches.</div>
        <div className={classes.emptyStateText}>
          Every time you run an Analyzer search, we remember your filters and
          you can run them again.
        </div>
      </div>
    );
  }

  renderPagination() {
    const { classes } = this.props;
    const { page, count } = this.state;

    if (count <= PAGE_SIZE) return null;

    return (
      <div className={classes.pagination}>
        <EcPaginate
          onPageChange={this.handlePageClick}
          pageCount={Math.ceil(count / PAGE_SIZE)}
          forcePage={page - 1}
        />
      </div>
    );
  }

  renderItemsTable() {
    const {
      applyFilters,
      classes,
      dateColumnHeader,
      disableApplyBtn,
      nameColumnHeader,
      savedSearches,
      searchFilters,
    } = this.props;
    const { listItems } = this.state;

    if (listItems.length) {
      return (
        <div className={classes.searchTable}>
          <table>
            <thead>
              <tr>
                <th className={classes.headerName}>{nameColumnHeader}</th>
                {savedSearches ? (
                  <>
                    <th className={classes.headerCreatedBy}>CREATED BY</th>
                    <th className={classes.headerDateCreated}>DATE CREATED</th>
                  </>
                ) : (
                  <th className={classes.headerCreatedBy}>
                    {dateColumnHeader}
                  </th>
                )}
                <th className={classes.headerApplyFilters} />
                <th className={classes.headerSearchFilters} />
                {nameColumnHeader === 'NAME' && (
                  <th className={classes.headerActions} />
                )}
              </tr>
            </thead>
            <tbody>
              {listItems.map((search) => {
                const searchAddedDate = formatDate(
                  search.date_added ? new Date(search.date_added) : null,
                  'full',
                );

                const rowCells = [];

                if (savedSearches) {
                  rowCells.push(
                    <td
                      className={classes.cellName}
                      key={`nameCell-${search.id}`}
                    >
                      {search.name}
                    </td>,
                    <td
                      className={classes.cellCreatedBy}
                      title={search.user_name}
                      key={`usernameCell-${search.id}`}
                    >
                      {search.user_name}
                    </td>,
                  );
                } else {
                  search.query?.length
                    ? rowCells.push(
                        <td
                          className={classes.cellName}
                          key={`nameCell-${search.id}`}
                        >
                          <SearchQueryEntry query={search.query} />
                        </td>,
                      )
                    : rowCells.push(
                        <td key={`nameCell-${search.id}`}>
                          No filters have been applied.
                        </td>,
                      );
                }

                rowCells.push(
                  <td
                    className={classes.cellDate}
                    key={`dateCell-${search.id}`}
                  >
                    {searchAddedDate}
                  </td>,
                  <td key={`applyCell-${search.id}`}>
                    <button
                      className={classes.tableAction}
                      disabled={disableApplyBtn}
                      onClick={() => applyFilters(search.query)}
                    >
                      <EditIcon blue />
                      <span id={`analyzer-apply-filters-${search.id}-link`}>
                        Apply Filters
                      </span>
                    </button>
                  </td>,
                  <td key={`searchCell-${search.id}`}>
                    <button
                      className={classes.tableAction}
                      onClick={() => searchFilters(search)}
                    >
                      <RerunIcon blue size="20" />
                      <span id={`analyzer-search-${search.id}-link`}>
                        Search
                      </span>
                    </button>
                  </td>,
                );

                if (savedSearches) {
                  rowCells.push(
                    <td key={`actionsCell-${search.id}`}>
                      <ActionsMenu
                        id={`saved_search_${search.id}_menu`}
                        items={this.getMenuItems(search)}
                        align="end"
                        title="edit saved search menu"
                      />
                    </td>,
                  );
                }

                return <tr key={`recent-search-${search.id}`}>{rowCells}</tr>;
              })}
            </tbody>
          </table>
          {savedSearches && this.renderPagination()}
        </div>
      );
    } else {
      return this.renderEmptyState();
    }
  }

  render() {
    const { classes, sectionTitle, sectionSubtitle, sectionIcon } = this.props;
    const { currentModal } = this.state;

    return (
      <div
        id={`analyzer-searches-${sectionTitle}`}
        className={classes.searchesSection}
      >
        <div className={classes.sectionHeader}>
          {sectionIcon}
          <div>
            <div className={classes.sectionTitle}>{sectionTitle}</div>
            <div className={classes.sectionSubtitle}>{sectionSubtitle}</div>
          </div>
        </div>
        <EcCard>{this.renderItemsTable()}</EcCard>
        {currentModal && currentModal.modal === MODAL_DELETE
          ? this.renderDeleteModal(currentModal.savedSearch)
          : null}
        {currentModal && currentModal.modal === MODAL_NAME
          ? this.renderNameModal(currentModal.savedSearch)
          : null}
      </div>
    );
  }
}

AnalyzersQueryTable.propTypes = {
  listItems: PropTypes.array,
  sectionTitle: PropTypes.string.isRequired,
  sectionSubtitle: PropTypes.string.isRequired,
  sectionIcon: PropTypes.object.isRequired,
  nameColumnHeader: PropTypes.string.isRequired,
  dateColumnHeader: PropTypes.string.isRequired,
  applyFilters: PropTypes.func.isRequired,
  searchFilters: PropTypes.func.isRequired,
  savedSearches: PropTypes.bool,
  renameQuery: PropTypes.func,
  deleteQuery: PropTypes.func,
};

export default injectSheet(styles)(AnalyzersQueryTable);
