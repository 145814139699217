import React, { useState } from 'react';

import { Panel, pluralize, useToast } from '~/eds';
import { DeleteAction as DeleteActionComponent } from '~/features/document-bulk-actions';
import { DeleteDataType } from '~/features/document-bulk-actions/DeleteAction';
import { api } from '~/redux';

import { BaseBulkActionProps } from '../types';

interface Props extends BaseBulkActionProps {
  onHide: () => void;
  selectedCount: number;
}

export const DeleteActionPanel = ({
  isAllSelected = false,
  onHide,
  searchAPI,
  selectedCount,
  selectedDocumentIds = [],
  query = {},
  onActionCompleted,
}: Props) => {
  const [{ comment, reason }, setDeleteData] = useState<DeleteDataType>({
    reason: null,
    comment: '',
  });
  const { toast } = useToast();
  const [
    bulkDelete,
    { isLoading },
  ] = api.endpoints.searchV2BulkDelete.useMutation();

  const onBulkDelete = async () => {
    try {
      const response = await bulkDelete({
        documentHandlerIds: selectedDocumentIds,
        searchAPI,
        searchQuery: query,
        isAllSelected,
        reason: reason ?? '',
        extraDetails: comment ?? '',
      }).unwrap();
      toast({
        status: 'success',
        message:
          response?.detail ?? 'Documents have been successfully deleted.',
      });
      onActionCompleted?.();
    } catch {
      toast({
        status: 'danger',
        message: 'An error occurred while deleting the documents.',
      });
    }
  };

  // 1. must choose a reason, 2. if reason is 'other', must provide details
  const deleteDisabled = !reason || (reason === 'other' && !comment.trim());

  const footer = {
    actions: [
      {
        text: 'Delete',
        level: 'danger' as const,
        onClick: onBulkDelete,
        disabled: deleteDisabled,
        isLoading,
      },
    ],
  };

  return (
    <Panel
      title={`Delete ${pluralize(selectedCount, 'Document')}`}
      enableBackdrop
      footer={footer}
      width="m"
      hidden={{ isHidden: false, onHide }}
      position="right"
    >
      <DeleteActionComponent
        comment={comment}
        reason={reason}
        onChange={setDeleteData}
        selectedCount={selectedCount}
        type="document"
      />
    </Panel>
  );
};
