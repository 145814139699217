export enum TableContextType {
  AuditLogs = 'AUDIT LOGS',
  AutomationHub = 'AUTOMATION HUB',
  Attempts = 'ATTEMPTS',
  CreditLedger = 'CREDIT LEDGER',
  CustomRoles = 'CUSTOM ROLES',
  Endpoints = 'ENDPOINTS',
  FieldAi = 'FIELD AI',
  GroupDocuments = 'GROUP DOCUMENTS',
  MyDeletedItems = 'MY DELETED ITEMS',
  RestorationHub = 'RESTORATION HUB',
  Search = 'SEARCH',
  SearchV3 = 'SEARCH V3',
  Documents = 'DOCUMENTS',
  SavedSearches = 'SAVED SEARCHES',
  SavedDashboards = 'SAVED DASHBOARDS',
  UploadFiles = 'UPLOAD FILES',
  Users = 'USERS',
  WorkflowTickets = 'WORKFLOW TICKETS',
  SyncPairsManagement = 'SYNC PAIRS MANAGEMENT',
}
