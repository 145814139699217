import { useSelector } from 'react-redux';

import { Actions, Grid, Layout, Modal, Panel, useToggle } from '~/eds';
import { slices } from '~/redux';

import { useGetLatestLibraryModelVersions } from '../hooks';
import { Categories } from './Categories';
import { ModelCard } from './ModelCard';
import { ModelCart } from './ModelCart';
import { ModelPreview } from './ModelPreview';

type Callback = () => void;

interface Props {
  onCancel: Callback;
  onNext: Callback;
}

export const ModelLibrary = ({ onCancel, onNext }: Props) => {
  const [isCartVisible, showCart] = useToggle(false);

  const loadingContent = { isLoading: false }; // TODO [EKP-29502]

  const category = useSelector(slices.xRayLibrary.selectors.selectCategory);

  const modelsInCart = useSelector(slices.xRayLibrary.selectors.selectCart);

  const models = useGetLatestLibraryModelVersions({
    category /* TODO [EKP-29371]: filters */,
  });

  const cards = models.map((model) => <ModelCard model={model} />);

  return (
    <Modal
      isFullPage
      isVisible
      enableContentPadding={false}
      headerCalloutContent={
        <Actions
          actions={[
            {
              text: `Selected Models (${modelsInCart.length})`,
              onClick: showCart,
            },
          ]}
        />
      }
      loadingContent={loadingContent}
      title="1. Select from Model Library"
      primaryAction={{
        text: 'Next: Add Scope',
        onClick: onNext,
      }}
      secondaryActions={[{ text: 'Save as Drafts' }]}
      // TODO [EKP-29337]: Modal footer leftActions
      // leftActions={[{
      //   icon: 'launch',
      //   text: 'New blank model',
      //   onClick: () => navigate(`${RoutePathType.AutomationHubFields}/new`),
      // }]}
      panel={
        isCartVisible
          ? {
              children: <ModelCart />,
            }
          : undefined
      }
      cancelText="Close"
      onCancel={onCancel}
      onHide={onCancel}
    >
      <Layout direction="column">
        <div>TODO [EKP-29370]: SearchInput</div>
        <div>TODO [EKP-29371]: SearchFilters</div>
        <Layout>
          <Categories />
          <Panel
            title={category ? `${category.label} (${models.length})` : ''}
            width="calc(100vw - 284px)"
          >
            <div>TODO [EKP-29376]: Model Library</div>
            <Grid columns={3} columnSpacing={4} rowGap={4}>
              {cards}
            </Grid>
          </Panel>
        </Layout>
        <ModelPreview />
      </Layout>
    </Modal>
  );
};
