import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import injectSheet from 'react-jss';

import {
  black1,
  black2,
  bold,
  evisortRed,
} from '~/assets/shared-styles/general';
import AdminConsolePageNavHeader from '~/components/Admin/AdminConsolePageNavHeader';
import {
  addDepartment,
  addDepartmentMembers,
  deleteDepartment,
  getClientDepartment,
  listDepartmentMembers,
  removeDepartmentMembers,
  renameDepartment,
} from '~/components/Admin/DepartmentsPage';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import EcButton from '~/components/Shared/EcButton';
import EcCard from '~/components/Shared/EcCard';
import EcDepartmentsTable from '~/components/Shared/EcDepartmentsTable';
import EcMembersTable from '~/components/Shared/EcMembersTable';
import EcModal from '~/components/Shared/EcModal';
import EcPaginate from '~/components/Shared/EcPaginate';
import { showToast } from '~/components/Shared/EcToast';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import PlusIcon from '~/components/Shared/Icons/PlusIcon';
import { PAGE_SIZE, PAGE_START } from '~/constants/page';
import { Modal } from '~/eds';
import { withRouting } from '~/routing';
import {
  MODAL_DELETE,
  MODAL_DELETE_DEPARTMENT,
  MODAL_DEPARTMENT_MEMBERS,
  MODAL_NAME,
} from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';

const tableStyles = { padding: '0 16px' };

class Page extends Component {
  constructor(props) {
    super(props);

    this.handlePageClick = this.handlePageClick.bind(this);
    this.navigateBack = this.navigateBack.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.onDepartmentCreate = this.onDepartmentCreate.bind(this);
    this.onDepartmentEdit = this.onDepartmentEdit.bind(this);
    this.onDepartmentRename = this.onDepartmentRename.bind(this);
    this.onDepartmentDelete = this.onDepartmentDelete.bind(this);
    this.onMembersDelete = this.onMembersDelete.bind(this);
    this.handleRemoveUsers = this.handleRemoveUsers.bind(this);

    this.state = {
      page: PAGE_START,
      departmentLoaded: false,
      membersLoaded: false,
      members: [],
      membersTotalCount: 0,
      membersToRemove: [],
      selectedDepartment: null,
      selectedItem: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.getClientDepartment();
  }

  componentDidUpdate(prevProps) {
    const {
      params: { departmentId },
    } = this.props;
    if (
      departmentId &&
      prevProps.params.departmentId !== this.props.params.departmentId
    ) {
      this.getClientDepartment(departmentId);
    }
  }

  getClientDepartment(id) {
    const { page } = this.state;
    const {
      params: { departmentId },
    } = this.props;

    const deptId = id || departmentId;

    getClientDepartment(deptId)
      .then((res) => {
        this.setState({
          department: res,
          departmentLoaded: true,
          errorLoadingDepartment: false,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          this.setState({ errorLoadingDepartment: false, noAccess: true });
        } else {
          this.setState({ errorLoadingDepartment: true });
        }
      })
      .finally(() => {
        this.setState({ departmentLoaded: true });
      });

    listDepartmentMembers(deptId, page, PAGE_SIZE)
      .then((res) => {
        this.setState({
          members: res.results,
          membersTotalCount: res.count,
          errorLoadingMembers: false,
          membersLoaded: true,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          this.setState({ errorLoadingMembers: false, noAccess: true });
        } else {
          this.setState({ errorLoadingMembers: true });
        }
      })
      .finally(() => {
        this.setState({ membersLoaded: true });
      });
  }

  navigateBack() {
    this.props.navigate(-1);
  }

  handlePageClick(clickedPage) {
    const selectedPage = clickedPage.selected + 1;
    this.setState({ page: selectedPage, membersLoaded: false }, () => {
      this.getClientDepartment();
    });
  }

  handleShowModal(modal, departmentName, forSubdepartment = false) {
    this.setState({
      modal,
      selectedDepartment: departmentName,
      forSubdepartment,
    });
  }

  handleHideModal() {
    this.setState({
      modal: null,
      selectedItem: null,
    });
  }

  handleShowToast(type, text) {
    showToast(type, text);
  }

  renderDepartmentNameModal() {
    const {
      params: { departmentId },
    } = this.props;
    const { selectedDepartment, forSubdepartment } = this.state;

    let title;
    let labelText;
    let itemsCurrentName;
    let itemsParentDepartmentId;
    let confirmButtonIcon;
    let confirmButtonText;
    let handleNameChange;

    if (selectedDepartment && !forSubdepartment) {
      labelText = 'DEPARTMENT NAME';
      title = 'Rename Department';
      confirmButtonText = 'Save';
      itemsCurrentName = selectedDepartment.department_name;
      itemsParentDepartmentId = null;
      handleNameChange = this.onDepartmentRename;
    } else if (!!forSubdepartment) {
      labelText = 'DEPARTMENT NAME';
      title = 'Add Subdepartment';
      itemsParentDepartmentId = departmentId;
      confirmButtonIcon = <PlusIcon size="20" color="#000" />;
      confirmButtonText = 'Create Subdepartment';
      handleNameChange = this.onDepartmentCreate;
    } else {
      labelText = 'DEPARTMENT NAME';
      title = 'Add Department';
      itemsCurrentName = '';
      itemsParentDepartmentId = null;
      confirmButtonIcon = <PlusIcon size="20" color="#000" />;
      confirmButtonText = 'Create Department';
      handleNameChange = this.onDepartmentCreate;
    }

    return (
      <EcModal
        modalType={MODAL_NAME}
        width="560px"
        title={title}
        labelText={labelText}
        itemsCurrentName={itemsCurrentName}
        itemsParentFolderId={itemsParentDepartmentId}
        confirmButtonIcon={confirmButtonIcon}
        confirmButtonText={confirmButtonText}
        handleNameChange={handleNameChange}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderDeleteDepartmentModalText() {
    const {
      selectedDepartment: { department_name },
    } = this.state;

    return (
      <div>
        Are you sure you want to delete&nbsp;
        <span>{department_name}</span> department? You won’t be able to undo
        this action.
      </div>
    );
  }

  renderDeleteDepartmentModal() {
    const { selectedDepartmentBeforeDelete } = this.state;

    return (
      <Modal
        title="Delete Department?"
        isVisible={true}
        onHide={this.handleHideModal}
        primaryAction={{
          onClick: this.onDepartmentDelete,
          text: selectedDepartmentBeforeDelete ? 'Delete and Assign' : 'Delete',
          variant: 'danger',
        }}
        children={this.renderDeleteDepartmentModalText()}
      />
    );
  }

  renderDeleteMembersModalText() {
    const {
      membersToRemove,
      department: { department_name },
    } = this.state;

    return (
      <div>
        Are you sure you want to remove{' '}
        {`${membersToRemove.length} ${pluralize(
          'user',
          membersToRemove.length,
        )}`}{' '}
        from the {department_name} department? You won’t be able to undo this
        action.
      </div>
    );
  }

  renderDeleteMembersModal() {
    return (
      <Modal
        title="Remove from Department?"
        isVisible={true}
        onHide={this.handleHideModal}
        primaryAction={{
          onClick: this.onMembersDelete,
          text: 'Remove',
          variant: 'danger',
        }}
        children={this.renderDeleteMembersModalText()}
      />
    );
  }

  onMembersDelete() {
    const {
      params: { clientId, departmentId },
    } = this.props;
    const {
      membersToRemove,
      department: { department_name },
    } = this.state;

    removeDepartmentMembers(
      clientId,
      departmentId,
      department_name,
      membersToRemove,
    )
      .then((data) => {
        this.handleShowToast(
          SUCCESS,
          `Users have been removed from ${data.department_name}.`,
        );
        this.setState({
          page: 1,
          membersLoaded: false,
          departmentLoaded: false,
        });
        this.getClientDepartment();
        this.handleHideModal();
      })
      .catch(() => {
        this.handleShowToast(
          ERROR,
          'An error occurred while removing users from the department.',
        );
      });
  }

  onDepartmentCreate(name) {
    const {
      params: { clientId, departmentId },
    } = this.props;

    const { forSubdepartment, selectedDepartment } = this.state;

    const members = [];
    const parent =
      forSubdepartment && selectedDepartment
        ? selectedDepartment.department_id
        : departmentId
        ? departmentId
        : null;

    addDepartment(name, members, parent, clientId)
      .then((data) => {
        this.handleShowToast(
          SUCCESS,
          `Department ${data.department_name} is created.`,
        );
        this.getClientDepartment();
      })
      .catch((err) => {
        if (
          err &&
          err.response.status === 400 &&
          err.response &&
          err.response.data
        ) {
          this.handleShowToast(ERROR, err.response.data[0]);
        } else {
          this.handleShowToast(
            ERROR,
            'An error occurred while creating the new department.',
          );
        }
      });
  }

  onDepartmentEdit(members) {
    const {
      params: { clientId, departmentId },
    } = this.props;
    const {
      department: { department_name },
    } = this.state;

    addDepartmentMembers(clientId, departmentId, department_name, members)
      .then((data) => {
        this.handleShowToast(
          SUCCESS,
          `Users have been added to ${data.department_name}.`,
        );
        this.setState({
          page: 1,
          membersLoaded: false,
          departmentLoaded: false,
        });
        this.getClientDepartment();
      })
      .catch(() => {
        this.handleShowToast(
          ERROR,
          'An error occurred while adding users to the department.',
        );
      });
  }

  onDepartmentRename(name) {
    const { selectedDepartment } = this.state;
    const {
      params: { clientId },
    } = this.props;
    const departmentId = selectedDepartment.department_id;

    renameDepartment(clientId, departmentId, name)
      .then((data) => {
        this.handleShowToast(
          SUCCESS,
          `Department is renamed to ${data.department_name}.`,
        );
        this.getClientDepartment();
      })
      .catch((err) => {
        if (
          err &&
          err.response.status === 400 &&
          err.response &&
          err.response.data
        ) {
          this.handleShowToast(ERROR, err.response.data[0]);
        } else {
          this.handleShowToast(
            ERROR,
            'An error occurred while renaming the new department.',
          );
        }
      });
  }

  onDepartmentDelete() {
    const { selectedDepartment } = this.state;
    const departmentId = selectedDepartment.department_id;

    deleteDepartment(departmentId)
      .then(() => {
        this.handleShowToast(SUCCESS, 'Department has been deleted.');
        this.navigateBack();
      })
      .catch(() => {
        this.handleShowToast(
          ERROR,
          'An error occurred while deleting the department.',
        );
      });
  }

  renderDepartmentsTableData() {
    const {
      params: { clientId },
    } = this.props;
    const { department } = this.state;

    return (
      <EcCard contentStyles={tableStyles}>
        <EcDepartmentsTable
          header="Subdepartments"
          clientId={clientId}
          listItems={department.children}
          handleShowModal={this.handleShowModal}
        />
      </EcCard>
    );
  }

  handleRemoveUsers(membersToRemove) {
    this.setState({ membersToRemove });
  }

  renderMembersTableData() {
    const { classes } = this.props;
    const {
      params: { clientId },
    } = this.props;
    const { members } = this.state;
    return (
      <div className={classes.membersTablContainer}>
        <EcCard contentStyles={tableStyles}>
          <EcMembersTable
            header="Users"
            clientId={clientId}
            listItems={members}
            handleShowModal={this.handleShowModal}
            handleAddUnassignedUsers={this.handleAddUnassignedUsers}
            handleRemoveUsers={this.handleRemoveUsers}
          />
        </EcCard>
      </div>
    );
  }

  renderPagination() {
    const { classes } = this.props;
    const { membersTotalCount, page } = this.state;
    return (
      membersTotalCount > PAGE_SIZE && (
        <div className={classes.paginationWrapper}>
          <EcPaginate
            onPageChange={this.handlePageClick}
            pageCount={Math.ceil(membersTotalCount / PAGE_SIZE)}
            forcePage={page - 1}
          />
        </div>
      )
    );
  }

  renderAddMembersModal() {
    const {
      params: { clientId, departmentId },
    } = this.props;

    return (
      <EcModal
        modalType={MODAL_DEPARTMENT_MEMBERS}
        width="560px"
        title="Add Users to Department"
        confirmButtonIcon={<PlusIcon size="20" color={black1} />}
        confirmButtonText="Add Users"
        handleActionClick={this.onDepartmentEdit}
        hideModal={this.handleHideModal}
        clientId={clientId}
        departmentId={departmentId}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const {
      modal,
      noAccess,
      departmentLoaded,
      membersLoaded,
      errorLoadingDepartment,
      errorLoadingMembers,
      department,
    } = this.state;

    if (!departmentLoaded || !membersLoaded) {
      return (
        <div className={classes.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    } else if (noAccess) {
      return (
        <div>
          <p>You do not have permission to access this page.</p>
          <p>To request permission, contact your organization administrator.</p>
        </div>
      );
    } else if (errorLoadingDepartment || errorLoadingMembers) {
      return (
        <div className={classes.errorLoadingMessage}>
          An error occurred while loading the department.
        </div>
      );
    }
    return (
      <div>
        <div className={classes.pageNavHeaderContainer}>
          <AdminConsolePageNavHeader
            title={department.department_name}
            subTitle="Departments"
            navigateBack={this.navigateBack}
          />
          <span className={classes.headerButton}>
            <EcButton
              text="Rename"
              onClick={() => this.handleShowModal(MODAL_NAME, department)}
            />
            <ActionsMenu
              id={`${department.department_name}_actions_menu`}
              items={[
                {
                  content: 'Delete',
                  onClick: () =>
                    this.handleShowModal(
                      MODAL_DELETE_DEPARTMENT,
                      department,
                      false,
                    ),
                },
              ]}
              align="end"
              title="edit department menu"
            />
          </span>
        </div>
        {this.renderDepartmentsTableData()}
        {this.renderMembersTableData()}
        {this.renderPagination()}
        {modal === MODAL_NAME ? this.renderDepartmentNameModal() : null}
        {modal === MODAL_DELETE ? this.renderDeleteMembersModal() : null}
        {modal === MODAL_DELETE_DEPARTMENT
          ? this.renderDeleteDepartmentModal()
          : null}
        {modal === MODAL_DEPARTMENT_MEMBERS
          ? this.renderAddMembersModal()
          : null}
      </div>
    );
  }
}

Page.propTypes = {
  classes: PropTypes.object,
};

const styles = {
  subheadWrapper: {
    display: 'flex',
  },
  subheader: {
    marginBottom: '24px',
    color: black2,
    fontSize: '19px',
    fontWeight: bold,
    lineHeight: '33px',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  errorLoadingMessage: {
    color: evisortRed,
  },
  membersTablContainer: {
    marginTop: '24px',
  },
  pageNavHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
      marginRight: '20px',
    },
  },
};

export default injectSheet(styles)(withRouting(Page));
