import React, { useEffect, useState } from 'react';

import { AND as AndOperator } from '~/constants/openSearch';
import { Box, Button, Layout, Panel, types, useToggle } from '~/eds';
import { Filter } from '~/evifields';
import { DragProvider } from '~/features/drag-and-drop';
import { QueryGroup } from '~/redux/api/methods/searchV3';
import {
  Query,
  QueryOperatorEntity,
  SearchFilter,
  SectionEntity,
} from '~/types';

import { FiltersAccordion } from './filters-accordion';
import { OperatorSelect } from './operator-select';
import {
  isBooleanFilter,
  toSectionFiltersOperators,
} from './QueryBuilder.utils';
import { Section } from './section';

type Props = {
  pinnedFiltersIds?: types.PilotId[];
  searchFilters: SearchFilter[];
  value?: Query;
  onHide: () => void;
  onSubmit: (groupsEntity: (QueryGroup | QueryOperatorEntity)[]) => void;
};
const newOperator = { type: 'operator', value: 'and' } as QueryOperatorEntity;
const newSection = {
  type: 'section',
  value: { filters: [], operator: newOperator },
};

export const QueryBuilder = ({
  pinnedFiltersIds,
  searchFilters,
  value,
  onSubmit,
  onHide,
}: Props) => {
  const [isLeftPanelVisible, toggleHidden] = useToggle(true);
  const [sections, setSections] = useState<QueryGroup[]>([newSection]);
  const [operators, setOperators] = useState<QueryOperatorEntity[]>([]);

  const isAnySectionEmpty = sections.some(
    (section) => section.value.filters.length === 0,
  );
  const isAnyFilterEmpty = sections
    .flatMap((section) => section.value.filters)
    .some(
      (filter) =>
        !isBooleanFilter(filter.operatorId) && filter.values.length === 0,
    );

  const hasNoSections = sections.length === 0;

  const handleAddSection = () => {
    setSections((prevSections) => {
      const newSections = [...prevSections, newSection];
      if (newSections.length > 1) {
        setOperators((prevOperators) => [...prevOperators, AndOperator]);
      }
      return newSections;
    });
  };

  const handleRemoveSection = (index: number) => {
    setSections((prevSections) => prevSections.filter((_, i) => i !== index));
    setOperators((prevOperators) =>
      prevOperators.filter((_, i) => i !== Math.max(0, index - 1)),
    );
  };

  const handleGroupOperatorSelect = (index: number, value: string) => {
    setOperators((prevOperators) =>
      prevOperators.map((op, i) =>
        i === index ? ({ type: 'operator', value } as QueryOperatorEntity) : op,
      ),
    );
  };

  const handleSectionOperatorSelect = (index: number, value: 'or' | 'and') => {
    setSections((prevSections) => {
      return prevSections.map((section, i) =>
        i === index
          ? {
              ...section,
              value: {
                ...section.value,
                operator: { type: 'operator', value },
              },
            }
          : section,
      );
    });
  };

  const handleAddFilter = (index: number, filter: Filter) => {
    setSections((prevSections) => {
      return prevSections.map((section, i) =>
        i === index
          ? {
              ...section,
              value: {
                ...section.value,
                filters: [...section.value.filters, filter],
              },
            }
          : section,
      );
    });
  };
  const handleRemoveFilter = (sectionIndex: number, filterIndex: number) => {
    setSections((prevSections) => {
      return prevSections.map((section, i) =>
        i === sectionIndex
          ? {
              ...section,
              value: {
                ...section.value,
                filters: section.value.filters.filter(
                  (_, j) => j !== filterIndex,
                ),
              },
            }
          : section,
      );
    });
  };

  const handleChangeFilter = (
    sectionIndex: number,
    filterIndex: number,
    newFilter: Filter,
  ) => {
    setSections((prevSections) => {
      return prevSections.map((section, i) =>
        i === sectionIndex
          ? {
              ...section,
              value: {
                ...section.value,
                filters: section.value.filters.map((filter, j) =>
                  j === filterIndex ? newFilter : filter,
                ),
              },
            }
          : section,
      );
    });
  };

  const handleSubmit = () => {
    const groupsEntity = sections.reduce(
      (acc: (QueryGroup | QueryOperatorEntity)[], val: QueryGroup, i) => {
        return operators[i] ? acc.concat(val, operators[i]) : acc.concat(val);
      },
      [],
    );
    onSubmit(groupsEntity);
  };

  const getOperator = (index: number) => {
    if (sections.length === 1 || index === sections.length - 1) return null;
    return (
      <OperatorSelect
        value={operators[index]?.value}
        onChange={(value) => handleGroupOperatorSelect(index, value)}
      />
    );
  };

  useEffect(() => {
    if (value && searchFilters) {
      const querySections = (value as SectionEntity[]).filter(
        (item: SectionEntity) => item.type === 'section',
      );
      const searchFiltersMap = Object.fromEntries(
        searchFilters.map((filter) => [filter.id, filter]),
      );
      const queryGroupFilters = toSectionFiltersOperators(
        querySections,
        searchFiltersMap,
      );
      const operatorsArray = (value as QueryOperatorEntity[]).filter(
        (item: QueryOperatorEntity) => item.type === 'operator',
      );
      setSections(queryGroupFilters as QueryGroup[]);
      setOperators(operatorsArray);
    } else {
      setSections([newSection]);
    }
  }, [value, searchFilters]);

  return (
    <DragProvider>
      <Panel
        hidden={{
          isHidden: false,
          onHide: onHide,
          placement: 'right',
        }}
        title="Advanced Queries"
        enableBackdrop
        position="right"
        mode="floating"
        width="720px"
        footer={{
          actions: [
            {
              text: 'Cancel',
              onClick: onHide,
            },
            {
              text: 'Apply',
              level: 'primary',
              disabled: isAnySectionEmpty || isAnyFilterEmpty || hasNoSections,
              onClick: handleSubmit,
            },
          ],
        }}
        sideContent={{
          content: (
            <FiltersAccordion
              pinnedFiltersIds={pinnedFiltersIds}
              searchFilters={searchFilters}
            />
          ),
          action: {
            text: 'Filters',
            icon: 'left-panel',
            onClick: toggleHidden,
          },
          isVisible: isLeftPanelVisible,
        }}
      >
        <Layout mb={2} direction="column" spacing={4} align="center">
          {sections.map((section, index) => (
            <React.Fragment key={`section_${index}`}>
              <Box w="100%" px={4}>
                <Section
                  searchFilters={searchFilters}
                  filters={section.value.filters}
                  operator={section.value?.operator?.value || 'and'}
                  onOperatorChange={(value) =>
                    handleSectionOperatorSelect(index, value)
                  }
                  onFilterAdd={(value) => handleAddFilter(index, value)}
                  onFilterChange={(filterIndex, filter) =>
                    handleChangeFilter(index, filterIndex, filter)
                  }
                  onFilterRemove={(filterIndex) =>
                    handleRemoveFilter(index, filterIndex)
                  }
                  onRemove={() => handleRemoveSection(index)}
                />
              </Box>

              {getOperator(index)}
            </React.Fragment>
          ))}
          <Button
            text="Add Section"
            variant="secondary-active"
            onClick={handleAddSection}
          />
        </Layout>
      </Panel>
    </DragProvider>
  );
};
