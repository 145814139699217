import pluralize from 'pluralize';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { ticketParticipantsSet, ticketStagesSet } from '~/actions';
import { reassignCoordinator, resetApproval, resetApprovals } from '~/api';
import UserName from '~/components/Client/UserName';
import AccessControl from '~/components/Shared/AccessControl';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import EcTextarea from '~/components/Shared/EcTextarea';
import { showToast } from '~/components/Shared/EcToast';
import FormLabel from '~/components/Shared/FormLabel';
import UserSelect from '~/components/Shared/UserSelect';
import { useToast } from '~/eds';
import {
  TicketPermissionType,
  TicketStageType,
  TicketStatusType,
} from '~/enums';
import { withUsers } from '~/hocs';
import { useAsync } from '~/hooks';
import { getJudgmentById } from '~/reducers/ticketReviewer';
import { api, TAGS } from '~/redux';
import { useRouting } from '~/routing';
import * as toastTypes from '~/types/toast.types';
import {
  Box,
  FlexLayout,
  Icon,
  Link,
  LoadingSpinner,
  Text,
  useModal,
  useModalSimple,
} from '~/ui';
import { externalUserToUser } from '~/utils/user';

import { useActivityLogUpdater } from '../../shared/useActivityLogUpdater';
import { UserInfo } from './UserInfo';

const getAssigneeRole = (stage) => {
  switch (stage) {
    case TicketStageType.Review:
      return 'reviewer';
    case TicketStageType.Sign:
      return 'signer';
    case TicketStageType.Finalize:
      return 'finalizer';
    default:
      return null;
  }
};

function TicketUserItem({
  userId,
  externalUser,
  ticketId,
  userPermissions,
  children,
  isListItem,
  currentUserId,
  stageId,
  issueId,
  entityType,
  note,
  status,
  allItemsNeutral,
  disableAdditionalActions,
  isCounterpartySigner,
  // injected
  users,
  // connected
  ticketParticipantsSet,
  ticket,
  judgment,
  assigneeIds,
  assignAction,
  setAssignAction,
  isCoordinator,
}) {
  const [reassign, setReassign] = useState(false);
  const [assigneeId, setAssigneeId] = useState(userId);
  const [comment, setComment] = useState('');
  const isAssignSelf = !assigneeIds.includes(currentUserId);
  const dispatch = useDispatch();
  const { navigate } = useRouting();

  const assigneeRole = getAssigneeRole(ticket.stage);
  const successToastMessage = assigneeRole
    ? `New ${assigneeRole} assigned.`
    : 'The task has been assigned.';

  const updateActivityLog = useActivityLogUpdater({ ticketId });

  const [
    getTicketCurrentStage,
  ] = api.endpoints.getTicketCurrentStage.useLazyQuery();

  const [
    getTicketParticipants,
  ] = api.endpoints.getTicketParticipants.useLazyQuery();

  const updateTicketData = async () => {
    const stages = await getTicketCurrentStage({ ticketId }).unwrap();
    ticketStagesSet(stages);
    dispatch(api.util.invalidateTags([TAGS.ACL_TAG]));
    await updateActivityLog();
    const participants = await getTicketParticipants({ ticketId }).unwrap();
    ticketParticipantsSet(participants.data);
  };

  const [
    reassignApproval,
    { isLoading: isReassignApprovalLoading },
  ] = api.endpoints.reassignApproval.useMutation();
  const { toast } = useToast();

  const handleReassignment = async (id) => {
    try {
      await reassignApproval({
        ticketId,
        issueId,
        oldAssigneeId: userId,
        newAssigneeId: id ?? assigneeId,
        entityType,
      }).unwrap();
      await updateTicketData();
      toast({ message: successToastMessage, status: 'success' });
    } catch (error) {
      const errorMessage = error?.response?.data?.data;
      const errorStatus = error?.response?.status;
      if (errorStatus === 403) {
        toast({
          message: "You don't have permission to see this ticket.",
          status: 'danger',
        });
        navigate('/workflow/tickets');
      }
      if (errorStatus === 400 && errorMessage?.includes('already assigned')) {
        toast({
          message:
            'Task already assigned to this person. Please select a different assignee.',
          status: 'danger',
        });
      } else {
        toast({
          message: `Reassignment failed. Please refresh the page and try again. (${errorMessage})`,
          status: 'danger',
        });
      }
    }
  };

  const {
    executor: handleCoordinatorReassignment,
    isLoading: isReassignCoordinatorLoading,
  } = useAsync(
    reassignCoordinator,
    { ticketId, stageId, coordinatorId: assigneeId },
    {
      errorToastMessage:
        'Reassignment failed. Please refresh the page and try again.',
      successHandler: async () => {
        try {
          await updateTicketData();
        } catch (error) {
          const errorStatus = error?.response?.status;
          if (errorStatus === 403) {
            toast({
              message: "You don't have permission to see this ticket.",
              status: 'danger',
            });
            navigate('/workflow/tickets');
          }
        }
      },
      successToastMessage: 'New coordinator assigned.',
    },
  );

  const handleOnReassignClick = () => setReassign(true);

  const handleOnCancelReassignClick = () => {
    if (isListItem) {
      // if user clicks cancel, hide the drop down menu
      setAssignAction(false);
    }
    setReassign(false);
    setAssigneeId(null);
  };

  const handleAssign = (assigneeId) => {
    if (isListItem) {
      handleReassignment(assigneeId);
      setAssignAction(false);
    } else {
      handleCoordinatorReassignment();
    }
    setReassign(false);
  };

  const handleAssignClick = (isSelfClick) => {
    let newAssigneeId = null;
    if (isAssignSelf && !reassign) {
      if (isSelfClick) {
        newAssigneeId = currentUserId;
      } else {
        newAssigneeId = assigneeId;
      }
      setAssigneeId(newAssigneeId);
    }
    if (ticket.stage === TicketStageType.Sign && isListItem) {
      showReAssignSignerModal();
    } else {
      handleAssign(newAssigneeId);
    }
  };

  const onHide = () => {
    setComment('');
  };

  const itemActions =
    reassign || assignAction ? (
      <FlexLayout alignItems="center" space={6}>
        <UserSelect
          notAvailableUsers={assigneeIds}
          value={assigneeId}
          onChange={(assigneeId) => setAssigneeId(assigneeId)}
        />
        <FlexLayout
          alignItems="center"
          space={1}
          onClick={() => handleAssignClick(false)}
        >
          <Icon icon="checkmark" size="s" color="blue-500" />{' '}
          <Link variant="xs-dense-medium">Assign</Link>
        </FlexLayout>
        <FlexLayout
          alignItems="center"
          space={1}
          onClick={handleOnCancelReassignClick}
        >
          <Link variant="xs-dense-medium">Cancel</Link>
        </FlexLayout>
      </FlexLayout>
    ) : (
      children
    );

  const resetStageText = (ticketStage) => {
    switch (ticketStage) {
      case TicketStageType.Review:
        return 'Item';
      case TicketStageType.Sign:
        return 'Signature';
      case TicketStageType.Finalize:
        return 'Task';
      default:
        return '';
    }
  };
  const stageText = resetStageText(ticket.stage);
  const stageTextWithCondition = isListItem
    ? stageText
    : pluralize(stageText) === 'Items'
    ? 'Phases'
    : pluralize(stageText);
  const hasSignatures = ticket.document.hasSignatures;

  const [resetApprovalsModal, showResetApprovalsModal] = useModal({
    title: `Reset ${stageTextWithCondition}`,
    onHide,
    actionButton: {
      text: `Reset ${stageTextWithCondition}`,
      errorHandler: () => {
        showToast(
          toastTypes.ERROR,
          `Unable to reset the ${stageText.toLowerCase()}(s). Please try again.`,
        );
      },
      promise: async () => {
        isListItem
          ? await resetApproval(ticketId, issueId, comment, entityType, [
              userId,
            ])
          : await resetApprovals(ticketId, stageId, comment);
        await updateTicketData();
        showToast(toastTypes.SUCCESS, `${stageText}(s) successfully reset.`);
      },
    },
    content: (
      <>
        <Box p="5px 0 20px">
          {isListItem ? (
            <Text variant="s-spaced">
              Are you sure you want to reset the item for{' '}
              <UserName userId={userId} /> as part of the{' '}
              {judgment ? judgment.name : ''} item?
            </Text>
          ) : (
            <Text variant="s-spaced">
              Are you sure you want to reset all of the{' '}
              {pluralize(stageText) === 'Items'
                ? 'phases'
                : pluralize(stageText.toLowerCase())}{' '}
              for this ticket?
            </Text>
          )}{' '}
          {ticket.stage === TicketStageType.Sign && hasSignatures && (
            <Text variant="s-spaced-bold">
              Signatures that were signed via upload will not be reset.{' '}
            </Text>
          )}
          <Text variant="s-spaced-bold">
            You won’t be able to undo this action.
          </Text>
        </Box>
        <FormLabel
          input={
            <EcTextarea
              placeholder={'Add an optional comment\u2026'}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          }
          label="Comment"
        />
      </>
    ),
  });

  const createResetApprovalTaskText = () => {
    switch (ticket.stage) {
      case TicketStageType.Finalize:
        return 'Reset Task';
      case TicketStageType.Review:
        return 'Reset Item';
      case TicketStageType.Sign:
        return 'Reset Signature';
      default:
        return null;
    }
  };

  const createResetApprovalsTasksText = () => {
    switch (ticket.stage) {
      case TicketStageType.Finalize:
        return 'Reset All Tasks';
      case TicketStageType.Sign:
        return 'Reset All Signatures';
      default:
        return 'Reset All Items';
    }
  };

  const shouldDisableResetAllSignatures = () => {
    if (ticket.stage !== TicketStageType.Sign) return false;

    const phases = ticket.stages.sign.phaseIds.map((id) => ticket.phases[id]);
    return phases.every((phase) => phase.status === TicketStatusType.Signed);
  };

  const isResetAllDisabled =
    allItemsNeutral || shouldDisableResetAllSignatures();
  const isLoading = isReassignApprovalLoading || isReassignCoordinatorLoading;

  const [reAssignSignerModal, showReAssignSignerModal] = useModalSimple({
    text: 'Reset signature & Re-assign',
    title: 'Re-assign signer?',
    content: (
      <FlexLayout flexDirection="column" space={4}>
        <Text variant="s-spaced">
          Doing this on a document that was already processed by the e-sig
          provider can result in mis-matched signer information. To correct
          this, we will require the signature coordinator to re-collect
          signatures.
        </Text>
        <Text variant="s-spaced-bold">
          All signatures will be voided and reset.
        </Text>
      </FlexLayout>
    ),
    confirm: handleAssign,
    width: 'm',
  });

  const getMenuItems = () => {
    const items = [];

    if (users[userId] || isCoordinator) {
      //EKP-2977: if it is an unassigned user, do not show Re-assign option
      items.push({
        content: 'Re-assign',
        disabled: status === TicketStatusType.Done,
        onClick: () => handleOnReassignClick(),
      });
    }

    items.push({
      content: 'Assign to me',
      disabled: currentUserId === userId || status === TicketStatusType.Done,
      onClick: () => handleAssignClick(true),
    });

    if (isListItem && status !== TicketStatusType.Signed) {
      items.push({
        content: createResetApprovalTaskText(),
        disabled: status === TicketStatusType.Pending,
        onClick: () => showResetApprovalsModal(),
      });
    } else {
      items.push({
        content: createResetApprovalsTasksText(),
        disabled: isResetAllDisabled,
        onClick: () => showResetApprovalsModal(),
      });
    }

    return items;
  };

  return (
    <>
      <FlexLayout
        alignItems="center"
        disabled={isLoading}
        justifyContent="space-between"
        p={4}
        sx={{ border: isListItem ? undefined : 'border', borderRadius: 'm' }}
      >
        <UserInfo
          isCounterpartySigner={isCounterpartySigner}
          userId={userId}
          externalUser={
            externalUser ? externalUserToUser(externalUser) : undefined
          }
          currentStage={ticket.stage}
          isCoordinator={isCoordinator}
        />

        {isLoading && <LoadingSpinner size="s" />}
        <FlexLayout alignItems="center" space={6}>
          {itemActions}
          <AccessControl
            userPermissions={userPermissions}
            requiredPermission={TicketPermissionType.ReassignAny}
          >
            {!isCounterpartySigner &&
              !disableAdditionalActions &&
              !reassign &&
              !assignAction && (
                <ActionsMenu
                  id={`workflow_ticket_user_item_id=${issueId}`}
                  items={getMenuItems()}
                  align="end"
                />
              )}
          </AccessControl>
        </FlexLayout>
      </FlexLayout>
      {note}
      {resetApprovalsModal}
      {reAssignSignerModal}
    </>
  );
}

const mapStateToProps = ({ ticket }, { issueId }) => {
  const judgment = getJudgmentById(ticket, issueId);

  let assigneeIds = [];
  if (judgment && ticket.stage === TicketStageType.Sign) {
    const approvals = judgment.approvalIds.map((id) => ticket.approvals[id]);
    assigneeIds = approvals.map((a) => a.userId);
  }

  return { ticket, judgment, assigneeIds };
};

export default connect(mapStateToProps, { ticketParticipantsSet })(
  withUsers(TicketUserItem),
);
