import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Accordion, Panel } from '../../../components';
import { Column, GroupedColumn } from '../types';
import { ColumnOrder } from './ColumnOrder';
import { ColumnSelection } from './ColumnSelection';

interface Props {
  columns: Column[];
  columnOrder: string[];
  mode: null | 'selection' | 'order';
  primaryColumnKey: string;
  onHide: () => void;
  onUpdateColumnOrder: (updatedColumnOrder: string[]) => void;
  groupedColumns?: GroupedColumn[];
}

export const ManageColumnsPanel = ({
  columns,
  columnOrder: initialColumnOrder,
  groupedColumns,
  mode,
  primaryColumnKey,
  onHide,
  onUpdateColumnOrder,
}: Props) => {
  const [draftColumnOrder, setDraftColumnOrder] = useState(initialColumnOrder);

  useEffect(() => {
    setDraftColumnOrder(initialColumnOrder);
  }, [initialColumnOrder]);

  const isDirty = !isEqual(initialColumnOrder, draftColumnOrder);

  const handleHide = () => {
    setDraftColumnOrder(initialColumnOrder);
    onHide();
  };

  const handleUpdate = () => {
    onUpdateColumnOrder(draftColumnOrder);
    onHide();
  };

  const accordionItems = [
    {
      title: 'Column selection',
      content: (
        <ColumnSelection
          columns={columns}
          columnOrder={draftColumnOrder}
          groupedColumns={groupedColumns}
          primaryColumnKey={primaryColumnKey}
          onUpdateColumnOrder={setDraftColumnOrder}
        />
      ),
      isExpanded: mode === 'selection',
    },
    {
      title: 'Column order',
      content: (
        <ColumnOrder
          columns={columns}
          columnOrder={draftColumnOrder}
          primaryColumnKey={primaryColumnKey}
          onUpdateColumnOrder={setDraftColumnOrder}
        />
      ),
      isExpanded: mode === 'order',
    },
  ];

  return mode !== null ? (
    <Panel
      footer={{
        actions: [
          { text: 'Cancel', level: 'tertiary' as const, onClick: handleHide },
          {
            text: 'Update',
            level: 'primary' as const,
            onClick: handleUpdate,
            disabled: !isDirty,
          },
        ],
      }}
      hidden={{
        onHide: handleHide,
      }}
      width="l"
      mode="floating"
      enableBackdrop
      title="Manage columns"
      enableContentPadding={false}
    >
      <Accordion items={accordionItems} />
    </Panel>
  ) : null;
};
