import { capitalize } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PersistedTable } from '~/components/Shared/PersistedTable';
import { User } from '~/components/Shared/User';
import { Layout, StatusMessage } from '~/eds';
import { TableContextType } from '~/enums';
import { useTableSettings } from '~/hooks';
import { api, slices } from '~/redux';
import { useRouting } from '~/routing';

import {
  DEFAULT_LIMIT_ENDPOINTS,
  initialEndpointsTableSettings,
} from '../constants';
import { Endpoint, EndpointStatus, EndpointType } from '../types';
import { getStatusChip, getUpdatedFilters, testIsActiveStatus } from '../utils';

export const Endpoints = () => {
  const { setSearchParams } = useRouting();
  const dispatch = useDispatch();

  const filters = useSelector(slices.webhooks.selectors.selectedFilters);

  const { tableSettings = initialEndpointsTableSettings } = useTableSettings(
    TableContextType.Endpoints,
  );

  const [sortBy, setSortBy] = useState<
    { id: string; desc: boolean } | undefined
  >(tableSettings.sortBy);

  const {
    data = { results: [], total: 0 },
    isLoading: isLoadingEndpoints,
  } = api.endpoints.getEndpoints.useQuery({
    sortBy,
  });

  const columns = [
    {
      key: 'url',
      title: 'Endpoint URL',
      cellType: 'link',
      mapCellProps: (endpoint: Endpoint) => ({
        pathname: endpoint.endpointUrl,
        text: endpoint.endpointUrl,
        onClick: () => {
          dispatch(slices.webhooks.actions.setActiveEndpoint(endpoint));
          dispatch(slices.webhooks.actions.setActiveEndpointType('view'));
        },
      }),
    },
    {
      key: 'status',
      title: 'Status',
      cellType: 'chips',
      mapCellProps: (endpoint: Endpoint) => {
        const { text, status } = getStatusChip(
          endpoint.status as EndpointStatus,
        );
        return {
          chips: [
            {
              text,
              status,
            },
          ],
        };
      },
    },
    {
      key: 'description',
      title: 'Description',
      cellType: 'text',
      mapCellProps: (endpoint: Endpoint) => ({
        text: endpoint.description,
        shouldTruncate: true,
      }),
    },
    {
      key: 'events',
      title: 'Selected Events',
      cellType: 'text',
      mapCellProps: (endpoint: Endpoint) => ({
        text: `${endpoint.events.length}`,
        isNumeric: true,
      }),
      width: 'm',
    },
    {
      key: 'dateCreated',
      title: 'Created',
      cellType: 'datetime',
      mapCellProps: (endpoint: Endpoint) => ({
        datetime: endpoint.dateCreated,
        format: 'full',
      }),
      width: 'm',
    },
    {
      key: 'dateModified',
      title: 'Modified',
      cellType: 'datetime',
      mapCellProps: (endpoint: Endpoint) => ({
        datetime: endpoint.dateModified,
        format: 'full',
      }),
      width: 'm',
    },
    {
      key: 'createdBy',
      title: 'Created by',
      minWidth: 'm',
      cellType: 'user',
      disableSortBy: true,
      mapCellProps: (endpoint: Endpoint) => ({
        asyncUser: {
          id: endpoint.createdBy,
          render: User,
        },
        mode: 'avatar-name',
      }),
    },
    {
      key: 'modifiedBy',
      title: 'Modified by',
      minWidth: 'm',
      cellType: 'user',
      disableSortBy: true,
      mapCellProps: (endpoint: Endpoint) => ({
        asyncUser: {
          id: endpoint.modifiedBy,
          render: User,
        },
        mode: 'avatar-name',
      }),
    },
  ];

  const handleRowAction = (endpoint: Endpoint, action: EndpointType) => {
    dispatch(slices.webhooks.actions.setActiveEndpoint(endpoint));
    dispatch(slices.webhooks.actions.setActiveEndpointType(action));
  };

  const handleUpdate = useCallback(
    (state: any, action?: any) => {
      if (action?.type === 'toggleSortBy') {
        const updatedSortBy = state.sortBy[0];

        setSortBy(updatedSortBy);
      }
    },
    [sortBy],
  );

  const getRowActions = useCallback(
    (endpoint: Endpoint) => {
      const { status } = endpoint;

      const modelActions = [
        'view details',
        'view attempts',
        'edit',
        testIsActiveStatus(status as EndpointStatus) ? 'suspend' : 'activate',
        'delete',
      ];

      const disabled = isLoadingEndpoints;

      return modelActions.map((action) => {
        return {
          key: action,
          disabled,
          label: capitalize(action),
          tooltip: disabled ? 'Loading endpoint…' : undefined,
          onClick: () => {
            if (action === 'view details') {
              handleRowAction(endpoint, 'view');
            } else if (action === 'view attempts') {
              const updatedFilters = getUpdatedFilters({
                filters,
                endpoint,
              });
              dispatch(slices.webhooks.actions.setFilters(updatedFilters));
              setSearchParams({ secondaryTab: 'attempts' });
            } else {
              handleRowAction(endpoint, action as EndpointType);
            }
          },
        };
      });
    },
    [isLoadingEndpoints, data],
  );

  return (
    <Layout preset="sections">
      {data.total > DEFAULT_LIMIT_ENDPOINTS && (
        <StatusMessage
          message="Results are limited to 500.  If you want to see the others, you will have to delete some endpoints."
          status="warning"
        />
      )}
      <PersistedTable
        columns={columns}
        context={TableContextType.Endpoints}
        data={data.results}
        getRowActions={getRowActions}
        isLoading={isLoadingEndpoints}
        options={{
          enableManageColumns: true,
          enableSelectRows: false,
          enableSearch: true,
          enablePagination: false,
          pageSizes: [],
        }}
        onUpdate={handleUpdate}
        reactTableOptions={{
          manualSortBy: true,
        }}
        state={{
          sortBy: [sortBy],
        }}
        totalCount={data.total}
      />
    </Layout>
  );
};
