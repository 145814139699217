import PT from 'prop-types';
import React from 'react';

import {
  CountrySelect,
  CurrencySelect,
  Select,
  StateProvinceSelect,
} from '~/eds';
import { WorkflowFieldDefaultValueInputType } from '~/enums';
import { Box, EmailInput, InputLabel, NumberInput, TextInput } from '~/ui';

function DefaultValueInput({
  customSettings,
  defaultValue,
  disabled = false,
  id,
  isRequired = true,
  inputType = WorkflowFieldDefaultValueInputType.TextInput,
  label = 'Default Value',
  options,
  placeholder,
  valueKey,
  width,
  defaultValueUpdateFactory,
  error,
}) {
  const value = defaultValue[valueKey];
  const onChange = defaultValueUpdateFactory(valueKey);

  let Input;
  switch (inputType) {
    case WorkflowFieldDefaultValueInputType.CountrySingleSelect:
      Input = (
        <CountrySelect
          name="country-select"
          value={value || null}
          width={width}
          onChange={onChange}
          enablePortal
        />
      );
      break;
    case WorkflowFieldDefaultValueInputType.CurrencySingleSelect:
      Input = (
        <CurrencySelect
          name="currency-select"
          value={value || null}
          width={width}
          onChange={onChange}
          enablePortal
        />
      );
      break;
    case WorkflowFieldDefaultValueInputType.Email:
      Input = (
        <EmailInput
          placeholder={placeholder}
          width={width}
          value={value}
          onChange={onChange}
        />
      );
      break;
    case WorkflowFieldDefaultValueInputType.MultiSelect:
      Input = (
        <Select
          isMulti
          name="multi-select"
          options={options}
          placeholder={placeholder || 'Select'}
          value={value || null}
          width={width}
          onChange={onChange}
          enableErrorMessage
          error={error}
          enablePortal
        />
      );
      break;
    case WorkflowFieldDefaultValueInputType.NumberInput:
      Input = (
        <NumberInput
          placeholder={placeholder || '...'}
          value={value}
          width={width}
          onChange={onChange}
          allowDecimals={customSettings && !!customSettings.allow_decimals}
        />
      );
      break;
    case WorkflowFieldDefaultValueInputType.SingleSelect:
      Input = (
        <Select
          name="single-select"
          options={options}
          placeholder={placeholder || 'Select'}
          value={value || null}
          width={width}
          onChange={onChange}
          enableErrorMessage
          error={error}
          enablePortal
        />
      );
      break;
    case WorkflowFieldDefaultValueInputType.StateSingleSelect:
      Input = (
        <StateProvinceSelect
          country={defaultValue.country}
          name="state-single-select"
          value={value || null}
          width={width}
          onChange={onChange}
          enablePortal
        />
      );
      break;
    case WorkflowFieldDefaultValueInputType.TextInput:
      Input = (
        <TextInput
          placeholder={placeholder || 'Enter text'}
          value={value || ''}
          width={width}
          onChange={onChange}
        />
      );
      break;
    default:
      break;
  }

  return (
    <InputLabel
      disabled={disabled}
      flex={width === 'fullWidth' ? '1 1 auto' : undefined}
      id={id}
      isRequired={isRequired}
      label={label}
    >
      <Box sx={{ flex: '1 1 auto' }}>{Input}</Box>
    </InputLabel>
  );
}

DefaultValueInput.propTypes = {
  id: PT.string.isRequired,
  defaultValue: PT.object.isRequired,
  inputType: PT.oneOf(Object.values(WorkflowFieldDefaultValueInputType)),
  label: PT.string,
  options: PT.arrayOf(
    PT.shape({
      value: PT.any.isRequired,
      label: PT.string.isRequired,
    }).isRequired,
  ),
  valueKey: PT.string.isRequired,
  width: PT.string,
  defaultValueUpdateFactory: PT.func.isRequired,
  error: PT.string,
};
export default DefaultValueInput;
