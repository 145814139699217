import {
  addDays,
  endOfDay,
  isToday,
  setHours,
  startOfDay,
  sub,
  subDays,
} from 'date-fns';

import { Filter } from '~/evifields';
import { Attempt } from '~/features/webhooks';
import { courier } from '~/services';

import {
  DeliveryAttemptGetResponseDto,
  ListApiArg,
  PageMetaDto,
} from '../../types/courier/deliveryAttempt';

interface Request {
  filters: Filter[];
  sortBy?: {
    id: string;
    desc: boolean;
  };
}

type ServerRequest = Request & {
  page: number;
  pageSize: number;
};

interface Response {
  meta: PageMetaDto;
  data: DeliveryAttemptGetResponseDto[];
}

type AttemptsParamPayload = ListApiArg;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getAttemptsExport = async ({
  filters,
  sortBy,
}: Request): Promise<{
  results: Attempt[];
  total: number;
}> => {
  const responses: Response[] = [];

  let page = 1;
  const pageSize = 1000;
  let shouldEnd = false;
  let retry = false;

  while (!shouldEnd) {
    const params = mapToParams({
      filters,
      page,
      pageSize,
      sortBy,
    });

    const queryString = buildQueryString(params);

    try {
      const response: Response = await courier.get(
        `/delivery-attempts${queryString}`,
      );

      const { meta } = response;
      const { hasNext } = meta;

      responses.push(response);

      shouldEnd = !hasNext;
      retry = false; // Reset retry flag after a successful request

      page += 1;
    } catch (error) {
      if (!retry) {
        retry = true; // Set retry flag
        console.log(`Retrying page ${page}...`);
        await delay(1000); // Wait for 1 seconds before retrying
      } else {
        shouldEnd = true; // Stop the loop if the retry also fails
      }
    }
  }

  return {
    results: prepareResponse(responses),
    total: responses.reduce((acc, res) => acc + res.data.length, 0),
  };
};

const prepareResponse = (responses: Response[]): Attempt[] => {
  return responses.reduce((acc, res) => {
    return acc.concat(
      res.data.map((record) => ({
        ...record,
        endpointUrl: record.url,
        dateSent: record.dateDelivered ? new Date(record.dateDelivered) : null,
        status: record.deliveryStatus,
        messageId: record.id,
        statusDescription: record.statusDescription
          ? record.statusDescription
          : '',
      })),
    );
  }, [] as Attempt[]);
};

const mapToParams = ({
  filters,
  page = 1,
  pageSize = 500,
  sortBy = {
    id: 'dateDelivered',
    desc: true,
  },
}: ServerRequest) => {
  const queryParam = filters?.reduce((query, filter) => {
    switch (filter.id) {
      case 'endpointUrl':
        query.url = filter.values as string[];
        break;
      case 'status':
        query.deliveryStatus = filter.values as string[];
        break;
      case 'eventType':
        query.eventType = filter.values as string[];
        break;
      case 'dateDelivered':
        const { operatorId, values } = filter;

        const [date1, date2] = (values as Date[]) ?? [];

        const today = new Date();

        const minEndDate = (date: Date) => {
          return isToday(date) ? today : endOfDay(date);
        };

        const maxEndDate = (date: Date) => {
          return isToday(date) ? startOfDay(today) : startOfDay(date);
        };

        switch (operatorId) {
          case 'date_after':
            query.dateDeliveredStart = maxEndDate(date1).toString();
            break;
          case 'date_before':
            query.dateDeliveredStart = subDays(
              setHours(today, 0),
              29,
            ).toString();
            query.dateDeliveredEnd = minEndDate(date1).toString();
            break;
          case 'date_on':
            query.dateDeliveredStart = setHours(date1, 0).toString();
            query.dateDeliveredEnd = minEndDate(date1).toString();
            break;
          case 'date_between':
            query.dateDeliveredStart = date1.toString();
            query.dateDeliveredEnd = minEndDate(date2).toString();
            break;
          case 'date_in_the_last':
          default:
            const { unit, value } = filter.values[0] as {
              unit: string;
              value: number;
            };
            const lastNumberOfDays = sub(today, {
              [unit]: value,
            });

            const inTheLastIncludesToday = addDays(lastNumberOfDays, 1);
            query.dateDeliveredStart = inTheLastIncludesToday.toString();
        }
        break;
    }

    return query;
  }, {} as AttemptsParamPayload);

  return {
    page: page.toString(),
    pageSize: pageSize.toString(),
    sortBy: sortBy.id,
    desc: sortBy.desc,
    dateDeliveredStart: queryParam.dateDeliveredStart,
    dateDeliveredEnd: queryParam?.dateDeliveredEnd
      ? queryParam.dateDeliveredEnd
      : undefined,
    deliveryStatus:
      queryParam?.deliveryStatus && queryParam.deliveryStatus.length > 0
        ? queryParam?.deliveryStatus
        : undefined,
    eventType:
      queryParam?.eventType && queryParam?.eventType?.length > 0
        ? queryParam?.eventType
        : undefined,
    endpointId:
      queryParam?.url && queryParam.url.length > 0
        ? queryParam?.url
        : undefined,
  };
};

const buildQueryString = (params: AttemptsParamPayload) => {
  const queryParams = [];

  if (params.page) {
    queryParams.push(`page=${params.page}`);
  }

  if (params.pageSize) {
    queryParams.push(`pageSize=${params.pageSize}`);
  }

  if (params.sortBy) {
    queryParams.push(`sortBy=${params.sortBy}`);
    queryParams.push(`desc=${params.desc}`);
  }

  if (params.deliveryStatus) {
    queryParams.push(
      `deliveryStatus=${encodeURIComponent(params.deliveryStatus.join(','))}`,
    );
  }

  if (params.eventType) {
    queryParams.push(
      `eventType=${encodeURIComponent(params.eventType.join(','))}`,
    );
  }

  if (params.dateDeliveredStart) {
    queryParams.push(`dateDeliveredStart=${params.dateDeliveredStart}`);
  }

  if (params.dateDeliveredEnd) {
    queryParams.push(`dateDeliveredEnd=${params.dateDeliveredEnd}`);
  }

  if (params.endpointId) {
    queryParams.push(
      `endpointId=${encodeURIComponent(params.endpointId.join(','))}`,
    );
  }

  return queryParams.length ? `?${queryParams.join('&')}` : '';
};
