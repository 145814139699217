import React from 'react';

import { Nullable } from '~/types';

interface Props {
  sample?: Nullable<string>;
}

export const CodeSample = ({ sample }: Props) => {
  if (!sample) {
    return null;
  }

  return (
    <pre
      style={{
        fontFamily: 'Courier New, Courier, monospace',
        fontSize: '14px',
        color: '#333',
      }}
    >
      <code>{sample}</code>
    </pre>
  );
};
