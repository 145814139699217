import { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  ticketActivitiesSet,
  ticketAttachmentsSet,
  ticketDocumentSet,
  ticketDocumentVersionsSet,
  ticketParticipantsSet,
  ticketReset,
  ticketStagesSet,
  ticketSummarySet,
  ticketUpdate,
} from '~/actions';
import {
  checkEnvelope,
  checkEsignatureIntegration,
  checkESignatureLogin,
  downloadTicketDocument,
  downloadTicketDocumentWithoutActivityLog,
  getActivities,
  getTicketAttachments,
  getTicketDocumentVersions,
  getTicketParticipants,
  patchTicketStage,
  updateEnvelope,
  uploadNewDocumentVersion,
} from '~/api';
import AccessControl from '~/components/Shared/AccessControl';
import AppLayout from '~/components/Shared/AppLayout';
import {
  ActivityLogPanel,
  Comments,
  TicketIntakeForm as IntakeFormPanel,
} from '~/components/Shared/AppLayout/SidebarContent';
import {
  EcTab,
  EcTabList,
  EcTabPanel,
  EcTabs,
} from '~/components/Shared/EcTabs';
import { showToast } from '~/components/Shared/EcToast';
import LoadingSpinner from '~/components/Shared/Icons/LoadingSpinner';
import TicketReviewEmptyStateIcon from '~/components/Shared/Icons/TicketReviewEmptyStateIcon';
import { User } from '~/components/Shared/User';
import { panels } from '~/constants/panels';
import {
  FORM_INFO_TAB_INDEX,
  TICKET_DOCUMENT_VERSION_EDITOR_KEY_POLLING_INTERVAL,
  TICKET_DOCUMENT_VERSION_POLLING_MAX_COUNTER,
  WORKFLOW_TAB_INDEX,
} from '~/constants/ticket';
import { useAppConfigContext } from '~/contexts';
import {
  Box as BoxEds,
  Button,
  ContentContainer,
  DateText,
  ErrorPage,
  Layout,
  Link,
  LoadingShimmer,
  PageSidebar,
  StatusMessage,
  Text as TextEds,
  TruncateText,
  useToast,
} from '~/eds';
import {
  EntityType,
  FeatureFlagType,
  FileMimeType,
  HttpStatusCodeType,
  PanelType,
  QueryParamType,
  TicketActivityType,
  TicketPermissionType,
  TicketStageType,
  TicketStatusType,
} from '~/enums';
import {
  partyNameFromPartyIdAndParties,
  TurnTrackingPanel,
} from '~/features/turn-tracking';
import { FlagType, useFlag } from '~/flags';
import { withUsers } from '~/hocs';
import {
  useAsync,
  useHasFeatureFlag,
  usePermission,
  useSearchParams,
} from '~/hooks';
import { checkIsWorkflowOnly } from '~/permissions';
import {
  getDocumentVersion,
  getHasTemporaryViewAnyPermission,
  getLatestActivity,
} from '~/reducers/ticket';
import { actions, api, selectors, TAGS } from '~/redux';
import { chatbotSlice } from '~/redux/slices/chatbot';
import { useRouting, withRouting } from '~/routing';
import * as toastTypes from '~/types/toast.types';
import { Box, Divider, FlexLayout, Icon, Text, Tooltip } from '~/ui';
import {
  coerceFileType,
  downloadFile,
  getBasename,
  getFileIcon,
} from '~/utils/files';
import { copyToClipboard } from '~/utils/helper.utils';
import { getPageSearchQueryByKey } from '~/utils/searchQuery';
import { removeFileNameExtension } from '~/utils/ticket';
import { externalUserToUser } from '~/utils/user';

import { useActivityLogUpdater } from '../shared/useActivityLogUpdater';
import { useAddTicketParticipantModal } from '../shared/useAddTicketParticipantModal';
import {
  ERROR_MESSAGES,
  TICKET_COMMENT_CHIP,
} from '../TicketReviewerPage/constants';
import { testPhaseNonEmpty } from '../Workflow.utils';
import ActivityLog from './ActivityLog';
import ESignatureSetupAlert from './ESignatureSetupAlert';
import TicketCancelledWarning from './TicketCancelledWarning';
import TicketDocumentActions from './TicketDocumentActions';
import TicketDocumentNotices from './TicketDocumentNotices';
import TicketHeader from './TicketHeader';
import TicketInboundEmail from './TicketInboundEmail';
import TicketParticipants from './TicketParticipants';
import TicketReviewPhase from './TicketReviewPhase';
import TicketSigningPhase from './TicketSigningPhase';
import TicketUserItem from './TicketUserItem';

const stagePermissions = {
  [TicketStageType.Edit]: TicketPermissionType.EditView,
  [TicketStageType.Review]: TicketPermissionType.ReviewView,
  [TicketStageType.Sign]: TicketPermissionType.SignView,
  [TicketStageType.Finalize]: TicketPermissionType.FinalizeView,
  [TicketStageType.Ticket]: TicketPermissionType.TicketView,
};

const ticketStatusHeadline = {
  [TicketStageType.Edit]: 'Your document is being reviewed.',
  [TicketStageType.Review]: 'Your document is being reviewed.',
  [TicketStageType.Sign]: 'Your document is pending signatures.',
  [TicketStageType.Finalize]: 'Your document is being finalized.',
};

const ticketStatusSubheadline = {
  [TicketStageType.Edit]:
    'Once your document is fully reviewed and approved, we will let you know. In the meantime, sit tight!',
  [TicketStageType.Review]:
    'Once your document is fully reviewed and approved, we will let you know. In the meantime, sit tight!',
  [TicketStageType.Sign]:
    'Once your document is signed and finalized, we will let you know.',
  [TicketStageType.Finalize]:
    'Once your document is fully complete, we will let you know.',
};

const TicketsViewPageEnhanced = ({
  params,
  // connected props
  currentUser,
  hasTemporaryViewAnyPermission,
  latestActivity,
  ticket,
  ticketActivitiesSet,
  ticketParticipantsSet,
  ticketAttachmentsSet,
  ticketReset,
  ticketDocumentSet,
  ticketStagesSet,
  ticketSummarySet,
  ticketUpdate,
  ticketDocumentVersionsSet,
}) => {
  const {
    isPanelEnlarged,
    togglePanelEnlarged,
    condensePanel,
    enlargeContent,
    condenseContent,
    isSidebarExpanded,
    openSidebar,
    closeSidebar,
  } = useAppConfigContext();
  const { navigate } = useRouting();
  const [error, setError] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(WORKFLOW_TAB_INDEX);
  const [isLoadingStage, setIsLoadingStage] = useState(false);
  const [isLoadingTicket, setIsLoadingTicket] = useState(true);
  const [isLoadingDocument, setIsLoadingDocument] = useState(true);
  const [isLoadingCurrentStage, setIsLoadingCurrentStage] = useState(true);
  const [selectedPanel, setSelectedPanel] = useState('');
  const [editorKeyRequestCount, setEditorKeyRequestCounter] = useState(0);
  const [
    ticketDocumentVersionEditorKey,
    setTicketDocumentVersionEditorKey,
  ] = useState('');
  const [
    hasEditorKeyPollingFinished,
    setHasEditorKeyPollingFinished,
  ] = useState(false);
  const { ticketId } = params;
  const errorInQuery = getPageSearchQueryByKey(QueryParamType.Error);
  const enableTurnTracking = useFlag(FlagType.TurnTracking);
  const enableIntakeFormPanel = useFlag(FlagType.IntakeFormPanel);

  const enableCheckSignatureStatusButton = useFlag(
    FlagType.CheckSignatureStatus,
  );
  const dispatch = useDispatch();
  const updateActivityLog = useActivityLogUpdater({ ticketId });
  const {
    hasPermission: hasAskAIPermission,
    isLoading: isLoadingPermission,
  } = usePermission({
    permission: {
      resourceId: 'conversational_ai',
      resourceType: 'edit',
    },
  });
  const { toast } = useToast();

  const defaultPanelActions = [
    {
      icon: 'maximize',
      isActive: isPanelEnlarged,
      label: isPanelEnlarged ? 'Condense' : 'Enlarge',
      value: isPanelEnlarged ? 'Condense' : 'Enlarge',
      onClick: togglePanelEnlarged,
    },
  ];

  const panelWidth = isPanelEnlarged ? 'fill' : 'm';

  const onPanelClick = (_action) => {
    if (isSidebarExpanded) {
      condensePanel();
      closeSidebar();
      enlargeContent();
    } else {
      openSidebar();
      condenseContent();
    }
  };

  const {
    data: ticketDocumentData = {},
    isSuccess: isTicketDocumentSuccess,
    fulfilledTimeStamp: ticketDocumentFulfilledTimeStamp,
  } = api.endpoints.getTicketDocument.useQuery({
    ticketId,
  });

  const {
    data: ticketCurrentStageData = {},
    isSuccess: isTicketCurrentStageSuccess,
    fulfilledTimeStamp: ticketCurrentStageFulfilledTimeStamp,
  } = api.endpoints.getTicketCurrentStage.useQuery({ ticketId });

  const {
    isError: isTicketSummaryError,
    error: ticketSummaryError,
    data: ticketSummaryData = {},
    isSuccess: isTicketSummarySuccess,
    fulfilledTimeStamp: ticketSummaryFulfilledTimeStamp,
  } = api.endpoints.getTicketSummary.useQuery({
    ticketId,
  });

  const {
    data: turnTrackingHistoryData = [],
    isError: turnTrackingHistoryError,
    isLoading: isLoadingTurnTrackingHistory,
    fulfilledTimeStamp: turnTrackingHistoryFulfilledTimeStamp,
  } = api.endpoints.getTurnTrackingHistory.useQuery(
    {
      ticketId,
    },
    { skip: !enableTurnTracking || !ticketId },
  );

  const {
    data: turnTrackingPartiesData = [],
    isError: turnTrackingPartiesError,
    isLoading: isLoadingTurnTrackingParties,
  } = api.endpoints.getTurnTrackingParties.useQuery(
    {
      workflowId: ticket.workflowId,
    },
    { skip: !ticket.workflowId || !enableTurnTracking },
  );

  const { document, inboundEmailAddress } = ticket;
  const { id: documentId } = document;

  const version = getDocumentVersion(ticket, params.versionNumber);
  const versionId = version?.id;
  const isWorkingDraft = version.hasWorkingDraft;
  const pilotDocHandlerId = ticket.pilotDocHandlerId;

  const skipEditorKeyRequest =
    !isWorkingDraft ||
    editorKeyRequestCount >= TICKET_DOCUMENT_VERSION_POLLING_MAX_COUNTER ||
    hasEditorKeyPollingFinished;

  const {
    data: editorKeyData,
    requestId,
  } = api.endpoints.getTicketDocumentVersionEditorKey.useQuery(
    { documentId, versionId },
    {
      pollingInterval: TICKET_DOCUMENT_VERSION_EDITOR_KEY_POLLING_INTERVAL,
      skip: skipEditorKeyRequest,
    },
  );

  const handleTicketError = () => {
    showToast(
      toastTypes.ERROR,
      'There has been an error when loading this ticket.',
    );
    navigate('/workflow/tickets');
  };

  const fetchActivityLog = useCallback(async () => {
    const activities = await getActivities({
      entityId: ticketId,
      entityType: EntityType.Ticket,
    });
    ticketActivitiesSet(activities);
  }, [ticketId]);

  // onSuccess for the ticketDocument fetch
  useEffect(() => {
    if (isTicketDocumentSuccess) {
      try {
        ticketDocumentSet(ticketDocumentData);
      } catch {
        handleTicketError();
      } finally {
        setIsLoadingDocument(false);
      }
    }
  }, [ticketDocumentFulfilledTimeStamp]);

  // onSuccess for the ticketSummary fetch
  useEffect(() => {
    if (isTicketSummarySuccess) {
      try {
        ticketSummarySet(ticketSummaryData);
      } catch {
        handleTicketError();
      } finally {
        setIsLoadingTicket(false);
        fetchActivityLog();
      }
    }
  }, [ticketSummaryFulfilledTimeStamp]);

  // onSuccess for the ticketCurrentStage fetch
  useEffect(() => {
    if (isTicketCurrentStageSuccess) {
      try {
        ticketStagesSet(ticketCurrentStageData);
      } catch {
        handleTicketError();
      } finally {
        setIsLoadingCurrentStage(false);
      }
    }
  }, [ticketCurrentStageFulfilledTimeStamp]);

  useEffect(() => {
    if (turnTrackingHistoryData) {
      dispatch(actions.setTurnTrackingHistory(turnTrackingHistoryData));
    }
  }, [turnTrackingHistoryFulfilledTimeStamp]);

  useEffect(() => {
    if (turnTrackingPartiesError) {
      toast({
        status: 'info',
        title: "We're experiencing difficulty loading Responsible Parties",
        message: 'Try again in a few minutes.',
      });
    }
  }, [turnTrackingPartiesError]);

  useEffect(() => {
    if (turnTrackingHistoryError) {
      toast({
        status: 'info',
        title: "We're experiencing difficulty loading Cycle Time History",
        message: 'Try again in a few minutes.',
      });
    }
  }, [turnTrackingHistoryError]);

  const hasTicketInboundEmail = useHasFeatureFlag(
    FeatureFlagType.TicketInboundEmail,
  );
  const hasPreSigCommentsFlagEnabled = useHasFeatureFlag(
    FeatureFlagType.PreSigComments,
  );
  const hasESigEnhancementFeatureFlag = useFlag(FlagType.EsignEnhancements);
  const hasActivityLogPanel = useFlag(FlagType.ActivityLogPanel);
  const isAskAnythingEnabled = useFlag(FlagType.AskAnything);
  const isAskAnythingPreSigEnabled =
    useFlag(FlagType.AskAnythingPreSig) &&
    isAskAnythingEnabled &&
    hasAskAIPermission;

  const [ticketCommentId] = useSearchParams(QueryParamType.TicketCommentId);
  const [activeTab] = useSearchParams(QueryParamType.ActiveTab);
  const ticketEntity = { id: ticketId, type: EntityType.Ticket };

  const [
    addParticipantModal,
    openAddParticipantModal,
  ] = useAddTicketParticipantModal({
    ticketId,
  });

  useEffect(() => {
    if (activeTab) {
      setActiveTabIndex(parseInt(activeTab));
    }
  }, [activeTab]);

  useEffect(() => {
    if (editorKeyData) {
      if (
        ticketDocumentVersionEditorKey &&
        editorKeyData.key !== ticketDocumentVersionEditorKey
      ) {
        setHasEditorKeyPollingFinished(true);
      }
      setTicketDocumentVersionEditorKey(editorKeyData.key);
    }
  }, [editorKeyData]);

  useEffect(() => {
    if (requestId) {
      setEditorKeyRequestCounter(editorKeyRequestCount + 1);
    }
  }, [requestId]);

  useEffect(() => {
    if (errorInQuery) {
      showToast(
        toastTypes.ERROR,
        'Login into e-signature provider failed. Please try again.',
      );
    }
  }, [errorInQuery]);

  useEffect(() => {
    return () => {
      ticketReset();
    };
  }, [ticketReset]);

  useEffect(() => {
    if (isTicketSummaryError) {
      if (ticketSummaryError.response?.status) {
        setError(ticketSummaryError.response?.status);
        setIsLoadingTicket(false);
      }
    }
  }, [isTicketSummaryError, ticketSummaryError]);

  const { data: documentData } = api.endpoints.getDocumentByTag.useQuery(
    { tag: versionId },
    {
      skip: !isAskAnythingPreSigEnabled || !versionId,
    },
  );

  useEffect(() => {
    if (documentData && documentData.currentVersion) {
      const { currentVersion, id } = documentData;
      // format the name to be "(v1) My document"
      const parsedFileName = `(v${
        version?.versionNumber
      }) ${removeFileNameExtension(
        currentVersion.fileName,
        version?.fileType,
      )}`;
      const isEdited = ticket.activities?.some(
        (activity) =>
          activity.action === TicketActivityType.DocumentEdit &&
          activity.data?.version?.id === versionId,
      );
      dispatch(
        actions.setCurrentDocument({
          id,
          versionId: currentVersion.id,
          name: parsedFileName,
          type: currentVersion.fileType,
          ticketVersionId: versionId,
          folder: '',
          isEdited,
        }),
      );
    }

    return () => {
      dispatch(actions.setCurrentDocument(null));
    };
  }, [documentData, ticket.activities]);

  const openChat = () => {
    dispatch(chatbotSlice.actions.toggleChatbot());
    closeSidebar();
    enlargeContent();
  };

  useAsync(
    checkESignatureLogin,
    { ticketId, redirectUri: window.location.href },
    {
      condition: enableCheckSignatureStatusButton
        ? ticket.stage === TicketStageType.Sign
        : true,
      deps: enableCheckSignatureStatusButton
        ? [ticketId, ticket.stage]
        : [ticketId],
      successHandler: async () => {
        let hasEnvelope = false;
        try {
          if (enableCheckSignatureStatusButton) {
            hasEnvelope = (await checkEnvelope(ticketId)).data;
            if (!hasEnvelope && ticket.stage === TicketStageType.Sign) {
              await updateEnvelope(ticketId);
            }
          } else {
            await updateEnvelope(ticketId);
          }
        } catch (error) {
          if (
            error.response?.status === HttpStatusCodeType.UnprocessableContent
          ) {
            const { errors } = error.response.data;
            const er = errors[0];
            toast({
              autoClose: false,
              message: er?.detail,
              status: 'warning',
            });
          }
        } finally {
          const hasEsignatureIntegration = await checkEsignatureIntegration();
          ticketUpdate({ hasEsignatureIntegration, hasEnvelope });
        }
      },
      errorToastMessage:
        'There was an issue communicating with the e-signature provider. Please try again.',
    },
  );

  useAsync(getTicketAttachments, ticketId, {
    condition: ticketId,
    deps: [ticketId],
    successHandler: (response) => {
      ticketAttachmentsSet(response);
    },
    errorToastMessage:
      'There was an error loading the ticket attachments. Please try again.',
  });

  const { isLoading: isLoadingDocumentVersions } = useAsync(
    getTicketDocumentVersions,
    documentId,
    {
      condition: documentId,
      deps: [documentId],
      successHandler: (response) => {
        ticketDocumentVersionsSet(response);
      },
      errorToastMessage:
        'There was an error loading the document versions. Please try again.',
    },
  );

  const {
    executor: handleNextStageClick,
    isLoading: isCompletingStage,
  } = useAsync(
    patchTicketStage,
    { ticketId },
    {
      successHandler: async (res) => {
        showToast(toastTypes.SUCCESS, 'Successfully completed stage');
        try {
          // we are changing stages, so next stage is not ready yet.
          setIsLoadingStage(true);
          const participants = await getTicketParticipants({ ticketId });
          dispatch(
            api.util.invalidateTags([TAGS.ACL_TAG, TAGS.TAG_BY_TICKET_SUMMARY]),
          );
          await updateActivityLog();
          ticketUpdate({
            stages: res.stages,
          });

          ticketParticipantsSet(participants.data);
          setIsLoadingStage(false);
        } catch (error) {
          window.location.reload();
        }
      },
      errorToast:
        'Transition to the next stage failed. Please refresh the page and try again.',
    },
  );

  const {
    executor: handleOnDownloadClick,
    isLoading: isLoadingDownload,
  } = useAsync(
    downloadTicketDocument,
    { documentId, versionId, isWorkingDraft },
    {
      successToastMessage: `"${ticket.name}" is now downloading\u2026`,
      successToastType: toastTypes.DOWNLOAD,
      successHandler: async (file) => {
        const fileType =
          file.type === FileMimeType.DocxUnofficial ? 'docx' : version.fileType;
        downloadFile(
          file,
          `${getBasename(document.name)}_${document.version}${
            isWorkingDraft ? '_draft' : ''
          }.${fileType}`,
        );
        await updateActivityLog();
      },
      errorToastMessage:
        'There has been an error with downloading this document. Please try again.',
    },
  );

  const isChatBotPanelVisible = useSelector(selectors.selectIsChatbotVisible);
  const renderAskAiStatusMessage = () => {
    const isEdited = ticket.activities?.some(
      (activity) =>
        activity.action === TicketActivityType.DocumentEdit &&
        activity.data?.version?.id === versionId,
    );

    return isEdited && isChatBotPanelVisible ? (
      <BoxEds mb={4} w="50%">
        <StatusMessage
          message="Questions are answered based upon the original document text,
        with all redlines accepted. This document has edits that have not been saved to a new version.
        Please save as new version before asking questions."
          status="active"
        />
      </BoxEds>
    ) : null;
  };

  async function uploadTicketDocVersion() {
    const documentBlob = await downloadTicketDocumentWithoutActivityLog({
      documentId,
      versionId,
      isWorkingDraft,
    });
    const file = new File(
      [documentBlob],
      `${getBasename(document.name)}.${version.fileType}`,
    );
    const res = await uploadNewDocumentVersion({
      ticketId,
      documentId,
      text: 'Send for signing',
      file,
      isCounterparty: false,
    });
    dispatch(
      // push the new version to redux
      ticketDocumentVersionsSet([
        { ...res, fileType: coerceFileType(res.fileType) },
        ...ticket.documentVersions,
      ]),
    );
    dispatch(api.util.invalidateTags([TAGS.TAG_CURRENT_STAGE]));
  }

  const {
    executor: handleSendForSigningExecutor,
    isLoading: isLoadingNewVersion,
  } = useAsync(
    uploadTicketDocVersion,
    {},
    {
      successToastMessage: 'Successfully created a new document version',
      successHandler: async () => {
        handleNextStageClick();
      },
      errorToastMessage:
        'An error occured when creating a new document version to send for signing.',
    },
  );

  const handleSendForSigning = async () => {
    if (isWorkingDraft) {
      handleSendForSigningExecutor();
    } else {
      handleNextStageClick();
    }
  };

  function handleOnAddSignersClick() {
    if (enableIntakeFormPanel) {
      setSelectedPanel(PanelType.IntakeForm);
      openSidebar();
    } else {
      setActiveTabIndex(FORM_INFO_TAB_INDEX);
    }
  }

  const renderTurnTrackingSidebar = () => {
    const currentPartyId = turnTrackingHistoryData[0]?.partyId;
    const currentPartyName = partyNameFromPartyIdAndParties(
      currentPartyId,
      turnTrackingPartiesData,
    );
    return (
      <BoxEds border="border" borderRadius="m" p={2} mb={2}>
        {isLoadingTurnTrackingHistory ||
        isLoadingTurnTrackingParties ||
        isLoadingPermission ? (
          <Layout h={40}>
            <LoadingShimmer />
          </Layout>
        ) : (
          <Layout direction="column">
            <TextEds id="currently-with" variant="tiny">
              Currently with:
            </TextEds>
            <TextEds aria-labelledby="currently-with" variant="body-bold">
              {currentPartyName ?? 'Not Set'}
            </TextEds>
            <Link
              variant="tiny"
              onClick={() => {
                setSelectedPanel(PanelType.CycleTimeTracker);
                openSidebar();
                condenseContent();
              }}
            >
              View cycle time tracker
            </Link>
          </Layout>
        )}
      </BoxEds>
    );
  };

  const renderTicketSidebar = () => {
    const { id } = ticket;

    return (
      <>
        {enableTurnTracking && renderTurnTrackingSidebar()}
        <TicketParticipants ticketId={id} />
        {hasTicketInboundEmail && (
          <>
            <Divider />
            <TicketInboundEmail ticketEmail={inboundEmailAddress} />
          </>
        )}
      </>
    );
  };

  const renderModifiedByLatest = ({ user, modifiedDate }) => {
    const userName = (
      <User
        id={user.isInternal ? user.id : undefined}
        user={!user.isInternal ? externalUserToUser(user) : undefined}
      />
    );

    const counterpartyEmail = document.lastModifiedByEmail;
    const submittedByEmailIntake =
      hasTicketInboundEmail &&
      ticket.activities[0]['action'] ===
        TicketActivityType.InboundAttachmentAccepted;
    return (
      <>
        <DateText
          date={modifiedDate ? new Date(modifiedDate) : undefined}
          format="duration"
          enableTick
        />{' '}
        by{' '}
        {submittedByEmailIntake && counterpartyEmail
          ? counterpartyEmail
          : userName}
      </>
    );
  };

  const renderDocumentSection = () => {
    const { document, documentVersions, permissions: userPermissions } = ticket;
    const currentVersion = documentVersions.find(
      (d) => d.versionNumber === document.version,
    );
    const currentVersionFileType = currentVersion?.fileType;
    const documentName = currentVersion?.name ?? document.name;
    const currentVersionDummyFile = new File(
      [],
      `file.${currentVersionFileType}`,
    ); // create a dummy file for reference with file APIs.

    return (
      <FlexLayout
        flexDirection="column"
        bg="white"
        p={6}
        space={6}
        sx={{ border: 'border', borderRadius: 'm', boxShadow: 'depth-1' }}
      >
        <FlexLayout alignItems="center" space={2}>
          <Icon icon={getFileIcon(currentVersionDummyFile)} size="xl" />
          <FlexLayout alignItems="flex-start" flexDirection="column" space={2}>
            <AccessControl
              userPermissions={userPermissions}
              requiredPermission={TicketPermissionType.Reviewer}
              renderNoAccess={() => (
                <Tooltip content="You do not have permissions to view this document.">
                  <FlexLayout alignItems="center" space={1}>
                    <TruncateText maxW={500} variant="body-bold" disabled>
                      {removeFileNameExtension(
                        documentName,
                        currentVersionFileType,
                      )}
                    </TruncateText>
                    <Icon icon="lock" color="gray-600" size="xs" />
                  </FlexLayout>
                </Tooltip>
              )}
            >
              <Link
                pathname={`/workflow/tickets/${ticket.id}/reviewer/${document.version}`}
                variant="body-bold"
                maxW={500}
                shouldTruncate
              >
                {removeFileNameExtension(documentName, currentVersionFileType)}
              </Link>
            </AccessControl>
            <FlexLayout alignItems="center">
              <Text color="gray-600" variant="xs-dense">
                {`Version ${document.version} • Last modified`}{' '}
                {latestActivity && renderModifiedByLatest(latestActivity)}
              </Text>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
        {ticket.isCancelled ? (
          <TicketCancelledWarning />
        ) : (
          <>
            {ticket.stage === TicketStageType.Sign && <ESignatureSetupAlert />}
            <Divider />
            {ticket.stage === TicketStageType.Review && (
              <TicketDocumentNotices />
            )}
          </>
        )}
        {ticket.status !== TicketStatusType.Completed && !ticket.isCancelled && (
          <TicketDocumentActions
            handlers={{
              handleOnDownloadClick,
              handleOnShareSuccess: updateActivityLog,
              handleNextStageClick,
              handleOnAddSignersClick,
              handleSendForSigning,
            }}
            loaders={{
              isLoadingDownload,
              isPreparingDownload: !skipEditorKeyRequest,
              isLoadingNextStage: isCompletingStage || isLoadingStage,
              isLoadingSendForSigning:
                isLoadingNewVersion || isCompletingStage || isLoadingStage,
            }}
          />
        )}
        {ticket.status === TicketStatusType.Completed &&
          !checkIsWorkflowOnly(currentUser) && (
            <FlexLayout flexDirection="column" space={1}>
              <Box>
                <Button
                  text="Go to Document"
                  disabled={pilotDocHandlerId === null}
                  onClick={() => navigate(`/document/${pilotDocHandlerId}`)}
                  variant="secondary"
                />
              </Box>
              {!ticket.pilotDocHandlerId && (
                <Text color="gray-600" variant="xs-dense">
                  Please wait for 2 minutes then refresh the page to see the
                  document.
                </Text>
              )}
            </FlexLayout>
          )}
      </FlexLayout>
    );
  };

  const renderReviewSection = () => {
    const { stage, stages, approvals } = ticket;
    const { phaseIds, coordinatorId, id } = stages.review;

    const nonEmptyPhaseIds = phaseIds.filter((phaseId) => {
      const phase = ticket.phases[phaseId];
      return testPhaseNonEmpty(phase);
    });

    const allApprovalsNeutral = !Object.values(approvals)
      .filter((approval) => nonEmptyPhaseIds.includes(approval.phaseId))
      .some((approval) => approval.status !== TicketStatusType.Pending);

    return (
      <FlexLayout flexDirection="column" space={4}>
        <Text variant="xl-dense-bold">Review</Text>
        {coordinatorId && (
          <FlexLayout flexDirection="column" space={3}>
            <Text color="gray-600" variant="xs-dense-caps">
              Review Coordinator
            </Text>
            <TicketUserItem
              userPermissions={ticket.permissions}
              stageId={id}
              ticketId={ticket.id}
              userId={coordinatorId}
              currentUserId={currentUser.id}
              allItemsNeutral={allApprovalsNeutral}
              isCoordinator={true}
            />
          </FlexLayout>
        )}
        <Box>
          {nonEmptyPhaseIds.map((phaseId, index) => (
            <TicketReviewPhase
              key={phaseId}
              phaseId={phaseId}
              previousPhaseId={nonEmptyPhaseIds[index - 1]}
              stage={stage}
            />
          ))}
        </Box>
      </FlexLayout>
    );
  };

  const renderSignaturesSection = () => {
    const { stage, stages, approvals } = ticket;
    const { phaseIds, coordinatorId, id } = stages.sign;

    const nonEmptyPhaseIds = phaseIds.filter((phaseId) => {
      const phase = ticket.phases[phaseId];
      return phase.judgmentIds.length > 0;
    });

    const allSignaturesNeutral = !Object.values(approvals)
      .filter((approval) => phaseIds.includes(approval.phaseId))
      .some(
        (approval) =>
          approval.status !== TicketStatusType.SignatureRequestNotSent,
      );

    return (
      <FlexLayout flexDirection="column" space={4}>
        <Text variant="xl-dense-bold">Signatures</Text>
        <FlexLayout flexDirection="column" space={3}>
          <Text color="gray-600" variant="xs-dense-caps">
            Signing Coordinator
          </Text>
          <TicketUserItem
            userPermissions={ticket.permissions}
            stageId={id}
            ticketId={ticket.id}
            userId={coordinatorId}
            currentUserId={currentUser.id}
            allItemsNeutral={allSignaturesNeutral}
            isCoordinator={true}
          />
        </FlexLayout>
        {hasESigEnhancementFeatureFlag && nonEmptyPhaseIds.length ? (
          <TicketSigningPhase phases={nonEmptyPhaseIds} phaseId={phaseIds[0]} />
        ) : (
          <Box>
            {nonEmptyPhaseIds.map((phaseId, index) => (
              <TicketReviewPhase
                key={phaseId}
                phaseId={phaseId}
                previousPhaseId={phaseIds[index - 1]}
                stage={stage}
              />
            ))}
          </Box>
        )}
      </FlexLayout>
    );
  };

  const renderChecklistSection = () => {
    const { stage, stages, approvals } = ticket;
    const { phaseIds, coordinatorId, id } = stages.finalize;

    const allTasksNeutral = !Object.values(approvals)
      .filter((approval) => phaseIds.includes(approval.phaseId))
      .some((approval) => approval.status !== TicketStatusType.Pending);

    const nonEmptyPhaseIds = phaseIds.filter((phaseId) => {
      const phase = ticket.phases[phaseId];
      return phase.judgmentIds.length > 0;
    });

    return (
      <FlexLayout flexDirection="column" space={4}>
        <Text variant="xl-dense-bold">Checklist</Text>
        <FlexLayout flexDirection="column" space={3}>
          <Text color="gray-600" variant="xs-dense-caps">
            Checklist Coordinator
          </Text>
          <TicketUserItem
            userPermissions={ticket.permissions}
            stageId={id}
            ticketId={ticket.id}
            userId={coordinatorId}
            currentUserId={currentUser.id}
            allItemsNeutral={allTasksNeutral}
            isCoordinator={true}
          />
        </FlexLayout>
        <Box>
          {nonEmptyPhaseIds.map((phaseId, index) => (
            <TicketReviewPhase
              key={phaseId}
              phaseId={phaseId}
              previousPhaseId={nonEmptyPhaseIds[index - 1]}
              stage={stage}
            />
          ))}
        </Box>
      </FlexLayout>
    );
  };

  const renderLoadingContainer = () => {
    return (
      <Box p={30} sx={{ textAlign: 'center' }}>
        <LoadingSpinner size="medium" mode="quote" />
      </Box>
    );
  };

  const renderStageContent = () => {
    const { stages, stage } = ticket;
    if (!stages[stage]) {
      return null;
    }

    switch (stage) {
      case TicketStageType.Review:
        return hasTemporaryViewAnyPermission && renderReviewSection();
      case TicketStageType.Sign:
        return hasTemporaryViewAnyPermission && renderSignaturesSection();
      case TicketStageType.Finalize:
        return hasTemporaryViewAnyPermission && renderChecklistSection();
      default:
        return null;
    }
  };

  function onMentionRequestPermission(mentionedEntity) {
    const { type } = mentionedEntity;
    const entity = mentionedEntity.data[type];
    if (entity && type === EntityType.User && mentionedEntity.isInvalid) {
      openAddParticipantModal({ type: 'user', value: entity });
    } else if (entity && type === EntityType.Department) {
      openAddParticipantModal({ type: 'department', value: entity });
    }
  }

  const renderErrorMessage = () => {
    const { preset, title, description } =
      ERROR_MESSAGES[ticketSummaryError?.response?.status] ??
      ERROR_MESSAGES['default'];

    return (
      <ErrorPage
        preset={preset}
        title={title}
        description={description}
        resolveActionText="Take me to Tickets"
        resolveAction={{
          onClick: () => navigate('/workflow/tickets'),
        }}
      />
    );
  };

  function onCopyLink(comment) {
    const linkConstructed = `${window.location.origin}${window.location.pathname}?${QueryParamType.TicketCommentId}=${comment.id}`;
    copyToClipboard(linkConstructed, undefined, undefined, undefined, false);
  }

  useEffect(() => {
    if (ticketCommentId) {
      setSelectedPanel(PanelType.Comments);
      openSidebar();
    }
  }, [ticketCommentId]);

  const renderTicketContent = () => {
    const { isCompleted, permissions } = ticket;
    let viewStagePermission = stagePermissions[ticket.stage];
    if (ticket.stage === TicketStageType.Finalize && isCompleted) {
      viewStagePermission = TicketPermissionType.CompletedView;
    }

    const isDocumentSectionLoading =
      isLoadingDocument ||
      isLoadingDocumentVersions ||
      ticket.documentVersions.length === 0 ||
      isLoadingCurrentStage;

    const activityLogCustomActions = enableIntakeFormPanel
      ? { intakeFormAction: () => setSelectedPanel(PanelType.IntakeForm) }
      : {};

    return (
      <AppLayout
        sidebar={
          (hasPreSigCommentsFlagEnabled ||
            hasActivityLogPanel ||
            enableIntakeFormPanel) && (
            <PageSidebar
              isExpanded={isSidebarExpanded}
              selectedPanel={selectedPanel}
              onUpdate={(panel) => {
                setSelectedPanel(panel);
                if (panel === PanelType.AskAI) {
                  openChat();
                }
              }}
              topDistance="m"
              width={panelWidth}
              panels={{
                ...(isAskAnythingPreSigEnabled
                  ? {
                      [PanelType.AskAI]: {
                        action: {
                          disabled: !documentData,
                          ...panels[PanelType.AskAI],
                        },
                        component: null,
                      },
                    }
                  : {}),
                ...(hasPreSigCommentsFlagEnabled
                  ? {
                      comments: {
                        action: {
                          ...panels[PanelType.Comments],
                          onClick: onPanelClick,
                        },
                        component: (
                          <Comments
                            actions={defaultPanelActions}
                            activeCommentId={ticketCommentId}
                            entity={ticketEntity}
                            isCommentsEnlarged={isPanelEnlarged}
                            isExpanded={Boolean(ticketCommentId)}
                            chips={[TICKET_COMMENT_CHIP]}
                            onMention={onMentionRequestPermission}
                            onCopyLink={onCopyLink}
                            filterComment={(comment) =>
                              !comment.context.some((context) =>
                                [
                                  EntityType.Highlight,
                                  EntityType.EditorHighlight,
                                ].includes(context.type),
                              )
                            }
                            width={panelWidth}
                          />
                        ),
                      },
                    }
                  : {}),
                ...(enableTurnTracking
                  ? {
                      cycleTimeTracker: {
                        action: {
                          ...panels[PanelType.CycleTimeTracker],
                          onClick: onPanelClick,
                        },
                        component: (
                          <TurnTrackingPanel
                            ticketId={ticketId}
                            workflowId={ticket.workflowId}
                          />
                        ),
                      },
                    }
                  : {}),
                ...(hasActivityLogPanel
                  ? {
                      activityLog: {
                        action: {
                          ...panels[PanelType.ActivityLogs],
                          onClick: onPanelClick,
                        },
                        component: (
                          <ActivityLogPanel
                            actions={defaultPanelActions}
                            ticketId={ticketId}
                            customActions={activityLogCustomActions}
                            versions={ticket.documentVersions}
                            width={panelWidth}
                          />
                        ),
                      },
                    }
                  : {}),
                ...(enableIntakeFormPanel
                  ? {
                      intakeForm: {
                        action: {
                          ...panels[PanelType.IntakeForm],
                          onClick: onPanelClick,
                        },
                        component: (
                          <IntakeFormPanel
                            actions={defaultPanelActions}
                            ticketId={ticketId}
                            title="Form Information"
                            width={panelWidth}
                          />
                        ),
                      },
                    }
                  : {}),
              }}
            />
          )
        }
      >
        <TicketHeader ticket={ticket} loading={isLoadingTicket} />
        {isAskAnythingPreSigEnabled && renderAskAiStatusMessage()}
        <AccessControl
          userPermissions={permissions}
          requiredPermission={viewStagePermission}
          renderNoAccess={() => (
            <FlexLayout
              alignItems="center"
              flexDirection="column"
              mt="118px"
              space={10}
            >
              <TicketReviewEmptyStateIcon />
              <FlexLayout alignItems="center" flexDirection="column" space={2}>
                <Text color="gray-700" variant="l-dense-bold">
                  {ticketStatusHeadline[ticket.stage]}
                </Text>
                <Text color="gray-700" variant="xs-dense">
                  {ticketStatusSubheadline[ticket.stage]}
                </Text>
              </FlexLayout>
            </FlexLayout>
          )}
        >
          <EcTabs
            selectedIndex={activeTabIndex}
            onSelect={(activeTabIndex) => setActiveTabIndex(activeTabIndex)}
          >
            <EcTabList>
              <EcTab>
                <Text variant="m-dense-bold">Workflow</Text>
              </EcTab>
            </EcTabList>
            <EcTabPanel>
              <FlexLayout space={6}>
                <FlexLayout flexDirection="column" flexGrow="1" space={12}>
                  <ContentContainer
                    loadingContent={{
                      isLoading: isDocumentSectionLoading,
                      message: 'Loading document information',
                    }}
                  >
                    {renderDocumentSection()}
                  </ContentContainer>
                  <ContentContainer
                    loadingContent={{
                      isLoading: isLoadingCurrentStage,
                      message: 'Loading stages information',
                    }}
                  >
                    {renderStageContent()}
                  </ContentContainer>
                  {(!hasActivityLogPanel || !hasPreSigCommentsFlagEnabled) && (
                    <AccessControl
                      userPermissions={ticket.permissions}
                      requiredPermission={TicketPermissionType.ActivityLog}
                    >
                      <ActivityLog ticketId={ticket.id} />
                    </AccessControl>
                  )}
                </FlexLayout>
                <Box sx={{ width: '280px' }}>{renderTicketSidebar()}</Box>
              </FlexLayout>
            </EcTabPanel>
          </EcTabs>
        </AccessControl>
        {addParticipantModal}
      </AppLayout>
    );
  };

  return (
    <div>
      {isLoadingTicket
        ? renderLoadingContainer()
        : error
        ? renderErrorMessage(error)
        : renderTicketContent()}
    </div>
  );
};

const mapStateToProps = ({ currentUser, ticket }) => ({
  currentUser,
  hasTemporaryViewAnyPermission: getHasTemporaryViewAnyPermission(ticket),
  ticket,
  latestActivity: getLatestActivity(ticket.activities),
});

export default connect(mapStateToProps, {
  ticketActivitiesSet,
  ticketDocumentSet,
  ticketParticipantsSet,
  ticketReset,
  ticketStagesSet,
  ticketAttachmentsSet,
  ticketSummarySet,
  ticketDocumentVersionsSet,
  ticketUpdate,
})(withUsers(withRouting(TicketsViewPageEnhanced)));
