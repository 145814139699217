import PT from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import FieldAssignmentMenu from '~/components/Workflow/WorkflowBuilderPage/Sidebar/FieldAssignmentMenu';
import { selectWidths } from '~/constants/selectWidths';
import { fieldTypeLabels } from '~/constants/workflow';
import { Select } from '~/eds';
import { getFieldOptions } from '~/reducers/workflow';
import { Box, FlexLayout, InputLabel, Text } from '~/ui';

function FieldSelect({
  blacklistValues,
  enableAddField = false,
  id,
  value,
  placeholder = '...',
  shouldShowLabel,
  width,
  onChange,
  // connected
  fields,
  options,
  shouldValidate,
}) {
  const [isAddingField, setIsAddingField] = useState(false);
  const [newFieldData, setNewFieldData] = useState();

  useEffect(
    () => {
      if (newFieldData) {
        onChange(newFieldData.fieldId);
      }
    },
    [newFieldData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  let error;
  if (shouldValidate) {
    if (value == null) {
      error = 'Missing Field';
    } else if (!options.find((option) => option.value === value)) {
      error = 'Invalid Field';
    }
  }

  function menuHeaderRenderer() {
    return (
      <Box px={3} onClick={() => setIsAddingField(true)}>
        <Text color="gray-900" variant="s-dense">
          Add new field
        </Text>
      </Box>
    );
  }

  const input = (
    <Select
      isClearable={false}
      error={error}
      id={id}
      options={options}
      placeholder={error || placeholder}
      value={value}
      width={selectWidths[width]}
      menuHeader={enableAddField ? menuHeaderRenderer() : undefined}
      onChange={onChange}
      filterOption={(option) => !blacklistValues.includes(option.value)}
      enablePortal={true}
    />
  );

  if (shouldShowLabel) {
    return (
      <FlexLayout sx={{ alignSelf: 'flex-start' }}>
        <InputLabel
          id="field-select"
          isRequired
          label="Field"
          labelRightContent={
            <Text color="gray-600" variant="2xs-dense-caps">
              {fieldTypeLabels[fields[value]?.type]}
            </Text>
          }
        >
          {input}
        </InputLabel>
        {isAddingField && (
          <FieldAssignmentMenu
            isVisible={isAddingField}
            trigger={<div />}
            onFieldAssign={(fieldAssignData) => {
              setNewFieldData(fieldAssignData);
              setIsAddingField(false);
            }}
            onHide={() => setIsAddingField(false)}
          />
        )}
      </FlexLayout>
    );
  }

  return input;
}

FieldSelect.propTypes = {
  blacklistValues: PT.arrayOf(PT.string.isRequired),
  enableAddField: PT.bool,
  value: PT.string,
  onChange: PT.func.isRequired,
  placeholder: PT.string,
};

const mapStateToProps = ({ builder, workflow }, { enableImplicitFields }) => ({
  fields: workflow.fields,
  options: getFieldOptions(workflow, enableImplicitFields),
  shouldValidate: builder.shouldValidate,
});

export default connect(mapStateToProps)(FieldSelect);
