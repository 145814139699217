import React from 'react';

import {
  AllIntegrations,
  ApiManagement,
  ManageSyncPair,
  ProcurementIntegrations,
  StorageIntegrations,
  ThirdPartyIntegrations,
} from '~/components/Admin/IntegrationPage';
import { PageLayout, Tabs } from '~/eds';
import { Webhooks } from '~/features/webhooks';
import { FlagType, useFlag } from '~/flags';
import { withCurrentUser } from '~/hocs';
import { useSearchParams } from '~/hooks';
import { generateNav, RoutePathType } from '~/routing';
import { featureFlagIncluded } from '~/utils/user';

const allTabs = {
  ALL: {
    label: 'All',
    query: 'all',
    value: 'ALL',
    component: <AllIntegrations />,
  },
  THIRD_PARTY: {
    label: '3rd Party Integrations',
    query: 'third-party',
    value: 'THIRD_PARTY',
    component: <ThirdPartyIntegrations />,
  },
  STORAGE: {
    label: 'Storage',
    query: 'storage',
    value: 'STORAGE',
    component: <StorageIntegrations />,
  },
  PROCUREMENT: {
    label: 'Procurement',
    query: 'procurement',
    value: 'PROCUREMENT',
    component: <ProcurementIntegrations />,
  },
  API_MANAGEMENT: {
    label: 'API Management',
    query: 'api-management',
    value: 'API_MANAGEMENT',
    component: <ApiManagement />,
  },
  WEBHOOKS: {
    label: 'Webhooks',
    query: 'webhooks',
    value: 'WEBHOOKS',
    component: <Webhooks />,
  },
};
function Page({ currentUser }) {
  const [tabValue, setTabValue] = useSearchParams('tab');
  const aribaIntegration = useFlag(FlagType.AribaIntegrationPhase1);
  const webhooksIntegration = useFlag(FlagType.Webhooks);
  if (!aribaIntegration) {
    delete allTabs.PROCUREMENT;
  }
  if (!webhooksIntegration) {
    delete allTabs.WEBHOOKS;
  }

  const tabs = Object.values(allTabs).filter(
    ({ featureFlag }) =>
      !featureFlag || featureFlagIncluded(currentUser, featureFlag),
  );

  const nav = generateNav({
    current: {
      text: 'Integrations',
      pathname: RoutePathType.AdminConsoleClientIntegrations,
    },
    from: RoutePathType.AdminConsoleClient,
    params: {
      clientId: currentUser.client,
    },
  });

  function getSelectedTab() {
    const activeTab = tabs.find((tab) => tab.query === tabValue);
    const firstTab = tabs[0];
    return activeTab?.value || firstTab?.value;
  }

  function setSelectedTab(tab) {
    const value = allTabs[tab].query;
    setTabValue(value);
    if (tab !== 'webhooks') {
      const url = new URL(window.location);
      url.searchParams.delete('secondaryTab');
      window.history.pushState({}, '', url);
    }
  }

  const selectedTab = getSelectedTab();

  return (
    <>
      <PageLayout title="Integrations" nav={nav}>
        <Tabs
          selectedTab={selectedTab}
          tabs={tabs}
          onSelectTab={setSelectedTab}
        />
      </PageLayout>
      {allTabs[selectedTab].component}
      <ManageSyncPair />
    </>
  );
}

export default withCurrentUser(Page);
