import React from 'react';
import { useDispatch } from 'react-redux';

import {
  AllIntegrations,
  ApiManagement,
  ManageSyncPair,
  ProcurementIntegrations,
  StorageIntegrations,
  ThirdPartyIntegrations,
} from '~/components/Admin/IntegrationPage';
import { PageLayout, Tabs } from '~/eds';
import { Webhooks } from '~/features/webhooks';
import { FlagType, useFlag } from '~/flags';
import { slices } from '~/redux';
import { useRouting } from '~/routing';

import DEPRECATED_Integrations from './DEPRECATED_index';

type TabKey =
  | 'all'
  | 'third_party'
  | 'storage'
  | 'procurement'
  | 'api_management'
  | 'webhooks';

const allTabs = {
  all: {
    label: 'All',
    value: 'all',
    component: <AllIntegrations />,
  },
  third_party: {
    label: '3rd Party Integrations',
    value: 'third_party',
    component: <ThirdPartyIntegrations />,
  },
  storage: {
    label: 'Storage',
    value: 'storage',
    component: <StorageIntegrations />,
  },
  procurement: {
    label: 'Procurement',
    value: 'procurement',
    component: <ProcurementIntegrations />,
  },
  api_management: {
    label: 'API Management',
    value: 'api_management',
    component: <ApiManagement />,
  },
  webhooks: {
    label: 'Webhooks',
    value: 'webhooks',
    component: <Webhooks />,
  },
};

const Page = () => {
  const dispatch = useDispatch();
  const enableNewIntegrationPage = useFlag(FlagType.NewIntegrationsPage);
  const { getSearchParams, setSearchParams } = useRouting();
  const searchParam = getSearchParams();

  const aribaIntegration = useFlag(FlagType.AribaIntegrationPhase1);
  const webhooksIntegration = useFlag(FlagType.Webhooks);

  const checkFeatureFlag = (tab: {
    label: string;
    value: string;
    component: React.ReactNode;
  }) => {
    switch (tab.value) {
      case 'webhooks':
        return webhooksIntegration;
      case 'procurement':
        return aribaIntegration;
      default:
        return true;
    }
  };

  if (!enableNewIntegrationPage) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <DEPRECATED_Integrations />;
  }

  const tabs = Object.values(allTabs)
    .map((tab) => ({
      ...tab,
      // Panel null is a workaround to avoid rendering the panel contents within the Tabs component
      panel: null,
      enabledTab: checkFeatureFlag(tab),
    }))
    .filter(({ enabledTab }) => enabledTab);

  const selectedTab = (searchParam.tab as TabKey) ?? 'all';

  return (
    <>
      <Tabs
        selectedTab={selectedTab}
        tabs={tabs}
        onSelectTab={(tab) => {
          setSearchParams({
            tab,
            secondaryTab: tab === 'webhooks' ? 'endpoints' : null,
          });
        }}
      />
      <PageLayout
        title="Integrations"
        actions={
          selectedTab === 'webhooks'
            ? [
                {
                  text: 'Add Endpoint',
                  level: 'primary',
                  onClick: () => {
                    dispatch(
                      slices.webhooks.actions.setActiveEndpointType('add'),
                    );
                  },
                },
              ]
            : []
        }
      >
        {allTabs[selectedTab].component}
        <ManageSyncPair />
      </PageLayout>
    </>
  );
};

export default Page;
