import React, { useState } from 'react';

import FolderPanel from '~/components/Shared/FolderPanel';
import {
  Box,
  Layout,
  Markdown,
  Panel,
  pluralize,
  types,
  useToast,
} from '~/eds';
import { BulkActionType } from '~/enums';
import { api } from '~/redux';
import { capitalize } from '~/utils/strings';

import { BaseBulkActionProps } from '../types';

interface Props extends BaseBulkActionProps {
  shouldDisableSyncPairedFolders: boolean;
  selectedCount: number;
  activePanel?: BulkActionType;
  onHide: () => void;
}
export const MoveCopyActionPanel = ({
  activePanel = 'move',
  isAllSelected = false,
  searchAPI,
  shouldDisableSyncPairedFolders,
  query = {},
  selectedCount,
  selectedDocumentIds = [],
  onActionCompleted,
  onHide,
}: Props) => {
  const isCopy = activePanel === 'copy';
  const apiName = isCopy ? 'searchV2BulkCopy' : 'searchV2BulkMove';

  const [bulkAction, { isLoading }] = api.endpoints[apiName].useMutation();
  const [selectedFolder, setSelectedFolder] = useState<
    types.Folder | undefined
  >();
  const { toast } = useToast();

  const onSaveClick = async () => {
    if (selectedFolder) {
      try {
        await bulkAction({
          destinationFolder: selectedFolder.id,
          isAllSelected,
          searchAPI,
          searchQuery: query,
          documentHandlerIds: selectedDocumentIds,
        }).unwrap();
        toast({
          status: 'success',
          message: `${selectedCount} items have been ${
            isCopy ? 'copied' : 'moved'
          } to ${selectedFolder?.path}`,
        });
        onActionCompleted?.();
      } catch {
        toast({
          status: 'danger',
          message: `An error occurred while ${
            isCopy ? 'copying' : 'moving'
          } the document(s).`,
        });
      }
    }
  };

  const footer = {
    actions: [
      {
        text: capitalize(activePanel),
        level: 'primary' as const,
        onClick: onSaveClick,
        disabled: !selectedFolder,
        isLoading,
      },
    ],
  };
  return (
    <Panel
      position="right"
      enableBackdrop
      width="m"
      hidden={{ isHidden: false, onHide }}
      title={isCopy ? 'Copy to Folder' : 'Move to Folder'}
      footer={footer}
    >
      <Layout direction="column" spacing={2}>
        <Box px={4}>
          <Markdown
            text={`Choose a folder to ${activePanel} the **${selectedCount}** ${pluralize(
              selectedCount,
              'selected document',
              {
                enableCount: false,
              },
            )} and ${selectedCount > 1 ? 'their' : 'its'} duplicates`}
          />
        </Box>
        <Box w="100%" h="100%">
          <FolderPanel
            selectedFolder={selectedFolder}
            onSelect={setSelectedFolder}
            folderPathId={selectedFolder?.id as number}
            isRootFolder
            disableSyncPaired={shouldDisableSyncPairedFolders}
          />
        </Box>
      </Layout>
    </Panel>
  );
};
