import { camelizeKeys, decamelizeKeys } from 'humps';
import uuid from 'uuid';

import { storageIntegrations } from '~/constants/integrations';
import {
  AribaIntegrationType,
  StorageProviderType,
  SyncPairStatusType,
} from '~/enums';
import { pilot } from '~/services';
import { AribaDataField, Uuid } from '~/types';
import { sortByDateValue, sortByStringValue } from '~/utils/array';

interface TokenId {
  id: Uuid;
}

interface S3ExternalId {
  externalId?: string;
}

interface Token {
  provider: StorageProviderType;
  state: string;
  code: string;
}

interface GetAuthenticateRequest {
  id?: string;
  provider?: StorageProviderType;
  returnTo: string;
}

interface SyncPairEditData {
  id: string;
  allowedSyncFileTypes: string[];
  folderId: number | string;
  sinceDate?: number;
  syncType?: string;
}

interface SyncPairData {
  allowedSyncFileTypes: string[];
  folderId: number | string;
  provider: StorageProviderType;
  providerFolderPath: string;
  providerOptions?: ProviderOptions | AribaProviderOptions;
  providerPrefix?: string;
  tokenId?: string;
  skipEmptyFolders?: boolean;
  syncType?: string;
  mapping?: object[];
}

interface CredentialsData {
  location: string;
  oauthClientId: string;
  oauthClientSecret: string;
  apiType: string;
  version: string;
}

interface SyncPair extends SyncPairData {
  id: string;
  evisyncPending: boolean;
  evisortFolderPath: string;
  fieldMappingFilePath: string;
  status: SyncPairStatusType;
  dateCreated: string;
  dateUpdated: string;
  taskDiscovery?: {
    enabled?: boolean;
    approverId?: string;
    passwordAdapter?: string;
  };
}

interface RemoteFolderRequest {
  path: string;
  provider: StorageProviderType;
  providerOptions?: ProviderOptions;
  providerPrefix?: string;
  tokenId: string;
  search?: string;
}

enum ProviderOptionStepType {
  Drive = 'Drive',
  Site = 'Site',
}

type ProviderOptions = Record<string, string>;

interface AribaProviderAuthRequest {
  location?: string;
  clientSecret: string;
  clientSecretId: string;
  apiType?: string;
}

interface AribaMultiTokenRequest {
  credentials: AribaProviderAuthRequest[];
  location: string;
  realm: string;
}

interface AribaMetadataRequest {
  sinceDate?: Date;
  syncPair: string;
}

interface AribaTokenUpdateRequest {
  id: string;
  tokens: CredentialsData[];
  realm: string;
  location: string;
}

interface AribaProviderOptions {
  file?: File;
  location: string;
  realm: string;
  apiKey: string;
  updatedBy?: number;
}

interface ProviderOptionsChoice {
  name: string;
  syncPaired: boolean;
  providerOptions: ProviderOptions;
}

interface ProviderOptionsRequest {
  provider: StorageProviderType;
  providerOptions: ProviderOptions;
  tokenId: string;
  search?: string;
}

interface ProviderOptionsResponse {
  steps?: ProviderOptionStepType[];
  baseProviderOptions?: ProviderOptions;
  nextStep: {
    name: string;
    choices: ProviderOptionsChoice[];
  };
}

interface ErrorsLog {
  count: number;
  results: object[];
}

enum SyncFolderType {
  Drive = 'drive',
  Internal = 'internal',
  External = 'external',
}

interface SyncFolder {
  id: string;
  name: string;
  editable?: boolean;
  syncPaired?: boolean;
  childPaired?: boolean;
  path?: string;
  providerOptions?: ProviderOptions;
  providerPrefix?: string;
  type?: SyncFolderType;
  children?: SyncFolder[];
  disabled?: boolean;
  expandable?: boolean;
  tooltip?: string;
}

const FOLDER_SYNC_PAIRED_TOOLTIP =
  'Folder is already part of another sync pair';
const FOLDER_NOT_ALLOWED_TOOLTIP =
  'Root is not allowed for sync. Choose a subfolder.';

const CONVERT_KEYS_EXCLUDE = ['_folder_id'];

const humpsEvisyncProcessor = (key: string, convert: any) =>
  CONVERT_KEYS_EXCLUDE.includes(key) ? key : convert(key);

const getSyncPairData = (syncPair: SyncPair): SyncPairData => {
  const {
    allowedSyncFileTypes,
    folderId,
    provider,
    providerFolderPath,
    providerOptions,
    providerPrefix,
    tokenId,
    skipEmptyFolders,
  } = syncPair;

  return {
    allowedSyncFileTypes,
    folderId,
    provider,
    providerFolderPath,
    providerOptions,
    providerPrefix,
    tokenId,
    skipEmptyFolders,
  };
};

const redirectToStorageTab = (returnTo: string) => {
  const url = new URL(returnTo);
  const params = new URLSearchParams(url.search);

  params.set('tab', 'storage');
  return `${url.origin}${url.pathname}?${params.toString()}`;
};

const testIsLastProviderOptionsStep = (
  response: ProviderOptionsResponse,
): boolean => {
  const { nextStep, steps } = response;
  if (!steps) {
    return true;
  }
  return steps[steps.length - 1] === nextStep.name;
};

const testIsAwsS3 = (provider: StorageProviderType): boolean => {
  return provider === StorageProviderType.AmazonS3;
};

const transformSyncFolder = (
  folder: SyncFolder,
  type: SyncFolderType,
  providerOptions?: ProviderOptions,
  providerPrefix?: string,
): SyncFolder => {
  const { id, children, name, syncPaired, childPaired, path = '' } = folder;
  const folderChildren = (children || [])
    .map((f) => transformSyncFolder(f, type))
    .sort(sortByStringValue('name'));

  // create a uuid for non-internal folders
  const isParentOfSyncPairs =
    childPaired || folderChildren.some((c) => c.disabled);
  const disabled = isParentOfSyncPairs || syncPaired;

  return {
    id: id || uuid.v4(),
    children: folderChildren,
    name,
    path,
    providerPrefix,
    type,
    disabled,
    expandable: !syncPaired,
    tooltip: disabled ? FOLDER_SYNC_PAIRED_TOOLTIP : undefined,
    providerOptions,
  };
};

const transformSyncDrives = (
  response: ProviderOptionsResponse,
  providerPrefix = '',
): SyncFolder[] => {
  const { choices = [] } = response.nextStep;

  const isLastStep = testIsLastProviderOptionsStep(response);
  return choices.map(({ name, syncPaired, providerOptions }) => ({
    disabled: !isLastStep || syncPaired,
    id: uuid.v4(),
    name,
    path: '/',
    providerPrefix: `${providerPrefix}/${name}`,
    type: isLastStep ? SyncFolderType.External : SyncFolderType.Drive,
    children: [],
    tooltip: syncPaired ? FOLDER_SYNC_PAIRED_TOOLTIP : undefined,
    providerOptions,
  }));
};

export const authenticate = async ({
  provider,
  returnTo,
}: GetAuthenticateRequest): Promise<string> => {
  return await pilot.get(
    `/sync-pair/authenticate/${provider}/?return_to=${redirectToStorageTab(
      returnTo,
    )}`,
  );
};

export const reauthenticate = async ({
  id,
  returnTo,
}: GetAuthenticateRequest): Promise<string> => {
  return await pilot.get(
    `/sync-pair/${id}/authenticate/?return_to=${redirectToStorageTab(
      returnTo,
    )}`,
  );
};

export const getToken = async ({ provider, state, code }: Token) => {
  return await pilot.post(
    `/sync-pair/token/${provider}/`,
    decamelizeKeys({ state, code }),
  );
};

export const getSyncPairs = async ({
  clientId,
}: {
  clientId?: string;
}): Promise<SyncPair[]> => {
  const parameters = clientId ? `?client_id=${clientId}` : '';
  const response = await pilot.get(`/sync-pair/${parameters}`);

  return camelizeKeys(response, humpsEvisyncProcessor) as SyncPair[];
};

export const getSyncPairsAriba = async ({
  clientId,
}: {
  clientId?: string;
}): Promise<SyncPair[]> => {
  const data = clientId ? `?client_id=${clientId}` : '';
  const response = await pilot.get(`/sync-pair/ariba/${data}`);
  const syncpairs = camelizeKeys(response, humpsEvisyncProcessor) as SyncPair[];
  return syncpairs.sort(sortByDateValue('dateCreated', 'asc'));
};

export const getSyncPair = async (id: string): Promise<SyncPair> => {
  const response = await pilot.get(`/sync-pair/${id}/`);
  return camelizeKeys(response, humpsEvisyncProcessor) as SyncPair;
};

export const createSyncPair = async (syncPair: SyncPair): Promise<SyncPair> => {
  const response = await pilot.post(
    '/sync-pair/',
    decamelizeKeys(getSyncPairData(syncPair)),
  );
  return camelizeKeys(response, humpsEvisyncProcessor) as SyncPair;
};

export const aribaPasswordAdapterChoices = [
  {
    value: 'PasswordAdapter1',
    label: 'Enterprise User',
    isDefault: false,
  },
  {
    value: 'ThirdPartyUser',
    label: 'Third Party User',
    isDefault: true,
  },
] as const;

export const createAribaSyncPair = async (
  syncPair: SyncPair,
): Promise<SyncPair> => {
  const formData = new FormData();
  formData.append(
    'allowed_sync_file_types',
    syncPair.allowedSyncFileTypes.toString(),
  );
  formData.append('folder_id', syncPair.folderId.toString());
  if (syncPair.providerOptions?.file) {
    formData.append('file', syncPair.providerOptions.file);
  }
  formData.append('provider_options', JSON.stringify(syncPair.providerOptions));
  formData.append('mapping', JSON.stringify(syncPair.mapping));
  formData.append('field_mapping_file_path', syncPair.fieldMappingFilePath);
  formData.append(
    'sync_type',
    syncPair.syncType ?? AribaIntegrationType.ONE_TIME,
  );
  // so far, extra context can only contain task discovery details.
  const extraContext: { taskDiscoveryDetails?: object } = {};
  if (syncPair.taskDiscovery?.enabled) {
    const taskDiscoveryDetails = {
      approver: {
        id: syncPair.taskDiscovery?.approverId,
        passwordAdapter: syncPair.taskDiscovery?.passwordAdapter,
      },
    };
    extraContext.taskDiscoveryDetails = taskDiscoveryDetails;
  }
  formData.append('extra_context', JSON.stringify(extraContext));

  const response = await pilot.patch(
    `/sync-pair/ariba/${syncPair.id}/`,
    formData,
  );
  return camelizeKeys(response, humpsEvisyncProcessor) as SyncPair;
};

export const updateSyncPair = async (syncPair: SyncPair): Promise<SyncPair> => {
  await pilot.put(
    `/sync-pair/${syncPair.id}/`,
    decamelizeKeys(getSyncPairData(syncPair)),
  );

  return getSyncPair(syncPair.id);
};

export const connectSyncPair = async (
  syncPair: SyncPair,
): Promise<SyncPair> => {
  await pilot.patch(`/sync-pair/${syncPair.id}/`);

  return getSyncPair(syncPair.id);
};

export const deleteSyncPair = async (
  id: string,
  clientId: string,
): Promise<void> => {
  return await pilot.remove(`/sync-pair/${id}/?client_id=${clientId}`);
};

export const getRemoteFolders = async (
  request: RemoteFolderRequest,
): Promise<SyncFolder[]> => {
  const {
    provider,
    providerOptions = {},
    providerPrefix = '',
    path = '',
    tokenId = '',
    search = '*',
  } = request;
  const params = decamelizeKeys({ ...providerOptions, tokenId });

  if (
    provider === StorageProviderType.SharePoint &&
    !providerOptions?.driveId
  ) {
    const providerOptionsRequest = {
      provider,
      providerOptions,
      tokenId,
      search,
    };
    const response = await getProviderOptions(providerOptionsRequest);
    return transformSyncDrives(response);
  }

  const fetchUrl = `/evisync/remote-path/${provider}${path ? path : '/'}`;
  const response = await pilot.get(fetchUrl, { params });

  return (camelizeKeys(
    response,
    humpsEvisyncProcessor,
  ) as SyncFolder[]).map((folder) =>
    transformSyncFolder(
      folder,
      SyncFolderType.External,
      providerOptions,
      providerPrefix,
    ),
  );
};

export const getProviderOptions = async (
  request: ProviderOptionsRequest,
): Promise<ProviderOptionsResponse> => {
  const { provider, providerOptions, tokenId = '', search = '*' } = request;
  const params = decamelizeKeys({ ...providerOptions, tokenId, search });
  const response = await pilot.get(`/evisync/provider-options/${provider}`, {
    params,
  });
  return camelizeKeys(
    response,
    humpsEvisyncProcessor,
  ) as ProviderOptionsResponse;
};

/**
 * Attempts to fetch remote sites/drives for a specified provider if available.
 *
 * Calls getProviderOptions recursively and builds the initial folder tree of sites/drives
 */
export const getRemoteDrives = async (
  request: RemoteFolderRequest,
): Promise<SyncFolder[]> => {
  const { provider, tokenId, search } = request;
  const providerOptionsRequest = {
    provider,
    providerOptions: {},
    tokenId,
    search,
  };
  const response = await getProviderOptions(providerOptionsRequest);
  return transformSyncDrives(response);
};

/**
 *  Light wrapper around getRemoteFolders to determine if drives should be fetched by first checking with getRemoteDrives
 */
export const getProviderFolders = async (
  request: RemoteFolderRequest,
): Promise<SyncFolder[]> => {
  const drives = await getRemoteDrives(request);

  if (drives.length > 0) {
    return drives;
  }

  const { provider } = request;

  const folders = await getRemoteFolders(request);
  const disabled = folders.some((f) => f.disabled) || testIsAwsS3(provider);
  const tooltipMessage = testIsAwsS3(provider)
    ? FOLDER_NOT_ALLOWED_TOOLTIP
    : FOLDER_SYNC_PAIRED_TOOLTIP;

  const rootFolder = {
    id: uuid.v4(),
    name: storageIntegrations[provider].name,
    path: '/',
    type: SyncFolderType.External,
    children: folders,
    expandable: true,
    disabled,
    tooltip: disabled ? tooltipMessage : undefined,
  };

  return [rootFolder];
};

export const getInternalFolders = async (): Promise<SyncFolder[]> => {
  const response: object = await pilot.get('/path/');
  return [
    transformSyncFolder(
      camelizeKeys(response, humpsEvisyncProcessor) as SyncFolder,
      SyncFolderType.Internal,
    ),
  ];
};

export const getExternalId = async ({
  clientId,
}: {
  clientId: string;
}): Promise<S3ExternalId> => {
  const response = await pilot.get(`/evisync/external-id/${clientId}/`);

  return camelizeKeys(response, humpsEvisyncProcessor) as S3ExternalId;
};

export const createS3TokenId = async ({
  roleArn,
}: {
  roleArn: string;
}): Promise<TokenId> => {
  const response = await pilot.post(
    '/sync-pair/awstoken/',
    decamelizeKeys({ roleArn }),
  );
  return camelizeKeys(response, humpsEvisyncProcessor) as TokenId;
};

export const authenticateAriba = async (
  providerOptions: AribaProviderAuthRequest,
): Promise<TokenId> => {
  const { location, clientSecret, clientSecretId, apiType } = providerOptions;
  const params = decamelizeKeys({
    location,
    apiType,
    oauthClientId: clientSecretId,
    oauthClientSecret: clientSecret,
  });
  const response = await pilot.post('/sync-pair/aribatoken/', params);
  return camelizeKeys(response, humpsEvisyncProcessor) as TokenId;
};

export const authenticateMultiTokensAriba = ({
  credentials,
  realm,
  location,
}: AribaMultiTokenRequest): Promise<object> => {
  const params = {
    credentials: credentials.map((f) => decamelizeKeys(f)),
    realm,
    location,
  };
  return pilot.post('/sync-pair/aribatoken/multi', params);
};

export const getAribaLocations = async (): Promise<object> => {
  const response: object = await pilot.get('/sync-pair/ariba/regions');
  return response;
};

export const getAribaWorkspaceCount = async ({
  sinceDate,
  syncPair,
}: AribaMetadataRequest): Promise<object> => {
  const sinceTimestamp = sinceDate?.getTime();
  const params = {
    since_date: sinceTimestamp,
    sync_pair: syncPair,
  };
  const response = await pilot.get('/sync-pair/ariba/metadata/count', {
    params,
  });
  return response;
};

export const getAribaFields = async (syncPair: string) => {
  const params = {
    sync_pair: syncPair,
  };
  const response = await pilot.get('/sync-pair/ariba/metadata/fields', {
    params,
  });
  return camelizeKeys(response) as {
    workspace: AribaDataField[];
    document: AribaDataField[];
  };
};

export const updateAribaSyncPair = async (
  syncPair: SyncPairEditData,
): Promise<object> => {
  const syncPairData = decamelizeKeys(syncPair);
  const response = await pilot.patch(
    `/sync-pair/ariba/${syncPair.id}/`,
    syncPairData,
  );
  return response;
};

export const updateAribaFileSyncPair = async ({
  file,
  syncPairId,
}: {
  file: File;
  syncPairId: string;
}): Promise<object> => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await pilot.patch(
    `/sync-pair/ariba/${syncPairId}/update-cw`,
    formData,
  );
  return response;
};

export const upddateTokensSyncPair = async ({
  id,
  tokens,
  realm,
  location,
}: AribaTokenUpdateRequest): Promise<object> => {
  const credentialsData = {
    credentials: tokens,
    realm,
    location,
  };
  const response = await pilot.patch(
    `/sync-pair/ariba/reauth/${id}/`,
    decamelizeKeys(credentialsData),
  );
  return response;
};

export const validateFieldMappingCsv = async ({
  file,
  syncPair,
}: {
  file: File;
  syncPair: string;
}): Promise<object> => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await pilot.post(
    `/sync-pair/field-mapping/${syncPair}/validate/`,
    formData,
  );
  return response;
};

export const processFieldMappingCsv = async ({
  file,
  syncPair,
}: {
  file: File;
  syncPair: string;
}): Promise<object> => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await pilot.post(
    `/sync-pair/field-mapping/${syncPair}/process/`,
    formData,
  );
  return response;
};

export const validateAribaDocumentIdFile = async ({
  file,
}: {
  file: File;
}): Promise<object> => {
  const formData = new FormData();
  formData.append('file', file);
  return await pilot.post(`/sync-pair/ariba/validate-cw/`, formData);
};

export const validateRealm = async ({
  realm,
  region,
}: {
  realm: string;
  region: string;
}): Promise<object> => {
  const response = await pilot.post(`/sync-pair/ariba/validate-realm/`, {
    realm,
    region,
  });
  return response;
};

export const getSyncPairsAribaFailures = async ({
  syncPair,
}: {
  syncPair: string;
}): Promise<ErrorsLog> => {
  const response = await pilot.get(
    `/sync-pair/ariba/sync-failures/${syncPair}`,
  );
  return camelizeKeys(response) as {
    count: number;
    results: object[];
  };
};
