import { endOfDay, subDays } from 'date-fns';

import { Field } from '~/components/Shared/Filters_DEPRECATED/types';
import { Filters } from '~/evifields';
import { Nullable } from '~/types';

import { statusTypeOptions } from './constants';
import {
  Attempt,
  AttemptStatus,
  ConfigurationType,
  Endpoint,
  EndpointStatus,
  EventList,
  EventsType,
  FieldProps,
  SamplePayloadsType,
} from './types';

export const testIsActiveStatus = (status: EndpointStatus) =>
  status === 'active';

export const getStatusChip = (status: EndpointStatus | AttemptStatus) => {
  switch (status) {
    case 'active':
      return { text: 'Active', status: 'success' };
    case 'suspended':
      return { text: 'Suspended', status: 'danger' };
    case 'failed':
      return { text: 'Failed', status: 'danger' };
    case 'success':
      return { text: 'Success', status: 'success' };
    case 'pending':
      return { text: 'Pending', status: 'info' };
    default:
      return { text: 'Unknown', status: 'info' };
  }
};

export const getDisabledTooltip = (
  configuration: Nullable<ConfigurationType>,
) => {
  if (configuration) {
    if (!configuration?.url) {
      return 'Please provide a valid URL';
    } else if (Boolean(checkValidUrl(configuration?.url))) {
      return checkValidUrl(configuration?.url);
    } else if (Object.keys(configuration?.events).length === 0) {
      return 'Select at least one event';
    }
  }
};

export const getDisabledCopyTooltip = ({
  sampleEvent,
  samplePayloads,
}: {
  sampleEvent?: Nullable<string>;
  samplePayloads?: SamplePayloadsType;
}) => {
  return !sampleEvent
    ? 'Select an event to copy'
    : getEventSamplePayload(sampleEvent, samplePayloads) === null
    ? 'This event type does not have a sample payload. Please try another event type.'
    : 'Copy';
};

export const getAttemptsDisabledTooltip = (
  isLoadingExportAttempts: boolean,
) => {
  return isLoadingExportAttempts ? 'Exporting attempts...' : 'Export Excel';
};

export const transformToStringArray = (events: EventsType) => [
  ...Object.keys(events).reduce<string[]>((acc, key) => {
    return [...acc, ...events[key]];
  }, []),
];

export const checkActiveEndpointEvents = ({
  activeEndpoint,
  eventGroups,
}: {
  activeEndpoint: Endpoint;
  eventGroups: EventList[];
}) => {
  const updatedEventGroups = eventGroups.map((group) => ({
    ...group,
    options: group.options.map((option) => ({
      ...option,
      checked: activeEndpoint.events.includes(option.value) ?? false,
    })),
  }));

  const transformEvents = updatedEventGroups.reduce((acc, group) => {
    acc[group.name] = group.options
      .filter((option) => option.checked === true)
      .map((option) => option.value);
    return acc;
  }, {} as EventsType);

  return transformEvents;
};

export const getFields = ({
  eventGroups,
  endpoints,
}: FieldProps): Record<string, Field> => {
  const today = new Date();
  const max = endOfDay(today);
  const min = subDays(today, 30);

  return {
    endpointUrl: {
      id: 'endpointUrl',
      label: 'Endpoint URL',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        options: endpoints.map(({ endpointUrl, id }) => ({
          label: endpointUrl,
          value: id,
        })),
      },
    },
    eventType: {
      id: 'eventType',
      label: 'Event Type',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        options: eventGroups,
      },
    },
    dateDelivered: {
      id: 'dateDelivered',
      label: 'Date Sent',
      type: 'date',
      whitelistOperatorIds: [
        'date_in_the_last',
        'date_on',
        'date_between',
        'date_after',
        'date_before',
      ],
      settings: {
        max,
        min,
        getDayTooltip: (date: Date) => {
          if (date > max) {
            return 'You can not select a future date';
          } else if (date < min) {
            return 'You can not go back more than 30 days';
          }
        },
      },
    },
    status: {
      id: 'status',
      label: 'Status',
      type: 'enum_set',
      whitelistOperatorIds: ['contains_any'],
      settings: {
        options: statusTypeOptions,
      },
    },
  };
};
export const getEventSamplePayload = (
  event?: Nullable<string>,
  samplePayloads?: SamplePayloadsType,
) => {
  if (
    !event ||
    !samplePayloads ||
    !samplePayloads[event as keyof typeof samplePayloads]
  ) {
    return null;
  }

  const payload = samplePayloads[event as keyof typeof samplePayloads];
  return JSON.stringify(payload, null, 2);
};

export const getUpdatedFilters = ({
  filters,
  endpoint,
}: {
  filters: Filters;
  endpoint: Endpoint;
}) => {
  return filters.map((filter) => {
    if (filter.id === 'endpointUrl') {
      return {
        ...filter,
        values: [endpoint.id],
        label: endpoint.endpointUrl,
      };
    }
    if (filter.id === 'dateDelivered') {
      return {
        ...filter,
        values: [
          {
            unit: 'days',
            value: 29,
          },
        ],
      };
    }
    return filter;
  });
};
export const getStringifyValue = (value?: Record<string, any>) => {
  if (!value) {
    return '';
  }
  return JSON.stringify(value, null, 2);
};

export const checkValidUrl = (url: string) => {
  if (url && !url.startsWith('https://')) {
    return `Invalid URL, please make sure it starts with https://`;
  } else if (url.startsWith('https://') && url.length < 9) {
    return `Invalid URL. Please provide a complete url to make a valid request`;
  } else {
    return undefined;
  }
};

export const getResponseStatus = (attempt: Attempt) => {
  let text;
  if (attempt.status === 'failed') {
    if (attempt.statusDescription) {
      text = attempt.statusDescription;
    } else {
      text = '-';
    }
  } else {
    if (attempt.responseStatus) {
      text = attempt.responseStatus;
    } else {
      text = '-';
    }
  }
  return text;
};
