import React, { useCallback, useEffect, useState } from 'react';

import { trackSegment } from '~/components/SegmentAnalytics';
import {
  Box,
  Button,
  ContentContainer,
  Icon,
  Layout,
  Menu,
  Modal,
  Panel,
  SearchInput,
  Text,
  TextInput,
  types,
  useToast,
} from '~/eds';
import { TableViewContextType } from '~/enums';
import { api } from '~/redux';
import { TableView } from '~/redux/api/methods';
import { Nullable } from '~/types';
import { MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH } from '~/types/modal.types';

interface Props {
  /** if undefined, the default context is Documents table. */
  context: TableViewContextType | undefined;
  modalType: string;
  hideModal: () => void;
  onViewSelected: (id: types.PilotId) => void;
}

const BaseLoadView = ({
  context,
  modalType,
  hideModal,
  onViewSelected,
}: Props) => {
  const [search, setSearch] = useState<Nullable<string>>('');
  const [newName, setNewName] = useState<Nullable<string>>('');
  const [selected, setSelected] = useState<Nullable<TableView>>();
  const { toast } = useToast();

  const {
    data: tableViews,
    isLoading: isLoadingTableViews,
  } = api.endpoints.getTableViews.useQuery({
    context,
  });

  const [
    loadTableView,
    loadTableViewResult,
  ] = api.endpoints.loadTableView.useMutation();
  const {
    data: loadTableResponseData,
    isError: loadTableError,
    isSuccess: loadTableSuccess,
  } = loadTableViewResult;

  const [
    loadDefaultTableView,
    loadDefaultTableViewResult,
  ] = api.endpoints.loadDefaultTableView.useMutation();
  const {
    isError: loadDefaultTableViewError,
    isSuccess: loadDefaultTableViewSuccess,
    data: loadDefaultTableViewData,
  } = loadDefaultTableViewResult;

  const [
    renameTableView,
    renameResult,
  ] = api.endpoints.renameTableView.useMutation();
  const { isLoading: isRenaming } = renameResult;

  const [
    deleteTableView,
    deleteResult,
  ] = api.endpoints.deleteTableView.useMutation();
  const { isLoading: isDeleting } = deleteResult;

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [renameVisible, setRenameVisible] = useState(false);

  // adds the context to segment.
  const segmentFeature = context ? context.toLowerCase() : 'documents page';

  useEffect(() => {
    if (loadTableSuccess && loadTableResponseData) {
      onViewSelected(loadTableResponseData.id);
      trackSegment(
        `New view selected for ${segmentFeature}`,
        loadTableResponseData,
      );
      hideModal();
    }
  }, [loadTableSuccess]);

  useEffect(() => {
    if (loadDefaultTableViewSuccess) {
      onViewSelected('DEFAULT');
      trackSegment(
        `Default view selected for ${segmentFeature}`,
        loadDefaultTableViewData,
      );
      hideModal();
    }
  }, [loadDefaultTableViewSuccess]);

  useEffect(() => {
    if (loadTableError) {
      toast({
        message: 'Error on loading view. Please try again.',
        status: 'danger',
      });
      hideModal();
    }
  }, [loadTableError]);

  useEffect(() => {
    if (loadDefaultTableViewError) {
      toast({
        message: 'Error on restoring default view. Please try again.',
        status: 'danger',
      });
      hideModal();
    }
  }, [loadDefaultTableViewError]);

  const handleOpenEdit = (tableView: TableView) => {
    setSelected(tableView);
    setNewName(tableView.name);
    setRenameVisible(true);
  };
  const handleOpenDelete = (tableView: TableView) => {
    setSelected(tableView);
    setConfirmVisible(true);
  };

  const handleCloseRename = () => {
    setSelected(undefined);
    setNewName('');
    setRenameVisible(false);
  };
  const handleCloseDelete = () => {
    setSelected(undefined);
    setConfirmVisible(false);
  };

  const handleViewSelected = useCallback((id: number) => {
    loadTableView({ context, id });
  }, []);

  const handleDefaultViewSelected = useCallback(() => {
    loadDefaultTableView({ context });
  }, []);

  const handleRename = async () => {
    if (selected) {
      try {
        await renameTableView({
          id: selected.id,
          name: newName ?? '',
        }).unwrap();
        toast({
          status: 'success',
          message: 'View has been renamed successfully',
        });
      } catch (e) {
        toast({
          status: 'danger',
          message: 'Error renaming view',
        });
      }
    }
    setRenameVisible(false);
  };

  const handleDelete = async () => {
    if (selected) {
      try {
        await deleteTableView({ id: selected.id }).unwrap();
        toast({
          status: 'success',
          message: 'View has been deleted successfully',
        });
      } catch (e) {
        toast({
          status: 'danger',
          message: 'Error deleting view',
        });
      }
    }
    setConfirmVisible(false);
  };

  if (modalType !== MODAL_DOCUMENTS_COLUMN_FIELDS_VIEW_SWITCH) return null;

  const noResults = !isLoadingTableViews && !tableViews?.length;

  return (
    <Panel
      title="Saved Views"
      hidden={{
        onHide: hideModal,
      }}
      width="m"
      mode="floating"
      enableBackdrop
    >
      <ContentContainer loadingContent={{ isLoading: isLoadingTableViews }}>
        <Layout w="100%" direction="column" spacing={4}>
          <SearchInput
            name="search viewset"
            placeholder="Filter saved views…"
            value={search}
            onChange={setSearch}
          />

          <Layout direction="column">
            {'default view'.includes((search ?? '').toLowerCase()) && (
              <Layout
                align="center"
                justify="space-between"
                borderBottom="border"
                py={2}
              >
                <Text>Default View</Text>
                <Layout>
                  <Box mr={2}>
                    <Button
                      icon="search"
                      text="Apply"
                      onClick={handleDefaultViewSelected}
                    />
                  </Box>

                  <Menu
                    disabled
                    trigger={<Icon icon="more" tooltip="More" />}
                    name="More Options"
                    options={[]}
                  />
                </Layout>
              </Layout>
            )}

            {tableViews
              ?.filter((view) =>
                view.name.toLowerCase().includes((search ?? '').toLowerCase()),
              )
              ?.map((view) => (
                <Layout
                  key={`view-${view.id}`}
                  align="center"
                  justify="space-between"
                  borderBottom="border"
                  py={2}
                >
                  <Text>{view.name}</Text>
                  <Layout>
                    <Box mr={2}>
                      <Button
                        icon="search"
                        text="Apply"
                        onClick={() => handleViewSelected(view.id)}
                      />
                    </Box>

                    <Menu
                      trigger={<Icon icon="more" tooltip="More" />}
                      name="More Options"
                      options={[
                        {
                          label: 'Rename',
                          value: 'rename',
                          onClick: () => handleOpenEdit(view),
                        },
                        {
                          label: 'Delete',
                          value: 'delete',
                          onClick: () => handleOpenDelete(view),
                        },
                      ]}
                    />
                  </Layout>
                </Layout>
              ))}

            {noResults && (
              <Box mt={4}>
                <Text textAlign="center">
                  You currently have no saved views.
                </Text>
              </Box>
            )}
          </Layout>
        </Layout>
      </ContentContainer>

      <Modal
        isVisible={confirmVisible}
        title="Delete View?"
        onHide={handleCloseDelete}
        onCancel={handleCloseDelete}
        primaryAction={{
          disabled: isDeleting,
          text: 'Delete',
          onClick: handleDelete,
          level: 'danger',
        }}
      >
        <ContentContainer loadingContent={{ isLoading: isDeleting }}>
          <Text>
            Are you sure you want to delete{' '}
            <Text variant="body-bold">{selected?.name}</Text> ? You won’t be
            able to undo this action.
          </Text>
        </ContentContainer>
      </Modal>

      <Modal
        isVisible={renameVisible}
        title="Rename View"
        primaryAction={{
          text: 'Rename',
          icon: 'check',
          onClick: handleRename,
          disabled: isRenaming,
        }}
        onCancel={handleCloseRename}
        onHide={handleCloseRename}
      >
        <ContentContainer loadingContent={{ isLoading: isRenaming }}>
          <TextInput
            placeholder="View Name"
            onChange={setNewName}
            name="New name"
            value={newName}
          />
        </ContentContainer>
      </Modal>
    </Panel>
  );
};

export default BaseLoadView;
