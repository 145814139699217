import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackSegment } from '~/components/SegmentAnalytics';
import Filters from '~/components/Shared/Filters';
import { Layout } from '~/eds';
import { attachRenderersAndSerializers } from '~/features/advanced-search/filters';
import { useCurrentUser } from '~/hooks';
import { api, selectors } from '~/redux';
import { DashboardEntityType } from '~/redux/api/methods';
import dashboardV2Slice from '~/redux/slices/dashboardV2';

type Props = {
  entityType?: DashboardEntityType;
};

export const DashboardV3Filters = ({ entityType }: Props) => {
  const { data: searchFilters } = api.endpoints.getFiltersV2.useQuery(
    { type: entityType! },
    { skip: !entityType },
  );
  const { data: sections } = api.endpoints.getFilterSections.useQuery(
    undefined,
  );

  const groupsOrder = useMemo(
    () => sections?.fieldGroups.map((group) => group.id),
    [sections?.fieldGroups],
  );

  const dispatch = useDispatch();
  const fieldValues = useSelector(selectors.selectDashboardFieldValues);
  const selectedFilters = useSelector(selectors.selectDashboardSelectedFilters);
  const crossFilters = useSelector(selectors.selectDashboardCrossFilters);
  const user = useCurrentUser();

  const fieldsWithLazyLoad = useMemo(
    () =>
      attachRenderersAndSerializers({
        filters: Object.values(searchFilters || {}),
        fieldValues,
        type: entityType,
      }),
    [searchFilters, fieldValues],
  );

  const removeCrossFilters = () => {
    trackSegment('clearFilters', {
      user_id: user.id,
      client_id: user.client,
    });
    dispatch(dashboardV2Slice.actions.setCrossFilters([]));
  };

  return (
    <>
      <Layout direction="row">
        <Layout wrap spacing={1} wrapSpacing={1} minW={0}>
          <Filters
            searchFilters={fieldsWithLazyLoad}
            filters={selectedFilters}
            groupsOrder={groupsOrder || []}
            onChange={(filters) => {
              dispatch(dashboardV2Slice.actions.setSelectedFilters(filters));
              dispatch(dashboardV2Slice.actions.setPage(1));
              dispatch(dashboardV2Slice.actions.setDashboardDirty(true));
            }}
            crossFilters={crossFilters}
            onChangeCrossFilters={(newFilters) => {
              dispatch(dashboardV2Slice.actions.setCrossFilters(newFilters));
            }}
            onClearFilters={removeCrossFilters}
          />
        </Layout>
      </Layout>
    </>
  );
};
