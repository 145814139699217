import { flatMap, uniq } from 'lodash';
import { useSelector } from 'react-redux';

import { Tree, types } from '~/eds';
import { slices } from '~/redux';

export const ModelCart = () => {
  const models = useSelector(slices.xRayLibrary.selectors.selectCart);

  const categories = uniq(flatMap(models.map((model) => model.tags)));

  const tree = categories.map((category) => ({
    children: models
      .filter((model) => model.tags.includes(category))
      .map((model) => ({
        key: model.libraryModelId,
        name: model.label,
      })) as types.TreeNode[],
    key: category.label,
    name: category.label,
  }));

  return (
    <Tree
      emptyContent="TODO: Selected Models"
      tree={tree}
      title="Selected Models"
    />
  );
};
