import { TableViewContextType } from '~/enums';
import { pilot } from '~/services';
import { PilotId } from '~/types';

// TBD BY EKP-6205
interface TableViewField {
  field_id: number | string;
  label: string;
  column_number: number;
}

export type TableViewSet = Array<TableViewField>;
export type CreateTableViewSet = Array<Omit<TableViewField, 'label'>>;
export interface TableView {
  id: PilotId;
  name: string;
}

type ViewSetType = 'FOLDER' | 'SEARCH' | 'GROUP' | 'EVISEARCH_SEARCH';
interface ViewSetParams {
  /** undefined is the default view set in Documents page. */
  context: ViewSetType | undefined;
}

interface CreateTableViewData {
  /** undefined is the default view set in Documents page. */
  context: TableViewContextType | undefined;
  name: string;
  fields: CreateTableViewSet;
}

interface UpdateTableViewData {
  /** undefined is the default view set in Documents page. */
  context: TableViewContextType | undefined;
  fields: CreateTableViewSet;
}

interface GetTableViewData {
  context?: TableViewContextType;
  id: number;
}

export const getCurrentTableView = async ({
  context,
}: ViewSetParams): Promise<TableViewSet> => {
  return pilot.get('viewset/current/', { params: { context } });
};

export const getTableViews = async ({
  context,
}: ViewSetParams): Promise<TableView[]> => {
  return pilot.get('viewset/', { params: { context } });
};

export const createTableView = ({
  context,
  name,
  fields,
}: CreateTableViewData): Promise<TableView> => {
  return pilot.post('viewset/', { name, fields }, { params: { context } });
};

export const updateTableView = ({
  context,
  fields,
}: UpdateTableViewData): Promise<void> => {
  return pilot.put('viewset/', fields, { params: { context } });
};

export const loadTableView = ({
  context,
  id,
}: GetTableViewData): Promise<TableView> => {
  return pilot.patch(`viewset/${id}/load/`, {}, { params: { context } });
};

export const loadDefaultTableView = ({
  context,
}: ViewSetParams): Promise<void> => {
  return pilot.patch('viewset/default/', {}, { params: { context } });
};

export const renameTableView = ({
  id,
  name,
}: GetTableViewData & { name: string }): Promise<void> => {
  return pilot.patch(`viewset/${id}/`, { new_name: name });
};

export const deleteTableView = ({ id }: GetTableViewData): Promise<void> => {
  return pilot.remove(`viewset/${id}`);
};

export const getDefaultTableView = ({
  context,
}: ViewSetParams): Promise<TableViewSet> => {
  return pilot.get('viewset/default/', { params: { context } });
};
