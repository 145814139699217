import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import injectSheet from 'react-jss';

import {
  createClientSectionData,
  createDataField,
  deleteClientSectionData,
  deleteDataField,
  getClientSectionsData,
  moveFieldToSection,
  reorderClientSectionsData,
  styles,
  updateClientSectionName,
  updateDataField,
  updateDataFieldOrder,
  updateSectionField,
} from '~/components/Admin/DataFieldsPage';
import ActionsMenu from '~/components/Shared/ActionsMenu';
import EcCard from '~/components/Shared/EcCard';
import EcCustomFieldTable from '~/components/Shared/EcCustomFieldTable';
import EcModal from '~/components/Shared/EcModal';
import { showToast } from '~/components/Shared/EcToast';
import { FormField, Modal, PageLayout, TextInput } from '~/eds';
import { FlagType, withFlags } from '~/flags';
import { withCurrentUser } from '~/hocs';
import { generateNav, RoutePathType, withRouting } from '~/routing';
import {
  MODAL_CUSTOM_FIELD,
  MODAL_CUSTOM_FIELD_MOVE,
  MODAL_DELETE,
  MODAL_NAME,
  MODAL_REORDER_SECTIONS,
} from '~/types/modal.types';
import { ERROR, SUCCESS } from '~/types/toast.types';

const contentStyles = { display: 'flex', padding: '16px' };

class Page extends Component {
  constructor(props) {
    super(props);

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleSaveSectionsReoorderChange = this.handleSaveSectionsReoorderChange.bind(
      this,
    );
    this.onSectionCreate = this.onSectionCreate.bind(this);
    this.onSectionRename = this.onSectionRename.bind(this);
    this.onSectionDelete = this.onSectionDelete.bind(this);
    this.onSectionMove = this.onSectionMove.bind(this);
    this.onFieldDelete = this.onFieldDelete.bind(this);
    this.onDataFieldCreate = this.onDataFieldCreate.bind(this);
    this.onDataFieldEdit = this.onDataFieldEdit.bind(this);
    this.handleFieldReorder = this.handleFieldReorder.bind(this);

    this.state = {
      dataLoaded: false,
      allSectionsCollapsed: false,
      selectedSection: null,
      selectedItem: null,
      deleteValue: '',
    };
  }

  UNSAFE_componentWillMount() {
    this.getClientSectionsData();
  }

  collapseSections(collapsed) {
    this.setState({ allSectionsCollapsed: collapsed });
  }

  getClientSectionsData() {
    const { dataLoaded } = this.state;
    const { flags } = this.props;
    const {
      params: { clientId },
    } = this.props;

    const enableDocumentXrayDataFields = flags[FlagType.DocumentXrayDataFields];

    this.setState({ loading: true });

    getClientSectionsData(clientId, {
      includeXRayDetails: enableDocumentXrayDataFields,
    })
      .then((data) => {
        this.setState({
          sectionsData: data,
          loading: false,
          errorLoadingSectionsData: false,
        });
        if (!dataLoaded) {
          this.setState({ dataLoaded: true });
        }
      })
      .catch(() => {
        this.setState({ loading: false, errorLoadingSectionsData: true });
      });
  }

  handleShowModal(
    modal,
    sectionName,
    selectedItem = null,
    deleteField = false,
  ) {
    this.setState({
      modal,
      selectedSection: sectionName,
      selectedItem,
      deleteField,
    });
  }

  handleHideModal() {
    this.setState({
      modal: null,
      selectedItem: null,
    });
  }

  handleShowToast(type, text) {
    showToast(type, text);
  }

  getHeaderMenuItems = (section, sectionsData) => {
    const { selectedItem } = this.state;
    const hasSmartField = sectionsData.some((field) => !!field.is_smart);

    const items = [];

    items.push({
      content: 'Rename',
      id: `data_fields_field_action_rename?fieldId=${section.id}`,
      onClick: () => this.handleShowModal(MODAL_NAME, section, selectedItem),
    });

    if (!hasSmartField) {
      items.push({
        content: 'Delete',
        id: `data_fields_field_action_delete?fieldId=${section.id}`,
        onClick: () =>
          this.handleShowModal(MODAL_DELETE, section, selectedItem),
      });
    }

    return items;
  };

  renderTitleRightComponent(section, sectionsData) {
    const { classes } = this.props;

    return (
      <div>
        <button
          className={classes.addDataField}
          onClick={() =>
            this.handleShowModal(MODAL_CUSTOM_FIELD, section, null)
          }
        >
          <span>Add Data Field</span>
        </button>
        <span className={classes.dropdownWrapper}>
          <ActionsMenu
            id={`data_fields_field_item_actions?fieldId=${section.id}_menu`}
            items={this.getHeaderMenuItems(section, sectionsData)}
            align="end"
            smallIcon
            title="edit data field menu"
          />
        </span>
      </div>
    );
  }

  renderReorderSectionsModal() {
    const { sectionsData } = this.state;
    const sectionOrder = sectionsData.map((section) => {
      return {
        id: section.id,
        position: section.position,
        label: section.label,
      };
    });
    return (
      <EcModal
        modalType={MODAL_REORDER_SECTIONS}
        width="560px"
        sectionOrder={sectionOrder}
        handleSaveChange={this.handleSaveSectionsReoorderChange}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderSectionNameModal() {
    const { selectedSection } = this.state;

    let title;
    let labelText;
    let itemsCurrentName;
    let confirmButtonText;
    let handleNameChange;

    if (selectedSection) {
      labelText = 'SECTION NAME';
      title = 'Rename Section';
      confirmButtonText = 'Save';
      itemsCurrentName = selectedSection.label;
      handleNameChange = this.onSectionRename;
    } else {
      labelText = 'SECTION NAME';
      title = 'New Section';
      itemsCurrentName = '';

      confirmButtonText = 'Create Section';
      handleNameChange = this.onSectionCreate;
    }

    return (
      <EcModal
        modalType={MODAL_NAME}
        width="560px"
        title={title}
        labelText={labelText}
        itemsCurrentName={itemsCurrentName}
        confirmButtonText={confirmButtonText}
        handleNameChange={handleNameChange}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderDeleteSectionModalText() {
    const { selectedSection } = this.state;

    return (
      <div>
        Are you sure you want to delete&nbsp;
        <span>{selectedSection.label}</span>? All of the data fields inside the
        section will also be deleted. You won’t be able to undo this action.
        <p>Type in the word "DELETE" into the field below to confirm.</p>
      </div>
    );
  }

  renderDeleteFieldModalText() {
    const { selectedItem, deleteField } = this.state;

    if (!deleteField) {
      return null;
    }
    return (
      <div>
        Are you sure you want to delete&nbsp;
        <span>{selectedItem.label}</span>? You won’t be able to undo this
        action.
        <p>Type in the word "DELETE" into the field below to confirm.</p>
      </div>
    );
  }

  renderDeleteSectionModal() {
    const { deleteField, deleteValue } = this.state;

    return (
      <Modal
        isVisible
        title={deleteField ? 'Delete Field?' : 'Delete Section?'}
        children={
          <FormField
            description={
              deleteField
                ? this.renderDeleteFieldModalText()
                : this.renderDeleteSectionModalText()
            }
            input={TextInput}
            onChange={(value) => {
              this.setState({ deleteValue: value });
            }}
            value={deleteValue}
          />
        }
        primaryAction={{
          disabled: deleteValue !== 'DELETE',
          text: 'Delete',
          variant: 'danger',
          onClick: () => {
            deleteField ? this.onFieldDelete() : this.onSectionDelete();
            this.setState({ deleteValue: '' });
            this.handleHideModal();
          },
        }}
        onHide={this.handleHideModal}
        onCancel={this.handleHideModal}
      />
    );
  }

  onDataFieldCreate(data) {
    const {
      selectedSection: { id },
    } = this.state;
    createDataField(data, id)
      .then(() => {
        this.handleShowToast(SUCCESS, 'Field has been created.');
        this.setState({ dataAddedEditedMessage: true });
        this.getClientSectionsData();
      })
      .catch((error) =>
        this.handleShowToast(
          ERROR,
          error.response.data.detail ||
            'An error occurred while creating the new field.',
        ),
      );
  }

  onDataFieldEdit(data) {
    const {
      selectedItem,
      selectedSection: { id },
    } = this.state;
    const fieldId = selectedItem.id;
    updateDataField(data, id, fieldId)
      .then((data) => {
        this.handleShowToast(SUCCESS, 'The field has been updated.');
        this.setState({ dataAddedEditedMessage: true });

        // replace the updated field state immediately but also getClientSectionsData and update all sections state
        // to handle concurrency updates, e.g. 2 admins update the fields at the same time
        const newSectionsData = updateSectionField(
          this.state.sectionsData,
          this.state.selectedSection.id,
          data,
        );
        this.setState({
          sectionsData: newSectionsData,
        });

        this.getClientSectionsData();
      })
      .catch((error) =>
        this.handleShowToast(
          ERROR,
          error.response.data.detail ||
            'An error occurred while updating the field.',
        ),
      );
  }

  renderCustomFieldModal() {
    const { selectedSection, selectedItem } = this.state;
    const {
      params: { clientId },
    } = this.props;

    let title;
    let confirmButtonText;
    let handleDataFieldChange;

    if (selectedItem) {
      title = 'Edit Data Field';
      confirmButtonText = 'Save';
      handleDataFieldChange = this.onDataFieldEdit;
    } else {
      title = 'New Data Field';
      confirmButtonText = 'Add Data Field';
      handleDataFieldChange = this.onDataFieldCreate;
    }

    return (
      <EcModal
        modalType={MODAL_CUSTOM_FIELD}
        width="560px"
        title={title}
        clientId={clientId}
        selectedSection={selectedSection ? selectedSection.label : null}
        selectedItem={selectedItem}
        confirmButtonText={confirmButtonText}
        handleDataFieldChange={handleDataFieldChange}
        hideModal={this.handleHideModal}
      />
    );
  }

  renderMoveFieldModalText() {
    const { classes } = this.props;
    const { selectedItem } = this.state;

    return (
      <div className={classes.modalLabel}>
        Where do you want to move <b>{selectedItem.name}</b>?
      </div>
    );
  }

  renderMoveFieldModal() {
    const { selectedItem, selectedSection, sectionsData } = this.state;
    const sections = sectionsData.map((section) => {
      return {
        id: section.id,
        name: section.label,
      };
    });

    return (
      <EcModal
        modalType={MODAL_CUSTOM_FIELD_MOVE}
        width="560px"
        title="Move Field to Section"
        fieldId={selectedItem ? selectedItem.id : null}
        selectedSection={selectedSection ? selectedSection : null}
        sections={sections}
        text={this.renderMoveFieldModalText()}
        confirmButtonText="Move"
        handleActionClick={this.onSectionMove}
        hideModal={this.handleHideModal}
      />
    );
  }

  onFieldDelete() {
    const { selectedItem } = this.state;

    deleteDataField(selectedItem.id)
      .then(() => {
        this.handleShowToast(SUCCESS, 'Field has been deleted.');
        this.getClientSectionsData();
      })
      .catch((err) => this.handleShowToast(ERROR, err.message));
  }

  onSectionMove(fieldId, selectedId) {
    moveFieldToSection(fieldId, selectedId)
      .then(() => {
        this.handleShowToast(SUCCESS, 'Field has been moved.');
        this.getClientSectionsData();
      })
      .catch(() =>
        this.handleShowToast(
          ERROR,
          'An error occurred while moving the field.',
        ),
      );
  }

  onSectionDelete() {
    const { selectedSection } = this.state;

    deleteClientSectionData(selectedSection.id)
      .then(() => {
        this.handleShowToast(SUCCESS, 'Section has been deleted.');
        this.getClientSectionsData();
      })
      .catch(() =>
        this.handleShowToast(
          ERROR,
          'An error occurred while deleting the section.',
        ),
      );
  }

  onSectionCreate(label) {
    const {
      params: { clientId },
    } = this.props;

    createClientSectionData(label, clientId)
      .then(() => {
        this.handleShowToast(SUCCESS, 'New section has been added.');
        this.getClientSectionsData();
      })
      .catch((error) => {
        this.handleShowToast(
          ERROR,
          error?.response?.data?.label ||
            'An error occurred while creating the section.',
        );
      });
  }

  onSectionRename(label) {
    const { selectedSection } = this.state;

    updateClientSectionName(label, selectedSection.id)
      .then(() => {
        this.handleShowToast(SUCCESS, 'Section has been renamed.');
        this.getClientSectionsData();
      })
      .catch(() =>
        this.handleShowToast(
          ERROR,
          'An error occurred while renaming the section.',
        ),
      );
  }

  handleSaveSectionsReoorderChange(sectionOrder) {
    const {
      params: { clientId },
    } = this.props;
    const orderData = sectionOrder.map((section, index) => {
      return { id: section.id, position: index };
    });

    reorderClientSectionsData(clientId, orderData)
      .then(() => {
        this.handleShowToast(SUCCESS, 'Section orders have been updated.');
        this.getClientSectionsData();
      })
      .catch((err) => this.handleShowToast(ERROR, err.message));
  }

  handleFieldReorder(data, id) {
    updateDataFieldOrder(data, id)
      .then(() => {
        this.handleShowToast(SUCCESS, 'The field has been reordered.');
        this.getClientSectionsData();
      })
      .catch(() => {
        this.handleShowToast(
          ERROR,
          'An error occurred while reordering the field.',
        );
        this.getClientSectionsData();
      });
  }

  renderSectionTableData() {
    const { classes } = this.props;
    const { allSectionsCollapsed, sectionsData } = this.state;

    return sectionsData
      ? sectionsData.map((section) => {
          return (
            <Fragment key={section.id}>
              <EcCard
                title={section.label}
                includeCollapseExpand={true}
                allCollapsed={allSectionsCollapsed}
                contentStyles={contentStyles}
                titleRightComponent={this.renderTitleRightComponent(
                  section,
                  section.doc_info_fields,
                )}
              >
                <EcCustomFieldTable
                  section={section}
                  listItems={section.doc_info_fields}
                  handleShowModal={this.handleShowModal}
                  handleFieldReorder={this.handleFieldReorder}
                />
              </EcCard>
              <div className={classes.separator} />
            </Fragment>
          );
        })
      : null;
  }

  render() {
    const { classes } = this.props;
    const { modal, loading, dataLoaded, errorLoadingSectionsData } = this.state;

    const loadingContent =
      loading && !dataLoaded
        ? {
            isLoading: true,
            message: 'Loading contents',
          }
        : undefined;

    const placeholderContent =
      !loading && errorLoadingSectionsData
        ? { message: 'An error occurred while loading the data fields.' }
        : undefined;

    const nav = generateNav({
      current: {
        text: 'Data Fields',
        pathname: RoutePathType.AdminConsoleClientDataFields,
      },
      from: RoutePathType.AdminConsoleClient,
      params: {
        clientId: this.props.currentUser.client,
      },
    });
    const actions = [
      {
        text: 'Reorder Sections',
        onClick: () => this.handleShowModal(MODAL_REORDER_SECTIONS),
      },
      {
        text: 'Add Sections',
        onClick: () => this.handleShowModal(MODAL_NAME),
      },
    ];

    const moreActions = [
      {
        text: 'Collapse All Sections',
        onClick: () => {
          this.collapseSections(true);
        },
      },
      {
        text: 'Expand All Sections',
        onClick: () => {
          this.collapseSections(false);
        },
      },
    ];

    return (
      <PageLayout
        title="Data Fields"
        nav={nav}
        loadingContent={loadingContent}
        placeholderContent={placeholderContent}
        actions={actions}
        moreActions={moreActions}
      >
        <div>
          <div className={classes.subheadWrapper}>
            <span className={classes.subheader}>Sections</span>
          </div>
          {this.renderSectionTableData()}
          {modal === MODAL_REORDER_SECTIONS
            ? this.renderReorderSectionsModal()
            : null}
          {modal === MODAL_NAME ? this.renderSectionNameModal() : null}
          {modal === MODAL_DELETE ? this.renderDeleteSectionModal() : null}
          {modal === MODAL_CUSTOM_FIELD ? this.renderCustomFieldModal() : null}
          {modal === MODAL_CUSTOM_FIELD_MOVE
            ? this.renderMoveFieldModal()
            : null}
        </div>
      </PageLayout>
    );
  }
}

Page.propTypes = {
  classes: PropTypes.object,
};

export default injectSheet(styles)(
  withCurrentUser(withRouting(withFlags(Page))),
);
