import { useState } from 'react';

import { Nullable } from '~/types';

import { ModelAddScope } from './ModelAddScope';
import { ModelLibrary } from './ModelLibrary';
import { Step } from './types';

type Callback = () => void;

interface Props {
  onCancel: Callback;
}

export const XRayLibrary = ({ onCancel }: Props) => {
  const [step, setStep] = useState<Nullable<Step>>('model-library');

  const handleCancel = () => {
    setStep(null);
    onCancel();
  };

  switch (step) {
    case 'model-library':
      return (
        <ModelLibrary
          onCancel={handleCancel}
          onNext={() => setStep('add-scope')}
        />
      );
    case 'add-scope':
      return (
        <ModelAddScope
          onBack={() => setStep('model-library')}
          onCancel={handleCancel}
        />
      );
    case null:
      return null;
  }
};
