import React, { useMemo } from 'react';

import FilterChips from '~/components/Shared/Filters/FilterChips';
import {
  addFilters,
  clearFilter,
  removeFilter,
  updateFilter,
} from '~/components/Shared/Filters/Filters.utils';
import { Box, Button, Layout, LoadingShimmer } from '~/eds';
import { Filter } from '~/evifields';
import { FlagType, useFlag } from '~/flags';
import { FiltersCount, PilotId, SearchFilter } from '~/types';

import { CrossFilters } from '../cross-filters';
import { ManageFilters } from '../manage-filters';
import { sortFilters } from './Filters.utils';

type Props = {
  crossFilters?: Filter[];
  isLoading?: boolean;
  selectedFilters: Filter[];
  searchFilters: SearchFilter[];
  pinnedFiltersIds?: Array<PilotId | string>;
  onChange: (filters: Filter[]) => void;
  onChangeCrossFilters?: (crossFilters: Filter[]) => void;
  onFiltersReset?: () => void;
  onQueryButtonClick?: () => void;
  onUpdatePinnedFilters?: (
    updatedPinnedFilters: Array<PilotId | string>,
  ) => void;
};

export const Filters = ({
  crossFilters = [],
  isLoading = false,
  pinnedFiltersIds = [],
  selectedFilters,
  searchFilters,
  onUpdatePinnedFilters,
  onQueryButtonClick,
  onFiltersReset,
  onChange,
  onChangeCrossFilters,
}: Props) => {
  const hasTextDelimitedMultiselectFilter = useFlag(
    FlagType.TextDelimitedDocumentIdFilter,
  );

  const sortedFilters = useMemo(
    () => sortFilters(selectedFilters, pinnedFiltersIds),
    [selectedFilters, pinnedFiltersIds],
  );

  const getActionButtonTooltip = ({ selectedFiltersCount }: FiltersCount) => {
    if (selectedFiltersCount === 0) {
      return 'Please select at least one filter.';
    }
    return '';
  };

  return (
    <>
      <Layout justify="space-between">
        <Layout
          spacing="1"
          minW={0}
          wrap
          role="list"
          aria-label="pinned"
          align="center"
        >
          <Box mb={1}>
            <ManageFilters
              preset="add-filters-button"
              getActionButtonTooltip={getActionButtonTooltip}
              groupsOrder={[]}
              collapsible={true}
              onApply={(selectedFilterIds) =>
                onChange(addFilters(selectedFilters, selectedFilterIds))
              }
              onOpenQueryBuilder={onQueryButtonClick}
              pinnedFiltersIds={pinnedFiltersIds}
              searchFilters={searchFilters}
              onUpdatePinnedFilters={onUpdatePinnedFilters}
            />
          </Box>

          {isLoading ? (
            <>
              {Array.from({ length: 6 }).map((_, index) => (
                <LoadingShimmer
                  key={`filter_loading_${index}`}
                  height="26px"
                  width="200px"
                />
              ))}
            </>
          ) : (
            <FilterChips
              pinnedFiltersIds={pinnedFiltersIds}
              enableFilterViews={hasTextDelimitedMultiselectFilter}
              disableRemove={false}
              searchFilters={searchFilters}
              filters={sortedFilters}
              onRemoveFilter={(filterToRemove) =>
                onChange(removeFilter(selectedFilters, filterToRemove))
              }
              onUpdateFilter={(updatedFilter) =>
                onChange(updateFilter(selectedFilters, updatedFilter))
              }
            />
          )}

          {onChangeCrossFilters && (
            <CrossFilters
              searchFilters={searchFilters}
              filters={crossFilters}
              onChange={onChangeCrossFilters}
            />
          )}
        </Layout>
        <Layout alignSelf="flex-start">
          <Button
            iconPosition="left"
            icon="reload"
            variant="action"
            text="Reset Filters"
            onClick={() => {
              if (onFiltersReset) {
                onFiltersReset();
              } else {
                onChange(
                  selectedFilters
                    .map(clearFilter)
                    .filter((filter) =>
                      pinnedFiltersIds.includes(filter.fieldId),
                    ),
                );
              }
            }}
          />
        </Layout>
      </Layout>
    </>
  );
};
