import { RefObject, useEffect, useRef, useState } from 'react';

import { getDistanceToWindow } from '~/eds';

interface Props {
  /** The current max height of the element */
  currentMaxHeight: number;
  /** Flag to indicate if the element is inside a Popover. */
  isElementInPopover?: boolean;
}
/** This hook can be used to calculate the max height of a given element based on the distance to the edges of the screen.
 */
export const useMaxHeightBasedOnDistance = <T extends HTMLElement>({
  currentMaxHeight,
  isElementInPopover = true,
}: Props): [RefObject<T>, number | undefined] => {
  const containerRef = useRef<T>(null);
  const [maxH, setMaxH] = useState<number | undefined>();

  const calculateMaxHeight = () => {
    if (containerRef.current) {
      const { bottom } = getDistanceToWindow(containerRef.current);
      setMaxH(bottom < currentMaxHeight ? bottom : currentMaxHeight);
    }
  };
  useEffect(() => {
    if (isElementInPopover && containerRef?.current) {
      console.log('test');
      setTimeout(() => {
        //Timeout is necessary because there is a delay to get the correct positioning, duo to how Tippy(Popover) works
        calculateMaxHeight();
      }, 500);
    } else {
      calculateMaxHeight();
    }
  }, [containerRef, isElementInPopover]);

  return [containerRef, maxH];
};
