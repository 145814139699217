import { Endpoint } from '~/features/webhooks';
import {
  RotateSecretApiArg,
  RotateSecretResponseDto,
} from '~/redux/api/types/courier/endpoints';
import { courier } from '~/services';

interface Request {
  endpoint: Endpoint;
}

export const rotateEndpointKey = async ({
  endpoint,
}: Request): Promise<boolean> => {
  const params: RotateSecretApiArg = {
    endpointId: endpoint.id,
  };

  const response: RotateSecretResponseDto = await courier.patch(
    `/endpoints/${params.endpointId}/secrets/rotate`,
  );

  return Boolean(response);
};
