import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageLayout } from '~/eds';
import {
  ModelModal,
  ModelResults,
  ModelVersionInfo,
  usePollModelProgress,
  useResolveTargetEntityDetails,
} from '~/features/x-ray';
import {
  CreditBalance,
  useCreditStatusMessage,
} from '~/features/x-ray/billing';
import { useCreateAction } from '~/features/x-ray/hooks';
import { FlagType, useFlag } from '~/flags';
import { slices } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';

type TabKey = 'all' | 'published' | 'suspended' | 'drafts';

const Page = () => {
  const dispatch = useDispatch();
  const { location, navigate, getSearchParams, setSearchParams } = useRouting();
  const searchParam = getSearchParams();
  const enableXRayLibrary = useFlag(FlagType.DocumentXRayPrebuiltModels);
  const enableXrayNewPricingModel = useFlag(
    FlagType.DocumentXrayNewPricingModel,
  );

  const activeModelResultInfo = useSelector(
    slices.xRay.selectors.selectActiveModelResultInfo,
  );

  const [targetEntityDetails] = useResolveTargetEntityDetails(
    activeModelResultInfo?.targetEntity ?? null,
  );

  const activeTargetEntityDetails =
    activeModelResultInfo?.targetEntityDetails ?? targetEntityDetails;

  const creditStatusMessage = useCreditStatusMessage();

  const { progressChip } = usePollModelProgress({
    modelId: activeModelResultInfo?.id,
  });

  const { entityType, title } = resolvePageProps(location.pathname);

  const createAction = useCreateAction(entityType);
  const browseAction = {
    level: 'primary' as const,
    text: 'Browse Model Library',
    onClick: () => navigate(RoutePathType.XRayLibrary),
  };

  const panel = activeModelResultInfo
    ? {
        children: (
          <ModelVersionInfo
            enableTitle
            modelId={activeModelResultInfo.id}
            version={activeModelResultInfo.latestVersion}
          />
        ),
        chips: [progressChip],
        hidden: {
          isHidden: false,
          onHide: () =>
            dispatch(slices.xRay.actions.setActiveModelResultInfo(null)),
        },
        title: activeTargetEntityDetails.label,
        width: 'm' as const,
      }
    : undefined;

  const tabs = () => {
    const getModelResults = (tabKey: TabKey) => {
      return (
        <ModelResults
          tabKey={tabKey}
          entityType={entityType}
          onShowResult={(modelResult) =>
            dispatch(
              slices.xRay.actions.setActiveModelResultInfo({
                id: modelResult.id,
                latestVersion: modelResult.latestVersion,
                targetEntity: modelResult.targetEntity,
              }),
            )
          }
        />
      );
    };

    const tabs = [
      {
        label: 'All models',
        panel: getModelResults('all'),
        value: 'all',
      },
      {
        label: 'Published',
        panel: getModelResults('published'),
        value: 'published',
      },
      {
        label: 'Suspended',
        panel: getModelResults('suspended'),
        value: 'suspended',
      },
      {
        label: 'Drafts',
        panel: getModelResults('drafts'),
        value: 'drafts',
      },
    ];

    return enableXrayNewPricingModel
      ? {
          tabs: tabs.map((tab) => tab),
          selectedTab: (searchParam.tab as string) ?? 'all',
          onSelectTab: (updatedTab: string) => {
            setSearchParams({ tab: updatedTab });
          },
        }
      : undefined;
  };

  return (
    <PageLayout
      actions={
        enableXRayLibrary
          ? [{ ...createAction, level: 'secondary' }, browseAction]
          : [createAction]
      }
      headerCalloutContent={<CreditBalance />}
      panel={panel}
      statusMessage={creditStatusMessage}
      title={title}
      tabs={tabs()}
    >
      {!enableXrayNewPricingModel && (
        <ModelResults
          entityType={entityType}
          onShowResult={(modelResult) =>
            dispatch(
              slices.xRay.actions.setActiveModelResultInfo({
                id: modelResult.id,
                latestVersion: modelResult.latestVersion,
                targetEntity: modelResult.targetEntity,
              }),
            )
          }
        />
      )}
      <ModelModal />
    </PageLayout>
  );
};

export default Page;

const resolvePageProps = (
  pathname: string,
): {
  entityType: 'field';
  title: string;
} => {
  switch (pathname) {
    case RoutePathType.AutomationHubFields:
    default:
      return {
        entityType: 'field',
        title: 'Automation Hub',
      };
  }
};
