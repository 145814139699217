export const EXPORT_DUPLICATED_TEXT =
  'This export is already processing. It may take a few minutes.';
export const EXPORT_COMPLETED_TEXT =
  'You will receive an email when the export is complete.';
export const EXPORT_DEFAULT_TEXT = 'Export Excel';
export const MIN_SEARCH_LENGTH = 2;

export const STATIC_COLUMNS = {
  ticket: ['name_Ticket'],
  document: ['name_Document', 'document_handler_id_Document'],
};
export const DEFAULT_SORT_BY = {
  document: { id: 'name_Document', desc: false },
  ticket: { id: 'name_Ticket', desc: false },
};
