import React, { useState } from 'react';
import { ActionType } from 'react-table';

import { ExportExcel } from '~/components/Shared/ExportExcel';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { EXPORT_DEFAULT_TEXT } from '~/constants/search';
import { ContentContainer, Table, types } from '~/eds';
import { FieldId } from '~/evifields';
import { FlagType, useFlag } from '~/flags';
import { useEvisearchColumns } from '~/hooks';
import { SearchItem, SearchResult, SearchType } from '~/redux/api/methods';
import { Query, SearchFilter } from '~/types';

import { getStaticColumns } from './ResultsTable.utils';

type Props = {
  data?: SearchResult;
  entity: SearchType;
  id?: string;
  enablePersistedTable?: boolean;
  isLoading?: boolean;
  query: Query;
  searchFilters: Record<FieldId, SearchFilter>;
  state: Partial<types.TableState>;

  getBulkActions?: (tableState: types.TableState) => types.Action[];
  onRowDetailsClick?: (row: SearchItem) => void;
  onSaveView?: () => void;
  onLoadView?: () => void;
  onSetColumnOrder?: (columnOrder: string[]) => void;
  onUpdateState: (state: types.TableState, action?: ActionType) => void;
};

export const ResultsTable = ({
  data,
  entity,
  id = 'default-search-table-id',
  enablePersistedTable,
  isLoading,
  query,
  searchFilters,
  state,

  getBulkActions,
  onRowDetailsClick,
  onLoadView,
  onSaveView,
  onSetColumnOrder,
  onUpdateState,
}: Props) => {
  const TableComponent = enablePersistedTable ? PersistedTable : Table;
  const enableSelectRows = entity === 'document';
  const staticColumns = getStaticColumns(entity);
  const { columns, columnGroups } = useEvisearchColumns({
    staticColumns,
    searchFilters,
  });
  const [showExportExcel, setShowExportExcel] = useState(false);
  const hasExportExcel = useFlag(FlagType.DashboardsExportExcel);

  // TODO include: const isDuplicateExport = data?.meta?.is_duplicate_export;
  const actions = hasExportExcel
    ? [
        {
          icon: 'file-spreadsheet',
          label: EXPORT_DEFAULT_TEXT,
          disabled: false,
          onClick: () => {
            setShowExportExcel(true);
          },
        },
      ]
    : [];

  const placeholderContent =
    data && !data.results?.length ? { title: 'No records found' } : undefined;
  return (
    <ContentContainer placeholderContent={placeholderContent}>
      <>
        <TableComponent
          context={`ResultsTable:${id}`}
          name="search result"
          totalCount={data?.meta?.total ?? 0}
          columns={columns}
          columnGroups={columnGroups}
          data={data?.results ?? []}
          actions={actions}
          isLoading={isLoading}
          state={state}
          getBulkActions={getBulkActions}
          onUpdate={onUpdateState}
          onSetColumnOrder={onSetColumnOrder}
          rowDetails={
            onRowDetailsClick ? { onClick: onRowDetailsClick } : undefined
          }
          onLoadView={onLoadView}
          onSaveView={onSaveView}
          options={{
            enableExportXlsx: false,
            enablePageSizeSelect: true,
            enableSelectRows,
          }}
          reactTableOptions={{
            autoResetSortBy: false,
            disableSortRemove: true,
            manualSortBy: true,
          }}
        />
        <ExportExcel
          entity={entity}
          query={query}
          isVisible={showExportExcel}
          onHide={() => setShowExportExcel(false)}
          totalDocs={data?.meta?.total}
          totalDupsAndDocs={data?.meta?.total}
          // TODO: Enable once export excel options supports evisearch ids
          // selectedFieldIds={state.columnOrder}
        />
      </>
    </ContentContainer>
  );
};
