import { get } from 'lodash';

import { SearchItem, SearchType } from '~/redux/api/methods';
import { RoutePathType } from '~/routing';
import { HandlerName } from '~/utils/table';

export const getDocumentNameColumn = (document: SearchItem) => {
  const handlers = get(
    document,
    'selected_field_values.document_handler_id_Document.value_structured',
    [],
  );
  return handlers?.length ? (
    <HandlerName
      onNavigate={() => {
        // TODO: Implement when new search navigation hook is ready
      }}
      handlers={handlers}
      onClick={() => console.log('click')}
    />
  ) : (
    'Deleted document'
  );
};

export const getStaticColumns = (entity: SearchType) => {
  const documentColumn = [
    {
      key: 'name_Document',
      title: 'Name',
      minWidth: 'm',
      renderCell: getDocumentNameColumn,
    },
  ];

  const ticketWorkflowColumn = [
    {
      key: 'name_Ticket',
      title: 'Name',
      minWidth: 'm',
      cellType: 'link',
      mapCellProps: (item: SearchItem) => ({
        text: get(item, 'selected_field_values.name_Ticket.value[0]'),
        pathname:
          item.type === 'ticket'
            ? RoutePathType.WorkflowTicketsTicket.replace(':ticketId', item.id)
            : undefined,
        state: { isRedirect: true },
      }),
    },
  ];

  switch (entity) {
    case 'document':
      return documentColumn;

    case 'ticket':
    case 'workflow':
      return ticketWorkflowColumn;

    default:
      return [];
  }
};
