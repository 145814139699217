import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ContentContainer, EmptyPage, Tabs } from '~/eds';
import { FlagType, useFlag } from '~/flags';
import { api, slices } from '~/redux';
import { useRouting } from '~/routing';

import { AttemptModal } from './Modals/AttemptModal';
import { EndpointModal } from './Modals/EndpointModal';
import { AttemptDetails } from './Panels/AttemptDetails';
import { EndpointDetails } from './Panels/EndpointDetails';
import { Attempts } from './Tabs/Attempts';
import { Endpoints } from './Tabs/Endpoints';
import { TabType } from './types';

export const Webhooks = () => {
  const dispatch = useDispatch();
  const { getSearchParams, setSearchParams } = useRouting();
  const searchParam = getSearchParams();
  const enableNewIntegrationPage = useFlag(FlagType.NewIntegrationsPage);

  const {
    data = { results: [], total: 0 },
    isLoading: isLoadingEndpoints,
    error: isErrorEndpoints,
  } = api.endpoints.getEndpoints.useQuery({});

  useEffect(() => {
    if (data) {
      dispatch(slices.webhooks.actions.setAllEndpoints(data.results));
    }
  }, [data]);

  //TODO: This useEffect is a temporary fix to set the endpoint tab as default for deprecated integrations page
  useEffect(() => {
    if (!searchParam.secondaryTab) {
      setSearchParams({ secondaryTab: 'endpoints' });
    }
  }, [searchParam]);

  return (
    <>
      <ContentContainer
        loadingContent={{ isLoading: isLoadingEndpoints }}
        placeholderContent={
          isErrorEndpoints
            ? {
                message: 'Error loading webhooks, please try again',
                image: 'error-page-error',
              }
            : undefined
        }
      >
        {data.total > 0 ? (
          <Tabs<TabType>
            actions={
              searchParam.secondaryTab === 'endpoints' &&
              !enableNewIntegrationPage
                ? [
                    {
                      text: 'Add Endpoint',
                      level: 'primary',
                      onClick: () => {
                        dispatch(
                          slices.webhooks.actions.setActiveEndpointType('add'),
                        );
                      },
                    },
                  ]
                : []
            }
            tabs={[
              {
                label: 'Endpoints',
                value: 'endpoints',
                panel: <Endpoints />,
              },
              {
                label: 'Attempts',
                value: 'attempts',
                panel: <Attempts />,
              },
            ]}
            selectedTab={searchParam.secondaryTab}
            onSelectTab={(tab) => {
              if (tab === 'endpoints') {
                dispatch(slices.webhooks.actions.resetFilters());
                dispatch(slices.webhooks.actions.resetAttemptConfiguration());
              } else if (tab === 'attempts') {
                dispatch(slices.webhooks.actions.resetEndpointConfiguration());
              }
              setSearchParams({ secondaryTab: tab });
            }}
          />
        ) : (
          <EmptyPage
            preset="no-webhooks"
            resolveAction={{
              level: 'primary',
              onClick: () =>
                dispatch(slices.webhooks.actions.setActiveEndpointType('add')),
              text: 'Add Endpoint',
            }}
          />
        )}
      </ContentContainer>
      <EndpointDetails />
      <AttemptDetails />
      <EndpointModal />
      <AttemptModal />
    </>
  );
};
