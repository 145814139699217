import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Filters_DEPRECATED } from '~/components/Shared/Filters_DEPRECATED';
import { Field } from '~/components/Shared/Filters_DEPRECATED/types';
import { Filter } from '~/evifields';
import { api, slices } from '~/redux';

import { getFields } from '../utils';

interface Props {
  filters: Filter[];
  onChange: (updatedFilters: Filter[]) => void;
}

export const AttemptsFilters = ({ filters, onChange }: Props) => {
  const selectAllEndpoints = useSelector(
    slices.webhooks.selectors.selectAllEndpoints,
  );

  const {
    data: eventGroups = [],
    isLoading: isLoadingEvents,
  } = api.endpoints.getEventFields.useQuery(undefined);

  const fields: Record<string, Field> = useMemo(
    () =>
      getFields({
        eventGroups,
        endpoints: selectAllEndpoints,
      }),
    [eventGroups, isLoadingEvents, selectAllEndpoints],
  );

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Filters_DEPRECATED
      fields={fields}
      filters={filters}
      groups={[]}
      options={{
        showInlineManageFilters: true,
        disableManageFilters: true,
        disableRemoveFilter: true,
      }}
      onChange={onChange}
    />
  );
};
